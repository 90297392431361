import React from "react";
import { Grid, Typography } from "@material-ui/core";

import useStyles from "../styles/CreationOpportunity";
import { useSelector } from "react-redux";
import Blocks from "editorjs-blocks-react-renderer";

const OpportunityTabStages = (props) => {
  const classes = useStyles();

  const stages = useSelector((state) => state.formReducer.customData?.stages);

  return (
      <Grid container className={classes.creationContainerBody}>
          <Grid item className={classes.creationBody}>
          {stages?.map((stage, index) => {
            return (
              <Grid key={stage.id} className={classes.opportunityitemStage}>
                <Typography variant="h2">{stage.title}</Typography>
                <Blocks data={JSON.parse(stage.body)} />
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default OpportunityTabStages;
