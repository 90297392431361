import * as types from "../actions";

const initState = {
  message: null,
  isOpen: false,
};

export default function successReducer(state = initState, action) {
  const { message } = action;

  switch (action.type) {
    case types.SET_MESSAGE:
      return {
        ...state,
        message: message,
        isOpen: true,
      };
    case types.HIDE_MESSAGE:
      return {
        ...state,
        message: null,
        isOpen: false,
      };

    default:
      return state;
  }
}
