import React from "react";

import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import arrow from "../../../assets/header_arrow.svg";
import { formGetHeaderDataAction } from "../../../actions/formActions";
import DividerWithText from "../../../components/DividerWithText";
import clsx from "clsx";
import useStyles from "./styles";

const Teams = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      formGetHeaderDataAction({ endpoint: `teams/i-member`, name: "teams" })
    );
  }, [dispatch]);

  const teams = useSelector((state) => state.formReducer?.header?.teams);
  const router = useSelector((state) => state.router);

  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const handleClose = (event) => {

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div className={classes.headerSubLink}>
      <Button
        ref={anchorRef}
        onClick={(e) => {
          setOpen((open) => !open);
        }}
        color="secondary"
      >
        Teams
        <img src={arrow} alt="arrow" />
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9999 }}
        placement="top-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.headerSubMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <DividerWithText position="left">My teams</DividerWithText>

                  {teams?.map((t) => (
                    <MenuItem
                      noWrap
                      key={t.id}
                      className={
                        router?.location?.pathname ===
                        `/calls/${t.opportunity_id}/teams/${t.id}`
                          ? "active"
                          : null
                      }
                    >
                      <Link
                        to={`/calls/${t.opportunity_id}/teams/${t.id}`}
                      >
                        {t.name}
                      </Link>
                    </MenuItem>
                  ))}
                  <MenuItem
                    noWrap

                    className={clsx(
                      router?.location?.pathname === "/teams" ? "active" : null,
                      "discover"
                    )}
                  >
                    <Link to="/teams">Discover teams</Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Teams;
