import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    containerDiscover: {
        maxWidth: 910,
        margin: "0 auto",
        padding: "55px 23px 22px",
        "& *": {
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
        },
        "& h1": {
            fontSize: "44px",
            lineHeight: "54px",
        },
        [theme.breakpoints.up("md")]: {
            padding: "150px 0 22px",
        },
    },
    titleDiscover: {
        [theme.breakpoints.down("xs")]: {
            "& .buttons": {
                justifyContent: "end",
                paddingTop: 14,
            }
        },
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            justifyContent: "space-between"
        },
    },
    filterDiscover: {
        padding: "31px 0 34px",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            "& #toggleFilterContainer": {
                position: "absolute",
                top: 31,
                right: 0,
            }
        },
        [theme.breakpoints.up("md")]: {
            padding: "80px 0 60px",
        },
    },
    filterDiscoverTitle: {
        color: "#636363",
        marginBottom: 23,
        [theme.breakpoints.up("md")]: {
            marginBottom: 30,
        },
    },
    discoverSelect: {
        "& .MuiInputLabel-root": {
            left: 16,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            background: "#fff"
        },
        "& .MuiSelect-select": {
            zIndex: 3,
            paddingLeft: 16,
            fontSize: 16,
        },
        "& .MuiSelect-select:focus": {
            background: "transparent",
        },
        "& .MuiSelect-icon": {
            width: 24,
            height: 24,
            fill: "#000",
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #BCBCBC",
            height: "100%"
        },
        "& .MuiInput-underline::after": {
            borderBottom: 0,
            height: "100%",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "1px solid #BCBCBC",
        },
        "& .MuiSelect-nativeInput": {
            padding: "14px 16px 12px",
            background: "#fff",
            fontFamily: "Gilroy",
            fontWeight: 500,
            boxSizing: "border-box"
        },
        "& .MuiInputBase-root": {
            paddingTop: 8,
            paddingBottom: 6
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "100%",
        },
    },

    searchNotFound: {
        "& *": {
            color: "#BCBCBC",
            fontSize: 32,
            lineHeight: "35px",
            fontWeight: 400,
            [theme.breakpoints.up("md")]: {
                fontSize: 44,
                lineHeight: "54px",
            },
        }
    },
    searchNotFoundTitle: {
        maxWidth: 600,
        color: "#000",
        paddingBottom: 22,
    },

    searchResult: {
        background: "#F5F5F5",
        position: "relative",
        padding: "30px 23px 50px",
        alignItems: "normal",
        [theme.breakpoints.up("sm")]: {
            padding: "30px 32px 50px",
        },
    },
}));

export default useStyles;