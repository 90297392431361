import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    centerForm: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 50,
        background: "#fff",
        flexGrow: 1,
        overflowX: "hidden"
    },
    selectHeader: {
        display: "flex",
        alignItems: "flex-start",
        paddingLeft: 23,
        paddingRight: 23,
        paddingTop: 31,
        position: "relative",
        flexDirection: "column-reverse",
        [theme.breakpoints.up("sm")]: {
            paddingTop: 60,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 148,
            paddingRight: 148,
            paddingTop: 135,
        },
    },
    selectHeaderText: {
        color: "#000",
        lineHeight: "35px",
        fontSize: "32px",
        marginTop: 18,
        [theme.breakpoints.up("sm")]: {
            marginTop: 0,
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "52px",
            lineHeight: "64px",
        },
    },
    selectHeaderObj: {
        maxWidth: "248px",
        transform: "rotate(210deg)  scale(-1, 1)",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "auto",
        },
        [theme.breakpoints.up("sm")]: {
            position: "absolute",
            left: 340,
            top: 20,
        },
        [theme.breakpoints.up("md")]: {
            left: 700,
            top: 110,
        },
    },
    paperForm: {
        width: "100%",
        "& > div": {
          display: "none",
        },
        "& .buttons": {
            paddingTop: 70,
            paddingBottom: 20,
        },
        "& .buttons button": {
            minWidth: 241,
            height: 56,
            fontSize: 24,
            textTransform: "uppercase",
            fontWeight: 600,
        },
        [theme.breakpoints.down("xs")]: {
            "& .buttons": {
                display: "block",
                textAlign: "center",
            },
            "& .buttons button + button ": {
                marginTop: 20,
            },
        },
        [theme.breakpoints.up("md")]: {
            "& .buttons": {
                paddingTop: 100,
                paddingBottom: 50,
            },
        },
    }
}));

export default useStyles;