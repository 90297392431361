import React, { useState, useEffect } from "react";
import {
  Tab,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import TeamTabMembers from "../components/TeamTabMembers";
import TeamTabBrief from "../components/TeamTabBrief";
import TeamTabBoard from "../components/TeamTabBoard";
import TeamTabChat from "../components/TeamTabChat";
import TeamTabStages from "../components/TeamTabStages";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";

import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  formGetDataAction,
  formGetCustomDataAction,
} from "../actions/formActions";

import { Redirect } from "react-router-dom";
import useStyles from "../styles/Team";
import useNotfoundStyles from "../styles/NotFound";

import { Helmet } from "react-helmet";
import { push } from "connected-react-router";
import Settings from "../assets/settings.svg";
import TeamModalRating from "../components/TeamModalRating";
import TeamModalDone from "../components/TeamModalDone";
import TeamModalInfo from "../components/TeamModalInfo";
import { useHistory } from "react-router-dom";

const Team = (props) => {
  const classes = useStyles();
  const classesNotfound = useNotfoundStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);

  let { opportunityId, teamId } = useParams();

  const router = useSelector((state) => state.router);
  const data = useSelector((state) => state.formReducer.data);
  const loading = useSelector((state) => state.formReducer.loading);

  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [openDoneModal, setOpenDoneModal] = useState(false);

  const [value, setValue] = React.useState(null);
  const [tabs, setTabs] = React.useState([]);






  useEffect(() => {

    let tabs = user?.id === data?.opportunity?.owner?.id && user?.id !== data?.owner?.id
      ? [
        { label: "Chat", value: "chat", hideRejected: false },
        { label: "Brief", value: "brief", hideRejected: false },
        { label: "Board", value: "board", hideRejected: true },
        { label: "Stages", value: "stages", hideRejected: true },
      ]
      : [
        { label: "Members", value: "members", hideRejected: false },
        { label: "Brief", value: "brief", hideRejected: false },
        { label: "Board", value: "board", hideRejected: true },
        { label: "Chat", value: "chat", hideRejected: false },
        { label: "Stages", value: "stages", hideRejected: true },
      ];

    tabs = data?.status?.code === "rejected" ? tabs.filter(t => t.hideRejected === false) : tabs;
    let initialTabValue = router.location.hash
      ? tabs.some((t) => `#${t.value}` === router.location.hash)
        ? router.location.hash.substring(1)
        : tabs?.[0]?.value
      : tabs?.[0]?.value;
    setValue(initialTabValue);
    setTabs(tabs);
  }, [router.location.hash, data?.opportunity?.owner?.id, data?.owner.id, user?.id, data?.status?.code])




  useEffect(() => {
    /*     dispatch(
          formGetDataAction({
            endpoint: `opportunities/${opportunityId}/teams/${teamId}`,
          })
        ); */

    dispatch(
      formGetCustomDataAction({
        endpoint: `classifiers-stage-statuses`,
        name: "stageStatuses",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `classifiers-team-statuses`,
        name: "teamStatuses",
      })
    );


    setOpenDoneModal((openModalDone) =>
      ["closed", "rejected"].includes(data?.status?.code) ? true : false
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `team-tab-stages-statuses/${teamId}`,
        name: "tabStageStatuses",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `team-tab-members-statuses/${teamId}`,
        name: "tabMembersStatuses",
      })
    );

  }, [data, dispatch, opportunityId, teamId]);

  useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: `opportunities/${opportunityId}/teams/${teamId}`,
      })
    );
  }, [teamId, router.location.key, opportunityId, dispatch]);


  let roles = [...new Set(data?.requests?.map((r) => r.role?.id))]?.map(
    (id) => data?.requests?.find((request) => request?.role?.id === id)?.role
  );

  let applications = roles?.reduce((acc, cur) => {
    let users = data?.requests
      ?.filter((r) => r?.role?.id === cur?.id)
      ?.map((u) => {
        const { role, ...rest } = u;
        return rest;
      });
    return [...acc, { ...cur, requests: users }];
  }, []);

  const handleChange = (event, newValue) => {
    setValue((value) => newValue);

    history.push({
      pathname: router.location.pathname,
      hash: newValue,
    });
  };

  if (loading || !data) {
    return <CircularProgress className="main-progress-bar" />;
  }

  if (
    data &&
    !data?.members?.map((m) => m?.member?.id).includes(user?.id) &&
    data?.opportunity?.created_by?.id !== user?.id &&
    data?.owner?.id !== user?.id
  ) {
    return <Redirect to="/profile" />;
  }

  let ratingNeeded =
    data?.owner?.id === user?.id &&
    data?.status?.code === "closed" &&
    data?.members?.filter((m) => m.id !== 0)?.some((m) => !m?.rate);


  return (
    <>
      <Helmet>
        <title>{`${data?.name}| Teams | weCreators`}</title>
      </Helmet>
      <div className={classes.teamContainer}>
        <Typography variant="h1" className={classes.teamContainerTitle}>
          {data?.name}
          {data.created_by.id === user.id ? (
            <>
              <Button
                className={classes.teamEditButton}
                disabled={!["draft","recruitment"].includes(data?.status?.code)}
                onClick={() =>
                  dispatch(
                    push(
                      `/calls/${data?.opportunity?.id}/teams/${data?.id}/edit`
                    )
                  )
                }
              >
                <img src={Settings} alt="Settings" />
              </Button>
            </>
          ) : null}
          <div className={classes.teamStatus}>{data?.status?.name}</div>
        </Typography>
        <TabContext value={value ? value : tabs?.[0]?.value}>
          <TabList
            onChange={handleChange}
            aria-label="simple tabs example"
            className={classes.teamTabs}
          >
            {tabs.map((t) => (
              <Tab key={t.value} label={t.label} value={t.value} />
            ))}
          </TabList>
          <Grid className={classes.teamTabPanel}>
            <TabPanel value="members">
              <TeamTabMembers
                teamStatus={data?.status}
                openRoles={data?.unusedRoles}
                teamOwner={data?.owner}
                teamId={data?.id}
                opportunity={data?.opportunity}
                members={data?.members}
                pending={data?.pending}
                applications={applications}
              />
            </TabPanel>
            <TabPanel value="brief">
              <TeamTabBrief data={data?.opportunity} />
            </TabPanel>
            <TabPanel value="board">
              <TeamTabBoard teamId={data?.id} members={data?.members} />
            </TabPanel>
            <TabPanel value="chat">
              <TeamTabChat
                team={data}
                teamOwner={data?.owner}
                opportunityOwner={data?.opportunity?.owner}
              />
            </TabPanel>
            <TabPanel value="stages">
              <TeamTabStages
                team={data}
                teamOwner={data?.owner}
                opportunityOwner={data?.opportunity?.owner}
              />
            </TabPanel>
          </Grid>
        </TabContext>
        {(ratingNeeded || openDoneModal) && user?.id !== data?.opportunity?.owner?.id && data?.id === data?.opportunity?.winner_team_id ? (
          <Grid>
            <div className={classesNotfound.yellowLineTeam} />
            <div className={classesNotfound.purpleLineTeam}>
              <div className={classesNotfound.yellowLineContainer}>
                <span>CONGRATULATIONS</span>
                <span>CONGRATULATIONS</span>
                <span>CONGRATULATIONS</span>
                <span>CONGRATULATIONS</span>
              </div>
            </div>
          </Grid>
        ) : null}
        <TeamModalRating
          opportunityId={opportunityId}
          teamId={teamId}
          ratingNeeded={ratingNeeded}
          setOpenRatingModal={setOpenRatingModal}
          members={data?.members?.filter((m) => m.id !== 0)}
        />
        <TeamModalDone
          setOpenDoneModal={setOpenDoneModal}
          openDoneModal={openDoneModal}
          ratingNeeded={ratingNeeded}
          winnerId={data?.opportunity?.winner_team_id}
          teamId={data?.id}
          opportunityOwner={data?.opportunity?.owner?.id}
          team={data}
        />
        <TeamModalInfo  members={data?.members}/>
      </div>
    </>
  );
};

export default Team;
