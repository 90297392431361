import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    teamContainer: {
        background: "#F5F5F5",
        boxSizing: "border-box",
        fontSize: "14px",
        lineHeight: "16px",
        position: "relative",
        minHeight: "calc(100vh - 287px)",
        overflow: "hidden",
        "& *": {
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        [theme.breakpoints.up("sm")]: {
            minHeight: "calc(100vh - 247px)",
        },
        [theme.breakpoints.up("md")]: {
            minHeight: "calc(100vh - 108px)",
        },
    },
    teamContainerTitle: {
        fontSize: "32px",
        lineHeight: "39px",
        background: "#fff",
        padding: "27px 23px 34px",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up("md")]: {
            padding: "27px 32px 34px",
        },
    },
    teamEditButton: {
        marginLeft: 15,
        padding: 0,
        minWidth: "auto",
        "&:hover": {
            background: "transparent",
        }
    },
    teamStatus: {
        position: "relative",
        fontSize: 12,
        lineHeight: "22px",
        left: 0,
        top: 0,
        marginLeft: 30,
        color: "#6233C0",
        fontWeight: 600,
    },
    teamTabs: {
        minHeight: 30,
        background: "#fff",
        padding: "0 23px",
        "& .MuiTab-root": {
            minWidth: "auto",
            marginRight: 30,
            padding: "0 0 14px",
            minHeight: 30,
            fontSize: 14,
            lineHeight: "18px",
            textTransform: "none",
            color: "#BCBCBC",
            opacity: 1,
        },
        "& .MuiTab-root.Mui-selected": {
            color: "#000",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#000"
        },
        [theme.breakpoints.up("md")]: {
            padding: "0 32px",
        },
    },
    teamTabPanel: {
        "& .MuiTabPanel-root": {
            padding: 0,
        }
    },
    teamMembers: {
        background: "#F5F5F5",
        padding: "16px 23px 66px",
        alignItems: "normal",
        [theme.breakpoints.up("md")]: {
            padding: "16px 32px 66px",
        },
    },
    teamApplications: {
        [theme.breakpoints.down("md")]: {
            paddingTop: 20,
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 20,
        },
    },
    titleMembers: {
        color: "#636363",
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: 10,
    },
    paperMembers: {
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
        borderRadius: 8,
        marginBottom: 20,
        padding: "30px 24px",
    },
    addMembers: {
        textTransform: "none",
        padding: " 0 0 30px",
        "&:hover": {
            background: "transparent"
        },
        "& .MuiButton-label": {
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 400,
        },
        "& svg": {
            width: 24,
            height: 24,
            marginRight: 10,
        }
    },
    teamMember: {
        padding: "10px 0 20px",
    },
    teamMemberUser: {
        flexWrap: "initial",
        "& div:first-child": {
            height: 40,
        },
        "& .default-profile-avatar-container, & img": {
            width: 40,
            height: 40,
            minWidth: 40,
        },
        "& .default-profile-avatar-container p": {
            fontSize: "20px",
            lineHeight: "24px",
        },
    },
    teamModalUsersContainer: {
        maxHeight: 220,
        overflowY: "scroll",
        scrollbarWidth: "thin",
        scrollbarColor: "#000 #fff",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#000",
        }
    },
    teamModalItemUser: {
        paddingBottom: 15,
        "& .MuiRating-root": {
            paddingLeft: 16,
        }
    },
    teaMemberRating: {
        display: "flex",
        flexWrap: "wrap",
    },
    teamMemberUserName: {
        paddingLeft: 16,
        height: "auto!important",
        "& a": {
          paddingLeft: 0,
        },
        "& .MuiTypography-body1": {
            fontSize: "18px",
            lineHeight: "22px",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiTypography-body2": {
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            color: "#636363",
            "& span": {
                color: "#6233C0",
                fontSize: "12px",
                lineHeight: "16px",
                fontWeight: 400,
                paddingLeft: 7,
            }
        },
    },
    teamMemberEmail: {
        "& .MuiTypography-body1": {
            fontSize: 14,
            lineHeight: "16px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "20px 0",
        },
    },
    teamMemberRole: {
        "& .MuiTypography-body1": {
            fontSize: 14,
            lineHeight: "16px",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "center",
        },
    },
    teamMemberRemove: {
        textAlign: "right",
        "& button": {
            padding: 0,
        },
        "& button:hover": {
            background: "transparent",
        },
        "& button:hover .MuiButton-label": {
            color: "#000"
        },
        "& button .MuiButton-label": {
            color: "#BCBCBC",
            fontWeight: 600,
            fontSize: 14,
            lineHeight: "16px",
            textTransform: "none",
        }
    },
    paperApplication: {
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
        borderRadius: 8,
        marginBottom: 20,
        "&:before": {
            display: "none"
        },
    },
    applicationTitle: {
        fontSize: "18px",
        lineHeight: "24px",
        "& span": {
            color: "#636363",
            paddingLeft: 12,
            fontSize: "14px",
            lineHeight: "16px",
        }
    },
    applicationSummary: {
        padding: "30px 24px",
        minHeight: "auto",
        "& svg": {
            color: "#000",
            fontSize: 22,
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
        "&.Mui-expanded": {
            minHeight: "auto",
        },
        "& .MuiIconButton-root": {
            padding: 0,
        },
    },
    applicationDetails: {
        padding: "30px 24px 40px",
        background: "#F5F5F5",
        flexDirection: "column",
        "& .MuiAccordionSummary-root": {
            padding: 0,
        },
        "& .MuiIconButton-root": {
            padding: 0,
        },
        "& svg": {
            color: "#000",
            fontSize: 22,
        },
        "& .buttons": {
            margin: "10px 0 0",
            display: "block",
        },
        "@media (min-width:1280px) and (max-width:1520px)": {
            "& .buttons button": {
                minWidth: 110,
            },
        },
        [theme.breakpoints.down("sm")]: {
            "& .buttons button": {
                margin: "10px 0 0",
                width: "100%",
            },
        },
        [theme.breakpoints.up("md")]: {
            "& .buttons": {
                display: "flex",
                margin: "20px 0 0",
                justifyContent: "left",
            },
            "& .buttons button": {
                margin: "0 20px 0 0",
            },
        },
    },
    applicationUser: {
        background: "transparent",
        boxShadow: "none"
    },
    teamMemberLetter: {
        color: "#636363",
        lineHeight: "22px",
        paddingTop: 20,
    },
    applicationUserSummary: {
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
        "&.Mui-expanded": {
            minHeight: "auto",
        }
    },
    applicationDetailsUser: {
        padding: 0,
    },
    applicationUserContainer: {
        paddingTop: 50,
        "&:first-child": {
            paddingTop: 0,
        }
    },
    modalAddMember: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#fff",
        padding: "95px 20px 60px",
        textAlign: "center",
        overflowY: "scroll",
        "& .buttons": {
            width: "100%",
            paddingTop: 20,
        },
        "& .MuiAutocomplete-inputRoot": {
            paddingTop: 8,
            paddingBottom: 6
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #BCBCBC",
            height: "100%"
        },
        "& .MuiInput-underline::after": {
            borderBottom: 0,
            height: "100%",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "1px solid #BCBCBC",
        },
        "& .MuiIconButton-label": {
            color: "#000",
        },
        "& .MuiAutocomplete-endAdornment": {
            right: 15,
        },
        "& .MuiInputBase-input": {
            padding: "14px 16px 12px",
            background: "#fff",
            fontFamily: "Gilroy",
            fontWeight: 500,
            textAlign: "left",
        },
        "& .MuiFormControl-root": {
            marginBottom: 20,
            minWidth: "inherit",
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input:first-child": {
            padding: "6px 16px",
        },
        "& .MuiSelect-icon": {
            color: "#000",
            right: 15,
        },
        "& .MuiInputLabel-root": {
            left: 16,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            background: "#fff",
        },
        [theme.breakpoints.up("sm")]: {
            width: 496,
            boxSizing: "border-box",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "70px 50px 60px",
            right: "unset",
            bottom: "unset",
            overflowY: "hidden",
        },
    },
    modalAddMemberTitle: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        marginBottom: 20,
        [theme.breakpoints.up("sm")]: {
            lineHeight: "39px",
        },
    },
    modalAddMemberClose: {
        position: "absolute",
        top: 25,
        right: 25,
        width: 26,
        height: 26,
        display: "block",
        cursor: "pointer",
        backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNEw0IDEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTQgNEwxMiAxMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
        backgroundSize: 26,
        [theme.breakpoints.up("sm")]: {
            top: 30,
            right: 30,
        },
    },
    chatAccounts: {
        [theme.breakpoints.down("md")]: {
            marginBottom: 40,
        },
        [theme.breakpoints.up("lg")]: {
            paddingRight: 20,
        },
    },
    chatAccountsPaper: {
        overflow: "hidden",
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
        borderRadius: 8,
    },
    chatAccountsPaperUser: {
        [theme.breakpoints.up("lg")]: {
            height: "100%",
            position: "relative",
        },
    },
    chatAccountsPaperUserContainer: {
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            overflowY: "auto",
            alignItems: "initial",
        },
    },
    teamInputField: {
        padding: "22px 24px 24px",
        margin: 0,
        minWidth: "auto",
        boxSizing: "border-box",
        "& .MuiIconButton-root": {
            padding: 0,
            marginRight: 8,
        },
        "& .MuiInput-underline:after, .MuiInput-underline:before": {
            display: "none"
        },
        "& .MuiInputBase-input": {
            padding: 0,
            fontSize: 16,
            lineHeight: "24px",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#BCBCBC",
            opacity: 1,
        },
        "& .MuiIconButton-label:hover, & .MuiIconButton-root:hover": {
            background: "none"
        }
    },
    teamList: {
        padding: 0,
        "& .MuiListItem-gutters": {
            padding: "15px 24px",
        },
        "& .MuiListItem-gutters.active":{
            background: "#F5F5F5!important",
        },
        "& .MuiListItem-gutters:hover": {
            background: "transparent",
            cursor: "pointer",
        },
    },
    chatTeam: {
        padding: "10px 24px",
        marginBottom: 5,
    },
    chatListIcon: {
        "& .default-profile-avatar-container, & img, & .userMenuAvatarBlock": {
            width: 40,
            height: 40,
        },
        "& .default-profile-avatar-container p": {
            fontSize: "20px",
            lineHeight: "24px",
        },
    },
    chatListName: {
        "& span": {
            fontSize: 18,
            lineHeight: "22px",
        }
    },
    chatAccountsPaperContainer: {
      height: "100%",
    },
    chatBody: {
        background: "#F5F5F5",
        minHeight: 390,
        position: "relative",
        "& .messageList": {
            display: "flex",
            flexDirection: "column",
            padding: "32px 24px 2px"
        },
/*         [theme.breakpoints.up("lg")]: {
            height: "calc(100% - 136px)",
        }, */
    },
    messageGroupContainer: {
        padding: "0 0 10px",
        flexDirection: "column",
    },
    messageGroupDate: {
        color: "#636363",
        fontSize: 14,
        lineHeight: "18px",
        paddingBottom: 20,
        paddingTop: 20,
    },
    messageGroup: {
        width: "100%",
        padding: 0,
        "& .messageMine, & .messageTheirs": {
            padding: "0 0 10px",
        },
        "& .messageMine .messageContainer, & .messageTheirs .messageContainer": {
            fontSize: 16,
            lineHeight: "24px",
            padding: "9px 20px 7px",
        },
        "& .messageMine .MuiListItemText-root, & .messageTheirs .MuiListItemText-root": {
            margin: 0,
        },
        "& .messageContainer": {
            display: "flex",
            alignItems: "end",
        },
        "& .messageTheirs .messageContainer ": {
            background: "#fff",
            borderRadius: "0 20px 20px 0",
        },
        "& .messageTheirs .messageContainer.messageContainerAttach": {
            borderRadius: "0 8px 8px 0",
        },
        "& .messageTheirs:first-child .messageContainer": {
            borderTopLeftRadius: 20,
        },
        "& .messageTheirs:first-child .messageContainer.messageContainerAttach": {
            borderTopLeftRadius: 8,
        },
        "& .messageTheirs:last-child .messageContainer": {
            borderBottomLeftRadius: 20,
        },
        "& .messageTheirs:last-child .messageContainer.messageContainerAttach": {
            borderBottomLeftRadius: 8,
        },
        "& .messageMine ": {
            alignItems: "flex-end",
            justifyContent: "flex-end",
        },
        "& .messageMine .messageContainer": {
            borderRadius: "20px 0 0 20px",
            background: "#000000",
            color: "#fff",
        },
        "& .messageMine .messageContainer.messageContainerAttach": {
            borderRadius: "8px 0 0 8px",
        },
        "& .messageMine .messageContainer .MuiTypography-colorPrimary": {
            color: "#fff",
        },
        "& .messageMine:first-child .messageContainer": {
            borderTopRightRadius: 20,
        },
        "& .messageMine:first-child .messageContainer.messageContainerAttach": {
            borderTopRightRadius: 8,
        },
        "& .messageMine:last-child .messageContainer": {
            borderBottomRightRadius: 20,
        },
        "& .messageMine:last-child .messageContainer.messageContainerAttach": {
            borderBottomRightRadius: 8,
        },
        "& .messageAllAttach": {
            display: "block",
        }
    },
    messageDate: {
        color: "#BCBCBC",
        paddingLeft: 10,
        minWidth: "auto!important",
        "& .MuiTypography-body1": {
            fontSize: 10,
            lineHeight: "20px",
            whiteSpace: "nowrap",
        }
    },
    chatSendMessage: {
        padding: "4px 24px 6px 0"
    },
    chatBtnSend: {
        "&.MuiFab-root": {
            width: 20,
            marginRight: 17,
            padding: "0 0 10px",
            minWidth: 20,
            background: "none",
            boxShadow: "none!important",
            cursor: "pointer",
            "& .MuiTouchRipple-root":{
                display: "none",
            }
        },
        "&.MuiFab-root:hover": {
            background: "none",
        },
        "&.MuiFab-root.Mui-disabled": {
            background: "none",
        },
        "&.MuiFab-root:hover.Mui-disabled": {
            backgroundColor: "transparent",
        }
    },
    chatBtnAttach: {
        "&.MuiFab-root": {
            width: 18,
            minWidth: 18,
            marginRight: 35,
            padding: "0 0 9px",
            background: "none",
            boxShadow: "none!important",
            cursor: "pointer",
            "& .MuiTouchRipple-root":{
                display: "none",
            }
        },
        "& *": {
            height: 19,
        },
        "&.MuiFab-root:hover": {
            background: "none",
        },
    },
    messageAvatar: {
        minWidth: "30px!important",
        marginRight: 16,
        "& img": {
            width: 30,
        }
    },
    chatHeader: {
        display: "flex",
        width: "100%",
        padding: "8px 24px",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
            paddingTop: 20,
        },
    },
    chatHeaderAvatar: {
        display: "flex",
        alignItems: "center",
    },
    chatHeaderAvatarName: {
        lineHeight: "22px",
        fontSize: 18,
        paddingLeft: 16,
    },
    chatHeaderButtons: {
        display: "flex",
        minHeight: 55,
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            justifyContent: "end"
        },
    },
    chatHeaderMenu: {
        padding: 0,
        "&:hover": {
            background: "none",
            color: "#000",
        }
    },
    chatHeaderField: {
        width: 201,
        paddingRight: 0,
        "& .MuiInputBase-input::placeholder": {
            color: "#000"
        }
    },
    chatMenu: {
        boxShadow: "0 0 8px rgba(37, 40, 43, 0.12)",
        borderRadius: 8,
        "& .MuiList-root": {
            padding: "6px 24px",
        },
        "& .MuiButton-root": {
            padding: 0,
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 400,
            textTransform: "none",
            justifyContent: "left",
        },
        "& .MuiButton-root:hover": {
            background: "none",
            color: "#6233C0",
        },
        "& .MuiMenuItem-root": {
            padding: "12px 0",
            borderTop: "1px solid #F5F5F5",
        },
        "& .MuiMenuItem-root:hover": {
            background: "none",
        },
        "& .MuiMenuItem-root:first-child": {
            borderTop: "0",
        }
    },
    teamModalRating: {
        margin: "50px 0 0 0",
        overflow: "hidden",
        "& .buttons": {
            paddingTop: 20,
        },
        "& .MuiPaper-root": {
            background: "transparent",
        },
    },
    paper: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#fff",
        padding: "95px 20px 60px",
        overflowY: "auto",
        maxHeight: "100vh",
        "& .buttons": {
            justifyContent: "center",
            height: 93,
            flexWrap: "wrap",
        },

        [theme.breakpoints.up("sm")]: {
        "& .buttons": {
            justifyContent: "left",
            height: "auto",
            flexWrap: "initial",
        },
        },
        "& .buttons button": {
            marginLeft: 0,
        },
        [theme.breakpoints.up("sm")]: {
            width: 678,
            boxSizing: "border-box",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "70px 50px 60px",
            right: "unset",
            bottom: "unset",
        },
    },
    paperSmall: {
        [theme.breakpoints.down("xs")]: {
            maxHeight: "initial",
            top: "50%",
            bottom: "unset",
            transform: "translate(0, -50%)",
        },
    },
    paperModalRaiting: {
        zIndex: 2,
        [theme.breakpoints.down("xs")]: {
            maxHeight: "initial",
            top: "50%",
            bottom: "unset",
            transform: "translate(0, -50%)",
        },
    },
    paperTitle: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        marginBottom: 30,
        fontWeight: 400,
        "& img": {
          marginLeft: 20,
        },
        "& span": {
            display: "block",
            color: "#BCBCBC",
        },
        [theme.breakpoints.up("sm")]: {
            lineHeight: "39px",
        },
    },
    paperClose: {
        position: "absolute",
        top: 25,
        right: 25,
        width: 26,
        height: 26,
        display: "block",
        cursor: "pointer",
        backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNEw0IDEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTQgNEwxMiAxMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
        backgroundSize: 26,
        [theme.breakpoints.up("sm")]: {
            top: 30,
            right: 30,
        },
    },
    teamBoard: {
        padding: "16px 23px 60px",
        position: "relative",
        "& .react-kanban-board": {
            padding: 0,
            margin: "0 -10px",
        },
        "& .react-kanban-column": {
            background: "transparent",
            margin: "0 10px",
            padding: 0,
            width: "15%",
            minWidth: 158,
            "& div": {
                display: "block!important"
            }
        },
        "& .react-kanban-board > div": {
            width: "100%",
        },
        "&:before": {
            content: "''",
            position: "absolute",
            right: 0,
            top: 0,
            background: "linear-gradient(270deg, #F5F5F5 0%, rgba(245, 245, 245, 0) 80%)",
            height: "100%",
            minWidth: "70px",
            zIndex: 10,
        },
        [theme.breakpoints.up("md")]: {
            padding: "16px 32px 60px",
        },
    },
    teamBoardTitle: {
        fontSize: 14,
        lineHeight: "18px",
        paddingLeft: 6,
        paddingBottom: 8,
        display: "block",
        color: "#636363",
    },
    teamBoardItem: {
        margin: "0 10px",
    },
    teamBoardButton: {
        background: "#fff",
        width: "100%",
        borderRadius: 0,
        color: "#BCBCBC",
        fontSize: 16,
        lineHeight: "24px",
        textTransform: "capitalize",
        marginBottom: 10,
        padding: "4px 6px 6px",
        "& .MuiButton-label": {
            display: "inline-block",
            width: "auto",
            position: "relative",
            paddingLeft: 30,
        },
        "&:hover": {
            background: "#fff",
            color: "#000",
        },
        "& .MuiButton-label:before,& .MuiButton-label:after": {
            content: "''",
            border: "1px solid #BCBCBC",
            width: 14,
            position: "absolute",
            left: 0,
            top: 11,
        },
        "& .MuiButton-label:after": {
            transform: "rotate(90deg)"
        },
        "&:hover .MuiButton-label:before, &:hover .MuiButton-label:after": {
            border: "1px solid #000",
        },
    },
    teamBoardTask: {
        background: "#000",
        color: "#fff",
        padding: "12px 24px 15px",
        marginBottom: 10,
        "&:hover": {
            background: "#6233C0",
        }
    },
    teamTaskTitle: {
        fontSize: "16px",
        lineHeight: "24px",
    },
    teamParentTaskTitle: {
        fontSize: "12px",
        lineHeight: "12px",
        position: "relative",
        top: "-2px",
    },
    teamTaskDate: {
        fontSize: "10px",
        lineHeight: "12px",
    },
    teamTaskInfo: {
        position: "relative",
    },
    teamTaskView: {
        color: "#fff",
        position: "absolute",
        right: 0,
        top: 0,
        padding: 0,
        fontSize: "10px",
        lineHeight: "12px",
        minWidth: "auto",
        textTransform: "capitalize",
    },
    teamCreateForm: {
        background: "red"
    },
    teamBoardComments: {
        width: "100%",
    },
    teamBoardCommentsFiles: {
      "& ul": {
          padding: "0 0 0 24px",
          listStyle: "none",
      }
    },
    teamBoardSendComment: {
        display: "flex",
        alignItems: "end",
        "& .MuiInputBase-multiline": {
            padding: 0,
        }
    },
    teamMembersButton: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    teamStatusButton: {
        minWidth: 140,
        height: 40,
        background: "#6233C0",
        color: "#fff",
        fontSize: 14,
        padding: "6px 26px",
        textTransform: "initial",
        fontFamily: "Gilroy",
        fontWeight: 600,
        borderRadius: 0,
        marginBottom: 30,
        "&:hover": {
            background: "#000",
            color: "#fff",
        },
        "&.Mui-disabled": {
            background: "#EAEAEA",
            color: "000",
        },
        "& + svg": {
            marginLeft: 10,
            marginBottom: 8,
        }
    }
}));

export default useStyles;