import React from "react";
import DefaultProfileAvatar from "./DefaultProfileAvatar";
import useStyles from "../styles/UserCard";
import clsx from "clsx";
import { isMaster } from "../utils/misc";
import premium from "../assets/premium.svg"

const ProfileAvatar = (props) => {
  const classes = useStyles();
  const imgProfileSrc = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${props.imageLink}`;
  return props.imageLink ? (
    <div className={clsx(
        "userMenuAvatarBlock",
        classes.userMenuAvatarBlock
    )}>
      <img
        src={imgProfileSrc}
        alt="avatar"
        className={classes.userMenuAvatar}
      />
      {isMaster(props?.user) ? (
        <img src={premium} alt="premium"
          style={{
            height: 15,
            width: 15,
            minWidth: 15,
            position: "absolute",
            right: -3,
            bottom: 0,
            fill: "#000",
          }}
        />
      ) : null}
    </div>
  ) : (
    <DefaultProfileAvatar
      user={props.user}
      className={classes.userMenuAvatarName}
    />
  );
};

export default ProfileAvatar;
