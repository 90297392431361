
import moment from "moment";
import { useLocation } from "react-router-dom";
import React from "react";

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

export const getTime = (date) => {
  if (!date) return "";
  let d = new Date(date);
  return moment(d).format('HH:mm');
};

export const setCookie = (name, value, dateMS) => {
  let expires = "";
  let domain = "domain=wecreators.com";
  let path = "path=/";
  if (dateMS) {
    expires = "expires=" + new Date(dateMS * 1000);
  }
  document.cookie = `${name} = ${value || ""};${expires};${path};${domain}`;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const getExpires = (day) => {
  let date = new Date();
  date.setDate(date.getDate() + day);
  return Date.parse(date) / 1000;
};

export const swiftWrapper = (data) => {
  return `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${data}`;
};

export const isCreator = (user) => {
  return user?.profile_types?.reduce((acc, cur) => {
    if (cur.name === "Creator") {
      acc = true;
      return acc;
    }
    return acc;
  }, false);
};

export const isMaster = (user) => {
  return user?.profile_types?.reduce((acc, cur) => {
    if (cur.name === "Master Creator") {
      acc = true;
      return acc;
    }
    return acc;
  }, false);
};

export const isBusiness = (user) => {
  return user?.profile_types?.reduce((acc, cur) => {
    if (cur.name === "Business") {
      acc = true;
      return acc;
    }
    return acc;
  }, false);
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const imageCrop = (data) => {
  const { image, options } = data;
  return `${process.env.REACT_APP_IMAGE_PROXY}/${options}/${image}`;
};

export const getUnifiedDate = (date) => {
  let d = new Date(date);
  return moment(d).format('YYYY-MM-DD');
};

export const fileUploadError = (code) => {
  switch (code) {
    case "file-invalid-type":
      return "Sending files of this format is not supported. You can attach a link to the file uploaded to an external cloud storage";
    case "file-too-large":
      return "Size of the file you want to send is too big. Instead of sending the file you can attach a link to the file uploaded to an external cloud storage";
    case "file-too-small":
      return "Size of the file you want to send is too small. Instead of sending the file you can attach a link to the file uploaded to an external cloud storage";
    case "too-many-files":
      return "Please, send your files separately, one by one.";

    default:
      return code;
  }
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const  useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}