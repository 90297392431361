import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import logoWhite from "../../../assets/logo_footer.svg";
import clsx from "clsx";

import useStyles from "./styles";

function Copyright() {
  return (
    <Typography variant="body2" color="initial">
      {"© "}
      {new Date().getFullYear()}{" "}
      <Link href="/" color="initial" underline="none">
        WeCreators. All rights reserved.
      </Link>
    </Typography>
  );
}

const footerLinks = [
  {
    title: "About",
    link: `${process.env.REACT_APP_MAIN_HOST}/about`,
    //link: `/about`,
  },
  {
    title: "FAQ",
    link: `${process.env.REACT_APP_MAIN_HOST}/faq`,
    //link: `/faq`,
  },
  {
    title: "Support",
    link: `${process.env.REACT_APP_MAIN_HOST}/support`,
    //link: `/support`,
  },
  {
    title: "Search",
    link: `${process.env.REACT_APP_MAIN_HOST}/search`,
    //link: `/search`,
  },
  {
    title: "Terms",
    link: `${process.env.REACT_APP_MAIN_HOST}/terms`,
    //link: `/terms`,
  },
];

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      className={clsx(classes.noGrow, classes.noWrap, classes.loginFooter)}
      id="Footer"
    >
      <img src={logoWhite} alt="Logo" />
      <Grid className={classes.footerNav}>
        {footerLinks.map((footer) => (
          <Link
            href={footer.link}
            key={footer.title}
            variant="subtitle1"
            className={classes.footerNavItem}
          >
            {footer.title}
          </Link>
        ))}
      </Grid>
      <Grid className={classes.copyright}>
        <Copyright />
      </Grid>
    </Grid>
  );
}
