import React from "react";
import {Typography, TextareaAutosize} from "@material-ui/core/";
import useStyles from "../styles/Search";

const HomeSearch = (props) => {
    const classes = useStyles();
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            return;
        }
    };
    const handleChange = (event) => {
        props.setValue(event.target.value);
    };
    return (
        <div>
            <Typography variant="h1" color="secondary">Hey!</Typography>
            <div className={classes.searchTextarea}>
                <TextareaAutosize
                    id="filled-name"
                    placeholder="What are you looking for?"
                    value={props.value}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
        </div>
    );
};
export default HomeSearch;
