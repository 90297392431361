import React from "react";

import { Grid, Typography, Button } from "@material-ui/core";
import ProfileAvatar from "./ProfileAvatar";

import { useSelector, useDispatch } from "react-redux";
import { formSubmitAction } from "../actions/formActions";
import useStyles from "../styles/Team";
import { Link } from "react-router-dom";
import useStylesCard from "../styles/UserCard";
import ConfirmationDialog from "./ConfirmationDialog";

const TeamMember = (props) => {
  const dispatch = useDispatch();
  const { pending, member, teamId, opportunity, teamOwner } = props;
  const router = useSelector((state) => state.router);
  const classes = useStyles();
  const userCardClasses = useStylesCard();

  const currentUser = useSelector((state) => state.userReducer.user);

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.teamMember}
    >
      <Grid item xs={12} md={currentUser?.id === teamOwner?.id ? 4 : 5}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.teamMemberUser}
        >
          <ProfileAvatar
            imageLink={member?.member?.profile_image?.link}
            user={member?.member}
          />
          <Grid className={classes.teamMemberUserName}>
            <Link
              target="_blank"
              className={userCardClasses.userMenuLinkName}
              to={`/profile/${member?.member?.id}`}
            >
              <Typography variant="body1">{`${member?.member?.first_name} ${member?.member?.last_name}`}</Typography>
            </Link>
            <Typography variant="body1">{`@${member?.member?.name}`}</Typography>
            <Typography variant="body2">
              {pending ? <span>pending</span> : null}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        md={currentUser?.id === teamOwner?.id ? 3 : 4}
        className={classes.teamMemberEmail}
      >
        <Typography variant="body1">{member?.member?.email}</Typography>
      </Grid>
      <Grid
        item
        xs={currentUser?.id === teamOwner?.id ? 9 : 12}
        md={3}
        className={classes.teamMemberRole}
      >
        <Typography variant="body1">{member?.role?.name}</Typography>
      </Grid>
      {currentUser?.id === teamOwner?.id ? (
        <Grid item xs={3} md={2} className={classes.teamMemberRemove}>
          <Button
            onClick={() => {
              setConfirmationOpen(true);
            }}
          >
            Remove
          </Button>
        </Grid>
      ) : null}

      <ConfirmationDialog
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        text={`Are you sure you want to remove member?`}
        confirmAction={() => {
          dispatch(
            formSubmitAction({
              formValues: null,
              submitType: "delete",
              endpoint: `opportunities/${opportunity?.id}/teams/${teamId}/roles/${member?.id}`,
              redirect: router.location.pathname,
            })
          );
        }}
      />
    </Grid>
  );
};

export default TeamMember;
