import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        "&>div": {
            backgroundColor: "#fff",
            color: "#636363",
            fontSize: "14px",
            lineHeight: "18px",
            fontFamily: "Gilroy",
            borderRadius: "8px",
            boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.04)",
            padding: "25px 36px 25px 36px",
        }
    },

}));

export default useStyles;