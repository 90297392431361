import http from "../http";

export const registerUserService = async (payload) => {
  const REGISTER_API_ENDPOINT = "/auth/register";
  try {
    const { data } = await http.post(REGISTER_API_ENDPOINT, payload);
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const loginUserService = async (payload) => {
  const LOGIN_API_ENDPOINT = "/auth/login";

  try {
    const { data } = await http.post(LOGIN_API_ENDPOINT, payload);
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const loginOauthService = async (payload) => {
  const ENDPOINT = "/auth/oauth";

  try {
    const { data } = await http.post(ENDPOINT, payload);
    return data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUserService = async () => {
  const GET_USER_API_ENDPOINT = "/auth/me";

  try {
    const { data } = await http.post(GET_USER_API_ENDPOINT);
    return data;
  } catch (error) {
    throw error;
  }
};

export const refreshTokenService = async () => {
  const ENDPOINT = "/auth/refresh";

  try {
    const { data } = await http.post(ENDPOINT);
    return data;
  } catch (error) {
    throw error;
  }
};