import { makeStyles } from "@material-ui/core/styles";
import * as variables from "../../../styles/variables"

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    noGrow: {
        flexGrow: 0,
    },
    center: {
        justifyContent: "center",
        display: "flex",
    },
    loginHeader: {
        paddingLeft: variables.gutterSm,
        paddingRight: variables.gutterSm,
        background: "#E5E5E5",
        "& .MuiToolbar-regular": {
            minHeight: 50,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: variables.gutterBg,
            paddingRight: variables.gutterBg,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        "& .filter-button": {
            marginRight: 4,
            padding: 0,
            height: 20,
        },
        "& .filter-button:hover": {
            background: "transparent",
        },
        "& .filter-button .MuiTouchRipple-root": {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    headerLogo: {
        marginRight: "auto",
    },
    headerLogLink: {
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    subHeaderContainer: {
        display: "flex"
    },
    mobileMenuBtn: {
        marginLeft: "0",
        [theme.breakpoints.up("md")]: {
            display: "none!important",
        },
    },
    closeMenuDrawer: {
        width: "100%",
        height: "56px",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "75px",
        boxSizing: "border-box"
    },
    closeMenuDrawerLogo: {
        paddingTop: "26px"
    },
    closeMenuDrawerIcon: {
        height: "56px",
        justifyContent: "flex-end",
        display: "flex",
        padding: "26px"
    },
    menuItem: {
        backgroundColor: "#000",
        color: "#fff",
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: "0!important",
        paddingRight: "0important",
        "& p": {
            color: "#fff",
            fontFamily: "Gilroy",
            fontSize: "32px",
            lineHeight: "35px",
        },
        "&.Mui-expanded": {
            minHeight: "auto",
        },
        "& .MuiAccordionSummary-content": {
            margin: "12px 0 7px",
            "&.Mui-expanded": {
                margin: "12px 0",
            }
        }
    },
    menuSubContainer: {
        backgroundColor: "#000",
        paddingTop: 0,
        paddingLeft: "0",
        paddingRight: "0",
        paddingBottom: 0,
        position: "relative",
        borderBottom: "1px solid #636363",
        "& a": {
            color: "#BCBCBC",
            textDecoration: "none",
            fontSize: "14px",
            fontFamily: "Gilroy",
            lineHeight: "18px",
            marginBottom: "25px",
        },
    },
    mobileMenuIcon: {
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    menuItemName: {
        color: "#fff",
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
    },
    closeMenuDrawerContainer: {
        backgroundColor: "#000",
        paddingLeft: "26px",
        paddingRight: "26px",
        height: "100%",
        "& .addMenuAccordion": {
            margin: 0,
        }
    },
    addMenuContainer: {
        marginTop: "auto",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    addMenuBtn: {
        padding: "27px",
        paddingLeft: 0,
        paddingRight: 0,
        color: "#fff",
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        textTransform: "none",
        justifyContent: "left",
        "&:hover": {
            backgroundColor: "#000"
        }
    },
    addMenuMobileBtn: {
        fontWeight: 400,
        padding: 0,
        marginBottom: 7,
        "&:first-child": {
            marginTop: 40,
            marginBottom: 19,
        }
    },
    userMenuIconContainer: {
        display: "flex",
    },
    centerMenuContainer: {
        alignItems: "center",
        "& button": {
            fontSize: "14px",
            fontFamily: "Gilroy",
            lineHeight: "18px",
            color: "#fff",
            paddingLeft: "23px",
            paddingRight: "7px",
            textDecoration: "none!important",
            whiteSpace: "nowrap",
        },
        "& div a": {
            textDecoration: "none",
            color: "#000"
        },
        "& button .MuiButton-label": {
            textTransform: "none!important"
        },
        "& div[class*='makeStyles-addMenuContainer']": {
            flexDirection: "row"
        },
        "& .MuiTouchRipple-root": {
            display: "none",
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 150,
        },
    },
    headerIcons: {
        whiteSpace: "nowrap",
        display: "flex",
        "& .MuiTouchRipple-root": {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 22,
        },
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 0,
        },
    },
    headerBlock: {
        height: 50,
        minHeight: 50,
        padding: "0 23px",
        "& .MuiIconButton-root": {
            padding: 0,
            margin: "0 0 0 20px",
        }
    },
    addMenu: {
        paddingRight: 10,
        "& button": {
            fontSize: 14,
            lineHeight: "18px",
            fontWeight: 400,
            background: "transparent",
        },
        "& button:hover": {
            background: "none"
        },
        "& button + button": {
            marginLeft: 30,
        },
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
    },
    sectionItemFilter: {
        color: "#000",
        fontFamily: "Gilroy",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "18px",
        textTransform: "initial",
        padding: "0 0 0 24px",
        minWidth: "auto",
        "& svg": {
            width: 15,
        },
        "&:hover": {
            background: "transparent",
        },
        "& .MuiTouchRipple-root": {
            display: "none"
        }
    },
    sectionSelectContent: {
        "& .MuiPaper-root": {
            borderRadius: 8,
            boxShadow: "0 0 8px rgba(37, 40, 43, 0.12)",
            maxHeight: 350,
            overflowY: "auto"
        },
        "& .MuiMenuItem-root": {
            padding: "0 24px",
        },
        "& .MuiMenuItem-root:hover": {
            background: "transparent",
        },
        "& .MuiToggleButton-root": {
            border: 0,
            color: "#000",
            padding: "11px 0",
            textTransform: "none",
            borderTop: "1px solid #fff",
        },
        "& .MuiToggleButton-root .MuiTypography-body2": {
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "18px",
        },
        "& .MuiToggleButton-root:hover, & .MuiToggleButton-root:hover .MuiTypography-body2, & .MuiToggleButton-root.Mui-selected": {
            color: "#6233C0!important",
            background: "transparent",
        },
        "& .MuiToggleButton-root:not(:first-child)": {
            borderTop: "1px solid #F5F5F5",
        },
        "& .MuiToggleButton-label": {
            justifyContent: "left",
        },
    },
    sectionMobileFilter: {
        "& .MuiMenu-paper": {
            marginTop: 24,
        },
        "& .MuiList-root": {
            padding: "6px 17px",
        },
        "& .MuiListItem-root": {
            padding: 0,
            alignItems: "normal"
        }
    },
    sectionMobileAccordion: {
        boxShadow: "none",
        "&:not(:first-child)":{
            borderTop: "1px solid #F5F5F5",
        },
        "&:before": {
          display: "none"
        },
        "& .MuiAccordionSummary-root": {
            padding: 0,
            minHeight: 48,
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0",
        },
        "& .MuiTypography-body1, & .MuiTypography-body2": {
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "18px"
        },
        "& svg": {
            width: 15,
        },
        "& .MuiAccordionDetails-root": {
            padding: 0,
        },
        "& .MuiToggleButtonGroup-root": {
            width: "100%"
        },
        "& .MuiToggleButton-root": {
            border: 0,
            textTransform: "none",
            justifyContent: "left",
            padding: "0 0 12px 0",
        },
        "& .MuiToggleButton-root:hover, & .MuiToggleButton-root.Mui-selected": {
            background: "transparent",
        },
        "& .MuiToggleButton-root:hover .MuiTypography-body2, & .MuiToggleButton-root.Mui-selected .MuiTypography-body2": {
            color: "#6233C0"
        },
        "& .MuiToggleButton-label": {
            justifyContent: "left"
        },
        "& .MuiTypography-body2": {
            color: "#636363",
        },
        "&.MuiAccordion-root.Mui-expanded": {
            margin: "0",
        }
    },
    headerUserMenu: {
        "& .MuiMenu-paper": {
            marginTop: 37,
        },
        "& .MuiMenu-list .MuiListItem-root a, & .MuiMenu-list .MuiListItem-root span": {
            textDecoration: "none",
            fontSize: 14,
            lineHeight: "18px",
            color: "#636363",
        },
        "& .MuiMenu-list .MuiListItem-root a:hover, & .MuiMenu-list .MuiListItem-root span:hover": {
            color: "#6233C0",
        },
        "& .MuiMenu-list .MuiListItem-root:nth-child(2)": {
            borderColor: "#fff",
        }
    },
    headerUserMenuCard: {
        minWidth: 176,
        margin: "3px -11px 4px",
    },
    headerNotificationContent: {
        paddingTop: "16px",
        borderBottom: "1px solid #F5F5F5",
    },
    headerNotificationButton: {
        "& .MuiIconButton-label": {
            position: "relative",
        },
    },
    headerNewNotification: {
        width: 6,
        height: 6,
        background: "#F5036B",
        borderRadius: 3,
        position: "absolute",
        top: 1,
        right: 1,
    },
    headerNotification: {
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
        "& .MuiMenu-paper": {
            marginTop: 34,
            borderRadius: "0 0 8px 8px",
            width: 320,
            scrollbarWidth: "thin",
            scrollbarColor: "#000 #fff",
            "&::-webkit-scrollbar": {
                width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#000",
            },
            [theme.breakpoints.down("xs")]: {
                width: "100%",
                maxWidth: "100%",
                left: "0!important",
            },
        },
        "& .MuiMenu-list": {
            maxHeight: "80vh",
            padding: "7px 0 17px 0!important",
            width: "auto!important",
        },
        "& .MuiMenu-list .MuiListItem-root": {
            padding: "0 18px 0 26px",
            whiteSpace: "normal",
            fontSize: 14,
            lineHeight: "18px",
            display: "block",
            borderTop: 0,
            "&:last-child > div": {
                borderBottom: 0,
            },
            "&.unread": {
                background: "rgba(98, 51, 192, 0.2)!important",
                "& .MuiButtonBase-root": {
                    color: "#6233C0",
                },
                "& .MuiButtonBase-root:hover": {
                    color: "#000",
                }
            }
        },
        "& .MuiMenu-list .MuiListItem-root:hover": {
            color: "#636363",
            cursor: "default"
        }
    },
    notificationUserName: {
        fontWeight: 700,
        paddingRight: 3,
    },
    notificationButtons: {
        padding: "5px 0 10px",
        display: "flex",
        justifyContent: "end",
    },
    notificationButton: {
        padding: 0,
        fontFamily: "Gilroy",
        fontSize: 14,
        lineHeight: "17px",
        textTransform: "initial",
        minWidth: "auto",
        color: "#BCBCBC",
        "&:hover": {
            background: "none",
            color: "#000",
        }
    },
    notificationUserIcon: {
        display: "inline-block",
        verticalAlign: "middle",
        padding: "0 5px 3px 0",
        "& .userMenuAvatarBlock": {
            height: 23,
        },
        "& *, & img": {
            width: 23,
            height: 23,
            minWidth: 23,
        },
        "& .default-profile-avatar-container p": {
            textAlign: "center",
            lineHeight: "23px",
        }
    },
    headerSubLink: {
        "& img": {
            marginLeft: 3,
            marginTop: 2,
        }
    },
    headerSubMenu: {
        borderRadius: 8,
        boxShadow: "0 0 8px rgba(37, 40, 43, 0.12)",
        marginTop: 14,
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
        scrollbarColor: "#000 #fff",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#000",
        },
        "& .MuiList-root": {
            padding: "8px 24px 6px",
            width: 220,
            boxSizing: "border-box",
            maxHeight: "83vh",
        },
        "& .MuiMenuItem-root": {
            padding: "12px 0",
            background: "none"
        },
        "& .MuiMenuItem-root.active": {
            pointerEvents: "none",
        },
        "& .MuiMenuItem-root.active a": {
            color: "#6233C0",
        },
        "& .MuiMenuItem-root:not(:first-child)": {
            borderTop: "1px solid #F5F5F5",
        },
        "& li.MuiMenuItem-root:first-of-type": {
            borderTop: "none",
        },
        "& .MuiMenuItem-root.discover": {
            borderTop: "1px solid #BCBCBC",
        },
        "& .MuiMenuItem-root a": {
            fontFamily: "Gilroy",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "18px",
            color: "#636363",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        },
        "& .MuiMenuItem-root a:hover": {
            color: "#6233C0",
        },
    },
    headerIdeationSpace: {
        "&.MuiList-root": {
            padding: "8px 17px 6px",
        },
    },
    headerIdeationSpaceCol: {
        padding: "0 7px",
    },
    menuAddLink: {
        paddingTop: 40,
        marginTop: "auto",
        paddingBottom: 13,
        "& > div": {
            display: "block",
        },
        "& > div button": {
            fontSize: 32,
            lineHeight: "35px",
            margin: 0,
            padding: "0 0 26px",
            display: "block",
        },
        "& button img": {
            width: 29,
            height: 29,
        }
    },
    discoverTeamsFilter: {
        [theme.breakpoints.up("md")]: {
            marginLeft: "-24px",
        },
    }
}));

export default useStyles;