import http from "../http";

export const loadMessagesService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  try {
    let { data } = await http.get(ENDPOINT);
    data.name = payload.name;
    return data;
  } catch (error) {
    throw error;
  }
};
