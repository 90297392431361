import React from "react";

import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ProfileAvatar from "./ProfileAvatar";
import { getUnifiedDate } from "../utils/misc";
import useStyles from "../styles/CreationOpportunityTeamForm";


const historyItem = (h) => (
    <Grid item xs={12} key={h?.id} className="itemHistoryTask">
    <ProfileAvatar imageLink={h.user?.profile_image?.link} user={h?.user} />
    <Grid className="infoHistoryTask">
        <Grid>
            <span>{h?.user?.name}</span>{h?.text}
        </Grid>
        <Typography variant="body2" className="dateHistoryTask">{getUnifiedDate(h?.created_at)}</Typography>
    </Grid>
  </Grid>
);

const TaskHistory = (props) => {
  const { history } = props;

  const historyAlwaysShown = history.slice(0, 2);
  const historyExpanded = history.slice(3);
  const classes = useStyles();

  return (
    <Grid container className={classes.taskHistory}>
      <Grid item xs={12}>
        <Typography variant="body2" className={classes.creationTitleField}>History</Typography>
      </Grid>
      <Accordion className={classes.containerTaskHistory}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {historyAlwaysShown.map((h) => historyItem(h))}
        </AccordionSummary>
        <AccordionDetails>
          {historyExpanded.map((h) => (
            <Grid item xs={12} key={h?.id}>{historyItem(h)}</Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TaskHistory;
