import React from "react";
import ManifestForm from "../forms/ManifestForm";
import Manifests from "../components/Manifests";

const Manifest = () =>
(
  <>
    <Manifests />
    <ManifestForm />
  </>
);

export default Manifest;
