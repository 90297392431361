import React from "react";

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import opportunityImg from "../assets/opportunity.svg";
import clsx from "clsx";
import useStyles from "../styles/SingleCard";
import { imageCrop } from "../utils/misc";

const getDataType = (data) => {
  switch (data) {
    case "creation":
      return "creations";
    case "opportunity":
      return "calls";

    default:
      return "";
  }
};

const SingleCard = (props) => {
  const classes = useStyles();
  const image = require("../assets/background_" +
    (props.data["id"] % 5) +
    ".jpg");
  let { data } = props;
  return (
    <Card className={classes.container}>
      <CardActionArea
        component={Link}
        to={`/${getDataType(data.type)}/${data.id}`}
        className={classes.link}
      >
        {data?.cover?.link ? (
          <CardMedia
            component="img"
            className={classes.media}
            image={imageCrop({
              image: `${data?.cover?.link}`,
              options: "329x200",
            })}
            title={data.title}
          />
        ) : (
          <img src={image.default} alt={data.title} className={classes.media} />
        )}
        <img
          src={opportunityImg}
          alt="Opportunity"
          className={classes.opportunity}
          style={{
            display: data.type === "opportunity" ? "initial" : "none",
          }}
        />
        <div className={clsx(classes.status, data?.status?.code)}>
          {data?.status?.name}
        </div>
        <CardContent className={classes.blockTitle}>
          <Typography
            variant="h4"
            color="textSecondary"
            component="p"
            className={classes.title}
          >
            {data.title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SingleCard;
