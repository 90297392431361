import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chatBody: {
    background: "#F5F5F5",
    minHeight: 390,
    position: "relative",
    "& .messageList": {
      display: "flex",
      flexDirection: "column",
      padding: "52px 24px 2px",
    },
    /*         [theme.breakpoints.up("lg")]: {
            height: "calc(100% - 136px)",
        }, */
  },

  messageGroupContainer: {
    padding: "0 0 10px",
  },

  messageGroup: {
    width: "100%",
    padding: 0,
    "& .messageMine, & .messageTheirs": {
      padding: "0 0 10px",
    },
    "& .messageMine .messageContainer, & .messageTheirs .messageContainer": {
      fontSize: 16,
      lineHeight: "24px",
      padding: "9px 20px 7px",
    },
    "& .messageMine .MuiListItemText-root, & .messageTheirs .MuiListItemText-root":
      {
        margin: 0,
      },
    "& .messageContainer": {
      display: "flex",
      alignItems: "end",
    },
    "& .messageTheirs .messageContainer ": {
      background: "#fff",
      borderRadius: "0 20px 20px 0",
    },
    "& .messageTheirs .messageContainer.messageContainerAttach": {
      borderRadius: "0 8px 8px 0",
    },
    "& .messageTheirs:first-child .messageContainer": {
      borderTopLeftRadius: 20,
    },
    "& .messageTheirs:first-child .messageContainer.messageContainerAttach": {
      borderTopLeftRadius: 8,
    },
    "& .messageTheirs:last-child .messageContainer": {
      borderBottomLeftRadius: 20,
    },
    "& .messageTheirs:last-child .messageContainer.messageContainerAttach": {
      borderBottomLeftRadius: 8,
    },
    "& .messageMine ": {
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
    "& .messageMine .messageContainer": {
      borderRadius: "20px 0 0 20px",
      background: "#000000",
      color: "#fff",
    },
    "& .messageMine .messageContainer.messageContainerAttach": {
      borderRadius: "8px 0 0 8px",
    },
    "& .messageMine .messageContainer .MuiTypography-colorPrimary": {
      color: "#fff",
    },
    "& .messageMine:first-child .messageContainer": {
      borderTopRightRadius: 20,
    },
    "& .messageMine:first-child .messageContainer.messageContainerAttach": {
      borderTopRightRadius: 8,
    },
    "& .messageMine:last-child .messageContainer": {
      borderBottomRightRadius: 20,
    },
    "& .messageMine:last-child .messageContainer.messageContainerAttach": {
      borderBottomRightRadius: 8,
    },
    "& .messageAllAttach": {
      display: "block",
    }
  },

  messageAvatar: {
    minWidth: "30px!important",
    marginRight: 16,
    "& img": {
      width: 30,
    },
  },

  messageDate: {
    color: "#BCBCBC",
    paddingLeft: 10,
    minWidth: "auto!important",
    "& .MuiTypography-body1": {
      fontSize: 10,
      lineHeight: "20px",
      whiteSpace: "nowrap",
    },
  },
}));

export default useStyles;
