import React from "react";
import EditorJs from "react-editor-js";
import Paragraph from "editorjs-paragraph-with-alignment";

import Embed from "@editorjs/embed";
//import Table from "@editorjs/table";
//import Paragraph from "@editorjs/paragraph";
//import List from "@editorjs/list";
//import Warning from "@editorjs/warning";
//import Code from "@editorjs/code";
//import LinkTool from "@editorjs/link";
import Image from "@editorjs/image";
//import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
//import Quote from "@editorjs/quote";
//import Marker from "@editorjs/marker";
//import CheckList from "@editorjs/checklist";
//import Delimiter from "@editorjs/delimiter";
//import InlineCode from "@editorjs/inline-code";
//import SimpleImage from "@editorjs/simple-image";
import http from "../http";
import { useRef, useEffect } from "react";
import { Field } from "react-final-form";

import { Grid, Button } from "@material-ui/core";

import MarkerTool from "../editorjs/MarkerTool";
import useStyles from "../styles/CreationOpportunityTeamForm";
import LinkWithTarget from "editorjs-link-with-target";

// Convert file to base64 string
export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsDataURL(file);
  });
};
// Example call:

/* const sanitizerConfig = {
  a: {
    //href: true, // leave <a> with href
    target: true,
    //target: '_blank',
  },
}; */

const EDITOR_JS_TOOLS = {
  marker: MarkerTool,
  embed: {
    class: Embed,
    inlineToolbar: true,
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
  link: {
    class: LinkWithTarget,
  },
  header: {
    class: Header,
    config: {
      inlineToolbar: ["link", "marker", "bold", "italic"],
    },
  },
  image: {
    class: Image,
    config: {
      uploader: {
        /**
         * Upload file to the server and return an uploaded image data
         * @param {File} file - file selected from the device or pasted by drag-n-drop
         * @return {Promise.<{success, file: {url}}>}
         */
        async uploadByFile(file) {
          // your own uploading logic here

          let b64 = await fileToBase64(file);

          return http
            .post("files", {
              name: file.name,
              b64: b64,
            })
            .then((response) => {
              return {
                success: 1,
                file: {
                  url: `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${response.data.data.link}`,
                },
              };
            })
            .catch((error) => {
              return {
                success: 0,
                error: error,
              };
            });
        },

        /**
         * Send URL-string to the server. Backend should load image by this URL and return an uploaded image data
         * @param {string} url - pasted image URL
         * @return {Promise.<{success, file: {url}}>}
         */
      },
    },
  },
};

  const Error = ({ name }) => {
    return (
      <Field
        name={`${name}.blocks`}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) => {
          return touched && error ? (
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
              {error}
            </p>
          ) : null;
        }}
      />
    );
  };

const Editor = React.memo((props) => {
  const editorRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {}, [editorRef]);

  const addBlock = (type, data) => {
    const index = editorRef.current.blocks.getBlocksCount() + 1;
    editorRef.current.blocks.insert(type, data, undefined, index);
  };


  return (
    <Grid container>
      <Grid item xs={12} className={classes.creationBodyText}>
        {/*         <Error name={props.name} /> */}
        <EditorJs
          placeholder="Body text"
          data={props.value}
          instanceRef={(instance) => (editorRef.current = instance)}
          tools={EDITOR_JS_TOOLS}
          onReady={async () => {
            if (!props.value) {
              editorRef.current.blocks.insert("paragraph", {
                text: "",
                alignment: "left",
              });

              /*               editorRef.current.blocks.insert(
                "paragraph",
                {
                  text: "Body Text2",
                  alignment: "left",
                },
                undefined,
                editorRef.current.blocks.getBlocksCount() + 1
              ); */
            }
          }}
          /*           sanitizer={{
            a: function(el) {
              const href = el.getAttribute("href");

              if (href && href.substring(0, 4) === "http") {
                return {
                  href: true,
                  target: "_blank"
                };
              } else {
                return {
                  href: true
                };
              }
            }
          }} */
          onChange={async (data) => {
            let result = await data.saver.save();
            //const cleanString = data.sanitizer.clean(result, sanitizerConfig)
            /*             result.blocks = result.blocks.map((b) => {
              b.data.text = data.sanitizer.clean(b.data.text, sanitizerConfig);
              return b;
            }); */
            props.onChange(result);
          }}
        />
        <Error name={props.name} error={props.error} />
      </Grid>

      <Grid item xs={12} className={classes.addItemBtnContainer}>
        <Button
          className={classes.addItemBtn}
          onClick={() => addBlock("image", undefined)}
        >
          Image
        </Button>
        <Button
          className={classes.addItemBtn}
          onClick={() => addBlock("paragraph", { text: "Text" })}
        >
          Text
        </Button>
        <Button
          className={classes.addItemBtn}
          onClick={() => addBlock("paragraph", { text: "Insert link" })}
        >
          Embed
        </Button>
      </Grid>
    </Grid>
  );
});

export default Editor;
