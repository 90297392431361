import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "../styles/CreationOpportunity";

import TeamMember from "./TeamMember";
import clsx from "clsx";
const OpportunityTabTeams = (props) => {
  const { data } = props;
  const teams = useSelector((state) => state.formReducer.customData?.teams);
  const classes = useStyles();

  return (
    <Grid>
      {teams?.map((team) => {
        return (
          <Accordion key={team.id} className={classes.itemTeam}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container>
                <div
                  className={clsx(
                    "default-profile-avatar-container",
                    `profile-gradient-${team?.id % 15}`
                  )}
                ></div>
                <Grid className={classes.itemTeamTitleBlock}>
                  <FormControlLabel
                    aria-label="Apply"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    className={classes.itemTeamTitle}
                    control={
                      <Link to={`/calls/${data?.id}/teams/${team.id}`}>
                        {team.name}
                      </Link>
                    }
                  />
                  <Grid className={classes.itemTeamStatus}>
                    Team status
                    <span>{team.status?.name}</span>
                  </Grid>
                  {team.id === data?.winner_team_id ? (
                    <Grid>
                      <Typography>Winner</Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid className={classes.itemTeamContent}>
                {team.pending?.map((m) => (
                  <Grid item xs={12} key={m.id}>
                    <TeamMember
                      pending={true}
                      member={m}
                      opportunity={data?.id}
                      teamId={team.id}
                    />
                  </Grid>
                ))}
                {team.members?.map((m) => (
                  <Grid item xs={12} key={m.id}>
                    <TeamMember
                      member={m}
                      opportunity={data?.id}
                      teamId={team.id}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Grid>
  );
};

export default OpportunityTabTeams;
