import {withStyles} from "@material-ui/core/styles";
import {Chip} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const CustomChip = (props) => {
    const StyledChip = withStyles({
        root: {
            color: "#ffffff",
            backgroundColor: "#6233C0 !important",
            '&:hover': {
                color: "#ffffff",
                backgroundColor: "#6233C0 !important",
            },
            '&:active': {
                color: "#ffffff",
                boxShadow: 'none',
                backgroundColor: "#6233C0",
                borderColor: "#6233C0",
            },
        },
        outlined: {
            color: props.color,
            border: "1px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: `transparent !important`,
        },
        icon: {
            color: props.variant === 'outlined' ? props.color : 'white',
        },
        deleteIcon: {
            color: props.variant === 'outlined' ? props.color : 'white',
        },
    })(Chip);

    return <StyledChip {...props} />;
};
CustomChip.propTypes = {
    color: PropTypes.string,
    variant: PropTypes.oneOf(['regular', 'outlined']),
};
export default CustomChip;