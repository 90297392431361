import React from "react";
import { Field } from "react-final-form";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import ImageDropzone from "../components/ImageDropzone";
import FileDropzone from "../components/FileDropzone";
import { TextField, makeValidate, Select } from "mui-rff";
import { formSubmitAction } from "../actions/formActions";
import Editor from "../components/Editor";
import CustomAutocomplete from "../components/CustomAutocomplete";
import {
  Typography,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { FormSpy } from "react-final-form";

import useStyles from "../styles/CreationOpportunityTeamForm";
import { Helmet } from "react-helmet";
import * as Yup from "yup";
import CoverImageModal from "../components/CoverImageModal";

const castValues = (data) => {
  let cover = data?.cover
    ? data?.cover?.b64
      ? { b64: data?.cover?.b64, name: data?.cover?.name }
      : data?.cover
    : null;
  data.cover = cover;
  data.cover_id = data.cover ? data.cover.id : null;
  data.body = JSON.stringify(data.body);
  data.purchase_type_id = Number.parseInt(data.purchase_type_id);

  return data;
};

const schemaPage1 = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  body: Yup.object().shape({
    blocks: Yup.array().min(1, "Body text required"),
  }),
});
const validatePage1 = makeValidate(schemaPage1);

const schemaPage3 = Yup.object().shape({
  price: Yup.number().required("Set the price"),
  purchase_type_id: Yup.number().required("Choose purchase type"),
});
const validatePage3 = makeValidate(schemaPage3);

const CreationForm = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { initialValues, edit } = props;

  const purchaseCodeRef = React.useRef(null);
  const user = useSelector((state) => state.userReducer.user);
  const references = useSelector((state) => state.formReducer.references);
  return (
    <>
      <Helmet>
        <title>Creations | weCreators</title>
      </Helmet>
      <Grid className={classes.creationMain}>
        <div className={classes.creationContainer}>
          <Typography className={classes.creationHeader}>
            {edit ? "Edit the creation" : "Create a creation"}
          </Typography>
          <Wizard
            className={classes.creationSteps}
            title="Create a creation"
            initialValues={initialValues ? initialValues : { user_id: user.id }}
            onSubmit={(values) => {
              if (edit) {
                dispatch(
                  formSubmitAction({
                    formValues: castValues(values),
                    submitType: "put",
                    endpoint: `/creations/${values.id}`,
                    redirect: `/creations/${values.id}`,
                  })
                );
              } else {
                dispatch(
                  formSubmitAction({
                    formValues: castValues(values),
                    endpoint: "/creations",
                    redirectToNew: true,
                  })
                );
              }
            }}
          >
            <Wizard.Page breadcrumb="1. Content" validate={validatePage1}>
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12}>
                  <TextField
                    placeholder="Title Text"
                    name="title"
                    required={true}
                    InputLabelProps={{ className: classes.creationTitleLabel }}
                    InputProps={{
                      className: classes.creationTitle,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field name="body">
                    {(props) => {
                      return (
                        <div>
                          <Editor {...props.input} {...props.meta} />
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page breadcrumb="2. Cover">
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12}>
                  <Field name="cover">
                    {(props) => {
                      return <CoverImageModal {...props.input}
                        mainText="Drag and drop your creation cover image"
                        subText="or <b>browse</b> to choose a file 660x400 px </br>JPEG, PNG"
                      />

                    }}
                  </Field>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page breadcrumb="3. Settings" validate={validatePage3}>
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <Field name="creative_fields">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-creative-fields"
                          label="Creative fields"
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <Field name="interest_areas">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-interest-areas"
                          label="Interest areas"
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <Field name="creation_tags">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-creation-tags"
                          label="Tags"
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.CreationSelectBlock}
                >
                  <Select
                    name="purchase_type_id"
                    label="Purchase type"
                    formControlProps={{ margin: "none" }}
                    className={classes.CreationSelect}
                  >
                    {references?.["classifiers-purchase-types"]?.map((at) => (
                      <MenuItem value={at.id} key={at.id}>
                        {at.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <FormSpy>
                  {(spyProps) => {

                    let purchaseCode = references?.[
                      "classifiers-purchase-types"
                    ]?.reduce((acc, cur) => {
                      if (cur.id === parseInt(spyProps?.values?.purchase_type_id)) {
                        acc = cur.code;
                      }
                      return acc;
                    }, "")

                    purchaseCodeRef.current = purchaseCode;

                    if (purchaseCodeRef.current==="not_for_sale") {
                      spyProps.form.change("price", 0);
                    }

                    return (
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.creationPriceField}
                      >

                        <TextField
                          disabled={purchaseCodeRef.current === "not_for_sale"}
                          label="Price"
                          name="price"
                          type="number"
                          InputProps={{ inputProps: { min: 0 } }}
                          required={true}
                          fieldProps={{
                            parse: (val) => {
                              return val.replace(/^0+(?=\d)/, "");
                            },
                            format: (val) => {
                              return parseFloat(val);
                            },
                          }}
                        />
                      </Grid>
                    )
                  }}
                </FormSpy>
                <Grid item xs={12} md={6}>
                  <Field name="files">
                    {(props) => {
                      return (
                        <div>
                          <FileDropzone {...props.input} />
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </Wizard.Page>
          </Wizard>
        </div>
      </Grid>
    </>
  );
};

export default CreationForm;
