import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    rootClass: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column"
    },
    noGrow: {
        flexGrow: 0,
    },
    grow: {
        flexGrow: 1,
    }
}));

export default useStyles;