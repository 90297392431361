import React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "../../../assets/menu_plus.svg";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import { isBusiness, isMaster } from "../../../utils/misc";

const AddMenu = () => {
  const user = useSelector((state) => state.userReducer.user);
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <div className={classes.addMenu}>
        <Button
          onClick={() => {
            dispatch(push(`/creations`));
          }}
          className={classes.addMenuBtn}
          color="secondary"
        >
          <img src={AddIcon} alt="Notifications" />
          Creation
        </Button>
        {isBusiness(user) ? (
          <Button
            onClick={() => {
              dispatch(push(`/calls`));
            }}
            className={classes.addMenuBtn}
            color="secondary"
          >
            <img src={AddIcon} alt="Notifications" />
            Call
          </Button>
        ) : null}
        {isMaster(user) ? (
          <Button
            onClick={() => {
              dispatch(push(`/create-team`));
            }}
            className={classes.addMenuBtn}
            color="secondary"
          >
            <img src={AddIcon} alt="Notifications" />
            Team
          </Button>
        ) : null}
      </div>
    </>
  );
};

export default AddMenu;
