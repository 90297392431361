import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    creationContainer: {
        paddingTop: 133,
        background: "#E5E5E5",
        [theme.breakpoints.up("md")]: {
            paddingLeft: 148,
            paddingRight: 148,
            paddingTop: 110,
        },
    },
    profileTitle:{
        position: "absolute",
        color: "#636363",
        maxWidth: 260,
        top: 50,
        "& h3": {
            fontFamily: "Gilroy",
            fontSize: "18px",
            lineHeight: "24px",
            fontWeight: 500,
            marginBottom: 8
        },
        "& h1": {
            fontFamily: "Gilroy",
            fontSize: "44px",
            lineHeight: "54px",
        },
        [theme.breakpoints.up("md")]: {
            bottom: 60,
            top: "unset",
            "& h1": {
                fontSize: "52px",
                lineHeight: "64px",
                fontWeight: 500
            },
        },
    },
    profileHeader: {
        position: "relative",
        backgroundColor: "#F5F5F5",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingLeft: 23,
        paddingRight: 23,
        height: 664,
        "& .buttonsCover": {
            position: "absolute",
            bottom: 144,
            left: "50%",
            marginLeft: -70,
        },
        "& .buttonsCover button": {
            margin: 0,
            padding: 0,
            lineHeight: "40px",
            background: "rgba(0, 0, 0, 0.6)",
            color: "#fff",
        },
        [theme.breakpoints.up("sm")]: {
            height: 830,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 148,
            paddingRight: 148,
            height: 400,
            "& .buttonsCover": {
                top: 78,
                right: 151,
                bottom: "unset",
                left: "unset",
                margin: 0,
            },
        }
    },
    profileImage: {
        width: "160px",
        height: "160px",
        borderRadius: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "-80px auto 30px"
    },
    profileUser: {
        paddingLeft: 23,
        paddingRight: 23,
        textAlign: "center",
        paddingBottom: 75,
        [theme.breakpoints.up("md")]: {
            paddingBottom: 130,
        }
    },
    profileUserName: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        fontWeight: 600,
        marginBottom: 34,
        "& span": {
            color: "#636363",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "36px",
            lineHeight: "44px",
            marginBottom: 44,
        }
    },
    profileImageDefault: {
        width: "160px",
        height: "160px",
        borderRadius: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "-80px auto 30px",
        "& h3": {
            fontFamily: "Gilroy",
            fontSize: "64px",
            lineHeight: "64px",
            color: "#fff",
        }
    },
    profileFormAvatar: {
        "& div, & img": {
            width: 120,
            height: 120,
            margin: "70px 0 40px",
        },
        "& h3": {
            fontSize: 52,
        },
    },
    profileBody: {
        paddingLeft: 23,
        paddingRight: 23,
        paddingBottom: 117,
        display: "grid",
        gridGap: 60,
        gridTemplateColumns: "repeat(1, 1fr)",
        width: "100%",
        boxSizing: "border-box",
        [theme.breakpoints.down("sm")]: {
            "& div:nth-child(1)": {
                order: 1,
            },
            "& div:nth-child(2)": {
                order: 4,
            },
            "& div:nth-child(3)": {
                order: 2,
            },
            "& div:nth-child(4)": {
                order: 3,
            },
            "& div:nth-child(5)": {
                order: 5,
            },
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 148,
            paddingRight: 148,
            paddingBottom: 73,
            gridTemplateColumns: "repeat(2, 1fr)",
        }
    },
    profileBalance: {
        display: "flex",
        fontFamily: "Gilroy",
        fontSize: "36px",
        lineHeight: "44px",
        fontWeight: 500,
        paddingBottom: 60,
        [theme.breakpoints.up("md")]: {
            paddingBottom: 28,
        }
    },
    profileCQ: {
      paddingRight: 65,
    },
    profileAttachments: {
      "& .MuiTypography-h6": {
          fontFamily: "Gilroy",
          fontSize: "14px",
          lineHeight: "18px",
          fontWeight: 500,
          marginBottom: 12,
          color: "#636363"
      }
    },
    profileLink: {
        fontFamily: "Gilroy",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 500,
        // display: "inline-block",
        "& a": {
            color: "#000",
            textDecoration: "none"
        },
        "& + div": {
            paddingTop: 12,
        }
    },
    profileTitleLink: {
        color: "#636363",
        paddingRight: 10,
        display: "inline-block",
    },
    profileTitleBlock: {
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: 500,
        marginBottom: 12,
        color: "#636363"
    },
    profileBio: {
        fontFamily: "Gilroy",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 500,
    },
    profileLabel: {
        background: "#F5F5F5",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        borderRadius: 4,
        marginRight: 10,
        marginBottom: 10,
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: 500,
        padding: "6px 10px 4px",
        "& span": {
            padding: 0,
        }
    },
    profileCardContainer: {
        background: "#F5F5F5",
        padding: "0 23px 35px",
        [theme.breakpoints.up("md")]: {
            padding: "0 32px 149px",
        }
    },
    profileForm: {
        padding: "50px 23px",
        maxWidth: 678,
        overflow: "hidden",
        "& .buttons": {
            justifyContent: "left",
            marginBottom: 65
        },
        "& .buttons button": {
            margin: "0 20px 0 0"
        },
        "& .buttons .invert": {
            minWidth: 98,
        },
        [theme.breakpoints.up("md")]: {
            padding: "120px 0",
            overflow: "visible",
        },
    },
    profileFormTitle: {
        fontFamily: "Gilroy",
        fontSize: "44px",
        lineHeight: "54px",
        fontWeight: 500,
    },
    profileUserInformation: {
        position: "relative",
        [theme.breakpoints.up("md")]: {
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: 20,
        },
    },
    profileBlockLink: {
        paddingRight: 20,
        "& .MuiFormControl-root": {
          minWidth: "auto"
        },
        [theme.breakpoints.up("md")]: {
            paddingRight: 0,
        },
    },
    profileField: {
        "& .MuiInputBase-input": {
            padding: "14px 16px 12px",
            zIndex: 2,
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiFormLabel-root": {
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            left: 16,
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #F5F5F5",
            minHeight: "48px",
            height: "100%",
            background: "#F5F5F5",
        },
        "& .MuiInput-underline::after": {
            borderBottomColor: "#F5F5F5",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottomColor: "#F5F5F5",
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "auto",
        },
    },
    profilePresence: {
        color: "#636363",
        fontFamily: "Gilroy",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 500,
        marginBottom: 20,
        marginRight: "-31px",
    },
    profileDelete: {
        display: "block",
        position: "absolute",
        right: "-22px",
        top: "130px",
        "&:before": {
            content: "''",
            width: 16,
            height: 16,
            display: "block",
            cursor: "pointer",
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNEw0IDEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTQgNEwxMiAxMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
        },
        [theme.breakpoints.up("md")]: {
            top: "26px",
        },
    },
    profileAddButton: {
        fontFamily: "Gilroy",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 500,
        background: "transparent",
        boxShadow: "none",
        position: "relative",
        textTransform: "initial",
        marginBottom: 35,
        paddingLeft: 27,
        color: "#636363",
        "&:before,&:after": {
            content: "''",
            border: "1px solid #636363",
            width: 14,
            position: "absolute",
            left: 0,
        },
        "&:after": {
            transform: "rotate(90deg)"
        },
        "&:hover": {
            background: "transparent",
            boxShadow: "none",
            color: "#000"
        },
        "&:hover&:before, &:hover&:after": {
            border: "1px solid #000",
        },
    },
    profileSelectBlock: {
        "& .MuiAutocomplete-inputRoot": {
            paddingTop: 8,
            paddingBottom: 6
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #BCBCBC",
            height: "100%"
        },
        "& .MuiInput-underline::after": {
            borderBottom: 0,
            height: "100%",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "1px solid #BCBCBC",
        },
        "& .MuiInputLabel-root": {
            left: 16,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            background: "#fff",
        },
        "& .MuiIconButton-label": {
            color: "#000",
        },
        "& .MuiAutocomplete-endAdornment": {
            right: 15,
        },
        "& .MuiInputBase-input": {
            padding: "14px 16px 12px",
            background: "#fff",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiSelect-icon": {
            color: "#000",
            right: 15,
        },
        "& .MuiChip-root": {
            background: "#6233C0",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            margin: "0 0 3px 10px",
            color: "#fff",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
            height: 28
        },
        "& .MuiChip-label": {
            paddingLeft: 10,
            paddingRight: 13
        },
        "& .MuiChip-deleteIcon": {
            color: "#fff"
        }
    },
    paper: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#fff",
        padding: "95px 20px 60px",
        textAlign: "center",
        overflowY: "scroll",
        "& .buttons": {
          marginTop: 30
        },
        "& input[type=range]": {
            width: 206,
            margin: 0,
        },
        "& input[type=range]::-webkit-slider-runnable-track, & input[type=range]::-moz-range-track": {
            background: "#BCBCBC"
        },
        "& input[type=range]::-webkit-slider-thumb, input[type=range]::-moz-range-thumb": {
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxNiAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUgMTUuNUw4IDIwTDEgMTUuNVYzQzEgMi40Njk1NyAxLjIxMDcxIDEuOTYwODYgMS41ODU3OSAxLjU4NTc5QzEuOTYwODYgMS4yMTA3MSAyLjQ2OTU3IDEgMyAxSDEzQzEzLjUzMDQgMSAxNC4wMzkxIDEuMjEwNzEgMTQuNDE0MiAxLjU4NTc5QzE0Ljc4OTMgMS45NjA4NiAxNSAyLjQ2OTU3IDE1IDNWMTUuNVoiIGZpbGw9IndoaXRlIiBzdHJva2U9IiMyNTI4MkIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==)",
            width: 16,
            height: 21,
            borderRadius: 0,
            backgroundColor: "transparent",
            border: 0,
            marginTop: 1,
        },
        [theme.breakpoints.up("sm")]: {
            width: 496,
            boxSizing: "border-box",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "70px 50px 60px",
            right: "unset",
            bottom: "unset",
            overflowY: "hidden",
        },
    },
    inputRange: {
      marginTop: 20,
    },
    paperTitle: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        marginBottom: 30,
        [theme.breakpoints.up("sm")]: {
            lineHeight: "39px",
        },
    },
    paperClose: {
        position: "absolute",
        top: 25,
        right: 25,
        width: 26,
        height: 26,
        display: "block",
        cursor: "pointer",
        backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNEw0IDEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTQgNEwxMiAxMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
        backgroundSize: 26,
        [theme.breakpoints.up("sm")]: {
            top: 30,
            right: 30,
        },
    },
    profileBanner: {
        background: "#F5F5F5",
        fontFamily: "Gilroy",
        padding: "53px 23px 24px",
        margin: "-57px 23px 117px",
        position: "relative",
        [theme.breakpoints.up("md")]: {
            padding: "60px 117px 45px",
            margin: "0 148px 87px",
        },
    },
    profileBannerTitle: {
        fontSize: "32px",
        lineHeight: "35px",
        color: "#000",
        fontWeight: 500,
        paddingBottom: 12,
        "& span": {
            display: "block",
            color: "#636363",
        },
        [theme.breakpoints.up("md")]: {
            lineHeight: "39px",
        },
    },
    profileBannerTip: {
        paddingBottom: 27,
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 400,
        color: "#636363",
    },
    profileBannerTipTitle: {
        color: "#000",
        "& span": {
            color: "#6233C0",
        }
    },
    profileBannerClose: {
        position: "absolute",
        width: 20,
        right: 24,
        top: 24,
        height: 20,
        cursor: "pointer",
        "&:hover:before, &:hover:after":{
          borderColor: "#000",
        },
        "&:before, &:after":{
            content: "''",
            width: 21,
            border: "1px solid #636363",
            position: "absolute",
            top: 9,
            left: "-2px",
            transform: "rotate(45deg)",
        },
        "&:after":{
            transform: "rotate(-45deg)",
        },
        [theme.breakpoints.up("md")]: {
            right: 39,
            top: 39,
        },
    },
    paperCover: {
        padding: "0 35px",
        marginBottom: "-60px",
        [theme.breakpoints.up("md")]: {
            marginBottom: "-90px",
        },
    },
    profileFileDropzone: {
        position: "relative",
        paddingRight: 31,
        "& .main-tooltip": {
            position: "absolute",
            top: 85,
            right: 0,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: 329,
        },
    },
    attachmentError: {
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "18px",
        color: "#F21B0F",
    },
}));

export default useStyles;