import React, {useEffect, useRef} from "react";
import { Link, Redirect } from "react-router-dom";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  Typography,
  Button,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import {
  registerUserAction,
  loginUserFacebookErrorAction,
  loginUserFacebookSuccessAction,
  loginUserGoogleAction,
  loginUserGoogleErrorAction,
  loginUserGoogleSuccessAction,
} from "../actions/authenticationActions";
import { Form, FormSpy, Field } from "react-final-form";
import { TextField, Select } from "mui-rff";
import { formGetReferenceTableValuesAction } from "../actions/formActions";
import {  minLength, composeValidators } from "../utils/fieldValidators";
import { sleep } from "../utils/misc";

import useStyles from "../styles/Login";
import FacebookLogin from "react-facebook-login";
import { Helmet } from "react-helmet";
import facebookIcon from "../assets/facebook-circle.svg";
import googleIcon from "../assets/google-circle.svg";
import clsx from "clsx";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import DarkTooltip from "../components/DarkTooltip";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stateRef = useRef();

  let googleLogin = useGoogleLogin({
    onSuccess: async(response) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${response.access_token}` } },
      );
      dispatch(
        loginUserGoogleSuccessAction({
          data: userInfo?.data,
          type: "google",
        })
      )
    },

    onError: (response) => {

      dispatch(loginUserGoogleErrorAction(response));
    }
  });

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-profile-types",
      })
    );
    dispatch(formGetReferenceTableValuesAction({ endpoint: "countries" }));
  }, [dispatch]);

  const user = useSelector((state) => state.userReducer.user);
  const references = useSelector((state) => state.formReducer.references);
  let errors = useSelector((state) => state.errorReducer.error);
  stateRef.current = errors;

  if (user) {
    return <Redirect to="/profile" />;
  }

  let componentClicked;

  let profileTypes = references?.["classifiers-profile-types"]?.map((c) => (
    <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
  ));

  let countries = references?.["countries"]?.map((c) => (
    <MenuItem value={c.id} key={c.id}>{c.name}</MenuItem>
  ));

  return (
    <>
      <Helmet>
        <title>Register | weCreators</title>
      </Helmet>
      <Grid className={classes.centerForm}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Form
            initialValues={{ policy_agree: false }}
            onSubmit={async (values) => {
              values.password_confirmation = values.password;
              values.profile_types = [values.profile_type_id];
              dispatch(registerUserAction(values));
              await sleep(600);
              return stateRef.current;
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signup" onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Typography variant="h1" noWrap className={classes.h1Var1}>
                    Sign up
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={clsx(classes.loginText, classes.registerText, classes.tooltipText)}
                >
                  <Select
                    variant="outlined"
                    name="profile_type_id"
                    label="Select your profile type"
                    formControlProps={{ margin: "none" }}
                    required
                  >
                    {profileTypes ? profileTypes : <></>}
                  </Select>
                  <DarkTooltip
                    className={classes.registerTooltip}
                    title="Business account can hire people for a project, creator can lead a project or participate in project development"
                  />
                </Grid>
                <FormSpy subscription={{ values: "profile_type_id" }}>
                  {(spyProps) => {
                    if (
                      spyProps.values.profile_type_id &&
                      references?.["classifiers-profile-types"].filter(
                        (pt) => pt.id === spyProps.values.profile_type_id
                      )[0].name === "Business"
                    ) {
                      return (
                        <Grid
                          item
                          xs={12}
                          className={clsx(
                            classes.loginText,
                            classes.tooltipText,
                            classes.registerText
                          )}
                        >
                          <TextField
                            id="name"
                            name="name"
                            label="Business Name"
                            variant="outlined"
                            className={classes.loginText}
                            required
                          />
                          <DarkTooltip
                            className={classes.registerTooltip}
                            title="Input a Company name for a Business account, or input a Nickname if you choose a Creator"
                          />
                        </Grid>
                      );
                    } else if (
                      spyProps.values.profile_type_id &&
                      references?.["classifiers-profile-types"].filter(
                        (pt) => pt.id === spyProps.values.profile_type_id
                      )[0].name === "Creator"
                    ) {
                      return (
                        <Grid
                          item
                          xs={12}
                          className={clsx(
                            classes.loginText,
                            classes.tooltipText,
                            classes.registerText
                          )}
                        >
                          <TextField
                            id="name"
                            name="name"
                            label="Nickname"
                            variant="outlined"
                            className={classes.loginText}
                            required
                          />
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  }}
                </FormSpy>
                <Grid item xs={12} className={classes.registerText}>
                  <TextField
                    id="firstName"
                    name="first_name"
                    label="First Name"
                    variant="outlined"
                    className={classes.loginText}
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.registerText}>
                  <TextField
                    id="lastName"
                    name="last_name"
                    label="Last Name"
                    variant="outlined"
                    className={classes.loginText}
                    required
                  />
                </Grid>
                <Grid item xs={12} className={clsx(classes.loginText, classes.registerText)}>
                  <Select
                    name="country_id"
                    variant="outlined"
                    label="Country"
                    formControlProps={{ margin: "none" }}
                    required
                  >
                    {countries ? countries : <></>}
                  </Select>
                </Grid>
                <Grid item xs={12} className={classes.registerText}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    className={classes.loginText}
                    required
                  />
                </Grid>
                <Grid item xs={12} className={classes.registerText}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    className={classes.loginText}
                    required
                    fieldProps={{
                      validate: composeValidators(
                        minLength(8, "Minimum password length is 8 characters")
                      ),
                    }}
                  />
                </Grid>
                  <Field name="policy_agree">
                      {(props) => {
                          return (
                              <Grid item xs={12} className={classes.policyAgree}>
                                  <FormControlLabel
                                      control={
                                          <Checkbox
                                              {...props.input}
                                              icon={<RadioButtonUncheckedIcon />}
                                              checkedIcon={
                                                  <RadioButtonCheckedIcon color="primary" />
                                              }
                                          />
                                      }
                                  />
                                  <Typography variant="body2">
                                      I certify that I have read and accept the{" "}
                                      <a href={`${process.env.REACT_APP_MAIN_HOST}/terms`} target="_blank" rel="noreferrer">Terms and conditions and Privacy policy</a>
                                  </Typography>
                              </Grid>
                          );
                      }}
                  </Field>
                <FormSpy subscription={{ values: "policy_agree" }}>
                  {(spyProps) => {
                    let buttonsDisabled = !spyProps.values.policy_agree;
                    return (
                      <>
                        <Grid item xs={12}>
                          <Button
                            disabled={buttonsDisabled}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.btnLogin}
                          >
                            Sign up
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.loginDivider} noWrap>
                            or
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.socialLogin}>
                          <FacebookLogin
                            appId={process.env.REACT_APP_FACEBOOK_API}
                            fields="name,email,picture"
                            textButton="Sign up with Facebook"
                            onClick={componentClicked}
                            className={classes.socialLoginItem}
                            onFailure={(response) => {
                              dispatch(loginUserFacebookErrorAction(response));
                            }}
                            icon={
                              <img
                                src={facebookIcon}
                                alt="facebook icon"
                                className=""
                              />
                            }
                            callback={(response) => {
                              if (!response.status) {
                                dispatch(
                                  loginUserFacebookSuccessAction({
                                    data: response,
                                    type: "facebook",
                                  })
                                );
                              }
                            }}
                          />
                          <Button
                          className="google"
                          startIcon={<img src={googleIcon} alt="google"/>}
                          onClick= {()=> googleLogin()}
                          >
                            Sign in with Google
                          </Button>
                        </Grid>
                      </>
                    );
                  }}
                </FormSpy>
                <Grid item xs={12}>
                  <p className={classes.textSignup}>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
