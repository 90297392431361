import React from "react";
import "@asseinfo/react-kanban/dist/styles.css";

import { useState, useEffect } from "react";

/* import { TextField } from "mui-rff"; */
import {
  Paper,
  Button,
  Typography,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  IconButton,
  MenuItem,
  Popper,
  Grow,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";

import SearchIcon from "../assets/search.svg";

import ProfileAvatar from "./ProfileAvatar";
import { useSelector, useDispatch } from "react-redux";
import { sendMessage } from "../actions/wsActions";

import { loadMessagesAction } from "../actions/chatActions";
import { Scrollbars } from "react-custom-scrollbars-2";
import useStyles from "../styles/Team";
import clsx from "clsx";
import MessageInput from "./MessageInput";
import MessagesList from "./MessagesList";

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 0,
    backgroundColor: "rgba(0, 0, 0)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => {
  return (
    <Scrollbars
      renderThumbHorizontal={renderThumb}
      renderThumbVertical={renderThumb}
      ref={props.reference}
      {...props}
    />
  );
};

const TeamTabChat = React.memo((props) => {
  const { team, teamOwner, opportunityOwner } = props;

  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);

  let activeChatTitleInitial =
    user?.id === opportunityOwner?.id && user?.id !== teamOwner?.id
      ? teamOwner?.name
      : team.name;

  let activeChatChannelInitial =
    user?.id === opportunityOwner?.id && user?.id !== teamOwner?.id
      ? `user.${user?.id}.to.${teamOwner.id}.team.${team?.id}`
      : `team.${team?.id}`;

  let activeChatChannelMemberInitial =
    user?.id === opportunityOwner?.id && user?.id !== teamOwner?.id
      ? teamOwner
      : null;

  const [activeChatTitle, setActiveChatTitle] = useState(
    activeChatTitleInitial
  );
  const [activeChatChannel, setActiveChatChannel] = useState(
    activeChatChannelInitial
  );
  const [activeChatChannelMember, setActiveChatChannelMember] = useState(
    activeChatChannelMemberInitial
  );

  const messages = useSelector(
    (state) => state.chatReducer.chats?.[activeChatChannel]
  );

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  /*   const handleMenuOpen = () => {
    setOpen(true);
  }; */

  useEffect(() => {
    async function getData() {
      await dispatch(
        loadMessagesAction({
          endpoint: `chat/history/${activeChatChannel}`,
          name: activeChatChannel,
        })
      );
    }
    getData();
  }, [activeChatChannel, dispatch]);

  useEffect(() => {
    async function getData() {
      await dispatch(
        loadMessagesAction({
          endpoint: `chat/history/${activeChatChannel}`,
          name: activeChatChannel,
        })
      );
    }
    getData();
  }, [dispatch, activeChatChannel]);

  let chatMembers = (props) => {
    const { team, user, teamOwner, opportunityOwner } = props;
    let members = [];

    if (user?.id === opportunityOwner?.id && user?.id !== teamOwner?.id) {
      teamOwner.member = teamOwner;
      members = [...members, teamOwner];
    }
    if (user?.id === teamOwner?.id) {
      opportunityOwner.member = opportunityOwner;
      members = [
        ...members,
        opportunityOwner,
        ...props?.team?.members.filter((m) => m?.id !== 0),
      ];
    }

    if (user?.id !== teamOwner?.id && user?.id !== opportunityOwner?.id) {
      members = [...members, ...team.members];
    }

    return [
      ...new Map(
        members
          .filter((m) => m.member.id !== user?.id)
          .map((item) => [item.member["id"], item])
      ).values(),
    ];
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.teamMembers}
    >
      <Grid item xs={12} lg={3} className={classes.chatAccounts}>
        <Paper
          className={clsx(
            classes.chatAccountsPaper,
            classes.chatAccountsPaperUser
          )}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.chatAccountsPaperUserContainer}
          >
            <Grid item xs={12} hidden>
              <TextField
                fullWidth
                placeholder="Search messages..."
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img src={SearchIcon} alt="Search messages" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.teamInputField}
              />
            </Grid>
            <Grid item xs={12}>
              <List className={classes.teamList}>
                {user?.id === teamOwner?.id ||
                team?.members?.some((m) => m?.member?.id === user?.id) ? (
                  <ListItem
                    key="RemySharp"
                    className={clsx(
                      classes.chatTeam,
                      activeChatChannel === `team.${team.id}` ? "active" : ""
                    )}
                    onClick={() => {
                      setActiveChatTitle(team.name);
                      setActiveChatChannel(`team.${team.id}`);
                      setActiveChatChannelMember(
                        (activeChatChannelMember) => null
                      );
                    }}
                  >
                    <ListItemIcon className={classes.chatListIcon}>
                      <div
                        className={clsx(
                          "default-profile-avatar-container",
                          `profile-gradient-${team?.id % 15}`
                        )}
                      >
                        <Typography variant="body2">
                          {team.name[0].toUpperCase()}
                        </Typography>
                      </div>
                    </ListItemIcon>
                    <ListItemText
                      primary={team.name}
                      className={classes.chatListName}
                    ></ListItemText>
                  </ListItem>
                ) : null}
                {chatMembers({ user, team, teamOwner, opportunityOwner }).map(
                  (m) => {
                    return (
                      <ListItem
                        className={
                          activeChatChannel ===
                            `user.${user?.id}.to.${m?.member?.id}` ||
                          activeChatChannel ===
                            `user.${user?.id}.to.${m?.member?.id}.team.${team?.id}`
                            ? "active"
                            : ""
                        }
                        key={m?.member?.id}
                        onClick={() => {
                          setActiveChatTitle(m?.member?.name);
                          setActiveChatChannel(
                            (activeChannel) =>
                              `user.${user?.id}.to.${m?.member?.id}.team.${team?.id}`
                          );

                          setActiveChatChannelMember(
                            (activeChatChannelMember) => m?.member
                          );
                        }}
                      >
                        <ListItemIcon className={classes.chatListIcon}>
                          <ProfileAvatar
                            imageLink={m?.member?.profile_image?.link}
                            user={m?.member}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={m?.member?.name}
                          className={classes.chatListName}
                        ></ListItemText>
                      </ListItem>
                    );
                  }
                )}
              </List>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Paper className={classes.chatAccountsPaper}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.chatAccountsPaperContainer}
          >
            <Grid className={classes.chatHeader}>
              <Grid className={classes.chatHeaderAvatar}>
                {activeChatChannelMember ? (
                  <ProfileAvatar
                    imageLink={activeChatChannelMember?.profile_image?.link}
                    user={activeChatChannelMember}
                  />
                ) : (
                  <div
                    className={clsx(
                      "default-profile-avatar-container",
                      `profile-gradient-${team?.id % 15}`
                    )}
                  >
                    <Typography variant="body2">
                      {activeChatTitle[0].toUpperCase()}
                    </Typography>
                  </div>
                )}
                <Typography
                  variant="body2"
                  className={classes.chatHeaderAvatarName}
                >
                  {activeChatTitle}
                </Typography>
              </Grid>
              <Grid className={classes.chatHeaderButtons}>
                {/*<TextField
                  fullWidth
                  id="input-with-icon-textfield"
                  className={clsx(
                    classes.teamInputField,
                    classes.chatHeaderField
                  )}
                  placeholder="Search within chat"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <img src={SearchIcon} alt="Search messages" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton id="chatMenu" onClick={handleMenuOpen} className={classes.chatHeaderMenu}>
                                    <MoreVertIcon />
                                </IconButton>*/}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.chatBody}>
              <CustomScrollbars
                style={{ height: "100%", position: "absolute" }}
                className={classes.scrollBars}
              >
                <MessagesList data={messages} />
              </CustomScrollbars>
            </Grid>
            <Grid item xs={12}>
              <MessageInput
                sendAction={(dataToSend) =>
                  dispatch(
                    sendMessage({ ...dataToSend, channel: activeChatChannel })
                  )
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Popper
        open={open}
        anchorEl={document.getElementById("chatMenu")}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.chatMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem>
                    <Button>Delete</Button>
                  </MenuItem>
                  <MenuItem>
                    <Button>Other Setting</Button>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
});

export default TeamTabChat;
