import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ProfileCreator from "../components/ProfileCreator";
import ProfileBusiness from "../components/ProfileBusiness";
import NotFound from "../pages/NotFound";
import { useParams } from "react-router-dom";

import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

import {
  formGetCustomDataAction,
  formGetDataAction,
} from "../actions/formActions";
import { isCreator } from "../utils/misc";

const Profile = (props) => {
  let { userId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);
  const data = useSelector((state) => state.formReducer.data);
  const error = useSelector((state) => state.errorReducer.error);

  let id = userId ? userId : user.id;
  const [isCurrentUser, setIsCurrentUser] = useState(
    () => parseInt(id) === user.id
  );

  useEffect(() => {
    async function getData() {
      await dispatch(
        formGetCustomDataAction({
          endpoint: `users/${id}/creations`,
          name: "creations",
        })
      );
      await dispatch(
        formGetCustomDataAction({
          endpoint: `users/${id}/opportunities`,
          name: "opportunities",
        })
      );
      if (!isCurrentUser) {
        await dispatch(formGetDataAction({ endpoint: `users/${id}` }));
      }
    }
    getData();
  }, [isCurrentUser, dispatch, id]);

  useEffect(() => {
    async function getData() {
      await dispatch(
        formGetCustomDataAction({
          endpoint: `users/${id}/creations`,
          name: "creations",
        })
      );
      await dispatch(
        formGetCustomDataAction({
          endpoint: `users/${id}/opportunities`,
          name: "opportunities",
        })
      );
    }
    getData();
    setIsCurrentUser((currentUser) => parseInt(id) === user.id);
  }, [dispatch, user, id]);

  useEffect(() => {
    async function getData() {
      if (!isCurrentUser) {
        await dispatch(formGetDataAction({ endpoint: `users/${id}` }));
      }
    }
    getData();
  }, [dispatch, id, isCurrentUser]);

  /*   if (loading) {
    return <CircularProgress className="main-progress-bar"/>;
  } */

  if (isCurrentUser) {
    return isCreator(user) ? (
      <ProfileCreator user={user} />
    ) : (
      <ProfileBusiness user={user} />
    );
  } else {
    if (error) {
      return <NotFound />;
    }

    return data ? (
      isCreator(data) ? (
        <ProfileCreator user={data} />
      ) : (
        <ProfileBusiness user={data} />
      )
    ) : (
      <CircularProgress className="main-progress-bar" />
    );
  }
};

export default Profile;
