import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    confirmationDialog: {
        "& .MuiDialog-paperWidthSm": {
            backgroundColor: "transparent",
            padding: "0",
            margin: 0,
            maxWidth: "100%",
            boxShadow: "none",
            borderRadius: 0,
            maxHeight: "100%",
            [theme.breakpoints.up("lg")]: {
                left: 100,
                right: 100,
                top: 50,
                bottom: 50,
                overflow: "visible",
                position: "fixed",
            },
        },
        "& .MuiBackdrop-root": {
            background: "rgba(0, 0, 0, 0.6)",
        },
    },
    confirmationClose: {
        position: "absolute",
        right: 20,
        top: 25,
        padding: 10,
        minWidth: "auto",
        "&:hover": {
            background: "none"
        },
        [theme.breakpoints.down("md")]: {
            "& img": {
                filter: "brightness(0%)",
            }
        },
        [theme.breakpoints.up("lg")]: {
            right: "-57px",
            top: "-10px",
        },
    },
    detailPostContainer:{
        [theme.breakpoints.up("lg")]: {
            height: "100%",
            display: "flex",
        },
    },
    detailPost: {
        background: "#FFFFFF",
        boxSizing: "border-box",
        width: "100%",
        [theme.breakpoints.up("lg")]: {
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
            borderRadius: 8,
            height: "100%",
        },
    },
    chatListIcon: {
        "& .default-profile-avatar-container, & img": {
            width: 40,
            height: 40,
        },
        "& .default-profile-avatar-container p": {
            fontSize: "20px",
            lineHeight: "24px",
        },
    },
    detailPostInfo: {
        padding: "69px 21px 0",
        boxSizing: "border-box",
        "& .MuiCardHeader-root": {
            padding: "0 0 28px",
        },
        "& .userMenuAvatarBlock": {
            height: 40,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "22px 21px",
            overflowY: "auto",
            height: "100%",
            scrollbarWidth: "thin",
            scrollbarColor: "#000 #fff",
            "&::-webkit-scrollbar": {
                width: 6,
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#000",
            }
        },
    },
    commentsSidebar: {
        background: "#FFFFFF",
        paddingTop: 36,
        boxSizing: "border-box",
        [theme.breakpoints.up("lg")]: {
            paddingTop: 22,
            height: "100%",
            marginLeft: 17,
            minWidth: 497,
            width: 497,
            boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
            borderRadius: 8,
        },
    },
    detailPostImage: {
        marginTop: 30,
        marginBottom: 17,
        "& img": {
            maxWidth: "100%",
            borderRadius: 8,
            overflow: "hidden",
        }
    }
}));

export default useStyles;
