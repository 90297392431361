import React from "react";
import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { formGetDataAction } from "../actions/formActions";
import { Redirect } from "react-router";
import OpportunityFormWrapper from "../containers/OpportunityFormWrapper";

const OpportunityEditWrapper = () => {
  const dispatch = useDispatch();
  let { opportunityId } = useParams();

  useEffect(() => {
    dispatch(formGetDataAction({ endpoint: `opportunities/${opportunityId}` }));
  }, [dispatch, opportunityId]);

  const data = useSelector((state) => state.formReducer.data);
  const user = useSelector((state) => state.userReducer.user);
  if (!data) {
    return <CircularProgress className="main-progress-bar" />;
  }

  if (data.created_by?.id !== user.id) {
    return <Redirect to={`/calls/${opportunityId}`} />;
  }

  let modifiedData = {
    ...data,
    body: JSON.parse(data.body),

    stages: data.stages?.map((s) => {
      if (typeof s.body === "object") {
        s.body = JSON.stringify(s.body);
      }
      s.body = JSON.parse(s.body);
      return s;
    }),
  };

  return <OpportunityFormWrapper initialValues={modifiedData} edit={true} />;
};

export default OpportunityEditWrapper;
