import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ".Mui-focused": {

    },
    autocompleFieldContainer: {
        marginBottom: 54,
        paddingLeft: 23,
        paddingRight: 23,
        lineHeight: "35px",
        fontSize: "32px",
        [theme.breakpoints.up("md")]: {
            paddingLeft: 148,
            paddingRight: 148,
            fontSize: "52px",
            lineHeight: "64px",
            marginBottom: 61,
        },
        maxWidth: "100%",
    },
    displayedAutoComplete: {
        display: "none"
    },
    autocompleField: {
        paddingBottom: 0,
        lineHeight: "35px",
        fontSize: "32px",
        marginBottom: 0,
        [theme.breakpoints.up("md")]: {
            fontSize: "52px",
            lineHeight: "64px",
        },
        "&>.MuiFormControl-root": {
            marginBottom: 0,
        },
        "& MuiInput-root": {
            height: 100
        },
        "& .MuiInputBase-root:before": {
            display: "none"
        },
        "& input": {
            lineHeight: "35px",
            fontSize: "32px",
            [theme.breakpoints.up("md")]: {
                fontSize: "52px",
                lineHeight: "64px",
            },
        },
        "&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot": {
            paddingRight: 0,
        }
    },
    autoCompleteLabel: {
        "&>label": {
            lineHeight: "35px",
            fontSize: "32px",
            [theme.breakpoints.up("md")]: {
                fontSize: "52px",
                lineHeight: "64px",
            },
        },
    },
    selectPillsContainer: {
        paddingLeft: 23,
        paddingRight: 23,
        "&:not(:empty)": {
            paddingBottom: 22,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 148,
            paddingRight: 148,
            "&:not(:empty)": {
                paddingBottom: 11,
            },
        },
    },
    selectPill: {
        marginBottom: 16,
        marginRight: 10,
        backgroundColor: "#6233C0",
        color: "#fff",
        borderRadius: 4,
        fontSize: 14,
        lineHeight: "18px",
        "&>svg": {
            color: "#fff!important"
        },
        "&:focus": {
            backgroundColor: "#6233C0",
        }
    },
    gridForm: {
        backgroundColor: "#F5F5F5",
    },
    gridPill: {
        backgroundColor: "#F5F5F5!important",
        color: "#000",
        fontSize: "25px",
        lineHeight: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: 188,
        cursor: "pointer",
        whiteSpace: "break-spaces",
        paddingRight: "27px",
        transition: "background-color .7s",
        textTransform: "uppercase",
        border: "1px solid #fff",
        "&:hover": {
            background: "#000000!important",
            color: "#fff!important",
        },
        [theme.breakpoints.up("md")]: {
            height: 125,
            paddingRight: "0",
        },
    },
    paperClass: {
        maxWidth: "329px",
        borderRadius: "0",
        paddingRight: "0",
        "&>*": {
            fontFamily: "Gilroy",
            fontSize: "36px",
            lineHeight: "43px",
        }
    },
    gridPillSelected: {
        backgroundColor: "#F5F5F5",
        color: "#000",
        fontSize: "25px",
        lineHeight: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: 188,
        cursor: "pointer",
        whiteSpace: "break-spaces",
        textTransform: "uppercase",
        border: "1px solid #fff",
        [theme.breakpoints.up("md")]: {
            height: 125,
        },
        "&:after": {
            content: "'SELECTED'",
            display: "block",
            fontSize: "12px",
            lineHeight: "24px",
            opacity: 0.8,
            fontFamily: "Gilroy"
        }
    },
}));

export default useStyles;