import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	media: {
		height: "auto",
		width: "100%",
		position: "relative",
		objectFit: "contain",
	},
	container: {
		boxShadow: "none",
		borderRadius: 8,
		height: "100%",
		"&:hover": {
			boxShadow: "0 10px 20px rgba(0, 0, 0, 0.06)",
		},
	},
	toggleContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
	},
	blockTitle: {
		padding: "17px 10px"
	},
	title: {
		fontFamily: "Gilroy",
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "20px",
		color: "inherit",
		maxHeight: 40,
		overflow: "hidden"
	},
	link: {
		height: "100%",
		background: "#fff",
		color: "#636363",
		"&:hover": {
			color: "#333"
		}
	},
	opportunity: {
		width: 22,
		height: 22,
		position: "absolute",
		left: 10,
		top: 10,
	},
	status: {
		color: "#fff",
		padding: "0 15px",
		lineHeight: "22px",
		fontSize: 12,
		fontWeight: 600,
		fontFamily: "Gilroy",
		position: "absolute",
		right: 10,
		borderRadius: 12,
		top: 10,
		'&.draft': {
			backgroundColor: "#6D6D71",
		},
		'&.consideration': {
			backgroundColor: "#FFA031",
		},
		'&.denied': {
			backgroundColor: "#D23535",
		},
		'&.approved': {
			backgroundColor: "#34A853",
		},
		'&.opened': {
			backgroundColor: "#2440FF",
		},
		'&.ongoing': {
			backgroundColor: "#4CBC08",
		},
		'&.completed': {
			backgroundColor: "#ED6400",
		},
		'&.done': {
			backgroundColor: "#6233C0",
		},
		'&.archived': {
			backgroundColor: "#6D6D71",
		},
		'&.for_sale': {
			backgroundColor: "#DE29C3",
		},
		'&.paused': {
			backgroundColor: "#3E3C8F",
		},
	},
	gradient: {
		background: "linear-gradient(207.36deg, #2100A4 11.1%, #F0A9D6 82.95%)"
	},
	teamItem: {
		paddingBottom: 98,
		[theme.breakpoints.up("md")]: {
			paddingBottom: 128,
		},
	},
	teamContainer: {
		paddingTop: 40,
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	teamImage: {
		maxWidth: "100%",
	},
	teamTitle: {
		width: "100%",
		"& a": {
			textDecoration: "none",
			color: "#000",
		},
		"& a:hover": {
			textDecoration: "underline",
			color: "#6233C0",
		},
		"& h3": {
			fontSize: 32,
			lineHeight: "39px",
			paddingBottom: 20,
			maxWidth: 570,
		},
		"& .MuiTypography-body2": {
			fontSize: 16,
			lineHeight: "24px",
			color: "#636363",
			maxWidth: 570,
			marginBottom: 26,
		}
	},
	teamRoles: {
		minWidth: 211,
		"& .buttons button": {
			margin: 0,
			width: "100%",
		},
		"& .buttons + .buttons": {
			marginTop: 10,
		},
		[theme.breakpoints.down("sm")]: {
			paddingTop: 28,
		},
	},
	filledRoles: {
		paddingBottom: 33,
	},
	titleRoles: {
		display: "flex",
		color: "#636363",
		paddingBottom: 10,
		alignItems: "center",
		"& svg": {
			marginLeft: 3,
		}
	},
	teamUser: {
		display: "flex",
		alignItems: "center",
		"& + div": {
			paddingTop: 12,
		}
	},
	teamUserName: {
		paddingLeft: 13,
	},
	teamButtonReview: {
		padding: 0,
		textTransform: "none",
		color: "#6233C0",
		"&:hover": {
			background: "none",
			textDecoration: "underline",
		}
	}
}));

export default useStyles;