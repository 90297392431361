import { useEffect } from "react";
import { connectToSocket } from "../actions/wsActions";
import { useSelector, useDispatch } from "react-redux";

const WebsocketWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userReducer.user);
  const socket = useSelector((state) => state.wsReducer.response);
  let channels = user?.channels;

/*   useEffect(() => {
    channels?.forEach((ch) => {
      dispatch(
        connectToSocket({
          channel: ch,
          socket,
        })
      );
    });
  }, [dispatch, socket, channels]); */

  return <>{children}</>;
};

export default WebsocketWrapper;
