import React from "react";
import { Snackbar } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import * as types from "../actions";

import useStyles from "../styles/SuccessNotification";

const SuccessNotification = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isOpen = useSelector((state) => state.successReducer.isOpen);
  const message = useSelector((state) => state.successReducer.message);

  const handleClose = (event, reason) => {
    dispatch({ type: types.HIDE_MESSAGE });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpen && message}
        className={classes.container}
        onClose={handleClose}
        message={message}
      />
    </div>
  );
};

export default SuccessNotification;
