import * as types from "../actions";
import { getUniqueListBy } from "../utils/misc";

const notificationsReducer = (
  state = {
    notifications: [],
    last: null,
    error: null,
    loading: false,
    meta: null,
  },
  action
) => {
  const response = action.response;
  switch (action.type) {
    case types.LOAD_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case types.NOTIFICATIONS_RESET_DATA:
      return {
        ...state,
        notifications: [],
        last: null,
        error: null,
        loading: false,
        meta: null,
      };
    case types.WEBSOCKET_NOTIFICATION_RECEIVED:
      let updatedNotifications = [response, ...state.notifications];
      return {
        ...state,
        last: response,
        notifications: updatedNotifications,
        error: null,
      };

    case types.LOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...response.data],
        meta: response.meta,
        error: null,
        loading: false,
      };
    case types.RELOAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        /*         notifications: getUniqueListBy(
          [...response.data, ...state.notifications],
          "id"
        ), */
        notifications: [...response.data],
        meta: response.meta,
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default notificationsReducer;
