import * as types from "../actions";

const wsReducer = (state = [], action) => {
  const response = action.response;

  switch (action.type) {
    case types.REGISTER_WS_SUCCESS:
      return { ...state, response };
    case types.REGISTER_WS_ERROR:
      return { ...state, response };
    default:
      return state;
  }
};

export default wsReducer;
