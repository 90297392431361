import React, { useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { TextField } from "mui-rff";
import { Button, Typography, Grid } from "@material-ui/core";
import {
  loginUserAction,
  loginUserGoogleAction,
  loginUserGoogleSuccessAction,
  loginUserGoogleErrorAction,
  loginUserFacebookSuccessAction,
  loginUserFacebookErrorAction,
} from "../actions/authenticationActions";
import { useSelector, useDispatch } from "react-redux";
import FacebookLogin from "react-facebook-login";
import { Form } from "react-final-form";
import { sleep } from "../utils/misc";
import useStyles from "../styles/Login";
import { Helmet } from "react-helmet";
import facebookIcon from "../assets/facebook-circle.svg";
import googleIcon from "../assets/google-circle.svg";
import { useLocation } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios";


const Login = () => {
  const dispatch = useDispatch();
  let location = useLocation();

  let from = location.state || { from: { pathname: "/" } };


  let googleLogin = useGoogleLogin({
    onSuccess: async(response) => {
      const userInfo = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${response.access_token}` } },
      );
      dispatch(
        loginUserGoogleSuccessAction({
          data: userInfo?.data,
          type: "google",
        })
      )
    },

    onError: (response) => {

      dispatch(loginUserGoogleErrorAction(response));
    }
  });

  const classes = useStyles();
  const stateRef = useRef();
  const user = useSelector((state) => state.userReducer.user);
  let errors = useSelector((state) => state.errorReducer.error);
  stateRef.current = errors;

  if (user) {
    return <Redirect to={from} />;
  }

  let componentClicked;
  return (
    <>
      <Helmet>
        <title>Login | weCreators</title>
      </Helmet>
      <Grid className={classes.centerForm}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Form
            onSubmit={async (values) => {
              dispatch(loginUserAction(values));
              await sleep(600);
              //history.replace(from);
              return stateRef.current;
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signin" onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Typography variant="h1" className={classes.h1Var1}>
                    Log in
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email Address"
                    variant="outlined"
                    className={classes.loginText}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    className={classes.loginText}
                    type="password"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.textForgetPassword}>
                    <Link to="/password-reset">Forgot password?</Link>
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.btnLogin}
                  >
                    Log in
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.loginDivider} noWrap>
                    or
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.socialLogin}>
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_API}
                    fields="name,email,picture"
                    icon={
                      <img
                        src={facebookIcon}
                        alt="facebook icon"
                        className=""
                      />
                    }
                    onClick={componentClicked}
                    className={classes.socialLoginItem}
                    onFailure={(response) => {
                      dispatch(loginUserFacebookErrorAction(response));
                    }}
                    callback={(response) => {
                      if (!response.status) {
                        dispatch(
                          loginUserFacebookSuccessAction({
                            data: response,
                            type: "facebook",
                          })
                        );
                      }
                    }}
                  />
                  <Button
                  className="google"
                  startIcon={<img src={googleIcon} alt="google"/>}
                  onClick= {()=> googleLogin()}
                  >
                    Sign in with Google
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.textSignup}>
                    Don't have an account ? <Link to="/register">Sign up</Link>
                  </p>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
