export const getShortBlock = ({type, data}) => {
    let content = ""
    switch (type) {
        case "header":
            content = data.text;
            break;
        case "paragraph":
            content = data.text;
            break;
        case "list":
            // content =
            //     data.items.map(item =>  <li class="cdx-list__item">{item}</li>)

            break;
        case "embed":
            break;
        case "code":
            break;
        case "image":
            break;
        default:
            content = data.text;//.replace(/<[^>]*>?/gm, '');
            break;
    }
    return content.replace("&nbsp;"," ");//.replace(/&amp;/g, '&');
};
function flatMap(array, fn) {
    let result = [];
    for (let i = 0; i < array.length; i++) {
        const mapping = fn(array[i]);
        result = result.concat(mapping);
    }
    return result;
}
function removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();

    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}
function decodeHTMLEntities(str) {
    return String(str).replaceAll("&amp;", '&').replaceAll("&lt;", '<').replaceAll("&gt;", '>').replaceAll("&quot;", '"');
}
// function decodeHTMLEntities(text) {
//     var textArea = document.createElement('textarea');
//     textArea.innerHTML = text;
//     return textArea.value;
// }
function strip(html){
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}
export const GetShortText = (props) => {
    let {body, searchValue} = props;
    searchValue = searchValue.trim();
    let content = "";
    if(body){
        let all = [];
        if(typeof body === "string"){
            all = JSON.parse(body);
        }
        else if(typeof body === "object"){
            all = body;
        }

        let result = all.blocks.map(block => getShortBlock(block));
        let text = strip(result.join(" "));

        if(text.length > 310) {
            content = text.substr(0, 310) ;
            if(searchValue){
                content = flatMap(content.split(searchValue), function (part) {
                    return [part, <span style={{background: "rgba(98, 51, 192, 0.2)", borderRadius: "100px",padding:"3px"}}>{searchValue}</span>];
                });
                content.pop();

            }
            content = content.concat(["..."]);

        }
        else {
            if(searchValue){
                text = flatMap(text.split(searchValue), function (part) {
                    return [part, <span style={{background: "rgba(98, 51, 192, 0.2)", borderRadius: "100px",padding:"3px"}}>{searchValue}</span>];
                });
                text.pop();
            }
            content = text;
        }


    }
    return (<div>
        {content}
    </div>);
}