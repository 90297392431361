import React from "react";
import { Grid, Typography } from "@material-ui/core";
import arrowRight from "../assets/creative_test_arrow_right.svg";

import useStyles from "../styles/ProfileCreativeTestStepOne";

const ProfileCreativeTestStepOne = (props) => {
  const classes = useStyles();

  return (
    <Grid className={classes.mainContainerStep}>
      <Grid className={classes.centerForm}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.containerStepOne}
        >
          <Grid className={classes.stepOneText}>
            <Typography className={classes.stepOneText1}>
              Let’s find out your <br />
              Creator type
            </Typography>
            <Typography className={classes.stepOneText2}>
              Press it and go back to fill in results
            </Typography>
            <img
              src={arrowRight}
              alt="arrow-right"
              className={classes.stepOneTextObj}
            />
          </Grid>
          <Grid className={classes.stepOneCircleBtnContainer}>
            <div
              onClick={() => {
                window.open(
                  "https://mycreativetype.com/",
                  "",
                  "",
                  "width=600,height=400,left=200,top=200"
                );
                props.setFormOpen(true);
              }}
            >
              <Typography variant="body2" className={classes.stepOneCircleBtn}>
                <span>START my creative type TEST</span>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProfileCreativeTestStepOne;
