import React, { useEffect, useRef } from "react";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import { makeValidate } from "mui-rff";
import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  formSubmitAction,
  formGetHeaderDataAction,
} from "../actions/formActions";
import useStyles from "../styles/CreationOpportunityTeamForm";

import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import TeamsFormPage1 from "./TeamsFormPage1";
import TeamsFormPage2 from "./TeamsFormPage2";
import TeamsFormPage3 from "./TeamsFormPage3";

const getBudget = (props) => {
  const { data, id } = props;
  let budget = data?.reduce((acc, cur) => {
    if (cur.id === parseInt(id)) {
      acc = cur.budget;
    }
    return acc;
  }, 0);

  return budget;
};

const schemaPage1 = Yup.object().shape({
  name: Yup.string().required("Team name is required"),
  description: Yup.string().required("Team description is required"),
  opportunity_id: Yup.number().required(
    "Select an opportunity for which team will be created"
  ),
});
const validatePage1 = makeValidate(schemaPage1);

const schemaPage2 = Yup.object().shape({
  opportunity_roles: Yup.array().of(
    Yup.object().shape({
      role_id: Yup.number().typeError("Select role"),
    })
  ),
});
const validatePage2 = makeValidate(schemaPage2);

const schemaPage3 = Yup.object().shape({
  terms_of_profit_id: Yup.number().required("Select terms of profit"),
});
const validatePage3 = makeValidate(schemaPage3);

const TeamsForm = (props) => {
  let params = useParams();
  let oppoId = params?.opportunityId
  const dispatch = useDispatch();
  const classes = useStyles();
  const { initialValues, edit } = props;
  //const opportunity = useRef(null);
  const opportunityCode = useRef(null);
  const opportunityRolesLength = useRef(null);

  const [maxProfit, setMaxProfit] = React.useState(0);
  const [budget, setBudget] = React.useState(0);
  const [opportunityBudget, setOpportunityBudget] = React.useState(0);
  const [placeholderSymbol, setPlaceholderSymbol] = React.useState("");
  const [profitSplit, setProfitSplit] = React.useState(null);
  const [opportunityId, setOpportunityId] = React.useState(
    oppoId ? parseInt(oppoId) : null
  );

  const [opportunity, setOpportunity] = React.useState(null);

  const opportunitiesList = useSelector(
    (state) => state.formReducer.references["opportunities"]
  );

  useEffect(() => {
    /*     opportunity.current = opportunitiesList?.reduce((acc, cur) => {
      if (cur.id === parseInt(opportunityId)) {
        acc = cur;
      }
      return acc;
    }, null); */
    setOpportunity((opportunity) =>
      opportunitiesList?.reduce((acc, cur) => {
        if (cur.id === parseInt(opportunityId)) {
          acc = cur;
        }
        return acc;
      }, null)
    );

    //opportunityCode.current = opportunity.current?.opportunity_type?.code;
  }, [opportunitiesList, opportunityId]);

  useEffect(() => {
    let budget = getBudget({
      data: opportunitiesList,
      id: opportunityId,
    });
    setMaxProfit((maxProfit) => budget);
    setBudget((test) => budget);
    setOpportunityBudget((opportunityBudget) => budget);
  }, [opportunitiesList, opportunityId]);

  useEffect(() => {
    let budget = getBudget({
      data: opportunitiesList,
      id: opportunityId,
    });
    setOpportunityBudget((opportunityBudget) => budget);
    // percent
    if (profitSplit === "percentage") {
      setMaxProfit((maxProfit) => 100);
      setBudget((budget) => 100);
      setPlaceholderSymbol("%");
    }

    // split
    if (profitSplit === "fixed_price") {
      setMaxProfit((maxProfit) => budget);
      setBudget((oldBudget) => budget);
      setPlaceholderSymbol("EUR");
    }
  }, [opportunitiesList, opportunityId, profitSplit]);

  useEffect(() => { }, [maxProfit]);

  return (
    <>
      <Helmet>
        <title>Teams | weCreators</title>
      </Helmet>
      <Grid className={clsx(classes.creationMain, classes.teamMain)}>
        <div className={classes.teamFormContainer}>
          {!edit ? (
            <Typography className={classes.teamHeaderOpportunity}>
              Call name
            </Typography>
          ) : null}
          <Typography className={classes.creationHeader}>
            {edit ? "Edit the team" : "Create a new team"}
          </Typography>
          <Wizard
            rerenderValue={[opportunity, budget, maxProfit]}
            className={classes.creationSteps}
            title="Create a new team"
            initialValues={
              initialValues
                ? initialValues
                : {
                  opportunity_id: oppoId ? oppoId : undefined,
                }
            }
            onSubmit={(values) => {
              if (edit) {
                dispatch(
                  formSubmitAction({
                    submitType: "put",
                    formValues: values,
                    endpoint: `/opportunities/${values.opportunity_id}/teams/${values.id}`,
                    redirect: `/calls/${values.opportunity_id}/teams/${values.id}`,
                  })
                );
              } else {
                dispatch(
                  formSubmitAction({
                    formValues: values,
                    endpoint: `/opportunities/${values.opportunity_id}/teams`,
                    redirectToNew: true,
                  })
                );
                dispatch(
                  formGetHeaderDataAction({
                    endpoint: `teams/i-member`,
                    name: "teams",
                  })
                );
              }
            }}
          >
            {initialValues?.status?.code === "recruitment" ?
              null :
              (<TeamsFormPage1
                breadcrumb="1.&nbsp;Team&nbsp;info"
                validate={validatePage1}
                setOpportunityId={setOpportunityId}
                oppoId={oppoId}
              />)
            }
            <TeamsFormPage2
              breadcrumb="2.&nbsp;Team&nbsp;roles"
              validate={validatePage2}
              opportunitiesList={opportunitiesList}
              opportunityId={opportunityId}
              opportunity={opportunity}
              opportunityCode={opportunityCode}
              opportunityRolesLength={opportunityRolesLength}
            />
            <TeamsFormPage3
              opportunity={opportunity}
              breadcrumb="3.&nbsp;Settings"
              validate={validatePage3}
              setProfitSplit={setProfitSplit}
              opportunityBudget={opportunityBudget}
              maxProfit={maxProfit}
              setMaxProfit={setMaxProfit}
              budget={budget}
              placeholderSymbol={placeholderSymbol}
            />
          </Wizard>
        </div>
      </Grid>
    </>
  );
};

export default TeamsForm;
