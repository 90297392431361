import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    cookiePopup: {
        background: "rgba(0, 0, 0, 0.8)",
        bottom: 0,
        width: "100%",
        transform: "none!important",
        position: "fixed!important",
        top: "unset!important",
        zIndex: 1000,
    },
    cookiePopupContainer: {
        padding: "31px 23px 32px",
        [theme.breakpoints.up("lg")]: {
            display: "flex",
            padding: "31px 265px 32px",
            alignItems: "center",
        }
    },
    cookiePopupDescription: {
        fontSize: 16,
        fontFamily: "Gilroy",
        lineHeight: "24px",
        color: "#F5F5F5",
        fontWeight: 500,
        "& a": {
            color: "#F5F5F5",
            textDecoration: "underline",
        },
        "& a:hover": {
            textDecoration: "none",
        },
    },
    cookiePopupTitle: {
        color: "#fff",
        fontSize: 26,
        lineHeight: "34px",
        paddingBottom: 8,
        fontWeight: 500,
        fontFamily: "Gilroy",
    },
    cookiePopupClose: {
        position: "absolute",
        top: 20,
        right: 20,
        width: 15,
        height: 15,
        padding: 0,
        minWidth: 15,
        "&:hover:before, &:hover:after": {
            opacity: 0.6,
        },
        "&:before, &:after": {
            content: "''",
            position: "absolute",
            width: 20,
            height: 1,
            background: "#fff",
            transform: "rotate(-45deg)",
            top: 7,
            left: -3,
        },
        "&:before": {
            transform: "rotate(45deg)",
        },
        [theme.breakpoints.up("lg")]: {
            right: 46,
            top: 22,
        }
    },
    cookiePopupButton: {
        background: "#000",
        display: "inline-block",
        color: "#fff",
        fontWeight: 600,
        textTransform: "uppercase",
        padding: "10px 25px",
        minWidth: 140,
        border: 0,
        boxSizing: "border-box",
        textAlign: "center",
        borderRadius: 0,
        fontFamily: "Gilroy",
        fontSize: 14,
        lineHeight: "18px",
        marginTop: 30,
        height: 40,
        "&:hover": {
            background: "#fff",
            color: "#000",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 0 0 129px",
        }
    }
}));

export default useStyles;