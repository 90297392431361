import * as types from "../actions";

const chatReducer = (state = { chats: null, error: null }, action) => {
  const response = action.response;

  let chats = {};
  switch (action.type) {
    case types.WEBSOCKET_MESSAGE_RECEIVED:
      let chatData = state.chats[response.channel.channel]
        ? state.chats[response.channel.channel]
        : [];
      chats = {
        ...state.chats,
        [response.channel.channel]: [...chatData, response],
      };
      return { ...state, chats, error: null };
    case types.LOAD_MESSAGES_SUCCESS:
      chats = {
        ...state.chats,
        [response.name]: response.data,
      };
      return { ...state, chats, error: null };

    case types.MESSAGES_RESET_DATA:
      return {...state, chats: null, error: null}
    default:
      return state;
  }
};

export default chatReducer;
