import React from "react";
import { Grid, Typography } from "@material-ui/core";
import Blocks from "editorjs-blocks-react-renderer";
import useStyles from "../styles/CreationOpportunity";
import moment from "moment";
import clsx from "clsx";

import useStylesSingleCard from "../styles/SingleCard";
import { getUnifiedDate } from "../utils/misc";

const TeamTabBrief = (props) => {
  const { data } = props;
  const classes = useStyles();
  const classesSingleCard = useStylesSingleCard();

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12} className={classes.creationHeader}>
        <div className={clsx(classes.creationContainer, classes.teamHeader)}>
          <Grid>
            <div className={classes.creationType}>Call</div>
            <Typography variant="h1" className={classes.creationTitleBlock}>
              {data.title}
              <div
                className={clsx(
                  classesSingleCard.status,
                  classes.creationStatus,
                  data?.status?.code
                )}
              >
                {data?.status?.name}
              </div>
            </Typography>
            <div className={classes.creationDate}>
              {getUnifiedDate(data.created_at)}
            </div>
          </Grid>
          <Grid className={classes.teamDateBlock}>
            <Grid item className={classes.cretionDateItem}>
              <Typography variant="h3" noWrap>
                Start date
              </Typography>
              <Typography variant="body2" noWrap>
                {getUnifiedDate(data?.start_date)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3" noWrap>
                End date
              </Typography>
              <Typography variant="body2" noWrap>
                {getUnifiedDate(data?.end_date)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.teamBriefBody}>
        <Grid container className={classes.creationContainer}>
          <Grid item className={classes.creationBody}>
            <Blocks data={JSON.parse(data.body)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamTabBrief;
