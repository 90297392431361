import React from "react";

import useStyles from "../styles/Team";
import { useDispatch } from "react-redux";
import { Typography, Button, Modal } from "@material-ui/core";
import { push } from "connected-react-router";
import clsx from "clsx";
import { useSelector } from "react-redux";

const TeamModalDone = (props) => {
  const { openDoneModal, setOpenDoneModal, ratingNeeded, teamId, winnerId, opportunityOwner, team } = props;

  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  const Text = () => {

    if (teamId === winnerId && user?.id !== opportunityOwner) {
      return <Typography variant="h1" className={classes.paperTitle}>
        Your project is done.
        <span>We will contact you about payment details</span>
      </Typography>
    }

    if (teamId === winnerId && user?.id === opportunityOwner) {
      return <Typography variant="h1" className={classes.paperTitle}>
        Your project is done.
        <span>You can start payment process</span>
      </Typography>
    }
    if (teamId !== winnerId && user?.id !== opportunityOwner && team?.status?.code !== "rejected") {
      return <Typography variant="h1" className={classes.paperTitle}>
        Your project is done.
        <span>Unfortunately, your team didn't win this time - try participating in other projects! </span>
      </Typography>
    }

    if (teamId !== winnerId && user?.id !== opportunityOwner && team?.status?.code === "rejected") {
      return <Typography variant="h1" className={classes.paperTitle}>
        {`Unfortunately, ${team?.opportunity?.owner?.name} didn’t choose your ${team?.name} team.`}
        <span>Nonetheless, you are allowed to create a new team and take chances one more time.</span>
      </Typography>
    }
    return null;
  }

  const ConditionalButton = () => {
    if (teamId === winnerId && user?.id !== opportunityOwner) {
      return <Button
        onClick={() => {
          dispatch(push(`/creations`));
        }}
      >
        +Creation
      </Button>
    }

    if (teamId === winnerId && user?.id === opportunityOwner) {
      return <Button
        onClick={() => {
          dispatch(push(`/calls`));
        }}
      >
        +Opportunity
      </Button>
    }
    if (teamId !== winnerId && user?.id !== opportunityOwner) {
      return <Button
        onClick={() => {
          dispatch(push(`/`));
        }}
      >
        Discover
      </Button>
    }
    return null;
  }

  return (
    <Modal
      open={openDoneModal && !ratingNeeded}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={clsx(classes.paper, classes.paperSmall)}>
        <span
          className={classes.paperClose}
          onClick={() => {
            setOpenDoneModal(false);
          }}
        ></span>
        {Text()}
        <div className="buttons">
          <Button
            onClick={() => {
              setOpenDoneModal(false);
            }}
          >
            Done
          </Button>
          {ConditionalButton()}
        </div>
      </div>
    </Modal>
  );
};

export default TeamModalDone;
