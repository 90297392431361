
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
container:{
    display: "flex",
    alignItems: "center",
    paddingTop: 11,
},
border:{
    borderBottom: "1px solid #BCBCBC",
    width: "100%",
},
content: {
    color: "#BCBCBC",
    paddingRight: 5,
    fontFamily: 'Gilroy',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: "18px",
    whiteSpace: "nowrap",
}
}));

export default useStyles;