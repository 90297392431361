import React from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import useStyles from "../styles/Profile";

const ProfileChips = (props) => {
  const { user } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" noWrap className={classes.profileTitleBlock}>
        {props.title}
      </Typography>

      {user[props.dataKey].map((data) => {
        return (
          <Chip
            key={data.id}
            label={data.name}
            className={classes.profileLabel}
          />
        );
      })}
    </>
  );
};

export default ProfileChips;

ProfileChips.propTypes = {
  title: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
};
