import { push } from "connected-react-router";
import { put, call } from "redux-saga/effects";
import * as types from "../actions";

import {
  formSubmitService,
  formUpdateUserService,
  formGetReferenceTableValuesService,
  formGetDataService,
  formGetCustomDataService,
  formGetHeaderDataService,
  formGetStaticPageService,
} from "../services/formService";

/* import { createMessage } from "../utils/createMessage"; */

export function* formSubmitAndGetCustomDataSaga(payload) {
  try {
    const responseSubmit = yield call(formSubmitService, payload.data);

    yield put({ type: types.FORM_SUBMIT_SUCCESS, response: responseSubmit });

    const { message } = payload.data;
    if (message) {
      yield put({
        type: types.SET_MESSAGE,
        message,
      });
    }

    payload.data.endpoint = payload.data.getEndpoint;
    const responseGet = yield call(formGetCustomDataService, payload.data);
    yield put({
      type: types.FORM_GET_CUSTOM_DATA_SUCCESS,
      response: responseGet,
    });

    if (payload.data.redirect) {
      yield put(
        push({ pathname: payload.data.redirect, state: payload.data.state })
      );
    }
    if (payload.data.redirectToNew) {
      yield put(
        push({
          pathname: `${payload.data.endpoint.replace("opportunities","calls")}/${responseSubmit.id}`,
          state: payload.data.state,
        })
      );
    }
  } catch (error) {
    yield put({ type: types.FORM_SUBMIT_ERROR, error });
  }
}



export function* formSubmitAndGetDataSaga(payload) {
  try {
    const responseSubmit = yield call(formSubmitService, payload.data);

    yield put({ type: types.FORM_SUBMIT_SUCCESS, response: responseSubmit });

    const { message } = payload.data;
    if (message) {
      yield put({
        type: types.SET_MESSAGE,
        message,
      });
    }

    payload.data.endpoint = payload.data.getEndpoint;
    const responseGet = yield call(formGetDataService, payload.data);
    yield put({
      type: types.FORM_GET_DATA_SUCCESS,
      response: responseGet,
    });

    if (payload.data.redirect) {
      yield put(
        push({ pathname: payload.data.redirect, state: payload.data.state })
      );
    }
    if (payload.data.redirectToNew) {
      yield put(
        push({
          pathname: `${payload.data.endpoint.replace("opportunities","calls")}/${responseSubmit.id}`,
          state: payload.data.state,
        })
      );
    }
  } catch (error) {
    yield put({ type: types.FORM_SUBMIT_ERROR, error });
  }
}









export function* formSubmitAndDispatchActionSaga(payload) {
  try {
    const responseSubmit = yield call(formSubmitService, payload.data);

    yield put({ type: types.FORM_SUBMIT_SUCCESS, response: responseSubmit });

    const { message } = payload.data;
    if (message) {
      yield put({
        type: types.SET_MESSAGE,
        message,
      });
    }
    if (payload.data.reset) {
      yield put({
        type: payload.data.reset.type,
      });
    }

    yield put({
      type: payload.data.action.type,
      data: payload.data.action.data,
    });

    if (payload.data.redirect) {
      yield put(
        push({ pathname: payload.data.redirect, state: payload.data.state })
      );
    }
    if (payload.data.redirectToNew) {
      yield put(
        push({
          pathname: `${payload.data.endpoint.replace("opportunities","calls")}/${responseSubmit.id}`,
          state: payload.data.state,
        })
      );
    }
  } catch (error) {
    yield put({ type: types.FORM_SUBMIT_ERROR, error });
  }
}

export function* formSubmitSaga(payload) {
  try {
    const response = yield call(formSubmitService, payload.data);

    yield put({ type: types.FORM_SUBMIT_SUCCESS, response });

    const { message } = payload.data;
    if (message) {
      yield put({
        type: types.SET_MESSAGE,
        message,
      });
    }
    // yield put({
    //   type: types.SET_MESSAGE,
    //   message: createMessage({ endpoint, submitType }),
    // });

    if (payload.data.redirect) {
      yield put(
        push({ pathname: payload.data.redirect, state: payload.data.state })
      );
    }
    if (payload.data.redirectToNew) {
      yield put(
        push({
          pathname: `${payload.data.endpoint.replace("opportunities","calls")}/${response.id}`,
          state: payload.data.state,
        })
      );
    }
  } catch (error) {
    yield put({ type: types.FORM_SUBMIT_ERROR, error });
  }
}

export function* formGetReferenceTableValuesSaga(payload) {
  try {
    const response = yield call(
      formGetReferenceTableValuesService,
      payload.data
    );
    yield put({ type: types.FORM_GET_REFERENCE_VALUES_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.FORM_GET_REFERENCE_VALUES_ERROR, error });
  }
}

export function* formGetCustomDataSaga(payload) {
  try {
    const response = yield call(formGetCustomDataService, payload.data);
    yield put({ type: types.FORM_GET_CUSTOM_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.FORM_GET_CUSTOM_DATA_ERROR, error });
  }
}

export function* formGetStaticPageSaga(payload) {
  try {
    const response = yield call(formGetStaticPageService, payload.data);
    yield put({ type: types.FORM_GET_STATIC_PAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.FORM_GET_STATIC_PAGE_ERROR, error });
  }
}

export function* formGetHeaderDataSaga(payload) {
  try {
    const response = yield call(formGetHeaderDataService, payload.data);
    yield put({ type: types.FORM_GET_HEADER_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.FORM_GET_HEADER_DATA_ERROR, error });
  }
}

export function* formUpdateUserSaga(payload) {
  try {
    const response = yield call(formUpdateUserService, payload.data);
    yield put({ type: types.FORM_UPDATE_USER_SUCCESS, response });

    //const { endpoint, submitType } = payload.data;
    // yield put({
    //   type: types.SET_MESSAGE,
    //   message: createMessage({ endpoint, submitType }),
    // });

    if (payload.data.redirect) {
      yield put(push(payload.data.redirect));
    }
  } catch (error) {
    yield put({ type: types.FORM_UPDATE_USER_ERROR, error });
  }
}

export function* formGetDataSaga(payload) {
  try {
    const response = yield call(formGetDataService, payload.data);
    yield put({ type: types.FORM_GET_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.FORM_GET_DATA_ERROR, error });
  }
}
