import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    ideationSpaces: {
        background: "#F5F5F5",
        padding: "0 23px 30px",
        [theme.breakpoints.up("md")]: {
            padding: "0 32px 50px",
            minHeight: "calc(100vh - 108px)",
            boxSizing: "border-box"
        }
    },
    titleIdeationSpaces:{
        padding: "83px 0 38px",
        fontWeight: 500,
        fontSize: 32,
        lineHeight: "39px",
        fontFamily: "Gilroy",
        [theme.breakpoints.up("lg")]: {
            padding: "27px 0 30px",
        }
    },
    searchIdeationSpaces: {
        "& .buttons": {
            justifyContent: "left",
        },
        "& .MuiFormControl-root": {
            minWidth: 200,
        },
        "& .buttons button": {
            margin: "15px 20px 35px 0",
            padding: "6px 15px",
        },
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            alignItems: "center",
            "& .buttons button": {
                margin: "0 0 0 20px",
            },
        }
    },
    searchInput: {
        marginBottom: 0,
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
        "& .MuiOutlinedInput-root": {
            borderRadius: 8,
            paddingLeft: 16,
        },
        "& .MuiButtonBase-root": {
            padding: "12px 2px 12px 2px",
        },
        "& .MuiButtonBase-root:hover": {
            background: "none",
        },
        "& .MuiInputBase-input": {
            fontWeight: 500,
            color: "#000",
        },
        "& .MuiInputBase-input::placeholder": {
            color: "#BCBCBC",
            opacity: 1,
        },
        [theme.breakpoints.up("sm")]: {
            marginRight: 5,
        }
    },
    ideationPostCard: {
        boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
        borderRadius: 8,
        background: "#fff",
        marginTop: 30,
        padding: "20px 24px 14px",
        [theme.breakpoints.down("xs")]: {
            marginLeft: "-23px",
            marginRight: "-23px",
        },
    },
    ideationPostCardMain: {
        paddingBottom: 10,
        [theme.breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 40,
        }
    },
    chatListIcon: {
        minWidth: "auto",
        "& .default-profile-avatar-container, & img": {
            width: 40,
            height: 40,
        },
        "& .default-profile-avatar-container p": {
            fontSize: "20px",
            lineHeight: "24px",
        },
    },
    ideationPostCardInfo: {
        "& .MuiCardHeader-root": {
            padding: "0 0 28px",
        },
        "& .MuiCardHeader-content .MuiCardHeader-title": {
            fontFamily: "Gilroy",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
            color: "#000",
        },
        "& .MuiCardHeader-content .MuiCardHeader-subheader": {
            fontFamily: "Gilroy",
            fontSize: "10px",
            lineHeight: "16px",
            fontWeight: 500,
            color: "#BCBCBC",
        },
        "& .userMenuAvatarBlock": {
            height: 40,
        },
        [theme.breakpoints.up("lg")]: {
            paddingRight: 39,
        }
    },
    ideationPostCardTitle: {
        fontSize: 26,
        lineHeight: "34px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        marginBottom: 30,
        cursor: "pointer",
        "&:hover": {
            color: "#6233C0"
        }
    },
    ideationPostCardBody: {
        fontSize: 16,
        lineHeight: "24px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        wordBreak: "break-word",
        "& h2": {
            fontSize: "26px",
            lineHeight: "34px",
            margin: "0 0 10px",
            fontWeight: 500,
        },
        "& img": {
            maxWidth: "100%",
            display: "block",
            marginBottom: 40,
        },
        "& p": {
            color: "#636363",
            opacity: 0.8,
            fontSize: "16px",
            lineHeight: "24px",
            margin: "0 0 20px",
        },
        "& p + p": {
            marginTop: "-8px",
        },
        "& figure": {
            margin: "0",
        },
        "& figure + figure": {
            marginTop: 8,
        },
        "& figure + p, & figure + h2": {
            marginTop: 30,
        },
        [theme.breakpoints.up("md")]: {
            "& figure + figure": {
                marginTop: 20,
            },
        }
    },
    ideationPostCardImage: {
        borderRadius: 8,
        position: "relative",
        width: "100%",
        paddingTop: "85.9%",
        overflow: "hidden",
        marginTop: 15,
        "& > img": {
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            objectFit: "contain"
        },
        [theme.breakpoints.up("md")]: {
            width: 257,
            minWidth: 257,
            height: 221,
            marginTop: 0,
            paddingTop: 0,
        }
    },
    ideationPostCardAdditional: {
        [theme.breakpoints.up("md")]: {
            display: "flex",
            justifyContent: "space-between",
        }
    },
    ideationPostBlock: {
        display: "flex",
    },
    ideationPostDate: {
        color: "#BCBCBC",
        fontSize: 10,
        lineHeight: "12px",
        fontFamily: "Gilroy",
        fontWeight: 500,
        whiteSpace: "nowrap",
        paddingTop: 8,
    },
    ideationPostCardInterest: {
        "& .MuiChip-root": {
            marginLeft: 10,
            marginBottom: 10,
            borderRadius: 4,
            background: "#F5F5F5",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            height: 28,
            "& .MuiChip-label": {
                fontSize: 14,
                lineHeight: "18px",
                fontFamily: "Gilroy",
                fontWeight: 500,
                padding: "0 10px",
            }
        }
    },
    ideationPostCardFiles: {
        "&:not(:empty)": {
            paddingTop: 30,
        },
        "& .MuiTypography-h6": {
            fontSize: 14,
            lineHeight: "20px",
            fontFamily: "Gilroy",
            color: "#636363",
            fontWeight: 500,
            marginBottom: 12,
        }
    },
    ideationPostCardComment: {
        fontSize: 12,
        lineHeight: "15px",
        fontFamily: "Gilroy",
        color: "#636363",
        paddingLeft: 10,
        paddingTop: 6,
        whiteSpace: "nowrap",
        [theme.breakpoints.down("md")]: {
            paddingTop: 10,
        },
    },
    postCommentTitle: {
        color: "#BCBCBC",
        paddingRight: 19,
    },
    ideationSpacesPosts:{
        [theme.breakpoints.down("md")]: {
            order: 2,
        },
    },
    ideationSpacesFilter: {
        position: "relative",
        "& *": {
            fontSize: 14,
            lineHeight: "18px",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& #toggleFilterContainer": {
            paddingBottom: 43,
            whiteSpace: "noWrap",
            "& .MuiToggleButtonGroup-root .MuiToggleButton-root:not(:first-child)": {
                marginLeft: "14px !important",
            },
            "& .MuiTypography-root": {
                color: "#636363",
            },
            "& .Mui-selected .MuiTypography-root": {
                color: "#6233C0",
            },
            [theme.breakpoints.down("md")]: {
                paddingBottom: 35,
                overflowX: "auto",
            },
        },
        [theme.breakpoints.down("md")]: {
            order: 1,
            "&:before": {
                content: "''",
                position: "absolute",
                right: 0,
                top: 0,
                height: 30,
                background: "linear-gradient(270deg, #C4C4C4 -2.39%, #F5F5F5 -2.39%, rgba(245, 245, 245, 0) 76.2%)",
                zIndex: 1,
                width: 50,
            }
        },
        [theme.breakpoints.up("sm")]: {
            paddingTop: 35,
        },
        [theme.breakpoints.up("lg")]: {
            padding: "0 0 0 95px",
            position: "relative",
            top: "-31px",
        },
    },
    ideationSpacesFilterTitle: {
        color: "#BCBCBC",
        paddingRight: 17,
    },
    ideationSpacesFilterItem: {
        "& .MuiChip-root": {
            border: "1px solid rgba(0, 0, 0, 0.1)",
            background: "#F5F5F5",
            borderRadius: 4,
            height: 28,
            margin: "0 10px 15px 0",
            "&:hover, &:focus, &:active": {
                color: "#ffffff",
                backgroundColor: "#6233C0 !important",
                borderColor: "#6233C0",
                boxShadow: 'none',
            },
        }
    },
    searchIdeationFilterDate: {
        display: "inline-block",
        fontSize: 16,
        lineHeight: "24px",
        padding: "0 15px 15px 0",
        "&:hover": {
            color: "#6233C0",
            cursor: "pointer"
        }
    },
    ideationSpacesNotFind: {
        paddingTop: 26,
        fontSize: 16,
        lineHeight: "24px",
        fontFamily: "Gilroy",
        textAlign: "center",
        "& img": {
            display: "block",
            margin: "0 auto",
        }
    },
    actionPost: {
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#6233C0 !important",
            backgroundColor: "rgba(0,0,0,.5)",
            borderRadius: 5,
        },
        "& > img": {
            padding: 10,
        }
    },
}));

export default useStyles;