import React from "react";
import useStyles from "../styles/Team";
import { useDispatch } from "react-redux";
import { Grid, Typography, Button, Modal } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import ProfileAvatar from "../components/ProfileAvatar";
import { formSubmitAction, formGetDataAction, formSubmitAndGetDataAction } from "../actions/formActions";
import clsx from "clsx";

const TeamModalRating = (props) => {
  const { opportunityId, teamId, ratingNeeded, setOpenRatingModal, members } =
    props;

  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Modal
      className={classes.teamModalRating}
      open={ratingNeeded}
      onClose={() => {
        setOpenRatingModal(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={clsx(classes.paper, classes.paperModalRaiting)}>
        <Typography variant="h1" className={classes.paperTitle}>
          Rate the project
          <span>Leave feedback for all members</span>
        </Typography>
        <Form
          initialValues={{
            data: members.map((m) => ({
              id: m.id,
              rate: m.rating,
              member: m.member,
            })),
          }}
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={async (values) => {
            const { members, ...neededValues } = values;
            dispatch(
              formSubmitAndGetDataAction({
                formValues: neededValues,
                submitType: "post",
                endpoint: `/teams/members/feedback`,
                getEndpoint: `opportunities/${opportunityId}/teams/${teamId}`,
              })
            );

            setOpenRatingModal(false);
          }}
          render={({
            submitError,
            handleSubmit,
            reset,
            submitting,
            pristine,
            values,
            form: {
              mutators: { push, pop, move, swap },
            },
          }) => (
            <form className="form-signin" onSubmit={handleSubmit}>
              <Grid>
                <Grid className={classes.teamModalUsersContainer}>
                  <FieldArray name="data" label="Stages">
                    {({ fields, meta: { error } }) => {
                      return fields.map((name, index) => {
                        return (
                          <Grid container className={classes.teamModalItemUser}>
                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                className={classes.teamMemberUser}
                              >
                                <ProfileAvatar
                                  imageLink={
                                    fields.value[index]?.member?.profile_image
                                      ?.link
                                  }
                                  user={fields.value[index]?.member}
                                />
                                <Grid className={classes.teaMemberRating}>
                                  <Grid className={classes.teamMemberUserName}>
                                    <Typography variant="body1">{`${fields.value[index]?.member?.first_name} ${fields.value[index]?.member?.last_name}`}</Typography>
                                    <Typography variant="body2">
                                      {fields.value[index]?.role?.name}
                                    </Typography>
                                  </Grid>
                                  <Field name={`${name}.rate`}>
                                    {(props) => {
                                      return (
                                          <Rating
                                              {...props.input}
                                              defaultValue={0}
                                              max={5}
                                          />
                                      );
                                    }}
                                  </Field>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      });
                    }}
                  </FieldArray>
                </Grid>
                <Grid item xs={12} className="buttons">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine || values?.data?.some(v => !v.rate)}
                  >
                    Rate
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        />
      </div>
    </Modal>
  );
};

export default TeamModalRating;
