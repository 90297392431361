import React from "react";

import {
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Avatar,
    Paper,
    Tooltip,
    Menu,
    MenuItem,
    Button, CardHeader, Dialog, ListItemIcon,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import clsx from "clsx";
import useStylesSingleCard from "../styles/SingleCard";
import useStyles from "../styles/IdeationSpaces";

import ProfileAvatar from "../components/ProfileAvatar";

import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import useStylesCard from "../styles/UserCard";
import {getUnifiedDate, swiftWrapper} from "../utils/misc";
import Chip from "@material-ui/core/Chip";
import Blocks from "editorjs-blocks-react-renderer";
import RemoveIcon from "../assets/close_white.svg";
import ActionIcon from "../assets/post_action.svg";
import IdeationPostForm from "../forms/IdeationPostForm";
import IdeationPostDetail from "./IdeationPostDetail";
import useIdeationPostStyles from "../styles/IdeationPostForm";
import useIdeationPostDetailStyles from "../styles/IdeationPostDetail";
import IdeationPostCommentForm from "../forms/IdeationPostCommentForm";
import {GetShortText} from "../utils/shortBodyFromEditor";

import {
    formSubmitAndGetCustomDataAction,
} from "../actions/formActions";
import IdeationPostEditWrapper from "./IdeationPostEditWrapper";

const IdeationPostCard = (props) => {
    const classes = useStyles();
    const userCardClasses = useStylesCard();
    const userSingleCardClasses =useStylesSingleCard();
    const userIdeationPostDetailClasses = useIdeationPostDetailStyles();
    const [openPostForm, setOpenPostForm] = React.useState(false);
    const useIdeationPostClasses =useIdeationPostStyles();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editOpen, setEditOpen] = React.useState(false);
    const open = Boolean(anchorEl);
    const [openComment, setOpenComment] = React.useState(true);
    let { data, searchValue } = props;
    const image = require("../assets/background_" +
        (props.data["id"] % 5) +
        ".jpg");

    const handleCloseForm = () => {

        setOpenPostForm(false);
    };

        const deletePost = (id) => () => {
            dispatch(
                formSubmitAndGetCustomDataAction({
                    submitType: "delete",
                    endpoint: `posts/${id}`,
                    getEndpoint:`posts`,
                    name: "posts",
                })
            );

            handleClose();
        }

    const user = useSelector((state) => state.userReducer.user);

    let isMasterCreator = user?.profile_types.reduce((acc, cur) => {
        if (cur.name === "Master Creator") {
            acc = true;
            return acc;
        }
        return acc;
    }, false);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };


    function Comment(props) {
        if(typeof (data.comments) !== "undefined" && data.comments.length > 0 ){
            return (
                <div>
                <span className={classes.postCommentTitle}>
                        Comment
                </span>
                    &nbsp;&nbsp;
                    <span className={classes.postCommentCount}>
                    {data.comments.length} comments
                </span>
                </div>
            );
        }
        return null;

    }

    //const date_at  = new Date(data.created_at);
    //date_at.toLocaleDateString("fr-FR", {year: 'numeric', month: 'short', day: 'numeric' });
    //const created_at = data.created_at;
    const cq =  typeof (data.created_by) !== "undefined" && data.created_by.cq !== null ? "CQ "+ data.created_by.cq : "CQ 0";
    let authorSrc =
        data.created_by.cover_image ? data.created_by.cover_image.link : swiftWrapper(data.created_by.cover_image?.link) ;
    const imgAuthorSrc = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${authorSrc}`;

    let postSrc =
        data.cover ? data.cover.link : swiftWrapper(data.cover?.link) ;
    const imgPostSrc = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${postSrc}`;

    const openDetail = () => {
        setOpenPostForm(true);
    }
    const CommentSidebar = (props) => {

    }

    return (
        <>
            <Grid className={classes.ideationPostCard}>
                <Grid className={classes.ideationPostCardMain}>
                    <Grid className={classes.ideationPostCardInfo}>
                        <Link style={{ textDecoration: 'none' }} to={`/profile/${data.created_by.id}`} className={classes.authorImage}  >
                            <CardHeader avatar={<ListItemIcon className={classes.chatListIcon}>
                                <ProfileAvatar
                                    imageLink={data.created_by?.profile_image?.link}
                                    user={data.created_by}
                                />
                            </ListItemIcon>} title={data.created_by.name} subheader={cq}/>
                        </Link>
                        <Typography variant="h3" className={classes.ideationPostCardTitle} onClick={openDetail}>{data.title}</Typography>
                        <Grid className={classes.ideationPostCardBody} onClick={openDetail}>
                            {data.body && <GetShortText body={data.body} searchValue={searchValue}/>}
                        </Grid>
                    </Grid>
                    <Grid className={classes.ideationPostCardImage}>
                        { data.cover && <img src={imgPostSrc}  onClick={openDetail} alt="Post"/>}
                        <div className={classes.actionPost} >
                            {user.id === data.created_by.id && <img
                                src={ActionIcon}
                                alt="Action"
                                // onClick={showMenu(item.id)}
                                onClick={handleClick}
                            />}
                            {user.id === data.created_by.id && <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {/*<MenuItem onClick={() => {setReportOpen(true);handleClose();}}>Report</MenuItem>*/}
                                <MenuItem onClick={() => {setEditOpen(true);handleClose();}}>Edit</MenuItem>
                                <MenuItem onClick={deletePost(data.id)}>Delete</MenuItem>
                            </Menu>}
                        </div>
                    </Grid>
                </Grid>
                <Grid className={classes.ideationPostCardAdditional}>
                    <Grid className={classes.ideationPostBlock}>
                        <span className={classes.ideationPostDate}>{getUnifiedDate(data?.created_at)}</span>
                        <Grid className={classes.ideationPostCardInterest}>
                            {data.interest_areas && data.interest_areas.map((item, i) => <Chip key={item.id} value={item.id} label={item.name} variant="outlined" />)}
                        </Grid>
                    </Grid>
                    <Grid className={classes.ideationPostCardComment}>
                        <Comment/>
                    </Grid>
                </Grid>
                <Dialog key={data.id} open={openPostForm} className={userIdeationPostDetailClasses.confirmationDialog}>
                    <Button onClick={handleCloseForm} className={userIdeationPostDetailClasses.confirmationClose}>
                        <img src={RemoveIcon} alt="Remove" />
                    </Button>
                    <Grid className={userIdeationPostDetailClasses.detailPostContainer}>
                        <Grid className={userIdeationPostDetailClasses.detailPost}>
                            <IdeationPostDetail key={data.id} handleCloseForm={handleCloseForm} postID={data.id} openComment={openComment} setOpenComment={setOpenComment}/>
                        </Grid>
                        {openComment && <Grid className={userIdeationPostDetailClasses.commentsSidebar} style={{display: openComment ? 'block' : 'none' }}>
                            <IdeationPostCommentForm key={data.id} handleCloseForm={handleCloseForm} postID={data.id} openComment={openComment} setOpenComment={setOpenComment}/>
                        </Grid>}
                    </Grid>
                </Dialog>



                <Dialog open={editOpen} className={useIdeationPostClasses.paper}>
                    <Button onClick={()=>setEditOpen(false)} className={useIdeationPostClasses.paperClose}></Button>
                    <div className={useIdeationPostClasses.form}>
                        <IdeationPostEditWrapper data={data} handleCloseForm={()=>{setEditOpen(false)}}/>
                    </div>
                </Dialog>


            </Grid>
        </>
    );
};

export default IdeationPostCard;
