import * as React from "react";
import { useState, useEffect, useRef } from "react";
import http from "../http";
import { TextField, Grid, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Scrollbars } from "react-custom-scrollbars-2";
import Chip from "@material-ui/core/Chip";
import useStyles from "../styles/SkillsAutocomplete";
import { Field } from "react-final-form";

   const Error = ({ name }) => {
    return (
      <Field
        name={name}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
          touched && error ? (
            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense">
              {error}
            </p>
          ) : null
        }
      />
    );
  };

const CustomAutocomplete = (props) => {
  const { record, reference } = props;
  const classes = useStyles();

  const scrollbar = useRef(null);

  let setInitialSelectedOption = (props) => {
    return props.multiple ? [] : {};
  };
  const [productOptions, setProductOptions] = useState([]);
  const [filteredProductOptions, setFilteredProductOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    setInitialSelectedOption(props)
  );

  useEffect(() => {
    async function getData() {
      const { data } = await http.get(reference, {});
      setProductOptions(data.data);
    }

    if (record) {
      setSelectedOption(record);
    }
    getData();
  }, [record, reference]);

  const handleAutocompleteInputChange = async (event, value) => {
    if (value) {
      const { data } = await http.get(props.reference, {
        params: {
          filter: JSON.stringify(["name", "contains", value]),
        },
      });
      setFilteredProductOptions(data.data);
    } else {
      setFilteredProductOptions([]);
    }
  };

  const handleDelete = (optionToDelete) => () => {
    setSelectedOption((options) => {
      let data = options.filter((opt) => opt.id !== optionToDelete.id);
      props.onChange(data);
      return data;
    });
  };

  const handleAdd = (optionToAdd) => () => {
    setSelectedOption((options) => {
      let data = [...options, optionToAdd];
      props.onChange(data);
      return data;
    });
  };

  const tileClick = (option) => () => {
    let isSelected = selectedOption.filter((so) => so.id === option.id).length;
    if (isSelected) {
      handleDelete(option)();
    } else {
      handleAdd(option)();
    }
  };

  const Chips = selectedOption.map((option, index) => (
    <Chip
      key={index}
      label={option.name}
      onDelete={handleDelete(option)}
      className={classes.selectPill}
    />
  ));

  const Tiles = productOptions?.map((option, index) => (
    <Grid item xs={12} md={4} key={option.id}>
      <div
        key={index}
        onClick={tileClick(option)}
        style={{
          backgroundColor: selectedOption.filter((so) => so.id === option.id)
            .length
            ? "#6233C0"
            : "initial",
          color: selectedOption.filter((so) => so.id === option.id).length
            ? "#fff"
            : "initial",
        }}
        className={
          selectedOption.filter((so) => so.id === option.id).length
            ? classes.gridPillSelected
            : classes.gridPill
        }
      >
        {selectedOption.filter((so) => so.id === option.id).length
          ? `${option.name} \n`
          : option.name}
        {/* {option.name} */}
      </div>
    </Grid>
  ));

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 0,
      backgroundColor: "rgba(0, 0, 0)",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const CustomScrollbars = (props) => {
    return (
      <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        ref={props.reference}
        {...props}
      />
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} className={classes.autocompleFieldContainer}>
        <Autocomplete
          {...props}
          popupIcon={false}
          closeIcon={false}
          classes={{ paper: classes.paperClass }}
          options={
            filteredProductOptions.length
              ? filteredProductOptions
              : productOptions
          }
          underlineStyle={{ display: "none" }}
          getOptionLabel={(option) => {
            if (Object.keys(option).length === 0) {
              return "";
            } else {
              return option.name;
            }
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          onChange={(e, v) => {
            setSelectedOption(v);
            if (props.setParentValue) {
              props.setParentValue(v);
            }

            if (v) {
              if (props.multiple) {
                props.onChange(v);
              } else {
                props.onChange(v.id);
              }
            }
          }}
          onInputChange={handleAutocompleteInputChange}
          value={selectedOption}
          disablePortal
          className={classes.autocompleField}
          renderOption={
            props.renderOption
              ? props.renderOption
              : (option) => {
                  return <div>{option.name}</div>;
                }
          }
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputLabelProps={{ shrink: false }}
                variant="standard"
                popupIcon={false}
                hiddenLabel={true}
                placeholder={`or search the ${props.title} list  →`}
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                className={classes.autoCompleteLabel}
                // label={selectedOption ? `or search the ${props.title} list ` : ''}
              />
            );
          }}
          renderTags={() => null}
        />
      </Grid>
      <Grid item xs={12} className={classes.selectPillsContainer}>
        {Chips}
      </Grid>
      <Grid item xs={12} className={classes.gridForm}>
        <CustomScrollbars
          reference={scrollbar}
          className={classes.scrollBars}
          style={{ height: 690 }}
        >
          <Grid container>{Tiles}</Grid>
        </CustomScrollbars>
      </Grid>
      <Grid item xs={12} hidden>
        <Button
          variant="contained"
          color="primary"
          onClick={() => scrollbar.current?.scrollToTop()}
        >
          {" "}
          Go up{" "}
        </Button>
      </Grid>
      <Error name={props.name} />
    </Grid>
  );
};

export default CustomAutocomplete;
