import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    attachFileLink: {
        display: "block",
        position: "relative",
        textDecoration: "none",
        marginBottom: 10,
        paddingLeft: 24,
        "&:hover": {
            textDecoration: "none",
        },
        "&:hover span": {
            color: "#636363",
        },

        "& img": {
            position: "absolute",
            left: 0,
            top: 2,
        },
        color: "#636363",
    },
    attachFileName: {
        color: "#333",
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 250,
        textOverflow: "ellipsis",
        "& span": {
          color: "#636363",
          paddingLeft: 10,
        },
        "@media (min-width:370px)": {
            maxWidth: 305,
        },
        [theme.breakpoints.up("sm")]: {
            maxWidth: 550,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: 300,
        }
    },
}));

export default useStyles;