import React, {useEffect} from "react";
import {
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { formGetDataAction } from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import HomeFilterType from "../components/HomeFilterType";
import SingleCard from "../components/SingleCard";
import { useLocation } from "react-router-dom";
import useStyles from "../styles/Home";
import { Helmet } from "react-helmet";

const Home = () => {
  const dispatch = useDispatch();

  let location = useLocation();
  const classes = useStyles();
  const data = useSelector((state) => state.formReducer.data);
  const loading = useSelector((state) => state.formReducer.loading);
  const [type, setType] = React.useState("all");
  const [tag, setTag] = React.useState("all");

  useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: "home",
        filter: { type, tag },
      })
    );
  }, [dispatch, type, tag]);

  useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: "home",
        filter: { type, tag },
      })
    );
  }, [dispatch, type, tag, location]);

  return (
    <>
      <Helmet>
        <title>weCreators</title>
      </Helmet>
      <Grid className={classes.homeContainer}>
        <div className={classes.toggleContainer} id="toggleFilterContainer">
          <HomeFilterType
            value={type}
            setValue={setType}
            choices={[
              { value: "creation", label: "Creations" },
              { value: "opportunity", label: "Calls" },
              { value: "all", label: "All" },
            ]}
          />
          <HomeFilterType
            value={tag}
            setValue={setTag}
            choices={[
              { value: "all", label: "All" },
              { value: "marketing", label: "Marketing" },
              { value: "design", label: "Design" },
              { value: "technology", label: "Technology" },
              { value: "product", label: "Product" },
            ]}
            mobile
          />
        </div>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.searchResult}
        >
          {loading ? (
            <CircularProgress className="main-progress-bar"/>
          ) : data?.length > 0 ? (
            <div className={classes.searchContainer}>
              {data?.map((d) => {
                return (
                  <Grid key={`${d.type}-${d.id}`}>
                    <SingleCard data={d} />
                  </Grid>
                );
              })}
            </div>
          ) : (
            <div className={classes.searchNotFound}>
              <div className={classes.searchNotFoundTitle}>
                Sorry, we couldn’t find any results for your search.
              </div>
              <div>Maybe try to use a different keyword.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
