import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ProfileCover from "../components/ProfileCover";
import ProfileOnlinePresence from "../components/ProfileOnlinePresence";
import ProfileBio from "../components/ProfileBio";
import { push } from "connected-react-router";

import ProfileCard from "../components/ProfileCard";
import useStyles from "../styles/Profile";
import AttachmentChips from "../components/AttachmentChips";

import ProfileImage from "../components/ProfileImage";
import { Helmet } from "react-helmet";
import { setCookie, getExpires, getCookie } from "../utils/misc";

const Profile = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const creations = useSelector(
    (state) => state.formReducer.customData?.creations
  );
  const opportunities = useSelector(
    (state) => state.formReducer.customData?.opportunities
  );

  const showBanner = getCookie("profile_banner") ? false : true;

  const [open, setOpen] = useState(showBanner);

  const currentUser = useSelector((state) => state.userReducer.user);

  const { user } = props;
  const imgProfileSrc = user?.profile_image
    ? `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${user?.profile_image?.link}`
    : null;

  const closeBanner = () => {
    let expirationDate = getExpires(30);
    setOpen(false);
    setCookie("profile_banner", "false", expirationDate);
  };

  return (
    <>
      <Helmet>
        <title>{`${user?.name} | weCreators`}</title>
      </Helmet>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <ProfileCover user={user} />
        </Grid>
        <Grid item xs={12} className={classes.profileUser}>
          <ProfileImage
            imageLink={imgProfileSrc}
            className={classes.profileImage}
          />
          <Typography variant="h1" className={classes.profileUserName}>
            {user?.first_name} {user?.last_name} <span>{user?.name}</span>
          </Typography>
          {user?.id === currentUser.id ? (
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                className="invert"
                onClick={() => dispatch(push(`/profile/edit/${user?.id}`))}
              >
                Edit profile
              </Button>
            </div>
          ) : null}
        </Grid>
        <Grid className={classes.profileBody}>
          <Grid>
{/*              <Grid className={classes.profileBalance}>
               <Grid className={classes.profileCQ}>
                <Typography variant="h6" className={classes.profileTitleBlock}>
                  CQ
                </Typography>
                {user?.cq}
              </Grid>
               <Grid>
                <Typography variant="h6" className={classes.profileTitleBlock}>
                  Wallet
                </Typography>
                € {user?.wallet}
              </Grid>
            </Grid> */}
            <ProfileOnlinePresence user={user} />
          </Grid>
          <Grid>
            <ProfileBio title="Description" user={user} />
          </Grid>
          <Grid className={classes.profileAttachments}>
            <AttachmentChips title="Attachments" data={user?.files} />
          </Grid>
        </Grid>
      </Grid>
      {open && user?.id === currentUser.id && (
        <Grid className={classes.profileBanner} item xs={12}>
          <span
            className={classes.profileBannerClose}
            onClick={closeBanner}
          ></span>
          <div className={classes.profileBannerTitle}>
            <span>
              Attract eligible Creators to work on your projects.
            </span>
            Tell us about yourself
          </div>
          <div className={classes.profileBannerTip}>
            <div className={classes.profileBannerTipTitle}>
              <span>Tip 1:</span> "Who you are looking for?"
            </div>
            Identify what kind of creators you need to implement the specific project.
          </div>
          <div className={classes.profileBannerTip}>
            <div className={classes.profileBannerTipTitle}>
              <span>Tip 2:</span> "What are your desired outcomes?"
            </div>
            Make your brief clear and detailed: summarize the project, explain your objectives, outline the deliverables you need.
          </div>
          <div className={classes.profileBannerTip}>
            <div className={classes.profileBannerTipTitle}>
              <span>Tip 3:</span> "Provide meaningful details about your company"
            </div>
            Think about additional elements to incorporate that emphasize your needs for a project: engage by describing your business values, product, or service.
          </div>
          <div className={classes.profileBannerTip}>
            <div className={classes.profileBannerTipTitle}>
              <span>Tip 4:</span> "Describing your project, unleash the specification"
            </div>
            Introduce the project in a tempting way. Include the project’s mission statement that describes purposeful input that the creator will hold when the project is implemented.
          </div>
          <div className={classes.profileBannerTip}>
            <div className={classes.profileBannerTipTitle}>
              <span>Tip 5:</span> "Provide your contact details"
            </div>
            Incorporate contact information that allows creators to effectively perform while having a possibility to contact someone at the company about any questions regarding the project.
          </div>
          <div className={classes.profileBannerTip}>
            <div className={classes.profileBannerTipTitle}>
              <span>Tip 6:</span> "Specify the terms of remuneration for each opportunity"
            </div>
            Financial information - include a section in the profile with information about the available project’s budget, so that creator would be able to self-assess their willingness to participate.
          </div>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.profileCardContainer}
      >
        <Grid item xs={12}>
          <ProfileCard data={creations} title="Creations" />
        </Grid>
        <Grid item xs={12}>
          <ProfileCard data={opportunities} title="Calls" />
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
