import React from "react";
import { Typography, Grid, Tooltip, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useStyles from "../styles/SingleCard";
import ProfileAvatar from "../components/ProfileAvatar";
import { push } from "connected-react-router";
import { useSelector, useDispatch } from "react-redux";
import { isMaster } from "../utils/misc";

const TeamCard = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  let { data } = props;
  const image = require("../assets/team_image" +
    (props.data["id"] % 5) +
    ".svg");

  const user = useSelector((state) => state.userReducer.user);

  return (
    <Grid className={classes.teamItem}>
      <Grid>
        <img src={image.default} alt="Team" className={classes.teamImage} />
      </Grid>
      <Grid className={classes.teamContainer}>
        <Grid className={classes.teamTitle}>
          {isMaster(user) ? (
            <Link to={`/calls/${data.opportunity_id}/teams/${data.id}`}>
              <Typography variant="h3">{data.name}</Typography>
            </Link>
          ) : (
            <Typography variant="h3">{data.name}</Typography>
          )}
          <Typography variant="body2">{data.description}</Typography>
          <Link
            target="_blank"
            to={`/calls/${data.opportunity_id}`}
          >{`Review ${data.opportunity?.title}`}</Link>
        </Grid>
        <Grid className={classes.teamRoles}>
          <Grid className={classes.filledRoles}>
            <div className={classes.titleRoles}>
              <Typography variant="body2" noWrap>
                Filled roles
              </Typography>
              <Tooltip title="These roles are already taken">
                <InfoOutlinedIcon />
              </Tooltip>
            </div>

            {data?.["usedRoles"]?.map((ot) => {
              return (
                <div className={classes.teamUser} key={ot.id}>
                  <ProfileAvatar
                    imageLink={ot.user?.profile_image?.link}
                    user={ot.user}
                  />
                  <Typography className={classes.teamUserName}>
                    {ot.name}
                  </Typography>
                </div>
              );
            })}
          </Grid>
          <div className={classes.titleRoles}>
            <Typography variant="body2" noWrap>
              Select your role
            </Typography>
            <Tooltip title="You can apply for an open role">
              <InfoOutlinedIcon />
            </Tooltip>
          </div>
          {data?.["unusedRoles"]?.map((ot) => (
            <Grid className="buttons" key={ot.id}>
              <Button
                variant="contained"
                color="primary"
                className="invert"
                onClick={() =>
                  dispatch(
                    push(
                      `/calls/${data.opportunity_id}/teams/${data.id}/apply`
                    )
                  )
                }
              >
                {ot?.role?.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamCard;
