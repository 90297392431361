
import React from "react";

import useStyles from "../styles/Team";
import { useDispatch } from "react-redux";
import { Typography, Button, Modal } from "@material-ui/core";
import { push } from "connected-react-router";
import clsx from "clsx";
import { useSelector } from "react-redux";

const TeamModalInfo = (props) => {
	const { members } = props
	const user = useSelector((state) => state.userReducer.user);
	const [openModal, setOpenModal] = React.useState(false);
	const classes = useStyles();


	const infoRead = localStorage.getItem(`team_page_info_${user?.id}`);
	const isTeamMember = members?.map(m=> m?.member_id)?.includes(user?.id)
	React.useEffect(() => {
		if (!infoRead) {
			setOpenModal(true);
		}
	}, [infoRead])
	return (
		<Modal
			open={openModal && isTeamMember}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<div className={clsx(classes.paper, classes.paperSmall)}>
				<span
					className={classes.paperClose}
					onClick={() => {
						setOpenModal(false);
					}}
				></span>

				<Typography variant="h1" className={classes.paperTitle}>
					This is a team page.
					<span>Here you can chat with your team mates, create tasks and see the progress.</span>
				</Typography>
				<div className="buttons">
					<Button
						onClick={() => {
							localStorage.setItem(`team_page_info_${user?.id}`, true);
							setOpenModal(false);
						}}
					>
						Got it
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default TeamModalInfo;
