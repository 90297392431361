import React, { useState } from "react";

/* import { TextField } from "mui-rff"; */
import { Grid, TextField, Fab } from "@material-ui/core";

import useStyles from "../styles/Team";
import MessageFileInput from "./MessageFileInput";
import SendIcon from "../assets/send.svg";

const MessageEditInput = React.memo((props) => {
    const { sendAction, messageToSendParam, filesToSendParam, placeHolder} = props;
    const classes = useStyles();

    const [messageToSend, setMessageToSend] = useState(messageToSendParam? messageToSendParam: "");
    const [filesToSend, setFilesToSend] = useState(filesToSendParam? filesToSendParam :[]);
    const [filesRendered, setFilesRendered] = useState(null);
    const [filesErrorsRendered, setFilesErrorsRendered] = useState(null);
    return (
        <Grid className={classes.teamBoardComments}>
            <Grid container className={classes.teamBoardCommentsFiles}>
                <Grid item xs={12}>
                    {filesRendered}
                </Grid>
                <Grid item xs={12}>
                    {filesErrorsRendered}
                </Grid>
            </Grid>
            <Grid className={classes.teamBoardSendComment}>
                <TextField
                    id="outlined-basic-email"
                    placeholder={placeHolder? placeHolder: "Type your message..."}
                    onChange={(e) => {
                        setMessageToSend(e.target.value);
                    }}
                    multiline
                    value={messageToSend}
                    fullWidth
                    className={classes.teamInputField}
                />
                <Fab
                    disabled={!messageToSend && !filesToSend.length}
                    aria-label="add"
                    className={classes.chatBtnSend}
                    onClick={() => {
                        if (sendAction) {
                            sendAction({ body: messageToSend, files: filesToSend });
                        }
                        setMessageToSend("");
                        setFilesToSend([]);
                        setFilesRendered([]);
                        setFilesErrorsRendered([]);
                    }}
                >
                    <img src={SendIcon} alt="Send message" />
                </Fab>
                <Fab aria-label="add" className={classes.chatBtnAttach}>
                    <MessageFileInput
                        onChange={setFilesToSend}
                        files={filesToSend}
                        setFiles={setFilesToSend}
                        setFilesRendered={setFilesRendered}
                        setFilesErrorsRendered={setFilesErrorsRendered}
                    />
                </Fab>
            </Grid>
        </Grid>
    );
});

export default MessageEditInput;
