import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as types from "../actions";

import { Snackbar } from "@material-ui/core";
import useStyles from "../styles/SuccessNotification";

const ErrorNotification = (props) => {
  const isOpen = useSelector((state) => state.errorReducer.isOpen);
  const error = useSelector((state) => state.errorReducer.error);

  let errorMessage = Array.isArray(error) ? error[0] : error;

  const classes = useStyles();
  const dispatch = useDispatch();

  function handleClose() {
    dispatch({ type: types.HIDE_ERROR });
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpen && error}
        onClose={handleClose}
        message={<>{errorMessage}</>}
        className={classes.container}
      />
    </div>
  );
};

export default ErrorNotification;
