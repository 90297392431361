import React, {useState} from "react";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import ImageDropzone from "../components/ImageDropzone";
import FileDropzone from "../components/FileDropzone";
import { TextField, Radios, Select } from "mui-rff";
import {
    Paper,
    Grid,
    Menu,
    MenuItem,
    Button,
    Avatar,
    CircularProgress,
    Dialog,
    DialogContent,
    TextareaAutosize, ListItemIcon
} from "@material-ui/core";
import { useEffect } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import UserCard from "../components/UserCard";
import SendIcon from "../assets/send.svg";
import FileIcon from "../assets/attach_file.svg";

import {
    formSubmitAction,
    formGetReferenceTableValuesAction,
    formUpdateUserAction,
    formGetCustomDataAction,
    formGetDataAction,
    formSubmitAndGetCustomDataAction,
} from "../actions/formActions";
import Editor from "../components/Editor";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Typography } from "@material-ui/core";

import CustomAutocomplete from "../components/CustomAutocomplete";

import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import ReplyIcon from '@material-ui/icons/Reply';

import useStyles from "../styles/IdeationPostCommentForm";
import { Helmet } from "react-helmet";
import MultipleSelectChips from "../components/MultipleSelectChips";
import AttachmentChips from "../components/AttachmentChips";
import {getTime, getUnifiedDate, swiftWrapper} from "../utils/misc";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
//import SearchIcon from "../assets/seatch_filter.svg";
import ActionIcon from "../assets/comment_action.svg";
import RemoveIcon from "../assets/close.svg";
import MessageInput from "../components/MessageInput";
import MessageFileAttachment from "../components/MessageFileAttachment";
import ConfirmationDialog from "../components/ConfirmationDialog";
import ProfileAvatar from "../components/ProfileAvatar";
import MessageEditInput from "../components/MessageEditInput";
import clsx from "clsx";
import Link from "@material-ui/core/Link";

const castValues = (data) => {
    let cover = data.cover
        ? data.cover.b64
            ? { b64: data.cover[0].b64, name: data.cover[0].name }
            : data.cover[0]
        : null;
    data.cover = cover;
    data.cover_id = data.cover ? data.cover.id : null;
    data.body = JSON.stringify(data.body);

    return data;
};

const IdeationPostCommentForm = (props) => {
    const { postID, handleCloseForm, setOpenComment, openComment } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [commentValue, setCommentValue] = React.useState("");
    const loading = useSelector((state) => state.formReducer.loading);
    const comments = useSelector(
        (state) => state.formReducer.customData?.comments
    );

    useEffect(() => {
        dispatch(
            formGetCustomDataAction({
                endpoint: `posts/${postID}/comments`,
                name: "comments",
            })
        );
    }, [postID]);

    if (loading || !comments) {
        return <CircularProgress className="main-progress-bar" />;
    }
    if(comments && comments[0] && comments[0]["post_id"] !== postID){
        return <CircularProgress className="main-progress-bar" />;
    }

    const ReportCommentDialog = (props) => {
        const { confirmAction, open, setOpen, text } = props;

        const handleCancel = () => {
            setOpen(false);
        };

        const handleOk = () => {
            confirmAction();
            setOpen(false);
        };

        const classes = useStyles();

        return (
            <Dialog open={open} className={classes.reportDialog}>
                <Button onClick={handleCancel} className={classes.reportClose}>
                    <img src={RemoveIcon} alt="Remove" />
                </Button>

                <Typography variant="h3" noWrap className={classes.reportTitle}>
                    Send report
                </Typography>
                <DialogContent className={classes.reportBody}>{text}
                    {/*<TextField name="report_body" id="outlined-basic" label="" variant="outlined" />*/}
                    <TextareaAutosize
                        name="report_body"
                        //maxRows={4}
                        //aria-label="maximum height"
                        placeholder="Leave message..."
                        style={{ width: "462px", height: "235px" }}
                        className={classes.reportBodyField}
                    />
                </DialogContent>
                <div className="buttons">
                    <Button onClick={handleOk}>Send</Button>
                    <Button autoFocus onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            </Dialog>
        );
    };

    const ReplyItem = (props) => {
        const {item, postID, sendComment, sendFile, hideReply} = props;
        let authorSrc =
            item.created_by.cover_image ? item.created_by.cover_image.link : swiftWrapper(item.created_by.cover_image?.link) ;
        const imgAuthorSrc = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${authorSrc}`;

        const [showReply, setShowReply] = React.useState(false);
        const [showInputComment, setShowInputComment] = React.useState(false);
        const [commentValue, setCommentValue] = React.useState("");
        const [anchorEl, setAnchorEl] = React.useState(null);
        const user = useSelector((state) => state.userReducer.user);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const [reportOpen, setReportOpen] = React.useState(false);
        const [editOpen, setEditOpen] = React.useState(false);
        const openInput = () => {
            // setShowInputComment(prevState => ({
            //     showInputComment: !prevState.showInputComment
            // }));
            setShowInputComment(!showInputComment);
        }
        const getReply = (id) => () => {
            setShowReply(!showReply);
        }
        const countReply = (id) => {
            let res = comments.filter(val => val.reply_to_id === id);
            return res.length;
        }
        const handleCommentChange = (e) => {
            setCommentValue(e.target.value);
        }

        const count = countReply(item.id);

        const showMenu = (id) => () => {
        }
        const deleteComment = (id) => () => {
            dispatch(
                formSubmitAndGetCustomDataAction({
                    submitType: "delete",
                    endpoint: "posts/"+postID+"/comments/"+id,
                    getEndpoint:`posts/${postID}/comments`,
                    name: "comments",
                })
            );

            handleClose();
        }
        return (
            <>
                <Grid item xs={12} key={item.id} className={clsx("itemRowComment", classes.rowComment)}>
                    <Link  style={{ textDecoration: 'none' }} to={`/profile/${item.created_by.id}`}>
                        <ListItemIcon className={classes.chatListIcon}>
                             <ProfileAvatar
                                    imageLink={item.created_by?.profile_image?.link}
                                    user={item.created_by}
                                />

                        </ListItemIcon>
                    </Link>
                    {/*<Avatar aria-label="author" src={imgAuthorSrc}/>*/}
                    <div className={classes.commentBlock}>
                        <div className={classes.commentBody}>
                            <span className={classes.createdBy}>{item.created_by.name}</span> &nbsp;
                            <span>{item.message} </span>
                            {item.files?.map((f) => {
                                return (
                                    <Grid key={f.id} className="messageContainer messageContainerAttach">
                                        <MessageFileAttachment
                                            date={getTime(item?.created_at)}
                                            file={f}
                                            msg={item}
                                        />
                                    </Grid>
                                );
                            })}
                        </div>
                        <div className={classes.actionComment} >
                            {user.id === item.created_by.id && <img
                                src={ActionIcon}
                                alt="Action"
                                // onClick={showMenu(item.id)}
                                onClick={handleClick}
                            />}
                            {user.id === item.created_by.id && <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {/*<MenuItem onClick={() => {setReportOpen(true);handleClose();}}>Report</MenuItem>*/}
                                <MenuItem onClick={() => {setEditOpen(true);handleClose();}}>Edit</MenuItem>
                                <MenuItem onClick={deleteComment(item.id)}>Delete</MenuItem>
                            </Menu>}
                            <ReportCommentDialog
                                open={reportOpen}
                                setOpen={setReportOpen}
                                text={`Give additional info to admin`}
                                confirmAction={() => {
                                    // dispatch(
                                    //     formSubmitAction({
                                    //         submitType: "post",
                                    //         endpoint: "posts/"+postID+"/comments/"+item.id,
                                    //     })
                                    // );
                                }}
                            />
                        </div>
                        <div className={classes.commentReply}>
                            <Grid>
                                {!hideReply && <span onClick={openInput} className={classes.wordReply}>Reply</span>}
                                {comments && count > 0  && <span onClick={getReply(item.id)} className={classes.countReplyBlock}><ReplyIcon/>
                                    <span className={classes.countReply}>{count > 0 ? count + " Replies" : "Reply" } </span>
                                </span>}
                            </Grid>
                            <span className={classes.dateReply}>{` ${getUnifiedDate(item?.created_at)}, ${getTime(item?.created_at)} `}</span>
                        </div>
                        <Grid className={classes.replySendComment}>
                            {editOpen && <MessageEditInput
                                messageToSendParam={item.message}
                                filesToSendParam ={item.files}
                                placeHolder="Comment"
                                sendAction={(dataToSend) => {

                                    dispatch(
                                        formSubmitAndGetCustomDataAction({
                                            submitType: "put",
                                            endpoint: "posts/"+postID+"/comments/"+item.id,
                                            formValues: {
                                                reply_to_id: item.reply_to_id,
                                                message:  dataToSend.body,
                                                files: dataToSend.files
                                            },
                                            getEndpoint: `posts/${postID}/comments`,
                                            name: "comments",
                                        })
                                    );

                                    setEditOpen(false);

                                }}
                            />}
                        </Grid>
                        { showReply && <Grid className={classes.replyComments}>
                            {comments.map(item2 => {
                                if(item2.reply_to_id === item.id){
                                    return <ReplyItem key={item2.id} postID={postID} sendComment={sendComment} sendFile={sendFile} item={item2} hideReply={true}/>
                                }
                            })}
                        </Grid>
                        }
                        <Grid className={classes.replySendComment}>
                            {showInputComment && <MessageEditInput
                                placeHolder="Comment"
                                sendAction={(dataToSend) => {

                                    dispatch(
                                        formSubmitAndGetCustomDataAction({
                                            submitType: "post",
                                            endpoint: "posts/"+postID+"/comments",
                                            formValues: {
                                                reply_to_id: item.id,
                                                message:  dataToSend.body,
                                                files: dataToSend.files
                                            },
                                            getEndpoint: `posts/${postID}/comments`,
                                            name: "comments",
                                        })
                                    );
                                }}
                            />}
                        </Grid>
                    </div>

                    {/*{showInputComment && false && <TextField*/}
                    {/*        fullWidth*/}
                    {/*        name={`comment_${item.id}`}*/}
                    {/*        id="standard-bare"*/}
                    {/*        variant="outlined"*/}
                    {/*        className={classes.commentInput}*/}
                    {/*        placeholder="Comment"*/}
                    {/*        size="small"*/}
                    {/*        value={commentValue}*/}
                    {/*        onChange={handleCommentChange}*/}
                    {/*        autoComplete="off"*/}
                    {/*        InputProps={{*/}
                    {/*            endAdornment: (*/}
                    {/*                <InputAdornment type="button" position="end">*/}
                    {/*                    <IconButton onClick={sendComment(postID, item.id, commentValue, setCommentValue)}>*/}
                    {/*                        <img src={SendIcon} alt="" />*/}
                    {/*                    </IconButton>*/}
                    {/*                    <IconButton onClick={sendFile(postID, item.id)}>*/}
                    {/*                        <img src={FileIcon} alt="" />*/}
                    {/*                    </IconButton>*/}
                    {/*                </InputAdornment>*/}
                    {/*            )*/}
                    {/*        }}*/}
                    {/*    />}*/}

                </Grid>
            </>
        )
    }

    const sendComment = (id, comment_id, commentValue, setCommentValue) => () => {
       // Promise.all([
            dispatch(
                formSubmitAndGetCustomDataAction({
                    submitType: "post",
                    endpoint: "posts/"+id+"/comments",
                    formValues: {
                        reply_to_id: comment_id,
                        message:  commentValue
                    },
                    getEndpoint: `posts/${postID}/comments`,
                    name: "comments",
                })
            );

        //]);
        setCommentValue("");

    }
    const sendFile = (id, comment_id) => () => {
    }
    const handleCommentChange = (e) => {
        setCommentValue(e.target.value);
    }


    return (
        <>
            <Form
                onSubmit={async (values) => {
                    // values.cover = values.cover_image[0];
                    // values.body = JSON.stringify(values.body);
                    // dispatch(
                    //     formSubmitAction({
                    //         submitType: "post",
                    //         endpoint: "/posts",
                    //         formValues: values,
                    //         //redirect: "/ideation-spaces",
                    //     })
                    // );

                    //handleCloseForm();
                }}
                //initialValues={{ cover_image: null }}
                render={({
                             submitError,
                             handleSubmit,
                             reset,
                             submitting,
                             pristine,
                             values,
                         }) => (
                    <form onSubmit={handleSubmit} className={classes.formComment}>
                        <Grid className={classes.allComments}>
                            {comments.map(item => {
                                if(item.reply_to_id === null){
                                    return <ReplyItem key={item.id} postID={postID} sendComment={sendComment} sendFile={sendFile} item={item} hideReply={false}/>
                                }
                            })}
                        </Grid>
                        <Grid className={classes.sendComment}>
                            <MessageEditInput
                                placeHolder="Comment..."
                                sendAction={(dataToSend) => {
                                    dispatch(
                                        formSubmitAndGetCustomDataAction({
                                            submitType: "post",
                                            endpoint: "posts/"+postID+"/comments",
                                            formValues: {
                                                reply_to_id: null,
                                                message:  dataToSend.body,
                                                files: dataToSend.files
                                            },
                                            getEndpoint: `posts/${postID}/comments`,
                                            name: "comments",
                                        })
                                    );
                                }}
                            />
                                {/*<TextField*/}
                                {/*    fullWidth*/}
                                {/*    name={`comment_${postID}`}*/}
                                {/*    id="standard-bare"*/}
                                {/*    variant="outlined"*/}
                                {/*    className={classes.commentInputMain}*/}
                                {/*    placeholder="Comment"*/}
                                {/*    size="small"*/}
                                {/*    value={commentValue}*/}
                                {/*    onChange={handleCommentChange}*/}
                                {/*    autoComplete="off"*/}
                                {/*    InputProps={{*/}
                                {/*        endAdornment: (*/}
                                {/*            <InputAdornment type="button" position="end">*/}
                                {/*                <IconButton onClick={sendComment(postID, null, commentValue, setCommentValue)}>*/}
                                {/*                    <img src={SendIcon} alt="" />*/}
                                {/*                </IconButton>*/}
                                {/*                <IconButton onClick={sendFile(postID, null)}>*/}
                                {/*                    <img src={FileIcon} alt="" />*/}
                                {/*                </IconButton>*/}
                                {/*            </InputAdornment>*/}
                                {/*        )*/}
                                {/*    }}*/}
                                {/*/>*/}
                        </Grid>
                    </form>
                )}
            >
            </Form>
        </>
    );
};

export default IdeationPostCommentForm;
