import {useEffect, React} from "react";
import PropTypes from "prop-types";

import {
  makeStyles,
  FormLabel,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import CustomChip from "./CustomChip";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0px",
    textAlign: "left",
  },
  chipsDiv: {
    // marginTop: ".3rem",
  },
  chip: {
    margin: "0px 10px 15px 0px",
    height: "28px",
    backgroundColor: "#F5F5F5",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    borderRadius: "4px",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#6233C0",
      filter: "brightness(120%)",
    },
    "&:active": {
      backgroundColor: "#6233C0",
    },
  },
  formHelperText: {
    textAlign: "center",
  },
}));

// 1.0.5

const MultipleSelectChips = ({
  value,
  setValue,
  options,
  label,
  error,
  setError,
  singleValue,
  limit,
  initValue
}) => {


  const classes = useStyles();

  useEffect(() => {

    if (initValue) {
      setValue(initValue);
    }
  }, []);

  const handleClick = (clickedValue) => {

    if (singleValue) {
      setValue([]);
    }
    if (setError) {
      setError("");
    }
    if (value.find((e) => e === clickedValue)) {
      const index = value.findIndex((e) => e === clickedValue);
      let arr = [...value];
      arr.splice(index, 1);
      setValue(arr);
    } else {
      if (singleValue) {
        setValue([clickedValue]);
      }
      else {
        if (limit && value && value.length == limit) {
          setError("Must choose no more than three “interest area” tags");
        }
        else {
          setValue([...value, clickedValue]);
        }

      }

    }
  };

  return (
    <>
      <div className={classes.container}>
        {label && (
          <FormLabel error={Boolean(error)}>
            <Typography variant="body2">{`${label}${value.length ? ":" : ""
              } ${options
                .filter((option) => value.indexOf(option.value) !== -1)
                .map((option) => option.label)
                .join(", ")}`}</Typography>
          </FormLabel>
        )}

        <div className={classes.chipsDiv}>
          {options && options.length
            ? options.map((option, i) => (
              <CustomChip
                icon={option.icon}
                className={classes.chip}
                key={i}
                color="#000"
                variant={
                  value.find((e) => e === option.value)
                    ? "default"
                    : "outlined"
                }
                //variant="outlined"
                label={
                  <Typography variant="body2">{`${option.label}`}</Typography>
                }
                clickable
                onClick={() => handleClick(option.value)}
              />
            ))
            : null}
        </div>
        {Boolean(error) && (
          <FormHelperText
            className={classes.formHelperText}
            error={Boolean(error)}
          >
            {error}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

MultipleSelectChips.propTypes = {
  label: PropTypes.string,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      icon: PropTypes.node,
    })
  ).isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
  singleValue: PropTypes.bool.isRequired,
  limit: PropTypes.number,
};

export default MultipleSelectChips;
