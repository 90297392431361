import React, { useEffect, useState } from "react";

import { IconButton, Menu, MenuItem, Grid, Button } from "@material-ui/core";
import NotificationsIcon from "../../../assets/notification_icon.svg";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./styles";
import {
  loadNotifications,
} from "../../../actions/notificationsActions";
import { formSubmitAndDispatchAction } from "../../../actions/formActions";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { Waypoint } from "react-waypoint";
import {
  RELOAD_NOTIFICATIONS,
} from "../../../actions";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";

const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [cursor, setCursor] = useState(0);

  useEffect(() => {
    async function getData() {
      await dispatch(
        loadNotifications({
          endpoint: `notifications?page=${page}&per_page=${perPage}`,
        })
      );
    }
    getData();
  }, [page, perPage, dispatch]);

  const notifications = useSelector(
    (state) => state.notificationsReducer?.notifications
  );
  const loading = useSelector((state) => state.notificationsReducer?.loading);
  const total = useSelector((state) => state.notificationsReducer?.meta?.total);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuId = "primary-search-account-menu";
  let hasMore = notifications.length > 0 && notifications.length < total;

  let menuItems = notifications?.reduce((acc, n, index) => {
    let item = (
      <MenuItem
        key={`${n?.id}-${n?.message}-${n?.datetime}-${n?.read_at}`}
        className={clsx(n?.read_at ? "read" : "unread")}
      >
        <Grid className={classes.headerNotificationContent}>
          <Grid>
            {n?.user ? (
              <>
                <Grid className={classes.notificationUserIcon}>
                  <ProfileAvatar
                    imageLink={n.user?.profile_image?.link}
                    user={n.user}
                  />
                </Grid>
                <span className={classes.notificationUserName}>
                  {n?.user.name}
                </span>
              </>
            ) : null}
            {n?.message}
          </Grid>
          <Grid className={classes.notificationButtons}>
            {n?.read_at ? (
              <Button
                className={classes.notificationButton}
                onClick={() => {
                  dispatch(
                    formSubmitAndDispatchAction({
                      endpoint: `/notifications/${n?.id}/unread`,
                      action: {
                        type: RELOAD_NOTIFICATIONS,
                        data: {
                          endpoint: `notifications?page=1&per_page=${perPage * page
                            }`,
                        },
                      },
                    })
                  );
                }}
              >
                Unread
              </Button>
            ) : (
              <Button
                className={classes.notificationButton}
                onClick={() => {
                  /*                       dispatch(
                        formSubmitAndDispatchAction({
                          endpoint: `/notifications/${n?.id}/read`,
                          action: {
                            type: LOAD_NOTIFICATIONS,
                            data: {
                              endpoint: `notifications?page=1&per_page=${
                                perPage * page
                              }`,
                            },
                          },
                          reset: {
                            type: RESET_NOTIFICATIONS,
                          },
                        })
                      ); */
                  dispatch(
                    formSubmitAndDispatchAction({
                      endpoint: `/notifications/${n?.id}/read`,
                      action: {
                        type: RELOAD_NOTIFICATIONS,
                        data: {
                          endpoint: `notifications?page=1&per_page=${perPage * page
                            }`,
                        },
                      },
                    })
                  );
                }}
              >
                Read
              </Button>
            )}
          </Grid>
        </Grid>
      </MenuItem>
    );
    acc = [...acc, item];

    if (index === notifications.length - 1) {
      let waypoint = (
        <MenuItem key="waypoint">
          {!loading &&
            hasMore &&
            notifications.length &&
            index === notifications.length - 1 ? (
            <Waypoint
              key={cursor}
              onEnter={() => {
                if (isMenuOpen) {
                  setPage((prev) => prev + 1);
                }
              }}
            />
          ) : null}
        </MenuItem>
      );
      acc = [...acc, waypoint];
    }
    return acc;
  }, []);


  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        className={classes.headerNotificationButton}
      >
        <img src={NotificationsIcon} alt="Notifications" />
        {notifications?.length && !notifications?.[0]?.read_at ? (
          <span className={classes.headerNewNotification}></span>
        ) : null}
      </IconButton>

      {menuItems?.length ?
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className={classes.headerNotification}
          getContentAnchorEl={null}
        >
          {menuItems}
          {loading && <CircularProgress />}
        </Menu>
      : null}
    </React.Fragment>
  );
};

export default Notifications;
