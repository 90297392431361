import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AccountIcon from "../../../assets/user_icon.svg";
import { logoutUserAction } from "../../../actions/authenticationActions";
import { useSelector, useDispatch } from "react-redux";
import UserCard from "../../../components/UserCard";
import Link from "@material-ui/core/Link";
import useStyles from "./styles";
import { isCreator, isMaster } from "../../../utils/misc";

export default function User() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.headerUserMenu}
    >
      <MenuItem className={classes.headerUserMenuCard}>
        <UserCard user={user} userLink="true"/>
      </MenuItem>
      <MenuItem>
        <Link href={ process.env.REACT_APP_MAIN_HOST + "/about" }>About WeCreators</Link>
      </MenuItem>
      <MenuItem>
        <Link href={ process.env.REACT_APP_MAIN_HOST + "/support" }>Support</Link>
      </MenuItem>
      <MenuItem>
        <Link href={ process.env.REACT_APP_MAIN_HOST + "/faq" }>FAQ</Link>
      </MenuItem>
{/*       {isCreator(user) && !isMaster(user) &&
        (<MenuItem>
         <Link href={ process.env.REACT_APP_MAIN_HOST + "/pricing" }>Pricing</Link>
      </MenuItem>)} */}
      <MenuItem>
        <Link href={ process.env.REACT_APP_MAIN_HOST + "/terms" }>Terms</Link>
      </MenuItem>
      <MenuItem>
          <span
            onClick={() => {
              dispatch(logoutUserAction());
            }}
            >
            Log out
          </span>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <IconButton
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <img src={AccountIcon} alt="Account"/>
      </IconButton>
      {renderMenu}
    </React.Fragment>
  );
}
