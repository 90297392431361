import React, { useEffect } from "react";

import { Popper, Typography, Button, Grid } from "@material-ui/core";
import { setCookie, getCookie } from "../utils/misc";
import useStyles from "../styles/CookiePopup";
import Link from "@material-ui/core/Link";

const CookiePopup = () => {

  const [open, setOpen] = React.useState(false);
  const popperRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  useEffect(() => {
    let cookie = getCookie("cookie_policy");
    if (!cookie) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    setAnchorEl(popperRef.current);
  }, [popperRef]);


  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div ref={popperRef}>
      <Popper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        placement="bottom"
        className={classes.cookiePopup}
      >
        <Grid className={classes.cookiePopupContainer}>
          <Grid className={classes.cookiePopupDescription}>
            <Typography variant="h3" className={classes.cookiePopupTitle}>
              We use cookies
            </Typography>
            <Grid>
              In order to improve your experience on our site and to show you
              relevant advertising. To&nbsp;find out more, read our updated{" "}
              <Link
                target="_blank"
                href={`${process.env.REACT_APP_MAIN_HOST}/terms`}
                key="privacy policy"
              >
                privacy&nbsp;policy
              </Link>{" "}
              and{" "}
              <Link
                href={`${process.env.REACT_APP_MAIN_HOST}/cookie`}
                target="_blank"
                key="cookie policy"
              >
                cookie&nbsp;policy
              </Link>
              .
            </Grid>
          </Grid>
          <Button
            className={classes.cookiePopupButton}
            onClick={() => {
              setCookie("cookie_policy", true, 2147483647);
              handleClose();
            }}
          >
            Agree
          </Button>
          <Button
            onClick={handleClose}
            className={classes.cookiePopupClose}
          ></Button>
        </Grid>
      </Popper>
    </div>
  );
};

export default CookiePopup;
