import React, { useEffect } from "react";

import { Button, Typography, Grid, CircularProgress } from "@material-ui/core";
import {
  formGetDataAction,
  formGetCustomDataAction,
  formGetReferenceTableValuesAction,
} from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import TeamCard from "../components/TeamCard";
import DiscoverTeamsFilter from "../components/DiscoverTeamsFilter";
import { push } from "connected-react-router";

import { useParams } from "react-router-dom";
import useStyles from "../styles/Teams";
import { Helmet } from "react-helmet";
import { isMaster } from "../utils/misc";


const DiscoverTeams = () => {
  const dispatch = useDispatch();

  let { opportunityId } = useParams();
  const classes = useStyles();
  const data = useSelector((state) => state.formReducer.data);
  const user = useSelector((state) => state.userReducer.user);
  const opportunity = useSelector(
    (state) => state.formReducer.customData?.opportunity
  );
  const loading = useSelector((state) => state.formReducer.loading);

  const [creativeField, setCreativeField] = React.useState("all");
  const [creativeFieldLabel, setCreativeFieldLabel] =
    React.useState("Creative fields");
  const [role, setRole] = React.useState("all");
  const [roleLabel, setRoleLabel] = React.useState("Roles");

  useEffect(() => {
    if (opportunityId) {
      dispatch(
        formGetDataAction({
          endpoint: `opportunities/${opportunityId}/teams`,
        })
      );

      dispatch(
        formGetCustomDataAction({
          endpoint: `opportunities/${opportunityId}`,
          name: "opportunity",
        })
      );
    } else {
      dispatch(
        formGetDataAction({
          endpoint: "teams",
          //filter: { role, creativeField, status },
        })
      );
    }

    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-opportunity-roles",
      })
    );
  }, [dispatch, opportunityId]);

  useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: opportunityId
          ? `opportunities/${opportunityId}/teams`
          : "teams",
        filter: { role, creativeField },
      })
    );
  }, [dispatch, opportunityId, role, creativeField]);

  return (
    <>
      <Helmet>
        <title>
          {opportunityId
            ? `${opportunity?.title} | Calls | weCreators`
            : "Teams | weCreators"}
        </title>
      </Helmet>
      <Grid className={classes.containerDiscover}>
        <Grid className={classes.titleDiscover}>
          <Typography variant="h1" color="primary">
            Apply for {opportunityId ? `${opportunity?.title}` : ""} teams
          </Typography>
          {isMaster(user) ? (
            <Grid className="buttons">
              <Button
                className="invert"
                onClick={() => dispatch(push(`/create-team`))}
              >
                Create team
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid className={classes.filterDiscover}>
          <Typography
            variant="body2"
            noWrap
            className={classes.filterDiscoverTitle}
          >
            Filter by
          </Typography>
          <div id="toggleFilterContainer">
            <div className={classes.searchFilter}>
              <DiscoverTeamsFilter
                creativeField={creativeField}
                creativeFieldLabel={creativeFieldLabel}
                setCreativeField={setCreativeField}
                setCreativeFieldLabel={setCreativeFieldLabel}
                role={role}
                setRole={setRole}
                roleLabel={roleLabel}
                setRoleLabel={setRoleLabel}
              />
            </div>
          </div>
        </Grid>

        {loading ? (
          <CircularProgress className="main-progress-bar" />
        ) : data?.length > 0 ?

          data?.map((d) => {
            return (
              <Grid key={d.id}>
                <TeamCard data={d} />
              </Grid>
            );
          }) : (

            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.searchResult}
            >
              <div className={classes.searchNotFound}>
                <div className={classes.searchNotFoundTitle}>
                  Sorry, we couldn’t find any results for your search.
                </div>
                <div>Maybe try to use a different filter.</div>
              </div>
            </Grid>
          )}
      </Grid>

    </>
  );
};

export default DiscoverTeams;
