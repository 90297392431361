import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  userMenuIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  userMenuNameBlock: {
    position: "relative",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  userMenuLink: {
    fontSize: "10px!important",
    color: "#636363",
    position: "absolute",
    right: 0,
    top: 5,
    lineHeight: "16px!important",
    textDecoration: "none",
    "&:hover": {
      color: "#000",
    },
  },
  userMenuName: {
    paddingLeft: 10,
  },
  userMenuAvatarBlock: {
    position: "relative",
    height: 30,
  },
  userMenuAvatar: {
    minWidth: 30,
    height: 30,
    borderRadius: "50%",
  },
  userMenuLinkName: {
    textDecoration: "none",
    width: "100%",
    color: "#000!important",
    paddingLeft: 10,
    "&:hover": {
      color: "#6233C0!important",
    },
  },
}));

export default useStyles;
