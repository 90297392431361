import React from "react";
import Header from "../views/global/header/Header";
import Footer from "../views/global/footer/Footer";
import ErrorNotification from "../components/ErrorNotification";
import SuccessNotification from "../components/SuccessNotification";
import CookiePopup from "../components/CookiePopup";

import useStyles from "../styles/Global.js";

const Layout = (props) => {
  const classes = useStyles();


  return (
    <div className={classes.rootClass}>
      <Header className={classes.noGrow} />
      <SuccessNotification />
      <div className={classes.grow}>
          {props.children}
        <CookiePopup />
      </div>
      <Footer className={classes.noGrow} />
      <ErrorNotification />
    </div>
  );
};

export default Layout;
