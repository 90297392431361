import React from "react";

import { useSelector } from "react-redux";
import ProfileCreativeTest from "../pages/ProfileCreativeTest";
import ProfileSkills from "../forms/ProfileSkills";
import ProfileTypeForm from "../forms/ProfileTypeForm";
import Manifest from "../pages/Manifest";
import { Redirect } from "react-router";
import { isCreator } from "../utils/misc";

const CompleteRegistration = () => {
  const user = useSelector((state) => state.userReducer.user);

  if (user.profile_types.length < 1) {
    return <ProfileTypeForm />;
  }

  if (isCreator(user)) {
    if (!user?.creative_test_result_id) {
      return <ProfileCreativeTest />;
    }

    if (!user?.interest_areas?.length) {
      return <ProfileSkills />;
    }
  }

  if (!user?.is_manifest_agree) {
    return <Manifest />;
  }
  return <Redirect to="/" />;
};

export default CompleteRegistration;
