import React from "react";
import {Link, Redirect} from "react-router-dom";


import {Paper, Button, Typography, Grid, TextField, DialogContent, Dialog, CircularProgress} from "@material-ui/core";
import {
    formGetDataAction,
    formGetCustomDataAction,
    formGetReferenceTableValuesAction, formSubmitAndGetCustomDataAction,
} from "../actions/formActions";
import {useSelector, useDispatch} from "react-redux";

import {useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";

import {
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Input,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IdeationPostCard from "../components/IdeationPostCard";
import {push} from "connected-react-router";

import {useParams} from "react-router-dom";
import useStyles from "../styles/IdeationSpaces";
import {Helmet} from "react-helmet";
import HomeFilterType from "../components/HomeFilterType";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "../assets/seatch_filter.svg";
import NotFound from "../assets/not_found.svg";
import Chip from "@material-ui/core/Chip";

import MultipleSelectChips from "../components/MultipleSelectChips";
import IdeationPostFormWrapper from "../containers/IdeationPostFormWrapper";
import Modal from "@material-ui/core/Modal";
import RemoveIcon from "../assets/close.svg";

import useIdeationPostStyles from "../styles/IdeationPostForm";

const IdeationSpaces = (props) => {

    const [type, setType] = React.useState("group");
    const [tag, setTag] = React.useState("all");
    const [searchValue, setSearchValue] = React.useState("");

    const [chipData, setChipData] = React.useState({});
    const [optionsChip, setOptionsChip] = React.useState([]);
    const [optionsGroupChip, setOptionsGroupChip] = React.useState([]);

    const [openPostForm, setOpenPostForm] = React.useState(false);
    const loading = useSelector((state) => state.formReducer.loading);

    const useIdeationPostClasses =useIdeationPostStyles();

    const handleCloseForm = () => {
        setOpenPostForm(false);
    };



    const dispatch = useDispatch();

    const classes = useStyles();
    const data = useSelector((state) => state.formReducer.data);
    const posts = useSelector((state) => state.formReducer.customData?.posts);
    const [area, setArea] = React.useState("all");

    const [firstCall, setFirstCall] = React.useState(true);

    const [valueChip, setValueChip] = React.useState([]);
    const [errorChip, setErrorChip] = React.useState("");

    const [groupValueChip, setGroupValueChip] = React.useState([]);
    const [errorGroupChip, setErrorGroupChip] = React.useState("");
    const [selectedDateCreate, setSelectedDateCreate] = React.useState(null);

    const user = useSelector((state) => state.userReducer.user);

    const groups = useSelector(
        (state) => state.formReducer.customData?.groups
    );

    const interests = useSelector(
        (state) => state.formReducer.customData?.interests
    );




    let isMaster = user?.profile_types.reduce((acc, cur) => {

        if (cur.name === "Master Creator" || cur.name === "Creator") {
            acc = true;
            return acc;
        }
        return acc;
    }, false);


    useEffect(() => {
        if(firstCall){
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    name: "posts"
                })
            );
            setFirstCall(false);
        }

    }, [dispatch, props]);

    useEffect(() => {
        if (type == "group") {
            if(!groups){
                dispatch(
                    formGetCustomDataAction({
                        endpoint: "groups",
                        name: "groups",
                    })
                );
            }
            loadPostByGroup(groupValueChip);

        }
        else if (type == "interest_area") {
            if(!interests){
                dispatch(
                    formGetCustomDataAction({
                        endpoint: "classifiers-interest-areas",
                        name: "interests",
                    })
                );
            }
            loadPostByInterestArea(valueChip);

        }
        else if (type == "owner") {
            const param = ["created_by", "=", user.id];
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    filter: param,
                    name: "posts"
                })
            );
        }

    }, [type]);

    useEffect(() => {
        var optionsChip = [];
        if(typeof interests !== "undefined"){
            interests.forEach(item => optionsChip.push({label: item.name, value: item.id}));
        }
        setOptionsChip(optionsChip);
    }, [interests]);

    useEffect(() => {
        var optionsGroupChip = [];
        if(typeof groups !== "undefined"){
            groups.forEach(item => optionsGroupChip.push({label: item.name, value: item.id}));
        }
        setOptionsGroupChip(optionsGroupChip);
    }, [groups]);

    const loadPostByInterestArea = (valueChip) => {
        if(valueChip.length > 0){
            const param = ["interest_areas.id", "in", valueChip];
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    filter: param,
                    name: "posts"
                })
            );
        }
        else{
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    name: "posts"
                })
            );
        }

    }
    useEffect(() => {
        if(firstCall){
            return ;
        }
        loadPostByInterestArea(valueChip);

    }, [valueChip]);

    const loadPostByGroup = (groupValueChip) => {
        if(firstCall){
            return ;
        }
        if(groupValueChip.length > 0){
            let item = groups.filter(x => x.id === groupValueChip[0])[0];

            let interest_areas = [];
            if(item.interest_areas){
                interest_areas = item.interest_areas.map(s=>s.id);
            }
            const param = ["interest_areas.id", "in", interest_areas];
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    filter: param,
                    name: "posts"
                })
            );
        }
        else{
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    name: "posts"
                })
            );
        }
    }
    useEffect(() => {

        loadPostByGroup(groupValueChip);

    }, [groupValueChip]);


    useEffect(() => {
        if (chipData.name == "group") {
            if(typeof chipData.chipToClick === "undefined"){
                dispatch(
                    formGetCustomDataAction({
                        endpoint: "posts",
                        name: "posts"
                    })
                );
            }
            else {
                let item = groups.filter(x => x.id === chipData.chipToClick.id)[0];
                let interest_areas = [];
                if(item.interest_areas){
                    interest_areas = item.interest_areas.map(s=>s.id);
                }
                const param = ["interest_areas.id", "in", interest_areas];
                dispatch(
                    formGetCustomDataAction({
                        endpoint: "posts",
                        filter: param,
                        name: "posts"
                    })
                );
            }

        }
        // if (chipData.name == "interest_area") {
        //     const param = ["interest_areas.id", "in", [chipData.chipToClick.id]];
        //     dispatch(
        //         formGetDataAction({
        //             endpoint: "posts",
        //             filter: param,
        //         })
        //     );
        // }
        if (chipData.name == "date") {
            var param;
            if(chipData.chipToClick?.id){
                switch (chipData.chipToClick.id) {
                    case 1:
                        //Today
                        let today = new Date().toISOString().slice(0, 10);
                        param = ["created_at", "=", [today]];
                        break;
                    case 2:
                        //Yesterday
                        let date = new Date();
                        date.setDate(date.getDate() - 1);
                        let yesterday = date.toISOString().slice(0, 10);
                        param = ["created_at", "=", [yesterday]];
                        break;
                    case 3:
                        //This week
                        var curr = new Date; // get current date
                        var first = curr.getDate() - curr.getDay() + 1; // First day is the day of the month - the day of the week
                        var last = first + 6; // last day is the first day + 6

                        var firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);
                        var lastday = new Date(curr.setDate(last)).toISOString().slice(0, 10);

                        param = [["created_at", ">=", firstday], "and", ["created_at", "<=", lastday]];
                        break;
                    case 4:
                        //Last week
                        var curr = new Date; // get current date
                        var first = curr.getDate() - curr.getDay() + 1 - 7; // First day is the day of the month - the day of the week


                        var last = first + 6; // last day is the first day + 6

                        var firstday = new Date(curr.setDate(first)).toISOString().slice(0, 10);

                        var curr2 = new Date; // get current date
                        var lastday = new Date(curr2.setDate(last)).toISOString().slice(0, 10);

                        param = [["created_at", ">=", firstday], "and", ["created_at", "<=", lastday]];
                        break;
                    case 5:
                        //Last month
                        var x = new Date();
                        x.setDate(0); // 0 will result in the last day of the previous month
                        x.setDate(1); // 1 will result in the first day of the month
                        var firstday = x.toISOString().slice(0, 10);
                        var y = new Date();
                        y.setDate(0); // 0 will result in the last day of the previous month
                        y.setDate(y.getDate()); //end day of the month
                        var lastday = y.toISOString().slice(0, 10);
                        param = [["created_at", ">=", firstday], "and", ["created_at", "<=", lastday]];
                        break;

                }
                dispatch(
                    formGetCustomDataAction({
                        endpoint: "posts",
                        filter: param,
                        name: "posts"
                    })
                );
            }
            else{
                dispatch(
                    formGetCustomDataAction({
                        endpoint: "posts",
                        name: "posts"
                    })
                );
            }

        }
    }, [chipData]);


    const handleFilterClick = (name, chipToClick) => (e) => {
        if(name == "date"){

            if(selectedDateCreate?.id && chipToClick.id == selectedDateCreate.id){
                setSelectedDateCreate({});
            }
            else{
                setSelectedDateCreate(chipToClick);
            }
        }
        //setChipData((chips) => chips.filter((chip) => chip.key !== chipToClick.key));
        if(chipData?.chipToClick?.id){
            if(chipData.chipToClick.id == chipToClick.id){
                setChipData({name});
            }
            else {
                setChipData({name, chipToClick});
            }
        }
        else {
            setChipData({name, chipToClick});
        }

    }
    const toggleSearch = () => {
        const param = [["title", "contains", searchValue],"or",["body", "contains", searchValue]];
        if (searchValue.length) {
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    filter: param,
                    name: "posts"
                })
            );
        }
        else {
            dispatch(
                formGetCustomDataAction({
                    endpoint: "posts",
                    name: "posts"
                })
            );
        }

    }
    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    }
    // useEffect(() => {
    //     toggleSearch();
    // }, [searchValue]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            toggleSearch();
        }, 2000)

        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    const onClearClick = () => {
        setSearchValue("");
        setChipData({});
        dispatch(
            formGetCustomDataAction({
                endpoint: "posts",
                name: "posts"
            })
        );
    }
    const keyPressSearch = (e) => {
        if (e.key === "Enter") {
            toggleSearch();
        }
    }
    const dates = [
        {"id": 1, "name": "Today"},
        {"id": 2, "name": "Yesterday"},
        {"id": 3, "name": "This week"},
        {"id": 4, "name": "Last week"},
        {"id": 5, "name": "Last month"},
    ];
    const handleOkPost = () => {

    }
    if (loading || !posts) {
        return <CircularProgress className="main-progress-bar" />;
    }
    return (
        <>
            <Helmet>
                <title>
                    Ideation spaces | weCreators
                </title>
            </Helmet>
            <Grid className={classes.ideationSpaces}>
                <Typography variant="h1" className={classes.titleIdeationSpaces}>Ideation space</Typography>
                <Grid container>
                    <Grid item xs={12} lg={7} className={classes.searchIdeationSpaces}>
                        <TextField
                            fullWidth
                            id="standard-bare"
                            variant="outlined"
                            className={classes.searchInput}
                            placeholder="Search"
                            size="small"
                            onChange={handleSearchChange}
                            onKeyDown={keyPressSearch}
                            value={searchValue}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment type="button" position="start">
                                        <IconButton onClick={toggleSearch}>
                                            <img src={SearchIcon} alt="Search tasks" />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Grid className="buttons">
                            <Button variant="text" onClick={onClearClick} className="invert">Cancel</Button>
                            {isMaster ? (
                                <>
                                    <Button onClick={() => setOpenPostForm(true)}>Create new post</Button>
                                    <Dialog open={openPostForm} className={useIdeationPostClasses.paper}>
                                            <Button onClick={handleCloseForm} className={useIdeationPostClasses.paperClose}></Button>
                                            {/*<DialogContent className={useIdeationPostClasses.confirmationTitle}>*/}

                                            {/*</DialogContent>*/}
                                            <div className={useIdeationPostClasses.form}>
                                                <IdeationPostFormWrapper handleCloseForm={handleCloseForm}/>
                                            </div>
                                            {/*<div className="buttons">*/}
                                            {/*    <Button onClick={handleOkPost}>Post</Button>*/}
                                            {/*</div>*/}
                                    </Dialog>
                                </>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} lg={7} className={classes.ideationSpacesPosts}>
                        {posts && posts.length > 0 ? posts.map((d) => {
                            return (
                                <Grid key={d.id}>
                                    <IdeationPostCard key={d.id} data={d} searchValue={searchValue}/>
                                </Grid>
                            );
                        }) : <div className={classes.ideationSpacesNotFind}>Ooops! We didn’t find anything!<img src={NotFound} alt="Not found tasks" /></div>}
                    </Grid>
                    <Grid item xs={12} lg={5} className={classes.ideationSpacesFilter}>
                        <div id="toggleFilterContainer">
                            <span className={classes.ideationSpacesFilterTitle}>Filter by:</span>
                            <HomeFilterType
                                value={type}
                                setValue={setType}
                                choices={[
                                    {value: "group", label: "Group"},
                                    {value: "interest_area", label: "Interest area"},
                                    {value: "date", label: "Date"},
                                    {value: "owner", label: "Post you’ve made"},
                                ]}
                            />
                        </div>
                        <div className={classes.ideationSpacesFilterItem}>
                            <div>
                                {/*{type == "group" && groups?.map((or) => (*/}
                                {/*    <Chip key={or.id} value={or.id} label={or.name} variant="outlined" onClick={handleFilterClick("group", or)}/>*/}
                                {/*))}*/}
                                { type == "group" && groups?.length >0 && <MultipleSelectChips
                                    //label="Label"
                                    value={groupValueChip}
                                    setValue={setGroupValueChip}
                                    options={optionsGroupChip}
                                    error={errorGroupChip}
                                    setError={setErrorGroupChip}
                                    singleValue={true}
                                />

                                }
                            </div>
                            <div>
                                {/*{type == "interest_area" && interests?.map((or) => (*/}
                                {/*    <Chip className={classes.postChipsFilter} key={or.id} value={or.id} label={or.name}*/}
                                {/*          variant="outlined" onClick={handleFilterClick("interest_area", or)}/>*/}
                                {/*))}*/}
                                { type == "interest_area" && optionsChip.length >0 && <MultipleSelectChips
                                    //label="Label"
                                    value={valueChip}
                                    setValue={setValueChip}
                                    options={optionsChip}
                                    error={errorChip}
                                    setError={setErrorChip}
                                    singleValue={false}
                                />

                                }
                            </div>
                            <div>
                                {type == "date" && dates?.map((or) => (
                                    <div
                                        key = {or.id}
                                        className={classes.searchIdeationFilterDate}
                                        variant="text"
                                        onClick={handleFilterClick("date", or)}
                                        //onClick={() => {setSelectedDateCreate(or); handleFilterClick("date", or)}}
                                        style={or.id === selectedDateCreate?.id ? {color: "#6233C0"} : {color: "#000000"}}
                                    >{or.name} </div>
                                ))}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default IdeationSpaces;
