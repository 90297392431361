import * as types from "./index";

export const registerUserAction = (data) => {
  return {
    type: types.REGISTER_USER,
    data,
  };
};

export const loginUserAction = (data) => {
  return {
    type: types.LOGIN_USER,
    data,
  };
};

export const loginUserGoogleAction = () => {
  return {
    type: types.LOGIN_USER_GOOGLE,
  };
};

export const loginUserGoogleSuccessAction = (data) => {
  return {
    type: types.LOGIN_USER_GOOGLE_SUCCESS,
    data,
  };
};

export const loginUserGoogleErrorAction = (data) => {
  return {
    type: types.LOGIN_USER_GOOGLE_ERROR,
    data,
  };
};

export const loginUserFacebookSuccessAction = (data) => {
  return {
    type: types.LOGIN_USER_FACEBOOK_SUCCESS,
    data,
  };
};

export const loginUserFacebookErrorAction = (data) => {
  return {
    type: types.LOGIN_USER_FACEBOOK_ERROR,
    data,
  };
};

export const logoutUserAction = () => {
  return {
    type: types.LOGOUT_USER,
  };
};

export const getUserAction = () => {
  return {
    type: types.GET_USER,
  };
};

export const refreshTokenAction = (data) => {
  return {
    type: types.REFRESH_TOKEN,
    data,
  };
};
