import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import CancelIcon from "../assets/close.svg";

import useStyles from "../styles/CreationOpportunityTeamForm";
import { fileUploadError } from "../utils/misc";

let containerPath = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}`;

function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = function () {
      const result = fileReader.result;
      return resolve({
        data: fileReader.result,
        name: file.name,
        size: file.size,
        type: file.type,
        b64: result,
        preview: URL.createObjectURL(file),
      });
    };
    fileReader.readAsDataURL(file);
  });
}

function Dropzone(props) {
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    //accept: "image/*",
    accept: [
      "image/bmp",
      "image/gif",
      "image/vnd.microsoft.icon",
      "image/jpeg",
      "image/png",
      "image/tiff",
      "image/webp",
    ],
    multiple: false,
    noDrag: false,
    onDrop: async (acceptedFiles) => {
      let files = await Promise.all(
        acceptedFiles.map((f) => {
          return readAsDataURL(f);
        })
      );
      setFiles(files);
      if (props.onChange) {
        props.onChange(files);
      }
    },
  });

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    if (newFiles.length) {
      props.onChange(newFiles);
    } else {
      props.onChange(null);
    }
  };

  const thumbs = files?.map((file) => {
    return (
      <div key={file.name} className={classes.thumbInner}>
        <img
          src={file.b64 ? file.b64 : `${containerPath}/${file.link}`}
          alt=""
        />
        <IconButton
          edge="end"
          aria-label="filter"
          aria-controls="removeFile"
          aria-haspopup="true"
          onClick={removeFile(file)}
          color="secondary"
          className={classes.thumbCancel}
        >
          <img src={CancelIcon} alt="Close" />
        </IconButton>
      </div>
    );
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => {
        URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );

  useEffect(() => {
    if (props.value) {
      setFiles((files) =>
        Array.isArray(props.value) ? props.value : [props.value]
      );
    }
  }, [props.value]);

  const errors = fileRejections.map((file) => {
    return (
      <div className={classes.dropzoneError} key={file.file.name}>
        {file.file.name}
        {file.errors?.map((e) => (
          <span className={classes.attachmentError}>
            {fileUploadError(e.code)}
          </span>
        ))}
      </div>
    );
  });

  return (
    <div className={classes.dropzoneBtn}>
      <div {...getRootProps({ className: "btn-dropzone" })}>
        <input {...getInputProps()} />
        <span className={classes.dropzoneBtnMainText}>{props.mainText}</span>
        <span
          className={classes.dropzoneBtnSubText}
          dangerouslySetInnerHTML={{ __html: props.subText }}
        ></span>
      </div>
      <aside className={classes.dropzoneAside}>{thumbs}</aside>
      {errors}
    </div>
  );
}

export default Dropzone;
