import React, { useEffect } from "react";
import { TextField, Select, DatePicker } from "mui-rff";
import { Button, Typography, Grid, MenuItem } from "@material-ui/core";
import { Field } from "react-final-form";
import UserCard from "../components/UserCard";
import AttachmentChips from "../components/AttachmentChips";
import useStyles from "../styles/CreationOpportunityTeamForm";
import MomentUtils from "@date-io/moment";
import TaskAssigneeSelect from "../components/TaskAssigneeSelect";
import { useSelector, useDispatch } from "react-redux";
import Comments from "../components/Comments";
import { formGetCustomDataAction } from "../actions/formActions";
import clsx from "clsx";

const BoardCardCreator = (props) => {
  const {
    cardToEdit,
    statuses,
    stages,
    members,
    setCardToEdit,
    setColumnToEdit,
    setOpen,
    tasks,
  } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    async function getData() {
      if (cardToEdit?.id) {
        await dispatch(
          formGetCustomDataAction({
            endpoint: `tasks/${cardToEdit.id}/comments`,
            name: "comments",
          })
        );
      }
    }
    getData();
  }, [dispatch, cardToEdit]);
  const comments = useSelector(
    (state) => state.formReducer.customData?.comments
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id="title"
          name="title"
          label="Task title"
          variant="filled"
          required
          className={classes.teamTextField}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
          name="description"
          label="Description"
          multiline
          variant="outlined"
          required
          className={classes.teamTextField}
          disabled={true}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          fullWidth
          dateFunsUtils={MomentUtils}
          name="due_date"
          variant="inline"
          label="Due date"
          format="yyyy-MM-DD"
          //required={true}
          className={classes.teamTextField}
          disabled={true}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(classes.CreationSelectBlock, classes.teamBoardSelect)}
      >
        {tasks ? (
          <Select
            name="parent_id"
            label="Parent task"
            formControlProps={{ margin: "none" }}
            disabled={true}
          >
            {tasks?.reduce((acc, cur) => {
              if (cur.id !== cardToEdit?.id) {
                acc = [
                  ...acc,
                  <MenuItem key={cur.id} value={cur.id}>
                    {cur.title}
                  </MenuItem>,
                ];
              }
              return acc;
            }, [])}
          </Select>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(classes.CreationSelectBlock, classes.teamBoardSelect)}
      >
        {statuses ? (
          <Select
            name="status_id"
            label="Status"
            formControlProps={{ margin: "none" }}
            required={true}
            disabled={true}
          >
            {statuses?.map((or) => (
              <MenuItem key={or.id} value={or.id}>
                {or.name}
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(
          classes.CreationSelectBlock,
          classes.teamBoardSelect,
          classes.teamBoardSelectStage
        )}
      >
        {stages ? (
          <Select
            name="stage_id"
            label="Stage"
            formControlProps={{ margin: "none" }}
            required={true}
            disabled={true}
          >
            {stages?.map((or) => (
              <MenuItem key={or.id} value={or.id}>
                {or.title}
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </Grid>
      {cardToEdit?.id ? (
        <Grid
          item
          xs={12}
          md={6}
          className={clsx(
            classes.CreationSelectBlock,
            classes.teamBoardReporter
          )}
        >
          <Typography
            variant="body2"
            className={classes.teamBoardReporterTitle}
          >
            Reporter
          </Typography>
          <UserCard user={cardToEdit?.created_by} />
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(classes.CreationSelectBlock, classes.teamBoardSelect)}
        style={{ paddingLeft: cardToEdit?.id ? 20 : 0 }}
      >
        <Field name="assignee_id">
          {(props) => {
            return (
              <TaskAssigneeSelect
                {...props.input}
                options={members}
                disabled={true}
              />
            );
          }}
        </Field>
      </Grid>
      <Grid className={classes.teamBoardComment}>
        <Typography variant="body2" className={classes.creationTitleField}>
          Comments
        </Typography>
        <Grid className={classes.teamCommentForm}>
          <Grid className={classes.teamCommentFormContainer}>
              <Comments
                comments={comments?.map((c) => {
                  c.user = c.created_by;
                  c.datetime = c.created_at;
                  return c;
                })}
              />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.teamBoardDropzone}>
        <Grid item xs={12}>
          <AttachmentChips title="Attachments" data={cardToEdit.files} />
        </Grid>
      </Grid>
      <Grid item xs={12} className="buttons">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            setColumnToEdit(null);
            setCardToEdit(null);
            setOpen(false);
          }}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  );
};

export default BoardCardCreator;
