import React from "react";

import {
  Button,
  MenuItem,
  Typography,
  Popper,
  MenuList,
  Grow,
  ClickAwayListener,
  Paper,
  Grid,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../views/global/header/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { useState } from "react";

const HomeSearchFilterSingle = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleChange = (event, value) => {
    if (value !== null) {
      props.setValue(value.value);
      props.setLabel(value.label);
      if (value.value === "all") {
        props.setLabel(props.defaultLabel);
      }
      setOpen(false);
    }
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <>
      <div className={classes.sectionDesktop}>
        <Button
          id={props.label}
          className={classes.sectionItemFilter}
          onClick={(e) => {
            setOpen(true);
          }}
          color="secondary"
        >
          {props.label}
          <KeyboardArrowDownIcon />
        </Button>

        <Popper
          open={open}
          anchorEl={document.getElementById(props.label)}
          role={undefined}
          transition
          disablePortal
          className={classes.sectionSelectContent}
          style={{ zIndex: 9999 }}
          placement="bottom-start"
        >
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <MenuItem>
                          <ToggleButtonGroup
                            orientation="vertical"
                            value={props}
                            exclusive
                            onChange={handleChange}
                            aria-label="Type"
                          >
                            {props.choices?.map((o) => (
                              <ToggleButton
                                key={o.label}
                                value={o}
                                aria-label="left aligned"
                              >
                                <Typography
                                  variant="body2"
                                  style={{
                                    color:
                                      o.value === props.value
                                        ? "#6233C0"
                                        : "initial",
                                  }}
                                >
                                  {o.label}
                                </Typography>
                              </ToggleButton>
                            ))}
                          </ToggleButtonGroup>
                        </MenuItem>
                      </Grid>
                    </Grid>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className={classes.sectionMobile}>
        <ToggleButtonGroup
          orientation="vertical"
          value={props}
          exclusive
          onChange={handleChange}
          aria-label="Type"
        >
          {props.choices?.map((o) => (
            <ToggleButton key={o.label} value={o} aria-label="left aligned">
              <Typography variant="body2">{o.label}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </>
  );
};

export default HomeSearchFilterSingle;
