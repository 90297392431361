import React from "react";
import Opportunities from "./Opportunities";
import Teams from "./Teams";
import IdeationSpaces from "./IdeationSpaces";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { isMaster, isBusiness, isCreator } from "../../../utils/misc";

const CenterMenu = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);

  return (
    <>
      <Button
        onClick={() => dispatch(push(`/`))}
        className={classes.centerMenuItem}
      >
        Discover
      </Button>
        {isCreator(user) || isMaster(user) ?
              <Button
                onClick={() => dispatch(push(`/ideation-spaces`))}
                className={classes.centerMenuItem}
              >
                Ideation spaces
              </Button>
            : null}
      {/*{isCreator(user) ? <IdeationSpaces /> : null}*/}
      {isBusiness(user) ? <Opportunities /> : null}
      {isCreator(user) || isMaster(user) ? <Teams /> : null}
    </>
  );
};

export default CenterMenu;
