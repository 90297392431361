export const regex = (regex, message) => (value) => {
  return value !== "" && typeof value === "string" && !regex.test(value)
    ? message
    : undefined;
};
export const minLength = (min, message) => (value) =>
  value && value.length >= min ? undefined : message;

export const maxChars = (max) => (value) => { return !value || value?.length < max ? undefined : `Should be shorter than ${max} characters` };

export const composeValidators =
  (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
