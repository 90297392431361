import { put, call } from "redux-saga/effects";

import {
  registerUserService,
  loginUserService,
  loginOauthService,
  getUserService,
  refreshTokenService,
} from "../services/authenticationService";

import {
  disconnectFromSocketSaga,
} from "./wsSaga";

import { setCookie, parseJwt } from "../utils/misc";

import { push } from "connected-react-router";
import * as types from "../actions";

const accessTokenKey = "access_token";
const getAccessToken = (r) => r.access_token.token;
const refreshTokenKey = "refresh_token";
const getRefreshToken = (r) => r.refresh_token.token;

export function* registerSaga(payload) {
  try {
    const response = yield call(registerUserService, payload.data);
    yield put({ type: types.REGISTER_USER_SUCCESS, response });
    let message = `${payload.data.name}, your account has been successfully created. To finish the registration, please click on the confirmation email that we sent to your email address.`;
    yield put({ type: types.SET_MESSAGE, message });
    setTimeout(function () {
      put({ type: types.HIDE_MESSAGE, message });
    }, 4000);
    yield put(push("/login"));
  } catch (error) {
    yield put({ type: types.REGISTER_USER_ERROR, error });
  }
}

export function* loginSaga(payload) {
  try {
    const response = yield call(loginUserService, payload.data);
    localStorage.setItem(accessTokenKey, getAccessToken(response));
    localStorage.setItem(refreshTokenKey, getRefreshToken(response));

    let expirationDate = parseJwt(getAccessToken(response))?.exp;
    if (expirationDate) {
      setCookie("token", getAccessToken(response), expirationDate);
    }
    yield put({ type: types.LOGIN_USER_SUCCESS, response });
  } catch (error) {
    setCookie("token", "", 0);
    yield put({ type: types.LOGIN_USER_ERROR, error });
  }
}

export function* loginOauthSaga(payload) {
  try {
    let data = {};
    if (payload.data.type === "facebook") {
      data.name = payload.data.data.name;
      data.email = payload.data.data.email;
      data.image = payload.data.data.picture.url;
      data.id = payload.data.data.id;
      data.type = payload.data.type;
    } else {

      data.name = payload.data.data.name;
      data.email = payload.data.data.email;
      data.image = payload.data.data.picture;
      data.id = payload.data.data.sub;
      data.type = payload.data.type;
    }
    const response = yield call(loginOauthService, data);

    let expirationDate = parseJwt(getAccessToken(response))?.exp;
    if (expirationDate) {
      setCookie("token", getAccessToken(response), expirationDate);
    }
    localStorage.setItem(accessTokenKey, getAccessToken(response));
    localStorage.setItem(refreshTokenKey, getRefreshToken(response));
    yield put({ type: types.LOGIN_OAUTH_SUCCESS, response });
  } catch (error) {
    setCookie("token", "", 0);
    yield put({ type: types.LOGIN_OAUTH_ERROR, error });
  }
}

export function* logoutSaga(payload) {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
  setCookie("token", "", 0);

  yield call(disconnectFromSocketSaga);
  yield put({ type: types.RESET_DATA });
  yield put(push("/login", payload.location));
}

export function* getUserSaga(payload) {
  try {
    yield put({ type: types.GET_USER });
    const response = yield call(getUserService, payload);
    yield put({ type: types.GET_USER_SUCCESS, response: response.data });
  } catch (error) {
    yield put({ type: types.GET_USER_ERROR, error });
  }
}

export function* refreshTokenSaga(payload) {
  try {
    const response = yield call(refreshTokenService);
    localStorage.setItem(accessTokenKey, getAccessToken(response));
    localStorage.setItem(refreshTokenKey, getRefreshToken(response));
    yield put({ type: types.REFRESH_TOKEN_SUCCESS, response: null });
  } catch (error) {
    setCookie("token", "", 0);
    yield put({
      type: types.REFRESH_TOKEN_ERROR,
      error,
      //error: { error: error, location: payload.data },
      location: payload.data,
    });
  }
}
