import { eventChannel } from "redux-saga";
import { all, fork,race,cancel, put, call, take, select } from "redux-saga/effects";
import { sendMessageService } from "../services/wsService";
import { subscribe } from "../ws";
import * as types from "../actions";

export function* registerWebsocketsSaga() {
  const token = localStorage.getItem("access_token");
  const ws = subscribe(token, "/broadcasting/auth");
  yield put({ type: types.REGISTER_WS_SUCCESS, response: ws });
}

export function* disconnectFromSocketSaga() {
  let ws = yield select((state)=>state.wsReducer?.response);
  if(ws) {

  ws.disconnect();
  yield put({ type: types.DISCONNECT_WS_SUCCESS });
  }
}

 export function* connectToSocketSaga(payload) {
  const channel = yield call(
    createEventChannel,
    payload.socket,
    payload.channel
  );
  while (true) {
    let response = yield take(channel);
    response.channel = payload.channel;
    if (response.channel?.event === "ChatMessage") {
      yield put({ type: types.WEBSOCKET_MESSAGE_RECEIVED, response });
    }
    if (response.channel?.event === "Notification") {
      yield put({ type: types.WEBSOCKET_NOTIFICATION_RECEIVED, response });
    }
    if (response.channel?.event === "Popup") {
      yield put({ type: types.SET_MESSAGE, message: response.message });
    }
  }
}


function* safeSomeSaga(channel) {

  let socket = yield select((state)=>state.wsReducer.response);
	const task = yield fork(connectToSocketSaga, {channel, socket});

	const { error } = yield race({
		success: take(types.SUCCESS),
		error: take(types.FAILURE),
	})

	if(error) {
		yield cancel(task)
	}
}

export function* testme() {
  let channels = yield select((state)=>state.userReducer.user?.channels);
	yield all(channels.map(ch => call(safeSomeSaga, ch)))
}






function createEventChannel(socket, data) {
  return eventChannel((emit) => {
    socket.private(data.channel).listen(data.event, (e) => {
      emit(e);
    });
    return () => {
      socket.close();
    };
  });
}

export function* sendMessageSaga(payload) {
  try {
    const response = yield call(sendMessageService, payload.data);
    yield put({ type: types.WS_SEND_MESSAGE_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.WS_SEND_MESSAGE_ERROR, error });
  }
}
