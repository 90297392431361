import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    confirmationDialog: {
        "& .MuiDialog-paperWidthSm": {
            position: "relative",
            boxShadow: "none",
            borderRadius: 0,
            padding: "0 39px 100px 23px",
            [theme.breakpoints.down("xs")]: {
                position: "fixed",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                margin: 0,
                display: "block",
                maxHeight: "initial"
            },
            [theme.breakpoints.up("md")]: {
                width: 578,
                maxWidth: "initial",
                padding: "0 50px 114px",
            },
        },
        "& .MuiBackdrop-root": {
            background: "rgba(0, 0, 0, 0.6)"
        },
        "& .buttons": {
            justifyContent: "left",
        },
        "& .buttons button": {
            margin: "0 30px 0 0",
        },
    },
    confirmationTitle: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "32px",
        lineHeight: "39px",
        padding: "116px 0 58px",
        [theme.breakpoints.up("md")]: {
            maxWidth: 350,
            padding: "67px 0 37px",
        },
    },
    confirmationClose: {
        position: "absolute",
        right: 2,
        top: 18,
        "&:hover": {
            background: "none"
        },
        [theme.breakpoints.up("md")]: {
            right: 9,
            top: 25,
        },
    }
}));

export default useStyles;