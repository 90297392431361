import React, { useEffect } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { Grid, Button, Typography, MenuItem } from "@material-ui/core";
import { TextField, Select } from "mui-rff";
import { useSelector, useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import CustomAutocomplete from "../components/CustomAutocomplete";
import {
  formGetReferenceTableValuesAction,
  formUpdateUserAction,
  formSubmitAction,
} from "../actions/formActions";
/* import { logoutUserAction } from "../actions/authenticationActions"; */
import AvatarModal from "../components/AvatarModal";
import ProfileCover from "../components/ProfileCover";
import ProfileImage from "../components/ProfileImage";
import useStyles from "../styles/Profile";
import { Helmet } from "react-helmet";
import { maxChars, composeValidators } from "../utils/fieldValidators";
import FileDropzone from "../components/FileDropzone";

const ProfileFormCreator = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-creative-test-results",
      })
    );
  }, [dispatch]);

  const user = useSelector((state) => state.userReducer.user);
  const references = useSelector((state) => state.formReducer.references);

  let testResultOptions = references?.[
    "classifiers-creative-test-results"
  ]?.map((c) => (
    <MenuItem key={c.id} value={c.id}>
      {c.name}
    </MenuItem>
  ));

  return (
    <>
      <Helmet>
        <title>{`${user?.name} | weCreators`}</title>
      </Helmet>
      <Form
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={(values) =>
          dispatch(
            formUpdateUserAction({
              submitType: "put",
              endpoint: "/users",
              formValues: values,
              id: user.id,
              redirect: "/profile",
            })
          )
        }
        initialValues={{
          name: user.name,
          first_name: user.first_name,
          last_name: user.last_name,
          biography: user.biography,
          email: user.email,
          online_presences: user.online_presences,
          user_id: user.id,
          interest_areas: user.interest_areas.map((ia) => ia.id),
          skills: user.skills.map((s) => s.id),
          profile_image: user.profile_image,
          creative_test_result_id: user.creative_test_result_id,
        }}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          values,
          form: {
            mutators: { push, pop },
          },
        }) => (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <ProfileCover user={user} />
            </Grid>
            <Grid className={classes.profileForm}>
              <Typography variant="h1" className={classes.profileFormTitle}>
                Profile settings
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} className={classes.profileFormAvatar}>
                  <FormSpy subscription={{ values: "profile_image" }}>
                    {(props) => {
                      return (
                        <ProfileImage
                          imageLink={
                            props.values.profile_image?.b64
                              ? props.values.profile_image?.b64
                              : props.values.profile_image?.link
                              ? `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${props.values.profile_image?.link}`
                              : null
                          }
                        />
                      );
                    }}
                  </FormSpy>
                </Grid>
                <Grid item xs={12}>
                  <Field name="profile_image">
                    {(props) => {
                      return <AvatarModal {...props.input} />;
                    }}
                  </Field>
                </Grid>
                <Grid className={classes.profileUserInformation}>
                  <TextField
                    label="Nickname"
                    name="name"
                    required={true}
                    fullWidth
                    className={classes.profileField}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    required
                    type="TYPE_EMAIL"
                    fullWidth
                    className={classes.profileField}
                  />
                </Grid>
                <Grid className={classes.profileUserInformation}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    fullWidth
                    className={classes.profileField}
                  />
                  <TextField
                    label="Last Name"
                    name="last_name"
                    fullWidth
                    className={classes.profileField}
                  />
                </Grid>
                <Grid className={classes.profileSelectBlock}>
                  <Select
                    name="creative_test_result_id"
                    label="Select your result"
                    formControlProps={{ margin: "none" }}
                    required
                  >
                    {testResultOptions ? testResultOptions : <></>}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Biography"
                    name="biography"
                    multiline
                    fullWidth
                    className={classes.profileField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.profileSelectBlock}>
                  <Field name="interest_areas">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-interest-areas"
                          record={user.interest_areas}
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.profileSelectBlock}>
                  <Field name="skills">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-skills"
                          record={user.skills}
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    noWrap
                    className={classes.profilePresence}
                  >
                    Online presence
                  </Typography>
                  <FieldArray name="online_presences" label="Online presence">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div className={classes.profileBlockLink} key={name}>
                          <div
                            key={name}
                            className={classes.profileUserInformation}
                          >
                            <TextField
                              label="Link name"
                              name={`${name}.website_name`}
                              fullWidth
                              required={true}
                              className={classes.profileField}
                            />
                            <TextField
                              label="URL Link"
                              name={`${name}.url_link`}
                              fullWidth
                              required={true}
                              className={classes.profileField}
                              fieldProps={{
                                validate: composeValidators(maxChars(255)),
                              }}
                            />
                            <span
                              onClick={() => fields.remove(index)}
                              className={classes.profileDelete}
                            ></span>
                          </div>
                        </div>
                      ))
                    }
                  </FieldArray>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    className={classes.profileAddButton}
                    onClick={() => push("online_presences", undefined)}
                  >
                    Add Link
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Field name="files">
                    {(props) => {
                      return (
                        <div className={classes.profileFileDropzone}>
                          <Typography
                            variant="body2"
                            className={classes.profilePresence}
                            noWrap
                          >
                            Share CV or other related documents
                          </Typography>
                          <FileDropzone
                            {...props.input}
                            tooltip={true}
                            tooltipText="File should not be larger than 5Mb"
                          />
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
{/*                 <Grid item xs={12} className="buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Do you really want to delete your profile?"
                        )
                      ) {
                        dispatch(
                          formSubmitAction({
                            formValues: null,
                            submitType: "delete",
                            endpoint: `profile`,
                            redirect: router.location.pathname,
                          })
                        );
                        setTimeout(function () {
                          dispatch(logoutUserAction());
                        }, 1000);
                      }
                    }}
                  >
                    Delete profile
                  </Button>
                </Grid> */}
                <Grid item xs={12} className="buttons">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine}
                  >
                    Save
                  </Button>
                </Grid>
                {/*           <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            </Grid>
          </Grid>
        )}
      />
    </>
  );
};

export default ProfileFormCreator;
