import React, {useEffect} from "react";

import {
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { formGetDataAction } from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../styles/Search";
import HomeFilterType from "../components/HomeFilterType";
import HomeSearch from "../components/HomeSearch";
import HomeSearchFilter from "../components/HomeSearchFilter";
import SingleCard from "../components/SingleCard";
import { Helmet } from "react-helmet";
import { useHistory, useLocation, useParams, } from "react-router-dom";
import { useQuery } from "../utils/misc";

const Search = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const data = useSelector((state) => state.formReducer.data);
  const loading = useSelector((state) => state.formReducer.loading);
  const typeParam = useSelector((state) => state.router.location?.type);
  const tagParam = useSelector((state) => state.router.location?.tag);
  const creativeFieldParam = useSelector(
    (state) => state.router.location?.creativeField
  );
  const interestAreaParam = useSelector(
    (state) => state.router.location?.interestArea
  );
  const searchParam = useSelector((state) => state.router.location?.s);
  const statusParam = useSelector((state) => state.router.location?.status);
  const [type, setType] = React.useState("creation");
  const [tag, setTag] = React.useState("all");
  const [tagLabel, setTagLabel] = React.useState("Tags");
  const [search, setSearch] = React.useState("");
  const [creativeField, setCreativeField] = React.useState("all");
  const [creativeFieldLabel, setCreativeFieldLabel] =
    React.useState("Creative fields");
  const [interestArea, setInterestArea] = React.useState("all");
  const [interestAreaLabel, setInterestAreaLabel] =
    React.useState("Interest areas");
  const [statusLabel, setStatusLabel] = React.useState("Status");
  const [status, setStatus] = React.useState("all");

  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  let query = useQuery();

   useEffect(() => {
  [{param:"search", action: setSearch},
   {param:"type", action:setType},
   {param:"tag", action: setTag},
   {param:"creativeField", action: setCreativeField},
   {param:"interestArea",action: setInterestArea},
   {param:"status",action: setStatus}].forEach((cur)=>{

    let [param, action] = Object.values(cur);
    let queryParam = query.get(param);
    if(queryParam){
      action(queryParam);
    }
  })
  },[]);


  useEffect(() => {

    let filter = [{search},{type},{tag},{creativeField},{interestArea},{status}].reduce((acc, cur) =>{
      let [key] = Object.keys(cur);
      let [value] = Object.values(cur);
      if(!["all", ""].includes(value)){
        acc[key] = value

      }
      return acc;
    },{})


    //const {name, value} = event?.target;
     const params = new URLSearchParams(filter);
     history.replace({ pathname: location.pathname, search: params.toString() });
    dispatch(
      formGetDataAction({
        endpoint: "home",
        filter: { type, tag, search, creativeField, interestArea, status },
      })
    );
  }, [dispatch, type, tag, status, search, interestArea, creativeField]);

/*   useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: "home",
        filter: { type, tag, search, creativeField, interestArea, status },
      })
    );
  }, [dispatch, type, tag, search, creativeField, interestArea, status]); */

  useEffect(() => {

    if (typeParam) {
      setType(typeParam);
    }

    if (tagParam) {
      setTag(tagParam);
      //setTagLabel(tagParam);
    }

    if (creativeFieldParam) {
      setCreativeField(creativeFieldParam);
      //setCreativeFieldLabel(creativeFieldParam);
    }

    if (interestAreaParam) {
      setInterestArea(interestAreaParam);
      //setInterestAreaLabel(interestAreaParam);
    }

    if (searchParam) {
      setSearch(searchParam);
    }

    if (statusParam) {
      setStatus(statusParam);
    }
  }, [
    typeParam,
    tagParam,
    creativeFieldParam,
    interestAreaParam,
    searchParam,
    statusParam,
  ]);

  useEffect(() => {
    setStatus("all");
    setStatusLabel("Status");
  }, [type]);

  return (
    <>
      <Helmet>
        <title>Search | weCreators</title>
      </Helmet>
      <Grid>
        <Grid className={classes.searchHeader}>
          <HomeSearch value={search} setValue={setSearch} />
          <div id="toggleFilterContainer">
            <div className={classes.searchFilter}>
              <HomeFilterType
                value={type}
                setValue={setType}
                choices={[
                  { value: "creation", label: "Creations" },
                  { value: "opportunity", label: "Calls" },
                ]}
              />
              <HomeSearchFilter
                tag={tag}
                tagLabel={tagLabel}
                setTag={setTag}
                setTagLabel={setTagLabel}
                creativeField={creativeField}
                creativeFieldLabel={creativeFieldLabel}
                setCreativeField={setCreativeField}
                setCreativeFieldLabel={setCreativeFieldLabel}
                interestArea={interestArea}
                interestAreaLabel={interestAreaLabel}
                setInterestArea={setInterestArea}
                setInterestAreaLabel={setInterestAreaLabel}
                status={status}
                setStatus={setStatus}
                statusLabel={statusLabel}
                setStatusLabel={setStatusLabel}
                type={type}
              />
            </div>
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.searchResult}
        >
          {loading ? (
            <CircularProgress className="main-progress-bar" />
          ) : data?.length > 0 ? (
            <div className={classes.searchContainer}>
              {data?.map((d) => {
                return (
                  <Grid key={`${d.type}-${d.id}`}>
                    <SingleCard data={d} />
                  </Grid>
                );
              })}
            </div>
          ) : (
            <div className={classes.searchNotFound}>
              <div className={classes.searchNotFoundTitle}>
                Sorry, we couldn’t find any results for your search.
              </div>
              <div>Maybe try to use a different keyword.</div>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Search;
