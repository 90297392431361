import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import useStyles from "../styles/Profile";

import { Typography } from "@material-ui/core";

const DefaultProfileImage = () => {
  const user = useSelector((state) => state.userReducer.user);
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.profileImageDefault,
        `profile-gradient-${user.id % 15}`
      )}
    >
      <Typography variant="h3">{user.name[0].toUpperCase()}</Typography>
    </div>
  );
};

export default DefaultProfileImage;
