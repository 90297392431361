import React from "react";
import { Grid, Typography, Paper, Button } from "@material-ui/core";

import useStyles from "../styles/NotFound";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const NotFound = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <>
      <Helmet>
        <title>Not found | weCreators</title>
      </Helmet>
      <Paper>
        <Grid
          className={classes.errorContainer}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ overflow: "hidden", backgroundColor: "black" }}
        >
          <Grid item xs={12}>
            <Typography className={classes.errorCode}>404</Typography>
            <Typography className={classes.errorHelper}>
              Creative block!
            </Typography>
            <Button
              variant="contained"
              className={classes.btnHome}
              onClick={() => dispatch(push(`/`))}
            >
              Home
            </Button>
            <div className={classes.purpleLine} />
            <div className={classes.yellowLine}>
              <div className={classes.yellowLineContainer}>
                <span>ERROR ERROR ERROR</span>
                <span>ERROR ERROR ERROR</span>
                <span>ERROR ERROR ERROR</span>
                <span>ERROR ERROR ERROR</span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default NotFound;
