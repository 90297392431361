import axios from "axios";
import { setCookie, parseJwt } from "../utils/misc";

const baseURL = process.env.REACT_APP_API_URL;
const accessTokenKey = "access_token";
const refreshTokenKey = "refresh_token";



const http = axios.create({
  baseURL,
  withCredentials: true,
  mode: "cors",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Origin":
      "https://img.wecreators.com , http://wecreators.iost.work, http://localhost:9999, http://localhost:8081, http://localhost:8082, http://localhost:3000, http://localhost:4000, http://localhost:5000, http://dev.wecreators.com, https://dev-app.wecreators.com, https://dev-app.wecreators.com, https://dev.wecreators.com, https://dev-admin.wecreators.com, http://dev-admin.wecreators.com, https://admin.wecreators.com, http://admin.wecreators.com",
  },
  exposedHeaders: ["set-cookie"],
});

http.interceptors.request.use(
  function (config) {
    if (config.url === "/auth/refresh") {
      let token = localStorage.getItem(refreshTokenKey);
      if (token) {
        let bearerToken = `Bearer ${token}`
        config.headers.Authorization = bearerToken;
        // let expirationDate = parseJwt(token)?.exp;
        // if (expirationDate) {

        //   setCookie("token", token, expirationDate)
        // }
        return config;
      }
    } else {
      if (["/terms", "/about", "/faq"].includes(config.url)) {
        config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
      }
      let token = `Bearer ${localStorage.getItem(accessTokenKey)}`;
      if (token) {
        config.headers.Authorization = token;
        return config;
      }
    }
  },
  function (error) {
    // Do something with request error

    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (config) {
    if (config.config.url === "/auth/refresh") {
      let token = config.data.access_token.token;
      if (token) {
        let expirationDate = parseJwt(token)?.exp;
        if (expirationDate) {
          setCookie("token", token, expirationDate)
        }
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    let token = localStorage.getItem(refreshTokenKey);
    if (401 === error.response.status && token) {
      localStorage.removeItem(accessTokenKey);
      localStorage.removeItem(refreshTokenKey);
      setCookie("token", "", 0)
      error.response.data = { error: "Unauthenticated" };
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default http;
