import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    h1Var1: {
        fontSize: 44,
        lineHeight: "54px",
        marginBottom: 54,
        fontWeight: 500,
        fontFamily: "Gilroy"
    },
    centerForm: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 93,
        background: "#E5E5E5",
        flexGrow: 1,
        paddingTop: 51,
        paddingLeft: 23,
        paddingRight: 23,
        [theme.breakpoints.down("xs")]: {
            "& .form-signin, & .form-signup": {
                width: "100%",
            },
        },
        [theme.breakpoints.up("sm")]: {
            "& .form-signin, & .form-signup": {
                width: 329,
            },
        },
        [theme.breakpoints.up("md")]: {
            paddingBottom: 78,
            paddingTop: 56,
            minHeight: "calc(100vh - 108px)",
            boxSizing: "border-box",
        },
    },
    registerTooltip: {
        position: "absolute",
        right: 0,
        top: 15,
        [theme.breakpoints.up("sm")]: {
            right: "-34px",
        },
    },
    tooltipText: {
        paddingRight: 38,
    },
    loginText: {
        position: "relative",
        "& .MuiOutlinedInput-root, & .MuiSelect-select:focus": {
            background: "#F5F5F5",
        },
        "& .MuiSvgIcon-root": {
            fill: "#000",
        },
        "& .MuiInputBase-input": {
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 500,
            fontFamily: "Gilroy",
            height: "auto",
            padding: "14px 16px 12px",
        },
        "& .MuiFormLabel-root": {
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 500,
            fontFamily: "Gilroy",
            color: "#000",
            transform: "translate(16px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(16px, -10px) scale(0.75)"
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "auto",
            "& .MuiFormControl-root": {
                minWidth: "auto",
            }
        },
    },
    registerText: {
        "& .MuiFormControl-root": {
            marginBottom: 12,
        }
    },
    btnLogin: {
        borderRadius: 0,
        width: "100%",
        maxWidth: "100%",
        position: "relative",
        height: 50,
        outline: "none",
        border: "none",
        color: "#fff",
        marginBottom: 25,
        background: "#6233C0",
        cursor: "pointer",
        fontFamily: "Gilroy",
        boxShadow: "none",
        lineHeight: "18px",
        textTransform: "none",
        fontWeight: 700,
        "&.Mui-disabled": {
            color: "#fff",
            background: "#C4C4C4",
        }
    },
    textForgetPassword: {
        fontSize: 12,
        marginBottom: 17,
        lineHeight: "16px",
        marginTop: "-33px",
        fontFamily: "Gilroy",
        fontWeight: 400,
        "& a": {
            textDecoration: "none",
            color: "#636363",
            letterSpacing: "0.2px",
        },
        "& a:hover": {
            color: "#000",
        },
    },
    loginDivider: {
        color: "#636363",
        fontSize: 12,
        lineHeight: "16px",
        letterSpacing: "0.2px",
        fontFamily: "Gilroy",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 25,
        position: "relative",
        "&::after": {
            content: "''",
            height: 1,
            width: "100%",
            display: "block",
            marginLeft: 13,
            backgroundColor: "#636363"
        },
        "&::before": {
            content: "''",
            height: 1,
            width: "100%",
            marginRight: 13,
            display: "block",
            backgroundColor: "#636363"
        }
    },
    textSignup: {
        color: "#636363",
        fontSize: 12,
        lineHeight: "15px",
        fontFamily: "Gilroy",
        margin: "5px 0 0",
        fontWeight: 500,
        "&>*": {
            color: "#636363",
            fontWeight: "bold",
            textDecoration: "none"
        }
    },
    textPasswordForgot: {
        color: "#636363",
        fontSize: 12,
        lineHeight: "16px",
        fontFamily: "Gilroy",
        margin: 0,
        fontWeight: 500,
        marginBottom: 58,
        letterSpacing: "0.2px",
    },
    loginFormWidth: {
        maxWidth: 329
    },
    socialLogin: {
        display: "flex",
        flexDirection: "column",
        "&>span": {
            opacity: "1!important",
        },
        "& button[disabled]": {
            background: "#C4C4C4!important",
            cursor: "default",
        },
        "& button[disabled] > span ": {
            color: "#fff",
        },
        "& button[disabled] svg ": {
            filter: "brightness(0) invert(1)",
        },
        "&>span button": {
            display: "flex",
            width: "100%",
            fontSize: 14,
            lineHeight: "17px",
            fontFamily: "Gilroy",
            marginBottom: 16,
            textTransform: "none",
            fontWeight: 500,
            justifyContent: "center",
            height: 40,
            alignItems: "center",
            padding: 0
        },
        "&>button": {
            display: "flex",
            width: "100%",
            fontSize: 14,
            lineHeight: "17px",
            fontFamily: "Gilroy!important",
            marginBottom: 25,
            textTransform: "none",
            cursor: "pointer",
            fontWeight: 500,
            justifyContent: "center",
            height: 40,
            alignItems: "center",
            position: "relative",
            padding: 0,
            opacity: "1!important",
            boxShadow: "none!important",
            filter: "drop-shadow(0px 4px 10px rgba(117, 117, 117, 0.12))",
            letterSpacing: "0.21875px",
        },
        "&>button>span": {
            color: "rgba(0, 0, 0, 0.54)",
        },
        "&>button>div": {
            background: "none!important",
            position: "absolute",
            left: 10,
            top: 1
        },
        "& .kep-login-facebook": {
            backgroundColor: "#3779EA",
            border: 0,
            filter: "drop-shadow(0px 4px 10px rgba(0, 135, 183, 0.12))",
            position: "relative"
        },
        "& .kep-login-facebook img": {
            position: "absolute",
            left: "21px"
        },
        "& .MuiButton-startIcon": {
            position: "absolute",
            left: 21
        },
        "& .google": {
            backgroundColor: "#fff"
        }
    },
    passwordGoBack: {
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#000",
        marginBottom: 25,
        marginTop: "-33px",
        cursor: "pointer",
        "&:before": {
            content: "''",
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTkgMTJINSIgc3Ryb2tlPSIjMjUyODJCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0xMiAxOUw1IDEyTDEyIDUiIHN0cm9rZT0iIzI1MjgyQiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=)",
            width: 24,
            height: 24,
            marginRight: 10,
        },
        [theme.breakpoints.up("sm")]: {
            display: "none",
        }
    },
    policyAgree: {
        display: "flex",
        padding: "12px 0 24px",
        "& .MuiTypography-body2": {
            fontSize: 16,
            lineHeight: "24px",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiTypography-body2 a": {
            color: "#000",
        },
        "& .MuiTypography-body2 a:hover": {
            textDecoration: "none",
        },
        "& .MuiFormControlLabel-root": {
            margin: "3px 9px 0 0",
            alignItems: "baseline",
        },
        "& .MuiIconButton-root": {
            padding: 0,
        },
        "& .Mui-checked .MuiSvgIcon-root": {
            fill: "#6233C0",
        },
        "& .MuiSvgIcon-root": {
            width: 16,
            height: 16,
            fill: "#000",
        }
    }
}));

export default useStyles;