import React from "react";
import { Grid, CircularProgress, Tab } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import {
  formGetDataAction,
  formGetCustomDataAction,
  formGetReferenceTableValuesAction,
} from "../actions/formActions";

import useStylesTeam from "../styles/Team";
import { Helmet } from "react-helmet";
import useStyles from "../styles/CreationOpportunity";

import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import OpportunityTabBrief from "../components/OpportunityTabBrief";
import OpportunityTabStages from "../components/OpportunityTabStages";
import OpportunityHeader from "../components/OpportynityHeader";
import OpportunityFooter from "../components/OpportunityFooter";
import OpportunitySideBar from "../components/OpportunitySidebar";
import OpportunityTabTeams from "../components/OpportunityTabTeams";
import clsx from "clsx";

import { useHistory } from "react-router-dom";

const Opportunity = (props) => {
  const dispatch = useDispatch();
  const teamClasses = useStylesTeam();
  const classes = useStyles();

  const history = useHistory();

  let { opportunityId } = useParams();
  opportunityId = opportunityId ? opportunityId : props.opportunityId;
  let data = useSelector((state) =>
    props.data ? props.data : state.formReducer.data
  );
  const loading = useSelector((state) => state.formReducer.loading);
  const references = useSelector((state) => state.formReducer.references);
  const currentUser = useSelector((state) => state.userReducer.user);
  const winnerFormRef = useRef(null);

  const router = useSelector((state) => state.router);

  const [winner, setWinner] = useState(null);

  useEffect(() => {
    if (!props.data) {
      dispatch(
        formGetDataAction({ endpoint: `opportunities/${opportunityId}` })
      );
    }
    dispatch(
      formGetCustomDataAction({
        endpoint: `opportunities/${opportunityId}/teams`,
        name: "teams",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `opportunities/${opportunityId}/stages`,
        name: "stages",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `opportunity-statuses/${opportunityId}`,
        name: "statuses",
      })
    );
  }, [dispatch, opportunityId, props]);
  useEffect(() => {
    dispatch(
      formGetCustomDataAction({
        endpoint: `opportunities/${opportunityId}/teams`,
        name: "teams",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `opportunities/${opportunityId}/stages`,
        name: "stages",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `opportunity-statuses/${opportunityId}`,
        name: "statuses",
      })
    );
  }, [data, dispatch, opportunityId]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    history.push({
      pathname: router.location.pathname,
      hash: newValue,
    });
  };

  let tabs =
    data?.created_by?.id === currentUser.id
      ? [
          { label: "Brief", value: "brief" },
          { label: "Stages", value: "stages" },
          { label: "Teams", value: "teams" },
        ]
      : [
          { label: "Brief", value: "brief" },
          { label: "Stages", value: "stages" },
        ];

  let initialTabValue = router.location.hash
    ? tabs.some((t) => `#${t.value}` === router.location.hash)
      ? router.location.hash.substring(1)
      : tabs?.[0]?.value
    : tabs?.[0]?.value;

  const [tabValue, setTabValue] = React.useState(initialTabValue);

  useEffect(() => {
    if (!data && !props.data) {
      dispatch(
        formGetDataAction({ endpoint: `opportunities/${opportunityId}` })
      );
      dispatch(
        formGetCustomDataAction({
          endpoint: `opportunity-statuses/${opportunityId}`,
          name: "statuses",
        })
      );
      return;
    }

    if (data.creative_fields) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-creative-fields",
          filter: data.creative_fields
            ? ["id", "in", data.creative_fields]
            : null,
        })
      );
    }
    if (data.interest_areas) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-interest-areas",
          filter: data.interest_areas
            ? ["id", "in", data.interest_areas]
            : null,
        })
      );
    }
    if (data.opportunity_tags) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-opportunity-tags",
          filter: data.opportunity_tags
            ? ["id", "in", data.opportunity_tags]
            : null,
        })
      );
    }
    if (data.award_types) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-award-types",
          filter: data.award_types ? ["id", "in", data.award_types] : null,
        })
      );
    }
  }, [data, props, dispatch, opportunityId]);

  return loading ? (
    <CircularProgress className="main-progress-bar" />
  ) : data && references ? (
    <>
      <Helmet>
        <title>{`${data?.title} | Calls | weCreators`}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <OpportunityHeader data={data} />

        <Grid className={classes.opportunityContainer}>
          <TabContext value={tabValue}>
            <TabList
              onChange={handleChange}
              aria-label="simple tabs example"
              className={clsx(teamClasses.teamTabs, classes.opportunityTabs)}
            >
              {tabs.map((t) => (
                <Tab key={t.value} label={t.label} value={t.value} />
              ))}
            </TabList>

            <Grid className={classes.creationInformation}>
              <TabPanel value="brief">
                <OpportunityTabBrief data={data} />
              </TabPanel>
              <TabPanel value="stages">
                <OpportunityTabStages />
              </TabPanel>
              <TabPanel value="teams">
                <OpportunityTabTeams
                  data={data}
                  ref={winnerFormRef}
                  setWinner={setWinner}
                />
              </TabPanel>
              <OpportunitySideBar
                data={data}
                opportunityId={opportunityId}
                ref={winnerFormRef}
                winner={winner}
              />
            </Grid>
          </TabContext>
        </Grid>
        <OpportunityFooter data={data} />
      </Grid>
    </>
  ) : null;
};

export default Opportunity;
