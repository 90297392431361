import React from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { regex, minLength, composeValidators } from "../utils/fieldValidators";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { formSubmitAction } from "../actions/formActions";
import useStyles from "../styles/Login";
import { Helmet } from "react-helmet";
import clsx from "clsx";

const PasswordResetForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let { token } = useParams();

  const user = useSelector((state) => state.userReducer.user);

  if (user) {
    return <Redirect to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title>Reset password | weCreators</title>
      </Helmet>
      <Paper className={classes.centerForm}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Form
            initialValues={{
              token: token,
            }}
            onSubmit={async (values) => {
              values.password_confirmation = values.password;
              dispatch(
                formSubmitAction({
                  formValues: values,
                  endpoint: "/auth/reset-password",
                  redirect: "/login",
                })
              );
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signup" onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Typography variant="h1" noWrap className={classes.h1Var1}>
                    New password
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email address"
                    variant="outlined"
                    className={classes.loginText}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    className={classes.loginText}
                    required
                    fieldProps={{
                      validate: composeValidators(
                        regex(
                          new RegExp("^[ -~]+$"),
                          "Only printing ASCII characters are allowed"
                        ),
                        minLength(8, "Minimum password length is 8 characters")
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.btnLogin}
                  >
                    Set password
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <p
                    className={clsx(
                      "mt-3 mb-3 text-muted text-center",
                      classes.textSignup
                    )}
                  >
                    Already registered ? <Link to="/login">Sign in here</Link>
                  </p>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Paper>
    </>
  );
};

export default PasswordResetForm;
