import React from "react";

import {
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import RemoveIcon from "../assets/close.svg";
import useStyles from "../styles/ConfirmationDialog";

const ConfirmationDialog = (props) => {
  const { confirmAction, open, setOpen, text } = props;

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    confirmAction();
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.confirmationDialog}>
      <Button onClick={handleCancel} className={classes.confirmationClose}>
        <img src={RemoveIcon} alt="Remove" />
      </Button>
      <DialogContent className={classes.confirmationTitle}>{text}</DialogContent>
      <div className="buttons">
        <Button onClick={handleOk}>Confirm</Button>
        <Button autoFocus onClick={handleCancel}>
          Dismiss
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
