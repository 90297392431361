import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import CompleteRegistration from "./CompleteRegistration";
import PropTypes from "prop-types";

const SecretRoute = ({ component: Component, roles, roleCondition, ...rest }) => {
  const userReducer = useSelector((state) => state.userReducer);
  const { user, loading } = userReducer;

  let restrictedRender = (user, roles, rest) => {
    if (roles) {

      let conditionMet = false;
      let profileTypes = user?.profile_types?.map((pt) => pt.name)

      switch (roleCondition){
        case "any":
          conditionMet = profileTypes
        .some((pt) => roles.includes(pt));
        break;
        case "only":
          conditionMet = profileTypes
        .every((pt) => roles.includes(pt));
        break;
        default:
          conditionMet = false;
          break;
      }

      return conditionMet ? (<Component {...rest} />) : (<Redirect to="/profile" />)
    } else {
      return <Component {...rest} />;
    }
  };


  return (
    <Route
      {...rest}
      render={(rest) => {
        if (loading) {
          return <CircularProgress className="main-progress-bar" />;
        } else {
          if (user) {
            return user.is_profile_complete ? (
              restrictedRender(user, roles, rest)
            ) : (
              <CompleteRegistration />
            );
          } else {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: rest.location } }}
                //to="/login"
              />
            );
          }
        }
      }}
    />
  );
};

SecretRoute.propTypes = {
    roleCondition: PropTypes.oneOf(['only', 'any']),
};

export default SecretRoute;
