import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    homeContainer: {
        background: "#F5F5F5",
        [theme.breakpoints.up("md")]: {
            minHeight: "calc(100vh - 108px)",
        },
    },
    toggleContainer: {
        background: "#fff",
        padding: "30px 23px 12px",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.up("md")]: {
            padding: "30px 32px 12px",
        },
    },
    filterButtonGroup: {
        "&>.Mui-selected": {
            backgroundColor: "transparent",

            "&>.MuiToggleButton-label .MuiTypography-root": {
                color: "#000000"
            }
        },
    },
    filterButtonGroupMobile: {
        width: "100%"
    },
    filterButton: {
        border: 0,
        justifyContent: "start",
        padding: 0,
        "&:not(:first-child)": {
            marginLeft: "22px!important",
        },
        "&:hover, &.Mui-selected:hover": {
            background: "transparent",
        },
        "& .MuiTouchRipple-root": {
            display: "none",
        }
    },
    filterLabel: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#BCBCBC",
        textTransform: "capitalize",
    },
    searchResult: {
        padding: "30px 23px 50px",
        [theme.breakpoints.up("sm")]: {
            padding: "30px 32px 50px",
        },
    },
    searchContainer: {
        gridGap: "20px",
        gridTemplateColumns: "repeat(1, 1fr)",
        width: "100%",
        display: "grid",
        boxSizing: "border-box",
        maxWidth: "2968px",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            "& .MuiGrid-root": {
                marginBottom: 10,
            }
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(5, 1fr)",
        },
        "@media (min-width:2149px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
        },
        "@media (min-width:2593px)": {
            gridTemplateColumns: "repeat(7, 1fr)",
        },
    },
    searchNotFound: {
        color: "#BCBCBC",
        fontSize: 32,
        lineHeight: "35px",
        fontWeight: 400,
        [theme.breakpoints.up("md")]: {
            fontSize: 44,
            lineHeight: "54px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "59px 218px 170px",
        },
    },
    searchNotFoundTitle: {
        maxWidth: 600,
        color: "#000",
        paddingBottom: 22,
    },
    mobileMenuList: {
        '&:hover': { backgroundColor: "#fff" },
        width: "100%",
        padding: 0,
        "& .MuiToggleButton-root": {
            padding: "12px 0",
            margin: "0!important",
        },
        "& .MuiToggleButton-root:not(:first-child)": {
            borderTop: "1px solid #f5f5f5",
        },
        "& .MuiToggleButton-root .MuiTypography-root": {
            color: "#636363",
        },
    },
    mobileMenu: {
        "& .MuiPaper-root": {
            width: "220px",
            marginTop: "-6px",
        },
        "& .MuiMenu-list .MuiListItem-root": {
            padding: 0,
        }
    },
    creationHeader: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        marginBottom: "30px",
        lineHeight: "35px",
        fontWeight: "normal",
        paddingLeft: 23,
        paddingRight: 23,
        [theme.breakpoints.up("md")]: {
            paddingLeft: 23,
            paddingRight: 23,
            paddingTop: 120,
            fontSize: "44px",
            lineHeight: "54px",
        }
    },
}));

export default useStyles;