import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Modal,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Form } from "react-final-form";

import { useParams, useHistory } from "react-router-dom";

import {
  formGetDataAction,
  formGetCustomDataAction,
  formGetReferenceTableValuesAction,
} from "../actions/formActions";
import Blocks from "editorjs-blocks-react-renderer";

import UserCard from "../components/UserCard";
import ReferenceChips from "../components/ReferenceChips";
import AttachmentChips from "../components/AttachmentChips";
import useStyles from "../styles/CreationOpportunity";
import { Helmet } from "react-helmet";
import { TextField } from "mui-rff";
import { formSubmitAction } from "../actions/formActions";
import clsx from "clsx";
import useStylesSingleCard from "../styles/SingleCard";
import UpdateCreationStatusButton from "../components/UpdateCreationStatusButton";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { getUnifiedDate } from "../utils/misc";
import ReferenceChipsSearch from "../components/ReferenceChipsSearch";


const Creation = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);

  let { creationId } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (!props.data) {
      dispatch(formGetDataAction({ endpoint: `creations/${creationId}` }));
    }

    dispatch(
      formGetCustomDataAction({
        endpoint: `creation-statuses/${creationId}`,
        name: "statuses",
      })
    );
  }, [dispatch, creationId, props]);

  const data = useSelector((state) => state.formReducer.data);
  const references = useSelector((state) => state.formReducer.references);
  const loading = useSelector((state) => state.formReducer.loading);
  const statuses = useSelector(
    (state) => state.formReducer.customData?.statuses
  );

  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classesSingleCard = useStylesSingleCard();

  useEffect(() => {
    if (!data) {
      dispatch(formGetDataAction({ endpoint: `creations/${creationId}` }));

      dispatch(
        formGetCustomDataAction({
          endpoint: `creation-statuses/${creationId}`,
          name: "statuses",
        })
      );
      return;
    }
    if (data.creative_fields) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-creative-fields",
          filter: data.creative_fields
            ? ["id", "in", data.creative_fields]
            : null,
        })
      );
    }
    if (data.interest_areas) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-interest-areas",
          filter: data.interest_areas
            ? ["id", "in", data.interest_areas]
            : null,
        })
      );
    }
    if (data.creation_tags) {
      dispatch(
        formGetReferenceTableValuesAction({
          endpoint: "classifiers-creation-tags",
          filter: data.creation_tags ? ["id", "in", data.creation_tags] : null,
        })
      );
    }

      dispatch(
        formGetCustomDataAction({
          endpoint: `creation-statuses/${creationId}`,
          name: "statuses",
        })
      );
  }, [data, dispatch, creationId]);

  return loading ? (
    <CircularProgress className="main-progress-bar" />
  ) : data && references ? (
    <>
      <Helmet>
        <title>{`${data?.title} | Creations | weCreators`}</title>
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.creationHeader}>
          <div className={classes.creationContainer}>
            <span
              className={classes.creationGoBack}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </span>
            <div className={classes.creationType}>Creation</div>
            <Typography variant="h1" className={classes.creationTitleBlock}>
              {data.title}
              <div
                className={clsx(
                  classesSingleCard.status,
                  classes.creationStatus,
                  data?.status?.code
                )}
              >
                {data?.status?.name}
              </div>
            </Typography>
            <div className={classes.creationDate}>
              {getUnifiedDate(data.created_at)}
            </div>
          </div>
        </Grid>
        <Grid className={classes.creationInformation}>
          <Grid className={classes.creationAdditional}>
            <Grid className={classes.creationAdditionalBlock}>
              <Typography
                variant="h3"
                noWrap
                className={classes.creationFieldTitle}
              >
                Creative field
              </Typography>
              {references?.["classifiers-creative-fields"]?.map((or) => (
                <Typography
                  key={or.id}
                  variant="h3"
                  noWrap
                  className={classes.creationField}
                >
                  {or.name}
                </Typography>
              ))}
            </Grid>
            <Grid className={classes.creationAdditionalBlock}>
              <Typography
                variant="h3"
                noWrap
                className={classes.creationFieldTitle}
              >
                Purchase type
              </Typography>
              <Typography variant="h3" noWrap className={classes.creationField}>
                {data?.purchase_type?.name}
              </Typography>
            </Grid>
            {data?.purchase_type?.code !== "not_for_sale" ?
              (<Grid className={classes.creationAdditionalBlock}>
                <Typography
                  variant="h3"
                  noWrap
                  className={classes.creationFieldTitle}
                >
                  Price
                </Typography>
                <Typography variant="h3" noWrap className={classes.creationField}>
                  € {data.price}
                </Typography>
              </Grid>)
              : null
            }
            <Grid className={classes.opportunityStatusButtons}>
              <Grid className="buttons">
                {data.created_by.id !== user.id && data?.status?.code === "for_sale" ?  (
                  <Button
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Enquiry
                  </Button>
                ) : null}
                {data.created_by.id === user.id ? (
                  <>
                    <Button
                      variant="contained"
                      disabled={data.status.code !== "draft"}
                      color="primary"
                      className="invert"
                      onClick={() =>
                        dispatch(push(`/creations/${data.id}/edit`))
                      }
                    >
                      Edit
                    </Button>
                  </>
                ) : null}
                {data?.created_by?.id === user?.id ? (
                  <Grid className={classes.opportunityStatusButtons}>
                    <Grid className="buttons">
                      <UpdateCreationStatusButton
                        statuses={statuses}
                        creationId={creationId}
                        currentStatus={data?.status?.code}
                      />
                      <Button onClick={() => setConfirmationOpen(true)}>
                        Delete
                      </Button>
                    </Grid>
                    <ConfirmationDialog
                      open={confirmationOpen}
                      setOpen={setConfirmationOpen}
                      text={`Are you sure you want to delete creation?`}
                      confirmAction={() => {
                        dispatch(
                          formSubmitAction({
                            submitType: "delete",
                            endpoint: `/creations/${creationId}`,
                            redirect: `/`,
                          })
                        );
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            <Grid className={classes.creationUserBlock}>
              <UserCard user={data.created_by} userLink="true" />
            </Grid>
          </Grid>
          <Grid
            container
            className={clsx(
              classes.creationContainerBody,
              classes.pageCreationBody
            )}
          >
            <Grid item className={classes.creationBody}>
              <Blocks data={JSON.parse(data.body)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.creationFooter}
        >
          <Grid className={classes.creationContainer}>
            <Grid className={classes.creationFooterContainer}>
              <Grid className={classes.creationFooterColumn}>
                <AttachmentChips title="Attachments" data={data.files} />
              </Grid>
              <Grid className={classes.creationFooterColumn}>
                <ReferenceChipsSearch
                  title="Interest areas"
                  data={references["classifiers-interest-areas"]}
                  type="creation"
                  param="interestArea"
                />
              </Grid>
              <Grid className={classes.creationFooterColumn}>
                <ReferenceChipsSearch
                  title="Tags"
                  data={references["classifiers-creation-tags"]}
                  type="creation"
                  param="tag"
                />
              </Grid>
              <Grid className={classes.creationFooterColumn}>
                <ReferenceChipsSearch
                  title="Creative fields"
                  data={references["classifiers-creative-fields"]}
                  type="creation"
                  param="creativeField"
                />

                {data?.purchase_type?.code !== "not_for_sale" ?
                  (<Grid className={classes.creationFooterPrices}>
                    <Typography variant="h6">Price</Typography>
                    <Typography
                      variant="body1"
                      className={classes.creationFooterPrice}
                    >
                      € {data.price}
                    </Typography>
                  </Grid>)
                  : null}
              </Grid>
              <div className="buttons">

                {data.created_by.id !== user.id && data?.status?.code === "for_sale" ?  (
                  <Button
                    onClick={() => {
                      handleOpen();
                    }}
                  >
                    Enquiry
                  </Button>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <span className={classes.paperClose} onClick={handleClose}></span>
          <Form
            /*             initialValues={{
              title: cardToEdit?.title,
              description: cardToEdit?.description,
              members: cardToEdit?.members,
              files: cardToEdit?.files,
              id: cardToEdit?.id,
            }} */
            onSubmit={async (values) => {
              //dispatch(loginUserAction(values));
              //await sleep(600);
              //return stateRef.current;

              dispatch(
                formSubmitAction({
                  formValues: values,
                  endpoint: `/creations/${data.id}/enquiries`,
                })
              );
              handleClose();
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signin" onSubmit={handleSubmit}>
                {/*                 <BoardCardMasterCreator cardToEdit={cardToEdit} /> */}
                <Grid container>
                  <Grid item xs={12} className={classes.creationModalUser}>
                    <UserCard
                      user={data.created_by}
                      userLink="true"
                      direction="column"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.paperTitle}>
                      What you would like to ask the creation owner?
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="message"
                      name="message"
                      label="Your message"
                      variant="outlined"
                      required
                      multiline
                      className={classes.paperTextField}
                    />
                  </Grid>
                  <Grid item xs={12} className="buttons">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    //class={classes.btnLogin}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </div>
      </Modal>
    </>
  ) : null;
};

export default Creation;
