import { put } from "redux-saga/effects";
import * as types from "../actions";

export function* errorSaga(payload) {
  yield put({ type: types.SET_ERROR, error: payload.error });

  if(payload.error?.response?.status === 401) {
      yield put({ type: types.REFRESH_TOKEN });
  }
  setTimeout(function () {
      put({ type: types.HIDE_ERROR, error: payload.error });
  }, 4000);
}
