import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formGetReferenceTableValuesAction } from "../actions/formActions";
import { CircularProgress } from "@material-ui/core";
import TeamsForm from "../forms/TeamsForm";

const TeamsFormWrapper = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "opportunities",
        filter: ["status.code", "=", "opened"]
      })
    );
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-team-visibility",
      })
    );
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-team-terms-of-profit",
      })
    );
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-opportunity-roles",
      })
    );
  }, [dispatch]);

  const references = useSelector((state) => state.formReducer.references);
  if (
    !references?.["opportunities"] ||
    !references?.["classifiers-team-visibility"] ||
    !references?.["classifiers-team-terms-of-profit"] ||
    !references?.["classifiers-opportunity-roles"]
  ) {
    return <CircularProgress className="main-progress-bar" />;
  }

  return <TeamsForm {...props} />;
};

export default TeamsFormWrapper;
