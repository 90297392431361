import * as types from "../actions";

const userReducer = (
  state = { user: null, loading: true, error: null },
  action
) => {
  const response = action.response;

  switch (action.type) {
    case types.GET_USER:
      return { ...state, user: null, loading: true, error: null };
    case types.GET_USER_SUCCESS:
      return { ...state, user: response, loading: false, error: null };
    case types.GET_USER_ERROR:
      return { ...state, error: response, loading: false, user: null };
    case types.LOGOUT_USER:
      return { ...state, user: null, error: null };
    case types.FORM_UPDATE_USER_SUCCESS:
      return { ...state, user: response, error: null };
    case types.FORM_UPDATE_USER_ERROR:
      return { ...state, error: response };
    case types.USER_RESET_DATA:
      return { ...state, user: null, loading: null, error: null };
    default:
      return state;
  }
};

export default userReducer;
