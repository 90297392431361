import React, {useState} from "react";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import ImageDropzone from "../components/ImageDropzone";
import FileDropzone from "../components/FileDropzone";
import { TextField, Radios, Select } from "mui-rff";
import {Paper, Grid, MenuItem, Button, CardHeader, Avatar, CircularProgress, ListItemIcon} from "@material-ui/core";
import { useEffect } from "react";
import { Form, Field, FormSpy } from "react-final-form";

import {
    formSubmitAction,
    formGetReferenceTableValuesAction, formUpdateUserAction, formGetCustomDataAction, formGetDataAction,
} from "../actions/formActions";
import Editor from "../components/Editor";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Typography } from "@material-ui/core";

import CustomAutocomplete from "../components/CustomAutocomplete";

import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
//import InfoIcon from '@mui/icons-material/Info';
import useStyles from "../styles/IdeationPostDetail";
import { Helmet } from "react-helmet";
import MultipleSelectChips from "../components/MultipleSelectChips";
import AttachmentChips from "../components/AttachmentChips";
import Blocks from "editorjs-blocks-react-renderer";
import Chip from "@material-ui/core/Chip";
import {Link} from "react-router-dom";
import { getUnifiedDate, swiftWrapper} from "../utils/misc";
import useStylesIdeationSpaces from "../styles/IdeationSpaces";
import ProfileAvatar from "./ProfileAvatar";

const castValues = (data) => {
    let cover = data.cover
        ? data.cover.b64
            ? { b64: data.cover[0].b64, name: data.cover[0].name }
            : data.cover[0]
        : null;
    data.cover = cover;
    data.cover_id = data.cover ? data.cover.id : null;
    data.body = JSON.stringify(data.body);

    return data;
};


const IdeationPostDetail = (props) => {
    const { postID, handleCloseForm, setOpenComment, openComment } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const post = useSelector(
        (state) => state.formReducer.customData?.post
    );

    const ideationSpacesClasses = useStylesIdeationSpaces();
    const loading = useSelector((state) => state.formReducer.loading);

    useEffect(() => {
        dispatch(
            formGetCustomDataAction({
                endpoint: `posts/${postID}`,
                name: "post",
            })
        );
    }, [postID]);

    if(post?.id !== postID){
        return <CircularProgress className="main-progress-bar" />;
    }

    const handleOpenComment = () => {
        let open = openComment;
        setOpenComment(!open);
    };

    if (loading || !post) {
        return <CircularProgress className="main-progress-bar" />;
    }
	let postSrc = post.cover ? post.cover.link : swiftWrapper(post.cover?.link) ;
	const imgPostSrc = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${postSrc}`;


    const cq =  typeof (post.created_by) !== "undefined" && post.created_by.cq !== null ? "CQ "+ post.created_by.cq : "CQ 0";
    let authorSrc =
        post.created_by.cover_image ? post.created_by.cover_image.link : swiftWrapper(post.created_by.cover_image?.link) ;
    const imgAuthorSrc = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${authorSrc}`;

    function Comment(props) {
          if(typeof (post.comments) !== "undefined" && post.comments.length > 0 ){
            return (
                <>
                <span className={ideationSpacesClasses.postCommentTitle}>
                        Comment
                </span>
                    &nbsp;&nbsp;
                    <span className={ideationSpacesClasses.postCommentCount}>
                    {post.comments.length} comments
                </span>
                </>
            );
        }
        return null;

    }
    return (
		<Grid className={classes.detailPostInfo}>
            <Link  style={{ textDecoration: 'none' }} to={`/profile/${post.created_by.id}`} className={classes.authorImage}  >
                <CardHeader
                    avatar={
                        <ListItemIcon className={ideationSpacesClasses.chatListIcon}>
                            <ProfileAvatar
                                imageLink={post.created_by?.profile_image?.link}
                                user={post.created_by}
                            />
                        </ListItemIcon>
                    }
                    title={post.created_by.name}
                    subheader={cq}
                />
            </Link>
            <Typography className={ideationSpacesClasses.ideationPostCardTitle}>
                {post.title}
            </Typography>
            <Grid className={ideationSpacesClasses.ideationPostCardBody}>
                <Blocks data={JSON.parse(post.body)} />
            </Grid>
            <Grid className={ideationSpacesClasses.ideationPostCardAdditional}>
                <Grid className={ideationSpacesClasses.ideationPostBlock}>
                    <span className={ideationSpacesClasses.ideationPostDate}>{getUnifiedDate(post?.created_at)}</span>
                    <Grid className={ideationSpacesClasses.ideationPostCardInterest}>
                        {post.interest_areas && post.interest_areas.map((item, i) => <Chip key={item.id} value={item.id} label={item.name} variant="outlined" />)}
                    </Grid>
                </Grid>
                <Grid className={ideationSpacesClasses.ideationPostCardComment} onClick={handleOpenComment}>
                    <Comment/>
                </Grid>
            </Grid>
            <Grid className={ideationSpacesClasses.ideationPostCardFiles}>
                {post.files && post.files.length > 0 && <AttachmentChips title="Attachments" data={post.files} />}
            </Grid>
        </Grid>
    );
};

export default IdeationPostDetail;
