import React from "react";
import { Button } from "@material-ui/core";
import DarkTooltip from "../components/DarkTooltip";
import { useDispatch } from "react-redux";
import {
  formSubmitAndGetDataAction,
} from "../actions/formActions";

const statusData = (data) => {
  const { code, currentStatus } = data;
  switch (code) {
    case "draft":
      return {
        code: "draft",
        title: "Draft",
        tooltip: "tooltip for draft",
      };

    case "consideration":
      return {
        code: "consideration",
        title: "Consideration",
        tooltip: "tooltip for consideration",
      };
    case "approved":
      return {
        code: "approved",
        title: "Approve",
        tooltip: "tooltip for approved",
      };
    case "opened":
      return {
        code: "opened",
        title: "Open",
        tooltip: "Wait while a moderator has considered your call, then open it for creators",
      };
    case "ongoing":
      let getTitle = (currentStatus) => {
        switch (currentStatus) {
          case "opened":
            return "Commence";
          case "completed":
            return "Ongoing";

          default:
            return "";
        }
      };
      return {
        code: "ongoing",
        title: getTitle(currentStatus),
        tooltip: "Wait while Master creators have formed teams, then choose performers and start the workstream",
      };
    case "completed":
      return {
        code: "completed",
        title: "Completed",
        tooltip: "Clicking on the button, you are ready to apply the result and choose a winning team.",
      };
    case "done":
      return {
        code: "done",
        title: "Finish",
        tooltip: "Choose a winner using a radio button",
        forceTooltip: true,
      };
    case "denied":
      return {
        code: "denied",
        title: "Deny",
        tooltip: "tooltip for denied",
      };
    case "archived":
      return {
        code: "archived",
        title: "Archive",
        tooltip: "tooltip for archived",
      };
    default:
      return {
        code: "",
        title: "",
        tooltip: "",
      };
  }
};

const UpdateOpportunityStatusButton = (props) => {
  const { statuses, opportunityId, currentStatus } = props;
  const dispatch = useDispatch();
  let nextStatuses = statuses.filter(
    (s) => s.code !== "denied" && s.code !== "done"
  );
  return nextStatuses.map((s) => {
    return (
      <div key={s.id} className="statusBlockButton">
        <Button
          className={statusData({ code: s.code, currentStatus }).code}
          disabled={!s.available}
          onClick={() => {
            dispatch(
              formSubmitAndGetDataAction({
                formValues: { status_id: s.id },
                submitType: "put",
                endpoint: `/opportunities/${opportunityId}`,

                getEndpoint: `opportunities/${opportunityId}`,
                //redirect: `/opportunity/${opportunityId}`,
              })
            );
          }}
        >
          {statusData({ code: s.code, currentStatus }).title}
        </Button>
        {!s.available ||
          statusData({ code: s.code, currentStatus }).forceTooltip ? (
          <DarkTooltip
            title={statusData({ code: s.code, currentStatus }).tooltip}
          />
        ) : null}
      </div>
    );
  });
};

export default UpdateOpportunityStatusButton;
