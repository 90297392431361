import React from "react";
import useStyles from "../styles/Profile";
const ProfileOnlinePresence = (props) => {
  const { user } = props;
  const classes = useStyles();

  return (
    <>
      {user?.online_presences?.map((op) => {
        return (
          <div className={classes.profileLink}>
            <span className={classes.profileTitleLink}>{op.website_name}</span>
            <a
              href={
                op.url_link.includes("http")
                  ? op.url_link
                  : `https://${op.url_link}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {op.url_link}{" "}
            </a>
          </div>
        );
      })}
    </>
  );
};

export default ProfileOnlinePresence;
