import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  boardFilter: {
    position: "absolute",
    right: 12,
    top: 25,
    zIndex: 10,
    [theme.breakpoints.down("md")]: {
      top: "unset",
      bottom: 73,
      right: 22,
    },
  },
  boardFilterBtn: {
    padding: 0,
    "& img": {
      width: 24,
    },
    "&:hover": {
      background: "none"
    },
    "& .MuiButton-label": {
      width: 27,
      height: 23,
      backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyNyAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjUuNTgyNyAwLjYyNUgxLjQxNjAyTDExLjA4MjcgMTIuMDU1OFYxOS45NTgzTDE1LjkxNiAyMi4zNzVWMTIuMDU1OEwyNS41ODI3IDAuNjI1WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+)",
    },
    "&.isFiltered .MuiButton-label": {
      width: 26,
      height: 24,
      backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNiAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjUuMTY2NyAxSDFMMTAuNjY2NyAxMi40MzA4VjIwLjMzMzNMMTUuNSAyMi43NVYxMi40MzA4TDI1LjE2NjcgMVoiIGZpbGw9ImJsYWNrIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=)",
    },
    [theme.breakpoints.down("md")]: {
      background: "#F5F5F5",
      height: 40,
      borderRadius: 20,
      minWidth: 40,
      "&:hover": {
        background: "#F5F5F5"
      },
    },
  },
  boardFilterPopper: {
    top: "0!important",
    zIndex: 100,
    left: "initial!important",
    bottom: 0,
    overflowY: "auto",
    right: 0,
    [theme.breakpoints.up("lg")]: {
      top: "202px!important",
      right: 30,
      width: 445,
      bottom: 100,
    },
  },
  boardFilterContainer: {
    background: "#fff",
    padding: "18px 24px 35px",
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      boxShadow: "0 6px 20px rgba(0, 0, 0, 0.04)",
      borderRadius: 8,
    },
  },
  boardFilterTitle: {
    fontFamily: "Gilroy",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    paddingBottom: 29,
  },
  boardFilterClose: {
    position: "absolute",
    top: 25,
    right: 24,
    width: 20,
    height: 20,
    minWidth: 12,
    display: "block",
    cursor: "pointer",
    backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTYgNEw0IDE2IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNNCA0TDE2IDE2IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=)",
    padding: 0,
    "&:hover": {
      backgroundColor: "#fff",
    }
  },
  boardFilterText: {
    fontFamily: "Gilroy",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#636363",
    paddingBottom: 12,
  },
  boardFilterSearch: {
    "& .MuiFormControl-root": {
      minWidth: "100%",
      background: "#F5F5F5",
    },
    "& .MuiInputBase-root:before, & .MuiInputBase-root:after": {
      display: "none",
    },
    "& img": {
      marginLeft: 20,
    },
    "& .MuiInputBase-input": {
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      padding: "16px 20px 15px 0",
      "&::placeholder": {
        color: "#000",
        opacity: 1,
      }
    }
  },
  boardFilterFilterUser: {
    width: "100%",
    marginBottom: 32,
  },
  boardFilterItemUser: {
    margin: "0 0 0 -5px",
    "&:first-child": {
      marginTop: "-8px",
    },
    "& .MuiCheckbox-root": {
      display: "none",
    },
    "& .MuiFormControlLabel-label": {
      padding: "8px 5px",
      width: "100%",
    },
    "& .MuiIconButton-label": {
      display: "none",
    },
    "& .Mui-checked + .MuiFormControlLabel-label": {
      background: "#6233C0",
    },
    "& .Mui-checked + .MuiFormControlLabel-label *": {
      color: "#fff",
    },
    "& .MuiTypography-body1": {
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "22px",
    }
  },
  boardFilterItemDate: {
    margin: "0 0 8px",
    "& .MuiTypography-body1": {
      fontFamily: "Gilroy",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .MuiSvgIcon-root": {
      width: 16,
      height: 16,
      marginRight: 9,
    },
    "& .MuiRadio-colorSecondary.Mui-checked, & .MuiRadio-colorSecondary.Mui-checked + .MuiFormControlLabel-label": {
      color: "#6233C0"
    },
    "& .MuiButtonBase-root": {
      padding: 0,
    }
  },
  boardFilterClear: {
    padding: "10px 0 0",
    minWidth: "auto",
    color: "#636363",
    fontFamily: "Gilroy",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    textTransform: "initial",
    "&:hover": {
      color: "#000",
      background: "none"
    }
  },
}));

export default useStyles;
