import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },

  typography: {
    fontFamily: [
      'Avenir', 'Gilroy'
    ].join(','),
  },

  overrides: {
    MuiFormControl: {
      root: {
        minWidth: 329,
        maxWidth: "100%",
        marginBottom: 40,
        borderRadius: 0,
        border: 0,
        outline: 0,
        "&:hover, &:focus": {
          border: 0,
          outline: 0,
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        border: 0,
        background: "#fff",
        fontFamily: "Gilroy"
      },
      notchedOutline: {
        border: 0,
        outline: 0,
      },
      input: {
        padding: 16,
        border: 0,
        display: "flex",
        alignItems: "center",
        fontFamily: "Gilroy"
      },
    }
  },
});

/* theme.props = {
  MuiTextField: {
    width: 329,
    height: 50,
  },
}; */

export default theme;
