import * as types from "../actions";
import { LOCATION_CHANGE } from "connected-react-router";

const formReducer = (
  state = {
    references: null,
    error: null,
    data: null,
    loading: false,
    customData: null,
    header: null,
  },
  action
) => {
  const response = action.response;

  switch (action.type) {
    case types.FORM_SUBMIT:
    return {...state, loading:true}
    case types.FORM_SUBMIT_SUCCESS:
    return {...state, loading:false}
    case types.FORM_SUBMIT_SUCCESS:
    return {...state, loading:false}
    case types.FORM_GET_REFERENCE_VALUES_SUCCESS:
      let references = {
        ...state.references,
        [response.endpoint]: response.data,
      };
      return { ...state, references, error: null, loading: false };

    case types.FORM_GET_DATA:
      return { ...state, loading: true };
    case types.FORM_GET_DATA_SUCCESS:
      return { ...state, data: response.data, error: null, loading: false };

    case types.FORM_GET_DATA_ERROR:
      return { ...state, data: null, error: response, loading: false };

    case types.FORM_GET_CUSTOM_DATA_SUCCESS:
      let customData = {
        ...state.customData,
        [response.name]: response.data,
      };
      return { ...state, customData, error: null, loading: false };

    case types.FORM_RESET_CUSTOM_DATA:
      let resettedData = {
        ...state.customData,
        [action.data.name]: [],
      };
      return {
        ...state,
        customData: resettedData,
        error: null,
        loading: false,
      };

    case types.FORM_GET_HEADER_DATA_SUCCESS:
      let header = {
        ...state.header,
        [response.name]: response.data,
      };
      return { ...state, header, error: null };

    case LOCATION_CHANGE:
      return {
        ...state,
        references: null,
        customData: null,
        data: null,
        error: null,
        loading: false,
      };
    case types.FORM_RESET_DATA:
      return {
        ...state,
        references: null,
        customData: null,
        data: null,
        error: null,
        loading: false,
      };

    case types.FORM_GET_STATIC_PAGE_SUCCESS:
      return { ...state, staticPage: response.data, error: null };

    default:
      return state;
  }
}

export default formReducer;