import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Grid,
  MenuItem,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Form, Field, FormSpy } from "react-final-form";
import { Select, TextField } from "mui-rff";
import {
  formGetReferenceTableValuesAction,
  formUpdateUserAction,
} from "../actions/formActions";
import useStyles from "../styles/Login";
import clsx from "clsx";

import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import DarkTooltip from "../components/DarkTooltip";

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-profile-types",
      })
    );
    dispatch(formGetReferenceTableValuesAction({ endpoint: "countries" }));
  }, [dispatch]);

  const user = useSelector((state) => state.userReducer.user);
  const references = useSelector((state) => state.formReducer.references);

  let profileTypes = references?.["classifiers-profile-types"]?.map((c) => (
    <MenuItem key={c.id} value={c.id}>
      {c.name}
    </MenuItem>
  ));

  let countries = references?.["countries"]?.map((c) => (
    <MenuItem key={c.id} value={c.id}>
      {c.name}
    </MenuItem>
  ));

  return (
    <Paper className={classes.centerForm}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Form
          initialValues={{ policy_agree: false, name: user?.name }}
          onSubmit={(values) => {
            values.profile_types = [values.profile_type_id];
            dispatch(
              formUpdateUserAction({
                endpoint: "/users",
                submitType: "put",
                formValues: values,
                id: user.id,
                redirect: "/profile",
              })
            );
          }}
          render={({
            submitError,
            handleSubmit,
            reset,
            submitting,
            pristine,
            values,
          }) => (
            <form className="form-signup" onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.h1Var1}>
                  Sign up
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.loginText}>
                <Select
                  variant="outlined"
                  name="profile_type_id"
                  label="Select your profile type"
                  formControlProps={{ margin: "none" }}
                  required
                >
                  {profileTypes ? profileTypes : <></>}
                </Select>
                <DarkTooltip
                  className={classes.registerTooltip}
                  title="Business account can hire people for a project, creator can lead a project or participate in project development"
                />
              </Grid>

              <FormSpy subscription={{ values: "profile_type_id" }}>
                {(spyProps) => {
                  if (
                    spyProps.values.profile_type_id &&
                    references?.["classifiers-profile-types"].filter(
                      (pt) => pt.id === spyProps.values.profile_type_id
                    )[0].name === "Business"
                  ) {
                    return (
                      <Grid
                        item
                        xs={12}
                        className={clsx(
                          classes.loginText,
                          classes.tooltipText,
                          classes.registerText
                        )}
                      >
                        <TextField
                          id="name"
                          name="name"
                          label="Business Name"
                          variant="outlined"
                          className={classes.loginText}
                          required
                        />
                      </Grid>
                    );
                  } else if (
                    spyProps.values.profile_type_id &&
                    references?.["classifiers-profile-types"].filter(
                      (pt) => pt.id === spyProps.values.profile_type_id
                    )[0].name === "Creator"
                  ) {
                    return (
                      <Grid
                        item
                        xs={12}
                        className={clsx(
                          classes.loginText,
                          classes.tooltipText,
                          classes.registerText
                        )}
                      >
                        <TextField
                          id="name"
                          name="name"
                          label="Nickname"
                          variant="outlined"
                          className={classes.loginText}
                          required
                        />
                      </Grid>
                    );
                  } else {
                    return null;
                  }
                }}
              </FormSpy>

              <Grid item xs={12} className={classes.registerText}>
                <TextField
                  id="firstName"
                  name="first_name"
                  label="First Name"
                  variant="outlined"
                  className={classes.loginText}
                  required
                />
              </Grid>
              <Grid item xs={12} className={classes.registerText}>
                <TextField
                  id="lastName"
                  name="last_name"
                  label="Last Name"
                  variant="outlined"
                  className={classes.loginText}
                  required
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={clsx(classes.loginText, classes.registerText)}
              >
                <Select
                  name="country_id"
                  variant="outlined"
                  label="Country"
                  formControlProps={{ margin: "none" }}
                  required
                >
                  {countries ? countries : <></>}
                </Select>
              </Grid>
              <Field name="policy_agree">
                {(props) => {
                  return (
                    <Grid item xs={12} className={classes.policyAgree}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...props.input}
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={
                              <RadioButtonCheckedIcon color="primary" />
                            }
                          />
                        }
                      />
                      <Typography variant="body2">
                        I certify that I have read and accept the{" "}
                        <a
                          href={`${process.env.REACT_APP_MAIN_HOST}/terms`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms and conditions and Privacy policy
                        </a>
                      </Typography>
                    </Grid>
                  );
                }}
              </Field>

              <FormSpy subscription={{ values: "policy_agree" }}>
                {(spyProps) => {
                  let buttonsDisabled = !spyProps.values.policy_agree;
                  return (
                    <Grid item xs={12}>
                      <Button
                        disabled={buttonsDisabled}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.btnLogin}
                      >
                        Select
                      </Button>
                    </Grid>
                  );
                }}
              </FormSpy>
              <Grid item xs={12}>
                <p
                  className={clsx(
                    "mt-3 mb-3 text-muted text-center",
                    classes.textSignup
                  )}
                >
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </Grid>
            </form>
          )}
        />
      </Grid>
    </Paper>
  );
};

export default Register;
