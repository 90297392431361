import React, { useState } from "react";
import "@asseinfo/react-kanban/dist/styles.css";
import Board, { moveCard } from "@asseinfo/react-kanban";
import { Form } from "react-final-form";

import { Modal, Button, Typography, Grid } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import BoardCardMasterCreator from "../forms/BoardCardMasterCreator";
import BoardCardCreator from "../forms/BoardCardCreator";
import { useEffect } from "react";
import Edit from "../assets/edit.svg";
import BoardFilter from "./BoardFilter";

import {
  formGetCustomDataAction,
  formSubmitAction,
  formSubmitAndGetCustomDataAction,
  formResetCustomDataAction,
} from "../actions/formActions";
import { getUnifiedDate, getTime } from "../utils/misc";
import useStyles from "../styles/Team";
import moment from "moment";

let board = {};

const TeamTabBoard = (props) => {
  const dispatch = useDispatch();

  const { members, teamId } = props;
  useEffect(() => {
    dispatch(
      formGetCustomDataAction({
        endpoint: `teams/${teamId}/stages`,
        name: "stages",
      })
    );
  }, [dispatch, teamId]);

  const columns = useSelector((state) => state.formReducer.customData?.columns);
  const tasks = useSelector((state) => state.formReducer.customData?.tasks);
  const stages = useSelector((state) => state.formReducer.customData?.stages);
  const user = useSelector((state) => state.userReducer.user);

  const [controlledBoard, setBoard] = useState(board);
  const [columnToEdit, setColumnToEdit] = useState(null);
  const [cardToEdit, setCardToEdit] = useState(null);
  const [action, setAction] = useState(null);
  const [open, setOpen] = useState(false);

  const [nameFilter, setNameFilter] = useState("");

  const [assigneeFilter, setAssigneeFilter] = useState(
    props.members?.map((m) => {
      return { key: m?.member?.id, id: m?.member?.id, checked: false };
    })
  );

  const [dateFilter, setDateFilter] = useState(false);

  useEffect(() => {
    async function getData() {
      await dispatch(
        formGetCustomDataAction({
          endpoint: `classifiers-task-statuses`,
          name: "columns",
        })
      );
      await dispatch(
        formGetCustomDataAction({
          endpoint: `teams/${teamId}/tasks`,
          name: "tasks",
        })
      );
    }
    getData();
  }, [dispatch, teamId]);

  useEffect(() => {
    async function getData() {
      await dispatch(
        formGetCustomDataAction({
          endpoint: `classifiers-task-statuses`,
          name: "columns",
        })
      );
    }
    getData();
  }, [tasks, dispatch]);

  useEffect(() => {
    let nameParsedFilter = nameFilter ? ["name", "contains", nameFilter] : null;

    let assignees = assigneeFilter?.reduce((acc, cur) => {
      acc = cur.checked ? [...acc, cur] : acc;
      return acc;
    }, []);
    let assigneesParsedFilter = assignees?.length
      ? ["assignee_id", "in", assignees.map((a) => parseInt(a.id))]
      : null;

    let dateParsedFilter = null;
    if (dateFilter === "overdue") {
      dateParsedFilter = [
        "due_date",
        "<",
        moment(moment()).format("yyyy-MM-DD"),
      ];
    } else if (dateFilter === "0") {
      dateParsedFilter = ["due_date", "=", null];
    } else {
      if (dateFilter) {
        dateParsedFilter = [
          ["due_date", ">=", JSON.parse(dateFilter)?.from],
          "and",
          ["due_date", "<=", JSON.parse(dateFilter)?.to],
        ];
      }
    }

    let combinedFilters = [
      nameParsedFilter,
      assigneesParsedFilter,
      dateParsedFilter,
    ].filter((f) => Boolean(f));
    let combinedParsedFilters = combinedFilters.length
      ? combinedFilters.reduce((acc, cur, index) => {
        if (index < combinedFilters.length - 1) {
          acc = [...acc, cur, "and"];
        } else {
          acc = [...acc, cur];
        }
        return acc;
      }, [])
      : null;

    if (combinedParsedFilters && combinedParsedFilters?.length === 1) {
      combinedParsedFilters = combinedParsedFilters?.[0]
    }
    async function getData() {
      await dispatch(
        formGetCustomDataAction({
          endpoint: `teams/${teamId}/tasks`,
          name: "tasks",
          filter: combinedParsedFilters,
        })
      );
    }
    getData();
  }, [nameFilter, assigneeFilter, dateFilter, dispatch, teamId]);

  board.columns = columns?.map((c) => {
    c.key = c.id;
    c.cards = tasks?.reduce((acc, cur) => {
      if (cur.status_id === c.id) {
        acc = [...acc, cur];
      }
      return acc;
    }, []);
    c.title = c.name;
    return c;
  });

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setColumnToEdit(null);
    setCardToEdit(null);
    setOpen(false);

    dispatch(
      formResetCustomDataAction({
        name: "comments",
      })
    );
  };

  const handleCardMove = (_card, source, destination) => {
    if (source.fromColumnId !== destination.toColumnId) {
      _card.status_id = destination.toColumnId;
      dispatch(
        formSubmitAction({
          submitType: "put",
          endpoint: `/teams/${props.teamId}/tasks/${_card.id}`,
          formValues: _card,
          //redirect: router.location.pathname,
        })
      );

      dispatch(
        formGetCustomDataAction({
          endpoint: `teams/${props.teamId}/tasks`,
          name: "tasks",
        })
      );
    }
    const updatedBoard = moveCard(controlledBoard, source, destination);
    setBoard(updatedBoard);
  };

  if (!columns || !tasks) {
    return null;
  }

  return (
    <div className={classes.teamBoard}>
      <Board
        renderCard={(card, { removeCard, dragging }) => {
          return (
            <div dragging={dragging} className={classes.teamBoardTask}>
              {card.parent?.name ? (
                <Typography
                  variant="body2"
                  className={classes.teamParentTaskTitle}
                >
                  {card.parent?.name}
                </Typography>
              ) : null}
              <Typography variant="body2" className={classes.teamTaskTitle}>
                {card.title}
              </Typography>
              <Grid className={classes.teamTaskInfo}>
                <Typography variant="body2" className={classes.teamTaskDate}>
                  {moment().isSame(card?.updated_at, "day") ? getTime(card?.updated_at) : getUnifiedDate(card?.updated_at)}
                </Typography>
                <Button
                  className={classes.teamTaskView}
                  onClick={() => {
                    setAction("edit");
                    setCardToEdit(card);
                    setColumnToEdit(
                      controlledBoard.columns.filter((col) =>
                        col.cards.includes(card)
                      )[0]
                    );
                    handleOpen();
                  }}
                >
                  View
                </Button>
              </Grid>
            </div>
          );
        }}
        allowAddCard
        onCardDragEnd={handleCardMove}
        disableColumnDrag
        renderColumnHeader={(props) => {
          return (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.teamBoardTitle}>
                  {props.title}
                </Typography>
              </Grid>
              {members?.some((m) => m?.member?.id === user.id) ? (
                <Grid item xs={12}>
                  <Button
                    className={classes.teamBoardButton}
                    onClick={() => {
                      setAction("add");
                      setColumnToEdit(props);
                      handleOpen();
                    }}
                  >
                    Add Task
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          );
        }}
      >
        {controlledBoard}
      </Board>
      <BoardFilter
        members={props.members}
        nameFilter={nameFilter}
        setNameFilter={setNameFilter}
        assigneeFilter={assigneeFilter}
        setAssigneeFilter={setAssigneeFilter}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Typography variant="h1" className={classes.paperTitle}>
            {action === "add" ? (
              "Create new task"
            ) : (
              <span>
                {cardToEdit?.title}
                <img src={Edit} alt="Edit" />
              </span>
            )}
          </Typography>
          <span className={classes.paperClose} onClick={handleClose}></span>
          <Form
            initialValues={{
              ...cardToEdit,
              status_id: cardToEdit?.status_id
                ? cardToEdit?.status_id
                : columnToEdit?.id,
            }}
            onSubmit={async (values) => {
              if (action === "add") {
                await dispatch(
                  formSubmitAndGetCustomDataAction({
                    endpoint: `/teams/${props.teamId}/tasks`,
                    getEndpoint: `/teams/${props.teamId}/tasks`,
                    formValues: values,
                    name: "tasks",
                  })
                );

                setCardToEdit(null);
              } else {
                await dispatch(
                  formSubmitAndGetCustomDataAction({
                    submitType: "put",
                    endpoint: `/teams/${props.teamId}/tasks/${cardToEdit.id}`,
                    getEndpoint: `/teams/${props.teamId}/tasks`,
                    formValues: values,
                    name: "tasks",
                  })
                );
              }

              setColumnToEdit(null);
              setCardToEdit(null);
              handleClose();
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                {props.members?.some((m) => m?.member?.id === user?.id) ? (
                  <BoardCardMasterCreator
                    cardToEdit={cardToEdit}
                    statuses={columns}
                    pristine={pristine}
                    teamId={props.teamId}
                    members={props.members}
                    stages={stages}
                    setOpen={setOpen}
                    setColumnToEdit={setColumnToEdit}
                    setCardToEdit={setCardToEdit}
                    tasks={tasks}
                  />
                ) : (
                  <BoardCardCreator
                    cardToEdit={cardToEdit}
                    statuses={columns}
                    pristine={pristine}
                    teamId={props.teamId}
                    members={props.members}
                    stages={stages}
                    setOpen={setOpen}
                    setColumnToEdit={setColumnToEdit}
                    setCardToEdit={setCardToEdit}
                    tasks={tasks}
                  />
                )}
              </form>
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TeamTabBoard;
