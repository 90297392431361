import React, {useState, useEffect} from "react";

import {
  Grow,
  MenuItem,
  Button,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { formGetHeaderDataAction } from "../../../actions/formActions";
import arrow from "../../../assets/header_arrow.svg";
import useStyles from "./styles";

const Opportunities = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    dispatch(
      formGetHeaderDataAction({
        endpoint: `users/${user.id}/opportunities`,
        name: "opportunities",
      })
    );
  }, [dispatch, user]);

  const opportunities = useSelector(
    (state) => state.formReducer.header?.opportunities
  );
  const router = useSelector((state) => state.router);

  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  return opportunities?.length > 0 ? (
    <div className={classes.headerSubLink}>
      <Button
        ref={anchorRef}
        onClick={(e) => {
          setOpen((open) => !open);
        }}
        color="secondary"
      >
        Calls
        <img src={arrow} alt="arrow" />
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="top-start"
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.headerSubMenu}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {opportunities
                    ? opportunities.map((o) => {
                        return (
                          <MenuItem
                            noWrap
                            className={
                              router?.location?.pathname ===
                              `/calls/${o.id}`
                                ? "active"
                                : null
                            }
                          >
                            <Link to={`/calls/${o.id}`}> {o.title}</Link>
                          </MenuItem>
                        );
                      })
                    : null}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  ) : null;
};

export default Opportunities;
