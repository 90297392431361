import React from "react";
import Wizard from "../components/Wizard";
import useStyles from "../styles/CreationOpportunityTeamForm";
import { TextField, Select } from "mui-rff";
import { Grid, MenuItem } from "@material-ui/core";
import { FormSpy } from "react-final-form";
import { useSelector } from "react-redux";

const TeamsFormPage1 = (props) => {
  const { setOpportunityId, oppoId } = props;

  const classes = useStyles();
  const references = useSelector((state) => state.formReducer.references);
  return (
    <Wizard.Page>
      <FormSpy
        subscription={{ values: "opportunity_id" }}
        onChange={(changeProps) => {
          setOpportunityId(
            (opportunityId) => changeProps.values?.opportunity_id
          );
        }}
      ></FormSpy>
      <Grid container className={classes.wizardTeamContainer}>
        <Grid item xs={12}>
          <TextField
            className={classes.teamTextField}
            label="Team Name"
            name="name"
            required={true}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.teamTextField}
            label="Team Description"
            name="description"
            multiline
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} className={classes.CreationSelectBlock}>
          <Grid className={classes.teamOpportunity}>
            <Select
              disabled={Boolean(oppoId)}
              required
              name="opportunity_id"
              label="Call"
              formControlProps={{ margin: "dense" }}
            >
              {references?.["opportunities"]?.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.title}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Grid>
    </Wizard.Page>
  );
};

export default TeamsFormPage1;
