import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

const baseURL = process.env.REACT_APP_API_URL;
 export const subscribe = (token, authEndpoint) =>
  new Echo({
    broadcaster: "pusher",
    key: `${process.env.REACT_APP_ECHO_KEY}`,
    cluster: "mt1",
    forceTLS: false,
    wsHost: `${process.env.REACT_APP_WS_HOST}`,
    wsPort: process.env.REACT_APP_WS_PORT,
    encrypted: false,
     enabledTransports: ['ws'],
    authEndpoint: `${baseURL}${authEndpoint}`,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
        "Access-Control-Allow-Origin": "http://wecreators.iost.work, http://localhost:6001, http://localhost:9999, http://localhost:8081, http://localhost:8082, http://localhost:3000, http://localhost:4000, http://localhost:5000, http://dev.wecreators.com, https://dev-app.wecreators.com, http://dev-app.wecreators.com, https://app.wecreators.com, http://app.wecreators.com, https://dev.wecreators.com, https://dev-admin.wecreators.com, http://dev-admin.wecreators.com, https://admin.wecreators.com, http://admin.wecreators.com ",
      },
    },
  });