import React from "react";
import { Button } from "@material-ui/core";
import DarkTooltip from "./DarkTooltip";
import { useDispatch } from "react-redux";
import { formGetCustomDataAction, formSubmitAndGetDataAction } from "../actions/formActions";
import clsx from "clsx";

const statusData = (data) => {
  const { code, currentStatus } = data;
  switch (code) {
    case "draft":
      return {
        code: "draft",
        title: "Draft",
        tooltip: "tooltip for draft",
      };

    case "deleted":
      return {
        code: "deleted",
        title: "Delete",
        tooltip: "tooltip for deleted",
      };

    case "recruitment":
      return {
        code: "recruitment",
        title: "Start recruitment",
        tooltip: "tooltip for recruitment",
      };

    case "formed":
      return {
        code: "formed",
        title: "Formed",
        tooltip: "Click when all roles in your team have occupied, then the call owner will assess your team",
      };

    case "rejected":
      return {
        code: "rejected",
        title: "Reject",
        tooltip: "tooltip for rejected",
      };

    case "in_progress":
      let getTitle = (currentStatus) => {
        switch (currentStatus) {
          case "formed":
            return "Commence";
          case "completed":
            return "Finish up";

          default:
            return "";
        }
      };
      return {
        code: "in_progress",
        title: getTitle(currentStatus),
        tooltip: "tooltip for in progress",
      };

    case "completed":
      return {
        code: "completed",
        title: "Complete",
        tooltip: "Press this buttons when all stages have been done",
      };

    case "closed":
      return {
        code: "closed",
        title: "Close",
        tooltip: "tooltip for closed",
      };
    default:
      return {
        code: "",
        title: "",
        tooltip: "",
      };
  }
};

const UpdateTeamStatusButton = (props) => {
  const { statuses, teamId, opportunityId, classes, currentStatus } = props;
  const dispatch = useDispatch();
  let nextStatuses = statuses.filter((s) => s.code !== "denied");
  return nextStatuses.map((s) => {
    return (
      <div key={s.id} className="stageButtonGrid">
        <Button
          disabled={!s.available}
          className={clsx(
            statusData({ code: s.code, currentStatus }).code,
            classes
          )}
          onClick={() => {

            dispatch(
              formSubmitAndGetDataAction({
                formValues: { status_id: s.id },
                submitType: "put",
                endpoint: `/opportunities/${opportunityId}/teams/${teamId}`,
                getEndpoint: `/opportunities/${opportunityId}/teams/${teamId}`,
              })
            );

          }}
        >
          {statusData({ code: s.code, currentStatus }).title}
        </Button>
        {s.available ? null : (
          <DarkTooltip
            title={statusData({ code: s.code, currentStatus }).tooltip}
          />
        )}
      </div>
    );
  });
};

export default UpdateTeamStatusButton;
