import React from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "../styles/Profile";

const ProfileBio = (props) => {
  const { user } = props;
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" className={classes.profileTitleBlock}>
        {props.title}
      </Typography>
      <Typography variant="body2" className={classes.profileBio}>
        {user?.biography}
      </Typography>
    </>
  );
};

export default ProfileBio;
