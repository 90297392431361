import React from "react";

import Wizard from "../components/Wizard";
import useStyles from "../styles/CreationOpportunityTeamForm";
import { TextField, Select } from "mui-rff";
import { Button, Grid, MenuItem, Typography } from "@material-ui/core";
import { FormSpy, Field } from "react-final-form";
import { useSelector } from "react-redux";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { FieldArray } from "react-final-form-arrays";
import UserCardWithButton from "../components/UserCardWithButton";
import RemoveIcon from "../assets/close.svg";

const TeamsFormPage2 = (props) => {
  const {
    opportunitiesList,
    opportunityId,
    opportunity,
    opportunityCode,
    opportunityRolesLength,
  } = props;

  const classes = useStyles();
  const references = useSelector((state) => state.formReducer.references);

  return (
    <Wizard.Page>
      <Grid container className={classes.wizardTeamContainer}>

        <FormSpy subscription={{ values: "opportunity_id" }}>
          {(spyProps) => {
            spyProps.form.change(
              "opportunity",
              opportunitiesList?.reduce((acc, cur) => {
                if (cur.id === parseInt(opportunityId)) {
                  acc = cur;
                }
                return acc;
              }, null)
            );

/*             opportunity.current = opportunitiesList?.reduce((acc, cur) => {
              if (cur.id === parseInt(opportunityId)) {
                acc = cur;
              }
              return acc;
            }, null); */

/*             opportunityCode.current =
              spyProps.values?.opportunity?.opportunity_type?.code;
              opportunity.current?.opportunity_type?.code;

            opportunityRolesLength.current =
              spyProps.values?.opportunity_roles?.length; */
            /*                     if (
                      opportunityCode.current === "one_team_apply" &&
                      opportunityRolesLength.current > 1
                    ) {
                      spyProps.form.change(
                        "opportunity_roles",
                        spyProps.values?.opportunity_roles
                          ? [spyProps.values?.opportunity_roles[0]]
                          : spyProps.values?.opportunity_roles
                      );
                    } */

            return <></>;
          }}
        </FormSpy>

        <FieldArray name="opportunity_roles" label="Call roles">
          {({ fields }) => {
            return (
              <div style={{ width: "100%" }}>
                {fields.map((name, index) => (
                  <div key={name} className={classes.teamRolesContainer}>
                    <Typography
                      className={classes.teamRoleTitle}
                      variant="body2"
                      noWrap
                    >
                      {`${index + 1}. Team role`}
                    </Typography>
                    <Grid item xs={12} className={classes.CreationSelectBlock}>
                      <Field name={`${name}.role_id`}>
                        {(props) => {
                          return (
                            <CustomAutocomplete
                              {...props.input}
                              {...props.meta}
                              reference="classifiers-opportunity-roles"
                              label="Role"
                              data={props.input.value}
                              name={`${name}.role_id`}
                            />
                          );
                        }}
                      </Field>
                    </Grid>

                    <FormSpy subscription={{ values: true }}>
                      {(spyProps) => {
                        let roleId =
                          spyProps.values.opportunity_roles[index]?.role_id;
                        if (!roleId) {
                          spyProps.form.change(`${name}.member_id`, null);
                          spyProps.form.change(`${name}.description`, null);
                        }
                        return (
                          <>
                            <Grid className={classes.CreationSelectBlock}>
                              <Field name={`${name}.member_id`}>
                                {(props) => {
                                  return (
                                    <CustomAutocomplete
                                      {...props.input}
                                      reference="users"
                                      label="Invite users to apply for the role (optional)"
                                      name={`${name}.member_id`}
                                      data={props.input.value}
                                      disabled={!roleId}
                                      ListboxComponent={(listboxProps) => {
                                        return (
                                          <Grid
                                            className={classes.teamUserSelect}
                                          >
                                            <Grid
                                              container
                                              {...listboxProps}
                                              children={React.Children.map(
                                                listboxProps.children,
                                                (child) => (
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={4}
                                                    className={
                                                      classes.teamUserSelectOption
                                                    }
                                                  >
                                                    {React.cloneElement(child)}
                                                  </Grid>
                                                )
                                              )}
                                            />
                                          </Grid>
                                        );
                                      }}
                                      renderOption={(o) => {
                                        return (
                                          <Grid
                                            className={classes.teamItemUser}
                                          >
                                            <UserCardWithButton user={o} />
                                          </Grid>
                                        );
                                      }}
                                    />
                                  );
                                }}
                              </Field>
                            </Grid>
                            <Grid className={classes.CreationSelectBlock}>
                              <TextField
                                multiline
                                name={`${name}.description`}
                                label="Role responsibilities"
                                disabled={!roleId}
                              />
                            </Grid>
                          </>
                        );
                      }}
                    </FormSpy>
                    <Button
                      onClick={() => fields.remove(index)}
                      className={classes.btnRemoveRole}
                    >
                      <img src={RemoveIcon} alt="Remove" />
                    </Button>
                  </div>
                ))}

                <FormSpy subscription={{ values: "opportunity_roles" }}>
                  {(spyProps) => {
/*                     opportunityCode.current = spyProps.values?.opportunity
                      ? spyProps.values?.opportunity?.opportunity_type?.code
                      : opportunity.current?.opportunity_type?.code;

                    opportunityRolesLength.current = spyProps.values
                      ?.opportunity_roles?.length
                      ? spyProps.values?.opportunity_roles?.length
                      : 0; */

                    return <></>;
                  }}
                </FormSpy>
                {/*                         {(opportunityCode.current === "one_team_apply" &&
                          (opportunityRolesLength.current < 1 ||
                            !opportunityRolesLength.current)) ||
                        opportunityCode.current === "teams_apply" ? ( */}
                <Button
                  className={classes.addRoleBtn}
                  onClick={() =>
                    fields.push({
                      role_id: null,
                      member_id: null,
                    })
                  }
                >
                  Add role
                </Button>
                {/*                         ) : null} */}
              </div>
            );
          }}
        </FieldArray>
      </Grid>
    </Wizard.Page>
  );
};

export default TeamsFormPage2;
