import React from "react";
import IdeationPostFormWrapper from "../containers/IdeationPostFormWrapper";

const IdeationPostEditWrapper = (props) => {
  const { data, handleCloseForm } = props;
  let modifiedData = { ...data, body: JSON.parse(data.body) };

  return <IdeationPostFormWrapper initialValues={modifiedData} edit={true} handleCloseForm={handleCloseForm}/>;
};

export default IdeationPostEditWrapper;
