import React from "react";
import clsx from "clsx";

import { Typography } from "@material-ui/core";
import { isMaster } from "../utils/misc";
import premium from "../assets/premium.svg"
const DefaultProfileAvatar = (props) => {
  return (
    <div
      className={clsx(
        "default-profile-avatar-container",
        `profile-gradient-${props.user?.id % 15}`
      )}
    >
      <Typography variant="body2">
        {props.user?.name?.[0].toUpperCase()}
      </Typography>
      {isMaster(props?.user) ? (
        <img src={premium} alt="premium"
          style={{
            height: 15,
            width: 15,
            minWidth: 15,
            position: "absolute",
            right: -3,
            bottom: 0,
            fill: "#000",
          }}
        />
      ) : null}
    </div>
  );
};

export default DefaultProfileAvatar;
