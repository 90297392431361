import React from "react";
import useStyles from "../styles/CreationOpportunity";

import OpportunityTabTeamsList from "./OpportunityTabTeamsList";
import OpportunityTabTeamsApply from "./OpportunityTabTeamsApply";
import OpportunityTabTeamsWinner from "./OpportunityTabTeamsWinner";
import { Grid } from "@material-ui/core";

const OpportunityTabTeams = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { data, setWinner } = props;
  return (
    <Grid container className={classes.creationContainerBody}>
      <Grid item className={classes.creationBodyTeam}>
        {["opened"].includes(data?.status?.code) ? (
          <OpportunityTabTeamsApply data={data} />
        ) : null}
        {["completed", "done"].includes(data?.status?.code) ? (
          <OpportunityTabTeamsWinner
            data={data}
            ref={ref}
            setWinner={setWinner}
          />
        ) : null}
        {["ongoing"].includes(data?.status?.code) ? (
          <OpportunityTabTeamsList data={data} />
        ) : null}
      </Grid>
    </Grid>
  );
});

export default OpportunityTabTeams;
