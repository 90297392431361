import * as types from "../actions";

const registerReducer = (state = [], action) => {
  const response = action.response;

  switch (action.type) {
    case types.REGISTER_USER_SUCCESS:
      return { ...state, response };
    default:
      return state;
  }
};

export default registerReducer;
