import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { AppBar, Toolbar, Badge } from "@material-ui/core";
import clsx from "clsx";

import User from "./User";
import CenterMenu from "./CenterMenu";
import AddMenu from "./AddMenu";
import Notifications from "./Notifications";

import logoBlack from "../../../assets/logo-black.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logoWhite from "../../../assets/logo-white.svg";
import SearchIcon from "../../../assets/search_icon.svg";
import MobileMenu from "./MobileMenu";

import useStyles from "./styles";

export default function PrimarySearchAppBar() {
  const classes = useStyles();

  const user = useSelector((state) => state.userReducer.user);

  return user ? (
    <div className={classes.noGrow}>
      <AppBar position="static" elevation={0} id="AppBar">
        <Toolbar className={classes.headerBlock}>
          <Link to="/" className={classes.headerLogLink}><img src={logoWhite} alt="Logo" className={classes.headerLogo} /></Link>
          <div
            className={clsx(
              classes.grow,
              classes.center,
              classes.sectionDesktop,
              classes.centerMenuContainer
            )}
          >
            <CenterMenu />
          </div>
          <div className={classes.headerIcons}>
            <AddMenu />
            <IconButton color="inherit">
              <Badge color="secondary">
                <Link to="/search">
                  <img src={SearchIcon} alt="Search"/>
                </Link>
              </Badge>
            </IconButton>
            <Notifications />
            <User />
            <MobileMenu className={classes.mobileMenuBtn} />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  ) : (
    <div className={(classes.center, classes.noGrow)}>
      <AppBar position="static" elevation={0} className={classes.loginHeader}>
        <Toolbar className={classes.center}>
          <Link to="/">
            <img src={logoBlack} alt="Logo" />
          </Link>
        </Toolbar>
      </AppBar>
    </div>
  );
}
