import http from "../http";

export const sendMessageService = async (payload) => {
  const ENDPOINT = "/chat";

  try {
    const { data } = await http.post(ENDPOINT, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
