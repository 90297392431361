import React from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { useState, useEffect } from "react";
import UserCard from "./UserCard";

const TaskAssigneeSelect = (props) => {
  const [selectedOption, setSelectedOption] = useState({});
  const { options, disabled, value } = props;

  useEffect(() => {
    if (value) {
      setSelectedOption(options.filter((d) => value === d.member?.id)?.[0]);
    }
  }, [options, value]);

  return (
    <Autocomplete
      {...props.input}
      freeSolo
      disabled={disabled}
      options={options}
      value={selectedOption}
      getOptionLabel={(option) =>
        option?.member?.name ? option?.member?.name : ""
      }
      id="disable-close-on-select"
      renderOption={(o) => {
        return <UserCard user={o?.member} />;
      }}
      onChange={(e, v) => {
        if (v) {
          props.onChange(v.member?.id);
        } else {
          props.onChange(null);
        }
      }}
      renderInput={(params) => <TextField {...params} label="Assignee" />}
    />
  );
};

export default TaskAssigneeSelect;
