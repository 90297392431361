import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import PropTypes from "prop-types";
import { formatBytes } from "../utils/misc";
import AttachFileIcon from "../assets/attach_file.svg";
import useStyles from "../styles/AttachFile";
const AttachmentChips = (props) => {

  let path =  `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}`;
    const classes = useStyles();
  return (
    <>
      <Typography variant="h6" noWrap>
        {props.title}
      </Typography>

      <Grid container>
        {props.data?.map((d) => {
          return (
              <Grid container key={d.id}>
                <Grid item>
                  <Link key={d.id} target="_blank" href={`${path}/${d.link}`} className={classes.attachFileLink}>
                      <img src={AttachFileIcon} alt="attach file"/>
                      <span className={classes.attachFileName}>{d.name}<span>{formatBytes(d.size)}</span></span>
                  </Link>
                </Grid>
              </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AttachmentChips;

AttachmentChips.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
