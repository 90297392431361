
import React from "react";

import Modal from "@material-ui/core/Modal";
import AvatarEditor from "react-avatar-editor";
import { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import useStylesProfile from "../styles/Profile";
import useStyles from "../styles/CreationOpportunityTeamForm";
import { fileUploadError } from "../utils/misc";
import { IconButton } from "@material-ui/core";
import CancelIcon from "../assets/close.svg";
import { ReactReduxContext } from "react-redux";

let containerPath = `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}`;

function readAsDataURL(file) {
	return new Promise((resolve, reject) => {
		let fileReader = new FileReader();
		fileReader.onload = function () {
			const result = fileReader.result;
			return resolve({
				data: fileReader.result,
				name: file.name,
				size: file.size,
				type: file.type,
				b64: result,
				preview: URL.createObjectURL(file),
			});
		};
		fileReader.readAsDataURL(file);
	});
}


const CoverImageModal = (props) => {
	const classes = useStyles();
	const classesProfile = useStylesProfile();
	const { width, height } = props;

	const { getRootProps, getInputProps, fileRejections } =
		useDropzone({
			//accept: "image/*",
			accept: [
				"image/bmp",
				"image/gif",
				"image/vnd.microsoft.icon",
				"image/jpeg",
				"image/png",
				"image/tiff",
				"image/webp",
			],
			multiple: false,
			noDrag: true,
			onDrop: async (acceptedFiles) => {

				let files = await Promise.all(
					acceptedFiles.map((f) => {
						return readAsDataURL(f);
					})
				);
				setImage(files[0]);
				setImageOriginal(acceptedFiles[0]);
				setOpen(true);
			},
		});

	const [scale, setScale] = useState(1);
	const [editor, setEditor] = useState(null);
	const [image, setImage] = useState(null);
	const [imageOriginal, setImageOriginal] = useState(null);

	// getModalStyle is not a pure function, we roll the style only on the first render
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const errors = fileRejections.map((file) => {
		return (
			<div className={classes.attachmentError} key={file.file.name}>
				{file.file.name}
				{file.errors?.map((e) => (
					<span> {fileUploadError(e.code)}</span>
				))}
			</div>
		);
	});

	const removeImage = (image) => () => {
		setImage(null);
		props.onChange(null);
	};

	React.useEffect(() => {
		if (props.value) {
			setImage((image) =>
				props.value
			);
		}
	}, [props.value]);


	return (

		<div>

			<div className={classes.dropzoneBtn}>
				<div {...getRootProps({ className: "btn-dropzone" })}>
					<input {...getInputProps()} />
					<span className={classes.dropzoneBtnMainText}>{props.mainText}</span>
					<span
						className={classes.dropzoneBtnSubText}
						dangerouslySetInnerHTML={{ __html: props.subText }}
					></span>
				</div>

				{image ?
					(<aside className={classes.dropzoneAside}>
						<div key={image?.name} className={classes.thumbInner}>
							<img
								src={image?.b64 ? image?.b64 : `${containerPath}/${image?.link}`}
								alt=""
							/>
							<IconButton
								edge="end"
								aria-label="filter"
								aria-controls="removeFile"
								aria-haspopup="true"
								onClick={removeImage(image)}
								color="secondary"
								className={classes.thumbCancel}
							>
								<img src={CancelIcon} alt="Close" />
							</IconButton>
						</div>
					</aside>)
					: null
				}



				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className={classesProfile.paper}>
						{fileRejections.length ? (
							<Grid container>
								<Grid item xs={12}>
									{errors}
									<span
										className={classesProfile.paperClose}
										onClick={handleClose}
									></span>
								</Grid>
							</Grid>
						) : (
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="h1" className={classesProfile.paperTitle}>
										Position or resize you profile picture
									</Typography>
									<span
										className={classesProfile.paperClose}
										onClick={handleClose}
									></span>
									<AvatarEditor
										ref={(editor) => setEditor(editor)}
										image={imageOriginal}
										width={width ? width : 329}
										height={height ? height : 200}
										border={50}
										color={[245, 245, 245, 0.8]} // RGBA
										scale={scale}
										rotate={0}
									/>
								</Grid>
								<Grid item xs={12} className={classesProfile.inputRange}>
									<input
										name="scale"
										type="range"
										onChange={(e) => {
											setScale(parseFloat(e.target.value));
										}}
										min="0.1"
										max="2"
										step="0.01"
										defaultValue={scale}
									/>
								</Grid>
								<Grid item xs={12} className="buttons">
									<Button
										onClick={() => {
											if (editor) {
												const result = editor.getImage().toDataURL();
												props.onChange({ b64: result, name: image.name });
												setOpen(false);
											}
										}}
									>
										Save photo
									</Button>
								</Grid>
							</Grid>
						)}
					</div>
				</Modal>
			</div>
		</div>
	);
};

export default CoverImageModal;
