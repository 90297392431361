import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        [theme.breakpoints.up("md")]: {
            minHeight: "calc(100vh - 108px)",
            display: "flex",
            width: "100%",
            alignItems: "center"
        },
    },
    container: {
        padding: "83px 23px 70px",
        "& *": {
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        [theme.breakpoints.up("md")]: {
            padding: "110px 0 132px",
            width: 678,
            margin: "0 auto",
        },
        "& .buttons": {
            justifyContent: "normal"
        },
        "& .buttons button": {
            margin: "0 25px 0 0",
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    teamTitle: {
        paddingBottom: 40,
        "& h1, & h2":{
            fontSize: 32,
            lineHeight: "35px",
        },
        "& h1":{
            color: "#BCBCBC",
        },
        [theme.breakpoints.down("sm")]: {
            "& h1":{
                paddingBottom: 6,
            },
        },
        [theme.breakpoints.up("md")]: {
            "& h1, & h2":{
                fontSize: 44,
                lineHeight: "54px",
            },
        },
    },
    teamDescription: {
        fontSize: 16,
        lineHeight: "24px",
        color: "#636363",
        paddingBottom: 60,
        "& h2": {
            fontSize: "26px",
            lineHeight: "34px",
            margin: "0 0 30px",
            color: "#000"
        },
        "& img": {
            maxWidth: "100%",
            display: "block",
        },
        "& p": {
            color: "#636363",
            fontSize: "16px",
            lineHeight: "24px",
            margin: "0 0 48px",
        },
        "& p + p": {
            marginTop: "-58px",
        },
        "& figure": {
            margin: "0",
        },
        "& figure + figure": {
            marginTop: 8,
        },
        "& figure + p, & figure + h2": {
            marginTop: 68,
        },
        [theme.breakpoints.up("md")]: {
            "& p": {
                margin: "0 0 70px",
            },
            "& figure + figure": {
                marginTop: 20,
            },
            "& figure + p, & figure + h2": {
                marginTop: 80,
            },
        }
    },
    teamInvitationLabel: {
        fontSize: "14px",
        lineHeight: "18px",
        color: "#636363",
        paddingBottom: 12,
    },
    teamInvitationRole: {
        paddingBottom: 35,
        "& p, & a": {
            fontSize: "32px",
            lineHeight: "39px",
            color: "#6233C0",
        },
        "& a:hover": {
            color: "#000",
        },
        [theme.breakpoints.up("md")]: {
            paddingBottom: 21,
        },
    },
    teamInvitationReward: {
        marginTop: "-25px",
        paddingBottom: 40,
        "& .MuiTypography-h6": {
            fontSize: "14px",
            lineHeight: "18px",
            color: "#636363",
            paddingBottom: 12,
        },
        "& .MuiChip-root": {
            border: "1px solid rgba(0, 0, 0, 0.1)",
            height: 28,
            margin: "0 10px 15px 0",
            background: "#F5F5F5",
            borderRadius: 4,
            fontSize: 14,
        }
    },
    teamInvitationOpportunity: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: 35,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 37,
        },
    },
    teamInvitationColReward: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: 25,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 37,
        },
    },
    teamInvitationAmount: {
        fontSize: "32px",
        lineHeight: "39px",
    },
    radioControl: {
        paddingBottom: 20,
        "& .MuiFormLabel-root": {
            fontSize: 14,
            lineHeight: "18px",
            color: "#636363",
            paddingBottom: 5,
        },
        "& .MuiTypography-body1": {
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
        },
        "& .MuiRadio-root":{
            padding: "6px 12px 6px 9px",
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            fontSize: 16,
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
            background: "none",
            color: "#6233C0",
        },
        "& .MuiFormControlLabel-label": {
            display: "flex",
            alignItems: "center",
        },
        "& .MuiRadio-colorSecondary.Mui-checked + .MuiFormControlLabel-label": {
            background: "none",
            color: "#6233C0",
        },
        "& .MuiFormControlLabel-label .MuiSvgIcon-root": {
            margin: "0 0 0 10px",
            color: "#000"
        },
        "& .MuiFormControlLabel-root": {
            paddingTop: 4,
        }
    },
    teamCover: {
        paddingBottom: 20,
        "& .MuiOutlinedInput-root": {
            background: "#F5F5F5",
            padding: "14px 16px",
            minHeight: 100,
            alignItems: "normal"
        },
        "& textarea": {
            fontSize: 16,
            lineHeight: "24px",
        },
        "& .MuiFormLabel-root": {
            fontSize: 16,
            lineHeight: "24px",
            color: "#000"
        },
        "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
            top: "-3px"
        }
    },
    teamTooltip: {
        display: "flex",
        paddingBottom: 35,
        "& .MuiTypography-body2": {
            fontSize: 16,
            lineHeight: "24px",
        },
        "& svg": {
            width: 20,
            height: 20,
            marginRight: 8,
            marginTop: 3,
        }
    },
    teamUser: {
        display: "flex",
        alignItems: "center",
        "& + div": {
            paddingTop: 12,
        }
    },
    teamUserName: {
        paddingLeft: 13,
    },
    titleRoles: {
        display: "flex",
        color: "#636363",
        paddingBottom: 10,
        alignItems: "center",
        "& svg": {
            marginLeft: 3,
            fontSize: 14,
        }
    },
    containerRoles: {
        paddingBottom: 20,
    }
}));

export default useStyles;