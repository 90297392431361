import React from "react";

import {
  FormLabel,
  Popper,
  Box,
  TextField,
  InputAdornment,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import SearchIcon from "../assets/seatch_filter.svg";
import UserCard from "./UserCard";
import moment from "moment";
import useStyles from "../styles/BoardFilter";
import clsx from "clsx";

const BoardFilter = (props) => {
  const {
    members,
    nameFilter,
    setNameFilter,
    assigneeFilter,
    setAssigneeFilter,
    dateFilter,
    setDateFilter,
  } = props;

  const [open, setOpen] = React.useState(false);

  const handleDateChange = (event) => {
    setDateFilter(event.target.value);
  };

  const handleChange = (event) => {
    setAssigneeFilter((assigneeFilter) =>
      assigneeFilter?.reduce((acc, cur) => {
        if (cur.id.toString() === event.target.name) {
          cur.checked = event.target.checked;
        }
        acc = [cur, ...acc];
        return acc;
      }, [])
    );
  };

  const classes = useStyles();

  return (
    <div className={classes.boardFilter}>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        className={clsx(
          classes.boardFilterBtn,
          dateFilter ||
            nameFilter ||
            assigneeFilter.some((f) => f.checked === true)
            ? "isFiltered"
            : ""
        )}
      ></Button>
      <Popper
        className={classes.boardFilterPopper}
        // id={id}
        open={open}
        //anchorEl={anchorEl}
      >
        <Box className={classes.boardFilterContainer}>
          <Typography variant="h1" className={classes.boardFilterTitle}>
            Filter
          </Typography>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            className={classes.boardFilterClose}
          ></Button>
          <Typography variant="body2" className={classes.boardFilterText}>
            Search cards
          </Typography>
          <Grid className={classes.boardFilterSearch}>
            <TextField
              placeholder="Type a term, label or number"
              value={nameFilter}
              onChange={(e) => {
                setNameFilter(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search tasks" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Box sx={{ display: "flex" }}>
            <FormControl
              component="fieldset"
              variant="standard"
              className={classes.boardFilterFilterUser}
            >
              <FormLabel component="legend" className={classes.boardFilterText}>
                Filter by assignee
              </FormLabel>
              <FormGroup>
                {members?.map((m) => {
                  return (
                    <FormControlLabel
                      key={m.id}
                      className={classes.boardFilterItemUser}
                      label={<UserCard user={m.member} />}
                      control={
                        <Checkbox
                          checked={assigneeFilter?.reduce((acc, cur) => {
                            if (cur.id === m?.member?.id) {
                              acc = cur.checked;
                            }
                            return acc;
                          }, false)}
                          onChange={handleChange}
                          name={m?.member?.id.toString()}
                          //checkedIcon={<></>}
                          //icon={<></>}
                        />
                      }
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </Box>
          <Box>
            <FormLabel component="legend" className={classes.boardFilterText}>
              Filter by date
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={dateFilter}
              onChange={handleDateChange}
            >
              <FormControlLabel
                value={JSON.stringify({
                  from: moment(moment()).add(1, "days").format("yyyy-MM-DD"),
                  to: moment(moment()).add(1, "days").format("yyyy-MM-DD"),
                })}
                control={<Radio />}
                label="Due in the next day"
                className={classes.boardFilterItemDate}
              />
              <FormControlLabel
                value={JSON.stringify({
                  from: moment(moment())
                    .add(1, "weeks")
                    .startOf("isoWeek")
                    .format("yyyy-MM-DD"),
                  to: moment(moment())
                    .add(1, "weeks")
                    .endOf("isoWeek")
                    .format("yyyy-MM-DD"),
                })}
                control={<Radio />}
                label="Due in the next week"
                className={classes.boardFilterItemDate}
              />
              <FormControlLabel
                value={JSON.stringify({
                  from: moment(moment())
                    .add(1, "months")
                    .startOf("month")
                    .format("yyyy-MM-DD"),
                  to: moment(moment())
                    .add(1, "months")
                    .endOf("month")
                    .format("yyyy-MM-DD"),
                })}
                control={<Radio />}
                label="Due in the next month"
                className={classes.boardFilterItemDate}
              />
              <FormControlLabel
                value={"overdue"}
                control={<Radio />}
                label="Overdue"
                className={classes.boardFilterItemDate}
              />
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="No due date"
                className={classes.boardFilterItemDate}
              />
            </RadioGroup>
          </Box>
          <Button
            className={classes.boardFilterClear}
            onClick={() => {
              setNameFilter((nameFilter) => "");
              setDateFilter((dateFilter) => null);
              setAssigneeFilter((assigneeFilter) =>
                members?.map((m) => {
                  return {
                    key: m?.member?.id,
                    id: m?.member?.id,
                    checked: false,
                  };
                })
              );
            }}
          >
            Clear all
          </Button>
        </Box>
      </Popper>
    </div>
  );
};

export default BoardFilter;
