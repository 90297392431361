import React from "react";
import PropTypes from "prop-types";
import useStyles from "../styles/DividerWithText";

const DividerWithText = (props) => {
    const {position, children} = props;
    const classes = useStyles();

    return (
      <div className={ classes.container }>
         {position !== "left" ? <div className={classes.border }/>: null}
        <span className={ classes.content }>
          {children}
        </span>
         {position !== "right" ? <div className={ classes.border }/>: null}
      </div>
    );
  };

export default DividerWithText;

DividerWithText.propTypes = {
  position: PropTypes.oneOf(["left, center, right"]).isRequired,
};
