import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "mui-rff";
import { Grid, MenuItem, Button, Typography } from "@material-ui/core";

import {
  formUpdateUserAction,
  formGetReferenceTableValuesAction,
} from "../actions/formActions";
import { Form } from "react-final-form";

import arrowRight from "../assets/creative_test_arrow_right.svg";
import useStyles from "../styles/ProfileCreativeTestStepOne";
import clsx from "clsx";

const ProfileCreativeTest = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-creative-test-results",
      })
    );
  }, [dispatch]);

  const userId = useSelector((state) => state.userReducer.user.id);
  const references = useSelector((state) => state.formReducer.references);

  let testResultOptions = references?.[
    "classifiers-creative-test-results"
  ]?.map((c) => (
    <MenuItem key={c.id} value={c.id}>
      {c.name}
    </MenuItem>
  ));

  return (
      <Grid className={classes.mainContainerStep}>
        <Grid className={classes.centerForm}>
        <Form
          onSubmit={(values) =>
            dispatch(
              formUpdateUserAction({
                submitType: "put",
                endpoint: "/users",
                formValues: values,
                id: userId,
                redirect: "/profile",
              })
            )
          }
          render={({
            handleSubmit,
            submitError,
            form,
            submitting,
            pristine,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container className={classes.containerStepOne}>
                  <Grid item className={classes.stepOneText}>
                    <Typography className={classes.stepOneText1}>
                      Fill in your creative type test results here
                    </Typography>
                    <Grid container className={classes.selectStylesContainer}>
                      <Select
                        name="creative_test_result_id"
                        label="Select your result"
                        formControlProps={{ margin: "none" }}
                        className={classes.selectStyles}
                        required
                      >
                        {testResultOptions ? testResultOptions : <></>}
                      </Select>
                    </Grid>
                    {/*                   <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    <img
                      src={arrowRight}
                      alt="arrow-right"
                      className={clsx(
                          classes.stepOneTextObj,
                          classes.arrowProfileCreative,
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    className={classes.stepOneCircleBtnContainer}
                  >
                    <Button type="submit" className={classes.stepOneCircleBtn}>
                      <Typography className={classes.stepOneCircleBtnText}>
                        SUBMIT
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
      </Grid>
  );
};

export default ProfileCreativeTest;
