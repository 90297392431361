import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        gridGap: "20px",
        gridTemplateColumns: "repeat(1, 1fr)",
        width: "100%",
        display: "grid",
        boxSizing: "border-box",
        maxWidth: "2968px",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(5, 1fr)",
        },
        "@media (min-width:2149px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
        },
        "@media (min-width:2593px)": {
            gridTemplateColumns: "repeat(7, 1fr)",
        },
    },
    profileCardTitle: {
        padding: "80px 0 47px",
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        fontWeight: 500,
        "& span": {
            color: "#BCBCBC",
            paddingLeft: 9
        },
        [theme.breakpoints.up("md")]: {
            padding: "80px 0 49px 122px",
            fontSize: "44px",
            lineHeight: "54px",
        },
    }
}));

export default useStyles;