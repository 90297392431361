import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { Grid, Typography, Button, CircularProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useStyles from "../styles/TeamApply";
import { formGetDataAction, formSubmitAction } from "../actions/formActions";
import ReferenceChips from "../components/ReferenceChips";

const TeamInvitation = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let { roleId } = useParams();

  const data = useSelector((state) => state.formReducer.data);
  const loading = useSelector((state) => state.formReducer.loading);
  const [renderStarted, setRenderStarted] =  useState(false);

  useEffect(() => {
    dispatch(
      formGetDataAction({ endpoint: `opportunity-team-roles/${roleId}` })
    );
  }, [dispatch, roleId]);

  useEffect(() => {
    if(loading){
      setRenderStarted(true);
    }
  },[loading])


  if (loading || !renderStarted) {
    return <CircularProgress className="main-progress-bar" />;
  }

  if (!data) {
    return <Redirect to="/profile" />;
  }

  /*   if (!props.location.state.name) {
    return <Redirect to="/profile" />;
  } */

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.container}>
        <Grid className={classes.teamTitle}>
          <Typography variant="h1">
            You have been invited to join a team
          </Typography>
          <Typography variant="h2" noWrap>
            {data?.opportunity_team?.name}
          </Typography>
        </Grid>
        <Typography variant="body2" className={classes.teamDescription}>
          {data?.opportunity_team?.description}
        </Typography>
        <Grid container className={classes.teamInvitationRole}>
          <Grid item xs={12} md={6}>
            <div className={classes.teamInvitationLabel}>
              Your role
            </div>
            <Typography variant="body2">
              {data?.role?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.teamInvitationOpportunity}>
            <div className={classes.teamInvitationLabel}>
              Call
            </div>
            <Link target="_blank" to={`/calls/${data?.opportunity_team?.opportunity_id}`}>
              {data?.opportunity_team?.opportunity?.title}
            </Link>
          </Grid>
        </Grid>
        <Typography variant="body2" className={classes.teamInvitationLabel}>
          Role responsibilities
        </Typography>
        <Typography variant="body2" className={classes.teamDescription}>
          {data?.description}
        </Typography>
        <Grid container className={classes.teamInvitationReward}>
          <Grid item xs={12} md={6}>
            <ReferenceChips
                title="Creative fields"
                data={data?.opportunity_team?.opportunity?.creative_fields}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.teamInvitationColReward}>
            <Typography className={classes.teamInvitationLabel}>Reward</Typography>
            <Typography variant="body2" className={classes.teamInvitationAmount}>€ {data?.amount}</Typography>
          </Grid>
        </Grid>
        <Grid className="buttons">
          <Button
            variant="contained"
            color="primary"
            className={classes.btnLogin}
            onClick={() => {
              dispatch(
                formSubmitAction({
                  formValues: null,
                  endpoint: `opportunity-team-roles/${roleId}/accept`,
                  redirect: `/calls/${data?.opportunity_team?.opportunity?.id}/teams/${data?.opportunity_team?.id}`,
                })
              );
            }}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="invert"
            //onClick={() => dispatch(push(`/profile`))}
            onClick={() => {
              dispatch(
                formSubmitAction({
                  formValues: null,
                  endpoint: `opportunity-team-roles/${roleId}/decline`,
                  redirect: "/",
                })
              );
            }}
          >
            Deny
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamInvitation;
