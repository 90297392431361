import { takeLatest, takeEvery } from "redux-saga/effects";
import {
  registerSaga,
  loginSaga,
  logoutSaga,
  getUserSaga,
  loginOauthSaga,
  refreshTokenSaga,
} from "./authenticationSaga";

import { registerWebsocketsSaga } from "./wsSaga";

import {
  formSubmitSaga,
  formUpdateUserSaga,
  formGetReferenceTableValuesSaga,
  formGetDataSaga,
  formGetCustomDataSaga,
  formGetHeaderDataSaga,
  formGetStaticPageSaga,
  formSubmitAndGetCustomDataSaga,
  formSubmitAndGetDataSaga,
  formSubmitAndDispatchActionSaga,
} from "./formsSaga";

import {
  resetDataSaga
} from "./dataSaga";

import {
  sendMessageSaga,
  testme
} from "./wsSaga";

import {
  loadMessagesSaga
} from "./chatSaga";

import {
  loadNotificationsSaga,
  reloadNotificationsSaga
} from "./notificationsSaga";

import { errorSaga } from "./errorSaga.js";

import * as types from "../actions";

export default function* watchUserAuthentication() {
  yield takeLatest(types.LOGIN_USER, loginSaga);
  yield takeLatest(types.LOGIN_USER_SUCCESS, getUserSaga);
   yield takeLatest(
    [
      types.LOGIN_USER_SUCCESS,
      types.LOGIN_USER_GOOGLE_SUCCESS,
      types.LOGIN_USER_FACEBOOK_SUCCESS,
      types.REFRESH_TOKEN_SUCCESS,
    ],
    registerWebsocketsSaga
  );
  yield takeLatest(
    [
      types.GET_USER_SUCCESS,
    ],
    testme
  );

  yield takeEvery([types.WS_SEND_MESSAGE], sendMessageSaga);

  yield takeLatest(
    [types.LOGIN_USER_GOOGLE_SUCCESS, types.LOGIN_USER_FACEBOOK_SUCCESS],
    loginOauthSaga
  );

  yield takeLatest([types.LOGOUT_USER, types.REFRESH_TOKEN_ERROR], logoutSaga);
  yield takeLatest([types.RESET_DATA], resetDataSaga);

  yield takeLatest(
    [
      types.REGISTER_USER_ERROR,
      types.LOGIN_USER_ERROR,
      types.GET_USER_ERROR,
      //types.REFRESH_TOKEN_ERROR,
      types.FORM_SUBMIT_ERROR,
      types.FORM_GET_DATA_ERROR,
      types.FORM_GET_CUSTOM_DATA_ERROR,
      types.FORM_GET_HEADER_DATA_ERROR,
      types.FORM_GET_STATIC_PAGE_ERROR,
      types.WS_SEND_MESSAGE_ERROR,
    ],
    errorSaga
  );

  yield takeLatest(
    [types.REFRESH_TOKEN_SUCCESS, types.LOGIN_OAUTH_SUCCESS],
    getUserSaga
  );
  yield takeLatest(types.REFRESH_TOKEN, refreshTokenSaga);

  yield takeLatest(types.REGISTER_USER, registerSaga);

  yield takeLatest(types.FORM_SUBMIT, formSubmitSaga);
  yield takeLatest(types.FORM_SUBMIT_AND_GET_CUSTOM_DATA, formSubmitAndGetCustomDataSaga);
  yield takeLatest(types.FORM_SUBMIT_AND_GET_DATA, formSubmitAndGetDataSaga);
  yield takeLatest(types.FORM_SUBMIT_AND_DISPATCH_ACTION, formSubmitAndDispatchActionSaga);
  yield takeLatest(types.FORM_UPDATE_USER, formUpdateUserSaga);

  yield takeLatest(types.FORM_GET_DATA, formGetDataSaga);
  yield takeEvery(types.FORM_GET_CUSTOM_DATA, formGetCustomDataSaga);
  yield takeLatest(types.FORM_GET_STATIC_PAGE, formGetStaticPageSaga);
  yield takeEvery(types.FORM_GET_HEADER_DATA, formGetHeaderDataSaga);

  yield takeEvery(
    types.FORM_GET_REFERENCE_VALUES,
    formGetReferenceTableValuesSaga
  );
  /*   yield takeLatest(types.GET_USER, getUserSaga); */
  // yield takeLatest(types.GET_USER_ERROR, refreshTokenSaga);


  yield takeEvery(types.LOAD_MESSAGES, loadMessagesSaga);
  yield takeEvery(types.LOAD_NOTIFICATIONS, loadNotificationsSaga);
  yield takeEvery(types.RELOAD_NOTIFICATIONS, reloadNotificationsSaga);
}

