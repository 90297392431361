import React from "react";

import {
  Grid,
  Typography,
} from "@material-ui/core";
import useStyles from "../styles/CreationOpportunity";
import useStylesSingleCard from "../styles/SingleCard";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { getUnifiedDate } from "../utils/misc";

const OpportunityHeader = (props) => {
  const { data } = props;

  const classes = useStyles();
  const classesSingleCard = useStylesSingleCard();
  const history = useHistory();
  return (
    <Grid item xs={12} className={classes.opportunityHeader}>
      <Grid className={classes.creationContainer}>
        <Grid>
          <span
            className={classes.creationGoBack}
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </span>
          <div className={classes.creationType}>Call</div>
          <Grid container>
            <Grid item>
              <Typography variant="h1" className={classes.creationTitleBlock}>
                {data.title}
                <div
                  className={clsx(
                    classesSingleCard.status,
                    classes.creationStatus,
                    data?.status?.code
                  )}
                >
                  {data?.status?.name}
                </div>
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.creationDate}>
            {getUnifiedDate(data.created_at)}
          </Grid>
        </Grid>
        <Grid className={classes.creationDateBlock}>
          <Grid item className={classes.cretionDateItem}>
            <Typography variant="h3">
              Start date
            </Typography>
            <Typography variant="body2">
              {getUnifiedDate(data?.start_date)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3">
              End date
            </Typography>
            <Typography variant="body2">
              {getUnifiedDate(data?.end_date)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityHeader;
