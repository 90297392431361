import React, { useEffect } from "react";
import InnerHTML from "dangerously-set-html-content";
import { formGetStaticPageAction } from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import CircularProgress from "@material-ui/core/CircularProgress";

const StaticPage = (props) => {
  const { endpoint } = props;
  const dispatch = useDispatch();
  const router = useSelector((state) => state.router);

  let search = router.location.search ? router.location.search : "";

  useEffect(() => {
    dispatch(
      formGetStaticPageAction({
        endpoint: `${endpoint}${search}`,
        name: "data",
      })
    );
  }, [dispatch, endpoint, search]);

  const data = useSelector((state) => state.formReducer.staticPage);

  if (!data) {
    return <CircularProgress className="main-progress-bar" />;
  }

  return (
    <>
      {/*<div dangerouslySetInnerHTML={{ __html: data }} className="static-page"/>*/}
      <Helmet>
        <title>{` ${props.title} | weCreators`}</title>
      </Helmet>
      <InnerHTML html={data} className="static-page" />
    </>
  );
};

export default StaticPage;
