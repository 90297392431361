import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    errorCode: {
        fontSize: 44,
        lineHeight: "54px",
        marginTop: 140,
        marginLeft: 265,
        fontWeight: 400,
        fontFamily: "Gilroy",
        color: "white",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 23,
            marginTop: 90,
        },
    },
    errorHelper: {
        fontSize: 44,
        lineHeight: "54px",
        marginLeft: 265,
        fontWeight: 400,
        fontFamily: "Gilroy",
        color: "rgba(188,188,188,1)",
        [theme.breakpoints.down("sm")]: {
            marginLeft: 23,
        },
    },
    errorContainer: {
        display: "flex",
        alignItems: "flex-start",
        paddingBottom: 50,
        background: "#000000",
        flexGrow: 1,
        [theme.breakpoints.down("xs")]: {
            minHeight: "calc(100vh - 284px)",
        },
        [theme.breakpoints.up("sm")]: {
            minHeight: "calc(100vh - 244px)",
        },
        [theme.breakpoints.up("md")]: {
            minHeight: "calc(100vh - 108px)",
        },
    },
    btnHome: {
        borderRadius: 0,
        width: 140,
        height: 40,
        marginLeft: 265,
        outline: "none",
        border: "none",
        color: "#000",
        marginTop: 31,
        background: "#fff",
        cursor: "pointer",
        fontFamily: "Gilroy",
        fontSize: 14,
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
            marginLeft: 23,
        },
    },
    yellowLineTeam: {
        height: 128,
        width: 3345,
        background: "#F7CC63",
        transform: "rotate(15deg)",
        position: "absolute",
        bottom: 0,
        left: -324,
        [theme.breakpoints.up("md")]: {
            height: 172,
        },
    },
    purpleLineTeam: {
        height: 128,
        width: 3245,
        background: "#6233C0",
        transform: "rotate(-15deg)",
        position: "absolute",
        bottom: 300,
        left: -314,
        whiteSpace: "nowrap",
        overflow: "hidden",
        "& div>span": {
            color: "#fff",
        },
        [theme.breakpoints.up("md")]: {
            height: 172,
        },
    },
    purpleLine: {
        height: 128,
        width: 3345,
        background: "#6233C0",
        transform: "rotate(15deg)",
        position: "relative",
        top: 435,
        left: -324,
        [theme.breakpoints.up("md")]: {
            height: 172,
        },
    },
    yellowLine: {
        height: 128,
        width: 3245,
        background: "#F7CC63",
        transform: "rotate(-15deg)",
        position: "relative",
        top: -338,
        left: -314,
        whiteSpace: "nowrap",
        [theme.breakpoints.up("md")]: {
            height: 172,
            top: -270,
        },
    },
    yellowLineContainer :{
        animation: "marquee 20s linear infinite",
        animationPlayState: "running",
        height: 128,
        display: "inline-flex",
        "&>span": {
            fontWeight: 600,
            fontSize: 100,
            color: "#000",
            fontFamily: "Gilroy",
            marginRight: 40,
            display: "inline-block",
            lineHeight: "128px",
        },
        [theme.breakpoints.up("md")]: {
            height: 172,
            "&>span": {
                fontSize: 140,
                lineHeight: "172px",
            },
        },
    },
    "@keyframes marquee": {
        "0%": {
            transform: "transform: translate3d(calc(-25% + 25%), 0, 0)"
        },
        "100%": {
            transform: "translate3d(calc(-50% + 25%), 0, 0)"
        }
    },
    "@-webkit-keyframes marquee": {
        "0%": {
            webkitTransform: "translate(0, 0)"
        },
        "100%": {
            webkitTransform: "translate(-100%, 0)"
        }
    },
}));

export default useStyles;