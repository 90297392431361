import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formGetReferenceTableValuesAction } from "../actions/formActions";
import { CircularProgress } from "@material-ui/core";
import CreationForm from "../forms/CreationForm";

const CreationFormWrapper = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-purchase-types",
      })
    );
    dispatch(
      formGetReferenceTableValuesAction({ endpoint: "classifiers-award-types" })
    );
  }, [dispatch]);

  const references = useSelector((state) => state.formReducer.references);
  if (
    !references?.["classifiers-purchase-types"] ||
    !references?.["classifiers-award-types"]
  ) {
    return <CircularProgress className="main-progress-bar" />;
  }

  return <CreationForm {...props} />;
};

export default CreationFormWrapper;
