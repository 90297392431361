import React from "react";
import { Field, FormSpy } from "react-final-form";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import SkillsAutocomplete from "../components/SkillsAutocomplete";
import { Paper, Grid, Typography } from "@material-ui/core";
import { formUpdateUserAction } from "../actions/formActions";
import { makeValidate } from "mui-rff";
import useStyles from "../styles/ProfileSkills";
import arrowRight from "../assets/creative_test_arrow_right.svg";
import * as Yup from "yup";

const castValues = (values) => {
  values.interest_areas = values.interest_areas.map((v) => v.id);
  values.skills = values.skills.map((v) => v.id);

  return values;
};

const schemaPage1 = Yup.object().shape({
  skills: Yup.array().min(1, "Select your skills"),
});
const validatePage1 = makeValidate(schemaPage1);

const schemaPage2 = Yup.object().shape({
  interest_areas: Yup.array().min(1, "Select your interest areas"),
});
const validatePage2 = makeValidate(schemaPage2);

const ProfileSkills = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);

  return (
    <Paper className={classes.centerForm}>
      <div className={classes.paperForm}>
        <Wizard
          noCancel={true}
          saveTitle="Next"
          initialValues={{
            user_id: user.id,
            skills: user.skills,
            interest_areas: user.interest_areas,
          }}
          onSubmit={(values) =>
            dispatch(
              formUpdateUserAction({
                submitType: "put",
                endpoint: "/users",
                formValues: castValues(values),
                id: user.id,
                redirect: "/profile",
              })
            )
          }
        >
          <Wizard.Page validate={validatePage1}>
            <FormSpy subscription={{ values: "skills" }}>
              {(spyProps) => {
                return (
                  <Grid container>
                    <Grid item xs={12} className={classes.selectHeader}>
                      <Typography className={classes.selectHeaderText}>
                        Select your skills
                      </Typography>
                      <img
                        src={arrowRight}
                        alt="arrow-right"
                        className={classes.selectHeaderObj}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="skills">
                        {(props) => {
                          return (
                            <SkillsAutocomplete
                              reference="classifiers-skills"
                              record={spyProps.values.skills}
                              title="skills"
                              multiple
                              {...props.input}
                              {...props.meta}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                );
              }}
            </FormSpy>
          </Wizard.Page>
          <Wizard.Page validate={validatePage2}>
            <FormSpy subscription={{ values: "interest_areas" }}>
              {(spyProps) => {
                return (
                  <Grid container>
                    <Grid item xs={12} className={classes.selectHeader}>
                      <Typography className={classes.selectHeaderText}>
                        Select your interests
                      </Typography>
                      <img
                        src={arrowRight}
                        alt="arrow-right"
                        className={classes.selectHeaderObj}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="interest_areas">
                        {(props) => {
                          return (
                            <SkillsAutocomplete
                              reference="classifiers-interest-areas"
                              record={spyProps.values.interest_areas}
                              title="interests"
                              multiple
                              {...props.input}
                            />
                          );
                        }}
                      </Field>
                    </Grid>
                  </Grid>
                );
              }}
            </FormSpy>
          </Wizard.Page>
        </Wizard>
      </div>
    </Paper>
  );
};

export default ProfileSkills;
