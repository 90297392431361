import React from "react";
import { useState } from "react";
import ProfileCreativeTestStepOne from "../components/ProfileCreativeTestStepOne";
import { default as Form } from "../forms/ProfileCreativeTest";

const ProfileCreativeTest = () => {
  const [formOpen, setFormOpen] = useState(false);

  return formOpen ? (
    <Form />
  ) : (
    <ProfileCreativeTestStepOne setFormOpen={setFormOpen} />
  );
};

export default ProfileCreativeTest;
