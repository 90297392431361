import React, { useState } from "react";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import ImageDropzone from "../components/ImageDropzone";
import FileDropzone from "../components/FileDropzone";
import { TextField, Radios, Select, makeValidate } from "mui-rff";
import { Paper, Grid, MenuItem, Button } from "@material-ui/core";
import { useEffect } from "react";
import { Form, Field, FormSpy } from "react-final-form";
import UserCard from "../components/UserCard";
import CoverImageModal from "../components/CoverImageModal";

import {
  formSubmitAction,
  formGetReferenceTableValuesAction,
  formUpdateUserAction,
  formGetCustomDataAction,
  formSubmitAndGetCustomDataAction,
} from "../actions/formActions";
import Editor from "../components/Editor";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Typography } from "@material-ui/core";

import CustomAutocomplete from "../components/CustomAutocomplete";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
//import InfoIcon from '@mui/icons-material/Info';
import useStyles from "../styles/IdeationPostForm";
import { Helmet } from "react-helmet";
import MultipleSelectChips from "../components/MultipleSelectChips";
import AttachmentChips from "../components/AttachmentChips";
import DarkTooltip from "../components/DarkTooltip";
import * as Yup from "yup";
import { validate } from "@material-ui/pickers";

const schemaBody = Yup.object().shape({
  //title: Yup.string().required("Title is required"),
  body: Yup.object().shape({
    blocks: Yup.array().min(1, "Body text required"),
  }),
});
const validateBody = makeValidate(schemaBody);

const castValues = (data) => {
  let cover = data?.cover
    ? data?.cover?.b64
      ? { b64: data?.cover?.b64, name: data?.cover?.name }
      : data?.cover
    : null;
  data.cover = cover;
  data.cover_id = data.cover ? data.cover.id : null;
  data.body = JSON.stringify(data.body);

  return data;
};

const IdeationPostForm = (props) => {
  const { initialValues,edit, handleCloseForm } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [optionsChip, setOptionsChip] = React.useState([]);
  const [valueChip, setValueChip] = React.useState([]);
  const [errorChip, setErrorChip] = React.useState("");
  const [open, setOpen] = useState(false);

  const references = useSelector((state) => state?.formReducer?.references);

  const user = useSelector((state) => state.userReducer.user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid className={classes.postForm}>
      <Form
        validate={validateBody}
        onSubmit={async (values) => {

          values.interest_areas = valueChip;
          if (valueChip.length == 0) {
            setErrorChip("Must choose at least one “interest area” tags");
          }
          else {

              if (edit) {
                dispatch(
                  formSubmitAndGetCustomDataAction({
                      submitType: "put",
                      endpoint: `/posts/${values.id}`,
                      formValues: castValues(values),
                      getEndpoint: "/posts",
                      name: "posts"
                  })
                );

              } else {
                dispatch(
                  formSubmitAndGetCustomDataAction({
                      submitType: "post",
                      endpoint: `/posts`,
                      formValues: castValues(values),
                      getEndpoint: "/posts",
                      name: "posts"
                  })
                );
              }
              handleCloseForm();



          }

        }}
        initialValues={initialValues ? initialValues : { cover: null }}
        render={({
          submitError,
          handleSubmit,
          reset,
          submitting,
          pristine,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.paperTitle}>
                  {edit ? "Edit the post" : "Create new post"}
                </Typography>
                <div className={classes.postAuthor}>
                  <UserCard user={user} userLink="true" />
                </div>
                <div className={classes.postLabel}>Cover picture</div>
                <Grid className={classes.paperCover}>
                  <Field name="cover" required={true}>
                    {(props) => {
                      return (
                        <div className={classes.postDropzone}>
                          <CoverImageModal {...props.input}
                            mainText="Drag and drop your group cover image"
                            subText="or <b>browse</b> to choose a file 660x400 px </br>JPEG, PNG"
                            width={257}
                            height={221}
                          />
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
                <Typography variant="body2" className={classes.postLabel}>
                  Message
                </Typography>
                <div className={classes.postBodyField}>
                  <TextField
                    placeholder="Title Text"
                    name="title"
                    required={true}
                    InputLabelProps={{ className: classes.postTitleLabel }}
                    InputProps={{
                      className: classes.postTitle,
                      disableUnderline: true,
                    }}
                  />
                  <Field name="body">
                    {(props) => {
                      return (
                        <div>
                          <Editor {...props.input}  {...props.meta}/>
                        </div>
                      );
                    }}
                  </Field>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.postLabel}>
                  Add interests to your post
                </div>
                <div className={classes.postInterestsList}>
                    <MultipleSelectChips
                      className={classes.postChipsFilter}
                      //label="Label"
                      singleValue={false}
                      value={valueChip}
                      setValue={setValueChip}
                      options={references?.["classifiers-interest-areas"]?.map(v=>({label: v.name, value :v.id}))}
                      error={errorChip}
                      setError={setErrorChip}
                      limit={3}
                      initValue={initialValues?.interest_areas?.map(v=>v.id)}
                    />
                  <DarkTooltip
                    className={classes.postInterestsTooltip}
                    title="Tags are mandatory for other team member to find your post."
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6} className={classes.postDropzone}>
                <Field name="files">
                  {(props) => {
                    return (
                      <div>
                        <FileDropzone {...props.input} />
                      </div>
                    );
                  }}
                </Field>
              </Grid>
            </Grid>
            <Grid className="buttons">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting }
              // disabled={submitting || pristine || !values?.cover_image?.length}
              >
                Post
              </Button>
            </Grid>
          </form>
        )}
      ></Form>
    </Grid>
  );
};

export default IdeationPostForm;
