import React from "react";
import {Grid, Typography, Link, ListItemText} from "@material-ui/core";
import {formatBytes, getTime, swiftWrapper} from "../utils/misc";
import useStyles from "../styles/MessageFileAttachment";

const MessageFileAttachment = (props) => {
  const { date, file, msg } = props;
  const classes = useStyles();
  return (
      <Grid>
        <Grid className={classes.messageFileAttachments}>
            <Grid>
                <Typography variant="h6" className={classes.messageFileName}>
                    {file.name}
                </Typography>
                <Grid className={classes.messageFileInfo}>
                    <Typography variant="h6" noWrap>
                        {formatBytes(file.size)}&nbsp;–&nbsp;
                        <Link target="_blank" href={swiftWrapper(file.link)} download={file.name}>
                            Download
                        </Link>
                    </Typography>
                    <Typography variant="h6" noWrap className={classes.messageFileDate}>
                        {date}
                    </Typography>
                </Grid>
            </Grid>
            <Link target="_blank" href={swiftWrapper(file.link)} download={file.name} className={classes.messageFileAttachmentsBtn}></Link>
        </Grid>
          {msg.body ? (
              <Grid className={classes.messageComment}>
                  <ListItemText
                      primary={msg.body}
                  ></ListItemText>
                  <Typography variant="h6" noWrap className={classes.messageFileDate}>
                      {getTime(msg?.datetime)}
                  </Typography>
              </Grid>
          ) : null}
      </Grid>
  );
};

export default MessageFileAttachment;
