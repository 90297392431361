import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "../styles/UserCard";
import ProfileAvatar from "./ProfileAvatar";

const UserCard = (props) => {
  const classes = useStyles();
  const { direction } = props;

  return (
    <Grid container>
      <Grid item xs={12} className={classes.userMenuIconContainer}>
        {props.userLink ? (
          <Link
            className={classes.userMenuLinkName}
            to={`/profile/${props.user?.id}`}
          >
            <div
              className={classes.userMenuNameBlock}
              style={{ flexDirection: direction ? direction : "row" }}
            >
              <ProfileAvatar
                imageLink={props.user?.profile_image?.link}
                user={props.user}
              />
              <Typography variant="body1" className={classes.userMenuName}>
                {props.user?.name}
              </Typography>
            </div>
          </Link>
        ) : (
          <div className={classes.userMenuNameBlock}>
            <ProfileAvatar
              imageLink={props.user?.profile_image?.link}
              user={props.user}
            />
            <Typography variant="body1" className={classes.userMenuName}>
              {props.user?.name}
            </Typography>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
export default UserCard;
