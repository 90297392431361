import React from "react";
import useStyles from "../styles/TeamApply";
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Typography,
  Button,
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

import { useEffect } from "react";
import { formGetDataAction } from "../actions/formActions";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";

import { formSubmitAction } from "../actions/formActions";
import { Helmet } from "react-helmet";

const TeamApply = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let { opportunityId, teamId } = useParams();
  useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: `opportunities/${opportunityId}/teams/${teamId}`,
      })
    );
  }, [dispatch, opportunityId, teamId]);

  const data = useSelector((state) => state.formReducer.data);

  return data ? (
    <>
      <Helmet>
        <title>{`${data?.title} | Teams | weCreators`}</title>
      </Helmet>
      <Grid className={classes.mainContainer}>
        <Grid className={classes.container}>
          <Form
            onSubmit={(values) => {
              values.role_id = Number.parseInt(values.role_id);
              dispatch(
                formSubmitAction({
                  formValues: values,
                  endpoint: `teams/${teamId}/requests`,
                  redirect: "/team-apply/success",
                  state: { name: data.name, description: data.description },
                })
              );
              //dispatch(loginUserAction(values));
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signin" onSubmit={handleSubmit}>
                <Grid className={classes.teamTitle}>
                  <Typography variant="h1" noWrap>
                    Apply for a team
                  </Typography>
                  <Typography variant="h2" noWrap>
                    {data.name}
                  </Typography>
                </Grid>
                <Typography variant="body2" className={classes.teamDescription}>
                  {data.description}
                </Typography>
                <Grid>
                  <Field name="role_id">
                    {(props) => {
                      return (
                        <FormControl
                          component="fieldset"
                          className={classes.radioControl}
                        >
                          <FormLabel component="legend">
                            Choose your role
                          </FormLabel>
                          <RadioGroup name="availableRoles" {...props.input}>
                            {data?.["unusedRoles"]?.map((ot) => {
                              return (
                                <FormControlLabel
                                  key={ot.id}
                                  value={ot.id.toString()}
                                  control={<Radio />}
                                  label={ot?.role?.name}
                                />
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      );
                    }}
                  </Field>
                </Grid>
                <Grid>
                  <TextField
                    id="cover_letter"
                    name="cover_letter"
                    label="Cover letter"
                    variant="outlined"
                    multiline
                    required
                    className={classes.teamCover}
                  />
                </Grid>
                {data.opportunity.opportunity_type?.description ? (
                  <Grid className={classes.teamTooltip}>
                    <InfoOutlinedIcon />
                    <Typography variant="body2">
                      {data.opportunity.opportunity_type?.description}
                    </Typography>
                  </Grid>
                ) : null}
                <Grid className="buttons">
                  <Button type="submit" variant="contained" color="primary">
                    Apply
                  </Button>
                  {/*                   <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          dispatch(push(`/opportunity/${opportunityId}`))
                        }
                      >
                        See opportunity
                      </Button> */}
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Grid>
    </>
  ) : null;
};

export default TeamApply;
