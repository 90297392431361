import React, { useEffect, useCallback } from "react";
import { TextField, Select, DatePicker } from "mui-rff";
import { Button, Typography, Grid, MenuItem } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import { Field } from "react-final-form";
import FileDropzone from "../components/FileDropzone";
import UserCard from "../components/UserCard";
import TaskAssigneeSelect from "../components/TaskAssigneeSelect";
import MessageInput from "../components/MessageInput";
import { formSubmitAction } from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import Comments from "../components/Comments";
import {
  formGetCustomDataAction,
  formSubmitAndGetCustomDataAction,
} from "../actions/formActions";
import useStyles from "../styles/CreationOpportunityTeamForm";
import clsx from "clsx";
import ConfirmationDialog from "../components/ConfirmationDialog";
import TaskHistory from "../components/TaskHistory";

const BoardCardMasterCreator = (props) => {
  const {
    cardToEdit,
    statuses,
    stages,
    pristine,
    teamId,
    members,
    setCardToEdit,
    setColumnToEdit,
    setOpen,
    tasks,
  } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  useEffect(() => {
    async function getData() {
      if (cardToEdit?.id) {
        await dispatch(
          formGetCustomDataAction({
            endpoint: `tasks/${cardToEdit.id}/comments`,
            name: "comments",
          })
        );
      }
    }
    getData();
  }, [dispatch, cardToEdit]);

  const handleCancel = useCallback(() => {
    setColumnToEdit((newValue) => null);
    setCardToEdit((newValue) => null);
    setOpen((newValue) => false);
  }, [setColumnToEdit, setCardToEdit, setOpen]);

  const comments = useSelector(
    (state) => state.formReducer.customData?.comments
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          id="title"
          name="title"
          label="Task title"
          variant="filled"
          required
          className={classes.teamTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
          name="description"
          label="Description"
          multiline
          variant="outlined"
          required
          className={classes.teamTextField}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          fullWidth
          dateFunsUtils={MomentUtils}
          name="due_date"
          variant="inline"
          label="Due date"
          format="yyyy-MM-DD"
          //required={true}
          className={classes.teamTextField}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(
          classes.CreationSelectBlock,
          classes.teamBoardSelect,
          classes.teamBoardSelectStage
        )}
      >
        {tasks ? (
          <Select
            name="parent_id"
            label="Parent task"
            formControlProps={{ margin: "none" }}
          >
            {tasks?.reduce((acc, cur) => {
              if (cur.id !== cardToEdit?.id) {
                acc = [
                  ...acc,
                  <MenuItem key={cur.id} value={cur.id}>
                    {cur.title}
                  </MenuItem>,
                ];
              }
              return acc;
            }, [])}
          </Select>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(classes.CreationSelectBlock, classes.teamBoardSelect)}
      >
        {statuses ? (
          <Select
            name="status_id"
            label="Status"
            formControlProps={{ margin: "none" }}
            required={true}
          >
            {statuses?.map((or) => (
              <MenuItem key={or.id} value={or.id}>
                {or.name}
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(
          classes.CreationSelectBlock,
          classes.teamBoardSelect,
          classes.teamBoardSelectStage
        )}
      >
        {stages ? (
          <Select
            name="stage_id"
            label="Stage"
            formControlProps={{ margin: "none" }}
            required={true}
          >
            {stages?.map((or) => (
              <MenuItem key={or.id} value={or.id}>
                {or.title}
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </Grid>
      {cardToEdit?.id ? (
        <Grid
          item
          xs={12}
          md={6}
          className={clsx(
            classes.CreationSelectBlock,
            classes.teamBoardReporter
          )}
        >
          <Typography
            variant="body2"
            className={classes.teamBoardReporterTitle}
          >
            Reporter
          </Typography>
          <UserCard user={cardToEdit?.created_by} />
        </Grid>
      ) : null}
      <Grid
        item
        xs={12}
        md={6}
        className={clsx(classes.CreationSelectBlock, classes.teamBoardSelect)}
        style={{ paddingLeft: cardToEdit?.id ? 20 : 0 }}
      >
        <Field name="assignee_id">
          {(props) => {
            return <TaskAssigneeSelect {...props.input} options={members} />;
          }}
        </Field>
      </Grid>
      {cardToEdit?.id ? (
        <Grid className={classes.teamBoardComment}>
          <Typography variant="body2" className={classes.creationTitleField}>
            Comments
          </Typography>
          <Grid className={classes.teamCommentForm}>
            <Grid className={classes.teamCommentFormContainer}>
              <Comments
                comments={comments?.map((c) => {
                  c.user = c.created_by;
                  c.datetime = c.created_at;
                  return c;
                })}
              />
            </Grid>
            <MessageInput
              sendAction={(dataToSend) => {
                dispatch(
                  formSubmitAndGetCustomDataAction({
                    formValues: dataToSend,
                    endpoint: `tasks/${cardToEdit.id}/comments`,
                    getEndpoint: `tasks/${cardToEdit.id}/comments`,
                    name: "comments",
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid xs={12} md={6}></Grid>
      )}

      {cardToEdit?.id ? <TaskHistory history={cardToEdit.history} /> : null}
      <Grid item xs={12} md={6} className={classes.teamBoardDropzone}>
        <Field name="files">
          {(props) => {
            return (
              <div>
                <FileDropzone {...props.input} />
              </div>
            );
          }}
        </Field>
      </Grid>
      <Grid item xs={12} className="buttons">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={pristine}
        >
          {cardToEdit?.id ? "Save" : "Create"}
        </Button>
        <Button onClick={handleCancel} className="invert">
          Cancel
        </Button>
        {cardToEdit?.id ? (
          <Button className="invert" onClick={() => setConfirmationOpen(true)}>
            Delete
          </Button>
        ) : null}
      </Grid>
      <ConfirmationDialog
        open={confirmationOpen}
        setOpen={setConfirmationOpen}
        text={`Are you sure you want to delete this task?`}
        confirmAction={() => {
          dispatch(
            formSubmitAction({
              formValues: null,
              submitType: "delete",
              endpoint: `teams/${teamId}/tasks/${cardToEdit?.id}`,
            })
          );
          dispatch(
            formGetCustomDataAction({
              endpoint: `teams/${props.teamId}/tasks`,
              name: "tasks",
            })
          );
          setColumnToEdit(null);
          setCardToEdit(null);
          setOpen(false);
        }}
      />
    </Grid>
  );
};

export default BoardCardMasterCreator;
