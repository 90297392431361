import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import useStyles from "../views/global/header/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "../assets/search_filter.svg";
import CloseIcon from "../assets/close.svg";

import useStylesHome from "../styles/Home";
import clsx from "clsx";

const HomeFilterType = (props) => {
  const classes = useStyles();
  const homeClasses = useStylesHome();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {
    if (value !== null) {
      props.setValue(value);
    }
  };

  const handleMenuOpen = () => {
    setAnchorEl(document.getElementById("toggleFilterContainer"));
  };

  return props.mobile ? (
    <>
      <div className={classes.sectionDesktop}>

        <ToggleButtonGroup
          className={homeClasses.filterButtonGroup}
          value={props.value}
          exclusive
          onChange={handleChange}
          aria-label="Type"
        >
          {props.choices?.map((o) => (
            <ToggleButton
              className={homeClasses.filterButton}

              key={o.label}
              value={o.value}
              aria-label="left aligned"
            >
              <Typography variant="body2"

                className={homeClasses.filterLabel}
              >{o.label}</Typography>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          edge="end"
          aria-label="filter"
          aria-controls="homeFilterMobile"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          className="filter-button"
        >
          {isMenuOpen ? <img src={CloseIcon} alt="Close"/> : <img src={FilterListIcon} alt="Filter"/>}
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          id="homeFilterMobile"
          keepMounted
          //transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className={homeClasses.mobileMenu}
        >
          <MenuItem className={homeClasses.mobileMenuList}>
            <ToggleButtonGroup

              className={clsx(
                homeClasses.filterButtonGroup,
                homeClasses.filterButtonGroupMobile,
              )}
              orientation="vertical"
              value={props.value}
              exclusive
              onChange={handleChange}
              aria-label="Type"
            >
              {props.choices?.map((o) => (
                <ToggleButton
                  className={homeClasses.filterButton}
                  key={o.label}
                  value={o.value}
                  aria-label="left aligned"
                >
                  <Typography variant="body2"

                    className={homeClasses.filterLabel}
                  >{o.label}</Typography>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </MenuItem>
        </Menu>
      </div>
    </>
  ) : (
    <ToggleButtonGroup
      value={props.value}
      exclusive
      onChange={handleChange}
      aria-label="Type"
      className={homeClasses.filterButtonGroup}
    >
      {props.choices?.map((o) => (
        <ToggleButton key={o.label} value={o.value} aria-label="left aligned"
          className={homeClasses.filterButton}
        >
          <Typography variant="body2"

            className={homeClasses.filterLabel}
          >{o.label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default HomeFilterType;
