import http from "../http";

export const formSubmitService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  try {
    let request = null;
    switch (payload.submitType) {
      case "put":
        request = () => {
          return http.put(ENDPOINT, payload.formValues);
        };
        break;
      case "delete":
        request = () => {
          return http.delete(ENDPOINT, payload.formValues);
        };
        break;
      default:
        request = () => {
          return http.post(ENDPOINT, payload.formValues);
        };
    }
    const response = await request();
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const formGetReferenceTableValuesService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  let params = {};
  if (payload.filter) {
    params = { filter: JSON.stringify(payload.filter) };
  }
  try {
    let { data } = await http.get(ENDPOINT, { params });
    data.endpoint = ENDPOINT;
    return data;
  } catch (error) {
    throw error;
  }
};

export const formGetCustomDataService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  let params = {};
  if (payload.filter) {
    params = { filter: JSON.stringify(payload.filter) };
  }
  try {
    let { data } = await http.get(ENDPOINT, { params });
    data.name = payload.name;
    return data;
  } catch (error) {
    throw error;
  }
};

export const formGetStaticPageService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  let params = {};
  if (payload.filter) {
    params = { filter: JSON.stringify(payload.filter) };
  }
  try {
    let   { data }  = await http.get(ENDPOINT, { params });
    let result = {}
    result.data = data
    result.name = payload.name;
    return result;
  } catch (error) {
    throw error;
  }
};

export const formGetHeaderDataService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  let params = {};
  if (payload.filter) {
    params = { filter: JSON.stringify(payload.filter) };
  }
  try {
    let { data } = await http.get(ENDPOINT, { params });
    data.name = payload.name;
    return data;
  } catch (error) {
    throw error;
  }
};

export const formUpdateUserService = async (payload) => {
  const ENDPOINT = `/users/${payload.id}`;
  try {
    let request = null;
    switch (payload.submitType) {
      case "put":
        request = () => {
          return http.put(ENDPOINT, payload.formValues);
        };
        break;
      default:
        request = () => {
          return http.post(ENDPOINT, payload.formValues);
        };
    }
    const { data } = await request();
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const formGetDataService = async (payload) => {
  const ENDPOINT = payload.endpoint;
  try {
    let params = {};
    if (payload.filter) {
      params = { filter: JSON.stringify(payload.filter) };
    }
    let { data } = await http.get(ENDPOINT, { params });
    data.endpoint = ENDPOINT;
    return data;
  } catch (error) {
    throw error;
  }
};
