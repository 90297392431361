import React from "react";
import {
  List,
  ListItem,
  Typography,
  Grid,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import MessageFileAttachment from "./MessageFileAttachment";
import ProfileAvatar from "./ProfileAvatar";
import useStyles from "../styles/Team";
import { useSelector } from "react-redux";
import { getTime, getUnifiedDate } from "../utils/misc";

const MessagesList = (props) => {

  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);
  let group = 1;
  const messages = props.data;

   let groupedMessages = messages
    ? Object.values(
        messages?.reduce((acc, cur, index) => {
          cur.separator = getUnifiedDate(cur.datetime);
          if (
            (messages[index - 1]?.datetime &&
              getUnifiedDate(cur.datetime) !==
                getUnifiedDate(messages[index - 1].datetime)) ||
            index === 0
          ) {
            group++;
          }
          if (cur.user?.id !== messages[index - 1]?.user?.id) {
            group++;
          }

          return {
            ...acc,
            [group]: {
              messages: [...(acc[group]?.messages || []), cur],
              separator: cur.separator,
            },
          };
        }, {})
      ).map((value) => value)
    : [];
  return (
    <List className="messageList">
      {groupedMessages?.map((group, index) => {
        let groupLen = group?.messages?.length;
        return (
          <ListItem key={index} className={classes.messageGroupContainer}>
            {group.separator !== groupedMessages?.[index-1]?.separator ?  (
              <Typography variant="body2" className={classes.messageGroupDate}>
                {group.separator}
              </Typography>
            ) : null}
            <List className={classes.messageGroup}>
              {group?.messages?.map((msg, i) => {
                let classMessage =
                  msg?.user?.id === user?.id ? "messageMine" : "messageTheirs";
                let avatarNeeded = msg?.user?.id !== user?.id;
                return (
                  <ListItem
                    key={`${msg?.user?.id}-${msg?.id}=${msg?.datetime}`}
                    className={`${classMessage}`}
                  >
                    {avatarNeeded ? (
                      <ListItemAvatar className={classes.messageAvatar}>
                         {groupLen === i + 1 ? (
                          <ProfileAvatar
                            imageLink={msg?.user?.profile_image?.link}
                            user={msg?.user}
                          />
                         ) : null}
                      </ListItemAvatar>
                    ) : null}
                    {msg.body && !msg.files.length ? (
                      <Grid className="messageContainer">
                        <ListItemText primary={msg.body}></ListItemText>
                        <ListItemText
                          className={classes.messageDate}
                          primary={getTime(msg?.datetime)}
                        ></ListItemText>
                      </Grid>
                    ) : null}

                    {msg.files.length ? (
                      <Grid className="messageContainer messageAllAttach">
                        {msg.files?.map((f) => {
                          return (
                            <Grid key={f.id} className="messageContainerAttach">
                              <MessageFileAttachment
                                date={getTime(msg?.datetime)}
                                file={f}
                                msg={msg}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    ) : null}
                  </ListItem>
                );
              })}
            </List>
          </ListItem>
        );
      })}
    </List>
  );
};

export default MessagesList;
