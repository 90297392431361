
import React from "react";
import { Typography, Chip} from "@material-ui/core";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ReferenceChipsSearch = (props) => {
  const {type, param} = props;
  return (
    <>
      <Typography variant="h6" noWrap>
        {props.title}
      </Typography>

      {props.data?.map((d) => {
        return (<Link key={d?.id}
        target="_blank"
        to={{pathname: `/search?type=${type}&${param}=${d?.code}`,
       type,
        [param]: d?.code}} >

            <Chip label={d?.name} />
        </Link>);
      })}
    </>
  );
};

export default ReferenceChipsSearch;

ReferenceChipsSearch.propTypes = {
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  param: PropTypes.array.isRequired,
};
