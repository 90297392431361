import React, { useEffect } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/CreationOpportunity";

import { formSubmitAction } from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import { isMaster, isCreator } from "../utils/misc";

import DarkTooltip from "./DarkTooltip";
import UserCard from "./UserCard";
import UpdateOpportunityStatusButton from "./UpdateOpportunityStatusButton";
import ConfirmationDialog from "./ConfirmationDialog";
import FinishOpportunityButton from "./FinishOpportunityButton";

const OpportunitySideBar = React.forwardRef((props, ref) => {
  const { data } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  let { opportunityId } = useParams();
  opportunityId = opportunityId ? opportunityId : props.opportunityId;

  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const references = useSelector((state) => state.formReducer.references);
  const currentUser = useSelector((state) => state.userReducer.user);
  const teams = useSelector((state) => state.formReducer.customData?.teams);
  const statuses = useSelector(
    (state) => state.formReducer.customData?.statuses
  );

  let currentRef = ref?.current;
  useEffect(() => {}, [currentRef]);

  useEffect(() => {}, [ref]);

  const ParticipateButton = (data, teams) => {
    return (
      <Grid className={classes.opportunityTooltip}>
        <Button
          disabled={!teams?.length}
          onClick={() => {
            /*             if (
              ["individuals_apply", "individuals_participate"].includes(
                data.opportunity_type?.code
              )
            ) {
              dispatch(push(`/opportunities/${opportunityId}/participate`));
            } else { */
            dispatch(push(`/calls/${opportunityId}/teams`));
            /*             } */
          }}
        >
          Participate
        </Button>
        <DarkTooltip title="Please wait while one team has been created at least" />
      </Grid>
    );
  };

  return (
    <Grid className={classes.creationAdditional}>
      <Grid className={classes.creationAdditionalBlock}>
        <Typography variant="h3" className={classes.creationFieldTitle}>
          Tags
        </Typography>
        {references?.["classifiers-opportunity-tags"]?.map((or) => (
          <Typography variant="body1" className={classes.creationField}>
            {or.name}
          </Typography>
        ))}
      </Grid>
      <Grid className={classes.creationAdditionalBlock}>
        <Typography variant="h3" noWrap className={classes.creationFieldTitle}>
          Reward
        </Typography>
        <Typography variant="h3" noWrap className={classes.creationField}>
          € {data.budget}
        </Typography>
      </Grid>
      <Grid className={classes.opportunityButtons}>
        <Grid className="buttons">
          {data?.status?.code === "opened" && isMaster(currentUser) ? (
            <Button
              onClick={() => dispatch(push(`/create-team/${opportunityId}`))}
            >
              Create team
            </Button>
          ) : null}
          {data?.status?.code === "opened" && isCreator(currentUser) ? (
            <>{ParticipateButton(data, teams)}</>
          ) : null}
          {data?.created_by?.id === currentUser?.id &&
          data.status?.code === "draft" ? (
            <>
              <Button
                className="invert"
                onClick={() => dispatch(push(`/calls/${data.id}/edit`))}
              >
                Edit
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid className={classes.creationUser}>
        <UserCard user={data.created_by} userLink="true" />
      </Grid>
      {data?.created_by?.id === currentUser?.id ? (
        <Grid className={classes.opportunityStatusButtons}>
          <Grid className="buttons">
            <UpdateOpportunityStatusButton
              statuses={statuses}
              opportunityId={opportunityId}
              currentStatus={data?.status?.code}
            />
            {data?.status?.code === "completed" ? (
              <FinishOpportunityButton
                ref={ref}
                statuses={statuses}
                opportunityId={opportunityId}
                winner={data?.winner_team_id}
              />
            ) : null}
            {["draft", "denied", "approved"].includes(data?.status?.code) && (
              <Button onClick={() => setConfirmationOpen(true)}>Delete</Button>
            )}
          </Grid>
          <ConfirmationDialog
            open={confirmationOpen}
            setOpen={setConfirmationOpen}
            text={`Are you sure you want to delete call?`}
            confirmAction={() => {
              dispatch(
                formSubmitAction({
                  submitType: "delete",
                  endpoint: `/opportunities/${opportunityId}`,
                  redirect: `/`,
                })
              );
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
});

export default OpportunitySideBar;
