import React from "react";

import {
  Paper,
  Grid,
  Typography,
} from "@material-ui/core";
import TeamMember from "./TeamMember";
import TeamApplication from "./TeamApplication";

import { useSelector, useDispatch } from "react-redux";
import useStyles from "../styles/Team";

import {
  formGetReferenceTableValuesAction,
  formGetCustomDataAction,
} from "../actions/formActions";
import { useEffect } from "react";
import clsx from "clsx";
import UpdateTeamStatusButton from "./UpdateTeamStatusButton";

const TeamTabMembers = (props) => {
  const dispatch = useDispatch();
  const { opportunity, teamId, openRoles, teamOwner } = props;

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-opportunity-roles",
      })
    );
    dispatch(
      formGetCustomDataAction({
        endpoint: `team-tab-members-statuses/${teamId}`,
        name: "tabMembersStatuses",
      })
    );
  }, [teamId, dispatch]);

  const currentUser = useSelector((state) => state.userReducer.user);
  const statuses = useSelector(
    (state) => state.formReducer.customData?.tabMembersStatuses
  );

/*   const [open, setOpen] = useState(false); */


  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.teamMembers}
    >
      <Grid item xs={12} lg={currentUser.id === teamOwner.id ? 9 : 12}>
        <Typography variant="body2" className={classes.titleMembers}>
          Team members
        </Typography>
        <Paper className={classes.paperMembers}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              {currentUser?.id === teamOwner?.id ? (
                <Grid className={classes.teamMembersButton}>

                  <Grid className={classes.addMembers}>
                  </Grid>
{/*                   <Grid>
                    {openRoles.length ? (
                      <Button
                        className={classes.addMembers}
                        onClick={() => {
                          handleOpen();
                        }}
                      >
                        <AddIcon />
                        Add Member
                      </Button>
                    ) : null}
                  </Grid> */}
                  <UpdateTeamStatusButton
                    statuses={statuses ? statuses : []}
                    teamId={teamId}
                    opportunityId={opportunity?.id}
                    classes={classes.teamStatusButton}
                  />
                </Grid>
              ) : null}
              {props.pending?.map((m) => (
                <Grid item xs={12} key={m.id}>
                  <TeamMember
                    pending={true}
                    member={m}
                    opportunity={opportunity}
                    teamId={teamId}
                    teamOwner={teamOwner}
                  />
                </Grid>
              ))}
              {props.members?.map((m) => {
                return (
                  <Grid item xs={12} key={m.id}>
                    <TeamMember
                      member={m}
                      opportunity={opportunity}
                      teamId={teamId}
                      teamOwner={teamOwner}
                    />
                  </Grid>
                );
              })}

              {openRoles?.map((m) => {
                return (
                  <Grid item xs={12} key={m.id}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.teamMember}
                    >
                      <Grid
                        item
                        xs={12}
                        md={currentUser?.id === teamOwner?.id ? 4 : 5}
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={classes.teamMemberUser}
                        >
                          <div
                            className={clsx(
                              "default-profile-avatar-container",
                              "open-role"
                            )}
                          ></div>
                          {/*                           <ProfileAvatar
                            imageLink={mm?.member?.profile_image?.link}
                            user={m?.member}
                          /> */}
                          <Grid className={classes.teamMemberUserName}>
                            <Typography variant="body1">Open role</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={currentUser?.id === teamOwner?.id ? 3 : 4}
                        className={classes.teamMemberEmail}
                      ></Grid>
                      <Grid
                        item
                        xs={currentUser?.id === teamOwner?.id ? 9 : 12}
                        md={3}
                        className={classes.teamMemberRole}
                      >
                        <Typography variant="body1">{m?.role?.name}</Typography>
                      </Grid>
                      {currentUser?.id === teamOwner?.id ? (
                        <Grid
                          item
                          xs={3}
                          md={2}
                          className={classes.teamMemberRemove}
                        ></Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {currentUser.id === teamOwner.id ? (
        <Grid item xs={12} lg={3} className={classes.teamApplications}>
          <Typography variant="body2" className={classes.titleMembers}>
            Applications to join team
          </Typography>
          {props.applications?.map((a) => (
            <TeamApplication application={a} key={a.id} />
          ))}
        </Grid>
      ) : null}
{/*       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalAddMember}>
          <Form
            onSubmit={async (values) => {
              //dispatch(loginUserAction(values));
              //await sleep(600);
              //return stateRef.current;

              dispatch(
                formSubmitAction({
                  formValues: values,
                  endpoint: `opportunities/${opportunity?.id}/teams/${teamId}/roles`,
                  redirect: router.location.pathname,
                })
              );

              handleClose();
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signin" onSubmit={handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h1"
                      className={classes.modalAddMemberTitle}
                    >
                      Add member
                    </Typography>
                    <span
                      className={classes.modalAddMemberClose}
                      onClick={handleClose}
                    ></span>

                    <Select
                      name="role_id"
                      label="Select role"
                      formControlProps={{ margin: "none" }}
                      required
                    >
                      {openRoles?.map((or) => (
                        <MenuItem value={or.id}>{or?.role?.name}</MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={12}>
                    <Field name="member_id">
                      {(props) => {
                        return (
                          <CustomAutocomplete
                            {...props.input}
                            reference="users"
                            label="User"
                            name="member_id"
                            data={null}
                            required
                            renderOption={(o) => {
                              return <UserCard user={o} />;
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>

                  <Grid className="buttons">
                    <Button type="submit" variant="contained" color="primary">
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </div>
      </Modal> */}
    </Grid>
  );
};

export default TeamTabMembers;
