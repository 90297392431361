import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router-dom";
import TokenWrapper from "./containers/TokenWrapper";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Search from "./pages/Search";
import PasswordReset from "./pages/PasswordReset";
import PasswordForgot from "./pages/PasswordForgot";
import ProfileForm from "./pages/ProfileForm";
import Opportunity from "./pages/Opportunity";
import OpportunityParticipate from "./forms/OpportunityParticipate";
import TeamApply from "./forms/TeamApply";
import Creation from "./pages/Creation";
import DiscoverTeams from "./pages/DiscoverTeams";
import NotFound from "./pages/NotFound";
import TeamApplySuccess from "./pages/TeamApplySuccess";
import PasswordResetForm from "./forms/PasswordResetForm";
import AccountVerify from "./forms/AccountVerify";
import Layout from "./containers/Layout";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { Provider } from "react-redux";
import configureStore, { history } from "./store/configureStore";
import "./index.css";
import { ConnectedRouter } from "connected-react-router";
import PrivateRoute from "./components/PrivateRoute";
import StaticPage from "./pages/StaticPage";
import Team from "./pages/Team";
import OpportunityEditWrapper from "./components/OpportunityEditWrapper";
import CreationEditWrapper from "./components/CreationEditWrapper";
import TeamEditWrapper from "./components/TeamEditWrapper";
import "./App.css";
import WebsocketWrapper from "./containers/WebsocketWrapper";
import NotificationsWrapper from "./containers/NotificationsWrapper";
import IdeationSpaces from "./pages/IdeationSpaces";
//import IdeationPostEditWrapper from "./components/IdeationPostEditWrapper";
//import IdeationPost from "./pages/IdeationPost";
import TeamsFormWrapper from "./containers/TeamsFormWrapper";
import OpportunityFormWrapper from "./containers/OpportunityFormWrapper";
import CreationFormWrapper from "./containers/CreationFormWrapper";
import TeamInvitation from "./pages/TeamInvitation";
import { GoogleOAuthProvider } from '@react-oauth/google';
//import store from "./store/store";
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <TokenWrapper>
            <NotificationsWrapper>
              <ThemeProvider theme={theme}>
                <WebsocketWrapper>
                  <Layout>
                    <Switch>
                      <Route path="/login" component={Login} />
                      <Route path="/register" component={Register} />
                      <Route
                        path="/password-reset"
                        exact
                        component={PasswordReset}
                      />
                      <Route
                        path="/password-reset/:token"
                        component={PasswordResetForm}
                        exact
                      />
                      <Route
                        path="/verify/:hash"
                        component={AccountVerify}
                        exact
                      />
                      <Route path="/forgot-password" component={PasswordForgot} />
                      <Route
                        path="/about"
                        component={() => (
                          <StaticPage endpoint="about" title="About" />
                        )}
                      />
                      <Route
                        path="/terms"
                        component={() => (
                          <StaticPage endpoint="terms" title="Terms" />
                        )}
                      />

{/*                       <PrivateRoute
                        path="/pricing"
                        exact
                        component={() => (
                          <StaticPage endpoint="pricing" title="Pricing" />
                        )}
                        roles={["Creator"]}
                        roleCondition="only"
                      /> */}

                      <Route
                        path="/cookie"
                        component={() => (
                          <StaticPage endpoint="cookie" title="Cookie policy" />
                        )}
                      />
                      <Route
                        path="/faq"
                        component={() => (
                          <StaticPage endpoint="faq" title="FAQ" />
                        )}
                      />
                      <Route
                        path="/support"
                        component={() => (
                          <StaticPage endpoint="support" title="Support" />
                        )}
                      />
                      <PrivateRoute path="/search" component={Search} />
                      <PrivateRoute
                        path="/calls"
                        exact
                        component={OpportunityFormWrapper}
                        roles={["Business", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        path="/calls/:opportunityId/edit"
                        exact
                        component={OpportunityEditWrapper}
                        roles={["Business", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        path="/invitations/:roleId"
                        exact
                        component={TeamInvitation}
                      />
                      <PrivateRoute
                        path="/teams"
                        exact
                        component={DiscoverTeams}
                        roles={["Creator", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        path="/calls/:opportunityId/teams"
                        exact
                        component={DiscoverTeams}
                        roles={["Creator", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        key={window.location.pathname}
                        path="/calls/:opportunityId"
                        exact
                        component={Opportunity}
                      />
                      <PrivateRoute
                        key={window.location.pathname}
                        path="/calss/:opportunityId/participate"
                        exact
                        component={OpportunityParticipate}
                      />
                      <PrivateRoute
                        key={window.location.pathname}
                        path="/calls/:opportunityId/teams/:teamId/apply"
                        exact
                        component={TeamApply}
                      />
                      <PrivateRoute
                        path="/create-team"
                        key={window.location.pathname}
                        exact
                        //TODO
                        component={TeamsFormWrapper}
                        roles={["Business", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        path="/create-team/:oppoId"
                        key={window.location.pathname}
                        exact
                        component={TeamsFormWrapper}
                        roles={["Business", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        path="/creations"
                        exact
                        component={CreationFormWrapper}
                      />
                      <PrivateRoute
                        key={window.location.pathname}
                        path="/creations/:creationId"
                        exact
                        component={Creation}
                      />
                      <PrivateRoute
                        path="/creations/:creationId/edit"
                        exact
                        component={CreationEditWrapper}
                      />
                      <PrivateRoute
                        path="/profile/edit/:userId"
                        exact
                        component={ProfileForm}
                        key={document.location.href}
                      />
                      <PrivateRoute
                        path="/team-apply/success"
                        exact
                        component={TeamApplySuccess}
                        key={document.location.href}
                      />
                      <PrivateRoute
                        path="/profile/:userId"
                        exact
                        component={Profile}
                        key={document.location.href}
                      />
                      <PrivateRoute
                        path="/profile"
                        exact
                        component={Profile}
                        key={document.location.href}
                      />
                      <PrivateRoute
                        path="/calls/:opportunityId/teams/:teamId"
                        exact
                        component={Team}
                        key={document.location.href}
                      />
                      <PrivateRoute
                        path="/calls/:opportunityId/teams/:teamId/edit"
                        exact
                        component={TeamEditWrapper}
                        roles={["Business", "Master Creator"]}
                        roleCondition="any"
                      />
                      <PrivateRoute
                        path="/ideation-spaces"
                        exact
                        component={IdeationSpaces}
                        roles={["Business", "Creator", "Master Creator"]}
                        roleCondition="any"
                      />
                      {/*<PrivateRoute
	                    key={window.location.pathname}
	                    path="/ideation-post/:postId"
	                    exact
	                    component={IdeationPost}
	                />
	                <PrivateRoute
	                    path="/ideation-post/:postId/edit"
	                    exact
	                    component={IdeationPostEditWrapper}
	                    roles={["Business", "Master Creator"]}
                      roleCondition="any"
	                />
	                */}
                      <PrivateRoute path="/" exact component={Home} />
                      {/*                 <Route path="/404" exact component={NotFound} /> */}
                      <Route component={NotFound} />
                    </Switch>
                  </Layout>
                </WebsocketWrapper>
              </ThemeProvider>
            </NotificationsWrapper>
          </TokenWrapper>
        </ConnectedRouter>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
