import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formGetReferenceTableValuesAction } from "../actions/formActions";
import { CircularProgress } from "@material-ui/core";
import OpportunityForm from "../forms/OpportunityForm";

const OpportunityFormWrapper = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-opportunity-types",
      })
    );
    dispatch(
      formGetReferenceTableValuesAction({ endpoint: "classifiers-award-types" })
    );

    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-creative-fields",
      })
    );
  }, [dispatch]);

  const references = useSelector((state) => state.formReducer.references);
  if (
    !references?.["classifiers-opportunity-types"] ||
    !references?.["classifiers-award-types"] ||
    !references?.["classifiers-creative-fields"]
  ) {
    return <CircularProgress className="main-progress-bar" />;
  }

  return <OpportunityForm {...props} />;
};

export default OpportunityFormWrapper;
