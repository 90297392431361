import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";

import { useEffect } from "react";
import { formGetDataAction } from "../actions/formActions";
import arrowRight from "../assets/creative_test_arrow_right.svg";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../styles/Manifests";
import { imageCrop } from "../utils/misc";


const Manifests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(formGetDataAction({ endpoint: `manifests` }));
  }, [dispatch]);

  const data = useSelector((state) => state.formReducer.data);

  return (
    <Grid className={classes.centerForm}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.manifestContainer}
      >
        <Grid item xs={12} className={classes.stepOneText}>
          <div>
            <Typography variant="h1" className={classes.stepOneText1}>
              WeCreators manifest
            </Typography>
            <Typography variant="h2" className={classes.stepOneText2}>
              WeCreators is based on the collective wisdom therefore we have to
              follow the common values
            </Typography>
          </div>
          <img
            src={arrowRight}
            alt="arrow-right"
            className={classes.stepOneTextObj}
          />
        </Grid>
        <Grid item xs={12}>
          {data
            ? data.map((m) => (
                <Card
                  key={m.id}
                  className={classes.manifestCard}
                  // Temporary disabled because we don't know where it should follow
                  // onClick={() => {
                  //   window.open(
                  //     m.link,
                  //     "",
                  //     "",
                  //     "width=600,height=400,left=200,top=200"
                  //   );
                  // }}
                >
                  <CardContent className={classes.manifestCardBody}>
                    <div className={classes.manifestCardBodyTextContainer}>
                      <Typography className={classes.manifestCardBodyText1}>
                        {m.title}
                      </Typography>
                      <Typography className={classes.manifestCardBodyText2}>
                        {m.body}
                      </Typography>
                    </div>
                    {m.picture ? (
                      <img
                        src={imageCrop({
                          image: m.picture?.link,
                          options: "395x395",
                        })}
                        alt="manifest"
                        width={395}
                        height={395}
                      />
                    ) : null}
                  </CardContent>
                </Card>
              ))
            : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Manifests;
