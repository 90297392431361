import React, {useEffect} from "react";
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Typography,
  Button,
} from "@material-ui/core";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { useParams } from "react-router-dom";

import {
  formGetDataAction,
} from "../actions/formActions";
import Blocks from "editorjs-blocks-react-renderer";
import { Form, Field } from "react-final-form";
import { TextField } from "mui-rff";
import ProfileAvatar from "../components/ProfileAvatar";
import useStyles from "../styles/TeamApply";

const OpportunityParticipate = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  let { opportunityId } = useParams();
  useEffect(() => {
    dispatch(formGetDataAction({ endpoint: `opportunities/${opportunityId}` }));
  }, [dispatch, opportunityId]);

  const data = useSelector((state) => state.formReducer.data);
  //const customData = useSelector((state) => state.formReducer.customData);

  /*   useEffect(() => {
      if (!data) {
        return;
      }

      dispatch(
        formGetCustomDataAction({
          endpoint: "classifiers-opportunity-roles",
          filter: data.opportunity_roles
            ? ["id", "in", data.opportunity_roles]
            : null,
        })
      );
    }, [data]); */

  const customDataDummy = {
    filledRoles: [
      {
        id: 1,
        name: "FilledRole1",
        user: {
          id: 1,
          name: "Name1",
        },
      },
      {
        id: 2,
        name: "FillerRole2",
        user: {
          id: 2,
          name: "Name2",
        },
      },
    ],
    availableRoles: [
      {
        id: 1,
        name: "AvailableRole1",
        user: {
          id: 1,
          name: "Name1",
        },
      },
      {
        id: 2,
        name: "AvailableRole2",
        user: {
          id: 2,
          name: "Name2",
        },
      },
    ],
  };

  return data && customDataDummy ? (
    <Grid className={classes.container}>
      <Form
        onSubmit={(values) => {
          //dispatch(loginUserAction(values));
        }}
        render={({
          submitError,
          handleSubmit,
          reset,
          submitting,
          pristine,
          values,
        }) => (
          <form className="form-signin" onSubmit={handleSubmit}>
            <Grid className={classes.teamTitle}>
              <Typography variant="h1">
                Participate in an opportunity
              </Typography>
              <Typography variant="h2">{data.title}</Typography>
            </Grid>
            <Grid className={classes.teamDescription}>
              <Blocks data={JSON.parse(data.body)} />
            </Grid>
            <Grid>
              <Grid className={classes.containerRoles}>
                <div className={classes.titleRoles}>
                  <Typography variant="body2" noWrap>
                    Filled roles
                  </Typography>
                  <Tooltip title="test">
                    <InfoOutlinedIcon />
                  </Tooltip>
                </div>

                {customDataDummy?.["filledRoles"]?.map((ot) => (
                  <div className={classes.teamUser}>
                    <ProfileAvatar
                      imageLink={ot.user.profile_image}
                      user={ot.user}
                    />
                    <Typography className={classes.teamUserName}>
                      {ot.name}
                    </Typography>
                  </div>
                ))}
              </Grid>
            </Grid>
            <Grid>
              <Field name="role_id">
                {(props) => {
                  return (
                    <FormControl
                      component="fieldset"
                      className={classes.radioControl}
                    >
                      <FormLabel component="legend">Choose your role</FormLabel>
                      <RadioGroup name="availableRoles" {...props.input}>
                        {customDataDummy?.["availableRoles"]?.map((ot) => (
                          <FormControlLabel
                            value={ot.id.toString()}
                            control={<Radio />}
                            label={ot.name}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  );
                }}
              </Field>
            </Grid>
            <Grid>
              <TextField
                id="cover"
                name="cover"
                label="Cover letter"
                variant="outlined"
                multiline
                required
                minRows={10}
                className={classes.teamCover}
              />
            </Grid>
            <Grid className={classes.teamTooltip}>
              <Tooltip title="test">
                <InfoOutlinedIcon />
              </Tooltip>
              <Typography variant="body2">
                This opportunity allows only individuals to participate
              </Typography>
            </Grid>
            <Grid className="buttons">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                //className={classes.btnLogin}
              >
                Participate
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(push(`/calls/${opportunityId}`))}
              >
                See call
              </Button>
            </Grid>
          </form>
        )}
      />
    </Grid>
  ) : null;
};

export default OpportunityParticipate;
