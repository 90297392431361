import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { IconButton } from "@material-ui/core";
import useStyles from "../styles/CreationOpportunityTeamForm";
import AttachFileIcon from "../assets/attach_file.svg";
import DarkTooltip from "./DarkTooltip";
import { fileUploadError } from "../utils/misc";

function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = function () {
      const result = fileReader.result;
      return resolve({
        data: fileReader.result,
        name: file.name,
        size: file.size,
        type: file.type,
        b64: result,
      });
    };
    fileReader.readAsDataURL(file);
  });
}


const FileDropzone = (props) => {

  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const {  fileRejections, getRootProps, getInputProps } =
    useDropzone({
      multiple: true,
      noDrag: false,
      onDrop: async (acceptedFiles, fileRejections) => {
        let filesToSet = await Promise.all(
          acceptedFiles.map((f) => {
            return readAsDataURL(f);
          })
        );
        setFiles((files) => filesToSet);
        if (props.onChange) {
          props.onChange(filesToSet);
        }
      },
      accept: [
        "text/plain",
        "image/png",
        "image/jpeg",
        "image/pjpeg",
        "image/gif",
        "application/zip",
        "application/pdf",
        "application/excel",
        "application/vnd.ms-excel",
        "application/x-excel",
        "application/x-msexcel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/wps-office.xls",
        "application/wps-office.xlsx",
        "application/doc",
        "application/ms-doc",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/wps-office.doc",
        "application/wps-office.docx",
        "application/mspowerpoint",
        "application/powerpoint",
        "application/vnd.ms-powerpoint",
        "application/x-mspowerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/wps-office.ppt",
      ],
    });

  useEffect(() => {
    if (props.value) {
      setFiles(props.value);
    }
  }, [props.value]);

  const removeFile = (file) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    if (props.onChange) {
      props.onChange(newFiles);
    }
  };

  const filesList = files.map((file) => {
    return (
      <li key={file.name} className={classes.attachFileItem}>
        <img src={AttachFileIcon} alt="attach file" />
        {file.name}
        <IconButton
          edge="end"
          aria-label="filter"
          aria-controls="removeFile"
          aria-haspopup="true"
          onClick={removeFile(file)}
          color="secondary"
          className={classes.deleteAttachFile}
        >
          Delete
        </IconButton>
      </li>
    );
  });

  const errors = fileRejections.map((file) => {
    return (
      <li className={classes.attachmentErrorItem} key={file.file.name}>
        {file.file.name}
        {file.errors?.map((e) => {
          return(
          <span className={classes.attachmentError}>{fileUploadError(e.code)}</span>
        )})}
      </li>
    );
  });

  return (
    <section className="container">
      <div className={classes.creationTitleField}>{props.label ? props.label : "Attachments"}</div>
        <div {...getRootProps({ className: classes.dropzoneFile })}>
          <input {...getInputProps()} />
          <span>Attach files</span>
        </div>
        {props.tooltip ?
          <DarkTooltip title={props.tooltipText}/>
          : null}
      <aside className={classes.attachments}>
{/*         <div className={classes.creationTitleField}>Attachments</div> */}
        <ul>{filesList}</ul>
        <ul>{errors}</ul>
      </aside>
    </section>
  );
};

export default FileDropzone;
