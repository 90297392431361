import { useEffect } from "react";
import { refreshTokenAction } from "../actions/authenticationActions";
import { useDispatch } from "react-redux";

const TokenWrapper = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const location = window.location.pathname;
    if (
      !location.includes("/verify/") &&
      !location.includes("/password-reset/") &&
      !location.includes("/register")
    ) {
      dispatch(refreshTokenAction(location));
    }
  }, [dispatch]);

  return <>{children}</>;
};

export default TokenWrapper;
