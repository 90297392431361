import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        "& .MuiDialog-paperWidthSm": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "#F5F5F5",
            padding: "95px 20px 60px",
            overflowY: "auto",
            maxHeight: "100vh",
            margin: 0,
            borderRadius:0,
            [theme.breakpoints.up("sm")]: {
                maxWidth: 678,
                boxSizing: "border-box",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "70px 50px 60px",
                right: "unset",
                bottom: "unset",
            },
        },
    },
    paperClose: {
        position: "absolute",
        top: 25,
        right: 25,
        minWidth: 26,
        padding: 0,
        height: 26,
        display: "block",
        cursor: "pointer",
        backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNEw0IDEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTQgNEwxMiAxMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
        backgroundSize: 26,
        "&:hover": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("sm")]: {
            top: 30,
            right: 30,
        },
    },
    paperTitle: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        marginBottom: 30,
        [theme.breakpoints.up("sm")]: {
            lineHeight: "39px",
        },
    },
    postForm: {
        "& .buttons": {
            marginTop: 60,
            justifyContent: "left",
            "& button": {
                marginLeft: 0,
            }
        }
    },
    postAuthor: {
        paddingBottom: 30,
        "& a": {
            paddingLeft: 0,
        },
        "& .MuiTypography-body1": {
            fontFamily: "Gilroy",
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 500,
        },
    },
    postLabel: {
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "24px",
        fontWeight: 500,
        color: "#636363",
        paddingBottom: 12,
    },
    postDropzone: {
        marginBottom: "-30px",
        "& .MuiButtonBase-root": {
            backgroundColor: "transparent",
        },
        [theme.breakpoints.up("md")]: {
            marginBottom: "-60px",
        },
    },
    postBodyField: {
        background: "#fff",
        padding: "30px 23px 0",
        marginBottom: 30,
        "& .codex-editor__redactor": {
            paddingBottom: "0!important",
        },
        "& .ce-toolbar__actions": {
            right: "6px",
        },
        "& .ce-toolbar__plus": {
            left: "-3px",
        },
        "& .ce-block__content": {
            marginRight: 0,
            marginLeft: 0,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            "& .ce-paragraph:before": {
                fontWeight: 500,
            }
        },
        "& .ce-block": {
            border: "1px solid #fff",
            padding: "0 9px",
        },
        "& .ce-block--focused": {
            border: "1px solid rgb(98,51,192,0.2)",
        },
        "& .ce-paragraph": {
            lineHeight: "24px",
            padding: 0,
            fontWeight: 500,
        },
        "& .ce-header": {
            margin: 0,
            padding: 0,
            fontSize: "16px",
            lineHeight: "24px",
        },
        "& .MuiFormControl-root": {
            marginBottom: 30,
        },
        [theme.breakpoints.up("md")]: {
            padding: "40px 30px 0",
        },
    },
    postTitle: {
        color: "#000",
        fontFamily: "Gilroy",
        fontSize: "26px",
        lineHeight: "34px",
        "&>input": {
            padding: "0 9px",
            height: 34,
            border: "1px solid #fff"
        },
        "&>input::selection": {
            background: "#fff"
        },
        "&>input:focus": {
            border: "1px solid rgb(98,51,192,0.2)"
        },
        "&:after, &:before": {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            fontWeight: 500,
        },
    },
    postInterestsList: {
        background: "#fff",
        padding: "10px 0 0 10px",
        marginBottom: 10,
        position: "relative",
        "& .MuiChip-root": {
            marginBottom: 10,
        }
    },
    postInterestsTooltip: {
        position: "absolute",
        right: 0,
        top: "-35px",
        [theme.breakpoints.up("md")]: {
            right: "-34px",
            top: 15,
        },
    }
}));

export default useStyles;