import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootReducer from "../reducers";
import rootSaga from "../sagas";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
export const history = createBrowserHistory();

/* const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 225,
  }) || compose; */

let composeEnhancers;
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 225,
    }) || compose;
} else {
  composeEnhancers = compose;
}

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  return {
    ...createStore(
      rootReducer(history),
      composeEnhancers(
        applyMiddleware(routerMiddleware(history), sagaMiddleware)
      )
    ),
    runSaga: sagaMiddleware.run(rootSaga),
  };
};

export default configureStore;
