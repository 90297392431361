import React from "react";
import { Field, FormSpy } from "react-final-form";
import Wizard from "../components/Wizard";
import { useSelector, useDispatch } from "react-redux";
import ImageDropzone from "../components/ImageDropzone";
import FileDropzone from "../components/FileDropzone";
import { TextField, makeValidate, Select, DatePicker, Radios } from "mui-rff";
import RemoveIcon from "../assets/close.svg";
import ArrowUpIcon from "../assets/arrow_up.svg";
import ArrowDownIcon from "../assets/arrow_down.svg";
import DarkTooltip from "../components/DarkTooltip";
import {
  formSubmitAction,
  formGetHeaderDataAction,
} from "../actions/formActions";
import Editor from "../components/Editor";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { Grid, MenuItem, Button, Typography } from "@material-ui/core";
import useStyles from "../styles/CreationOpportunityTeamForm";
import { Helmet } from "react-helmet";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { FieldArray } from "react-final-form-arrays";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as Yup from "yup";
import CoverImageModal from "../components/CoverImageModal";

const schemaPage1 = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  body: Yup.object().shape({
    blocks: Yup.array().min(1, "Body text required"),
  }),
});
const validatePage1 = makeValidate(schemaPage1);

const schemaPage3 = Yup.object().shape({
  stages: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string().required("Stage title is required"),
      })
    )
    .min(1, "Create at least one stage for the call"),
});
const validatePage3 = makeValidate(schemaPage3);

const schemaPage4 = Yup.object().shape({
  opportunity_type_id: Yup.number().required("Select call type"),
  budget: Yup.number().required("Set the budget"),
  start_date: Yup.string().required("Set start date"),
  end_date: Yup.string().required("Set finish date"),
});
const validatePage4 = makeValidate(schemaPage4);

const makeOnDragEndFunction = (fields) => (result) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  fields.swap(result.source.index, result.destination.index);
};

const castValues = (data) => {
  data.opportunity_type_id = Number.parseInt(data.opportunity_type_id);
  let cover = data?.cover
    ? data?.cover?.b64
      ? { b64: data?.cover?.b64, name: data?.cover?.name }
      : data?.cover
    : null;
  data.cover = cover;
  data.cover_id = data.cover ? data.cover.id : null;
  data.body = JSON.stringify(data.body);
  data.start_date = moment(data.start_date).format("yyyy-MM-DD");
  data.end_date = moment(data.end_date).format("yyyy-MM-DD");
  data.stages = data.stages?.map((s) => {
    s.body = JSON.stringify(s.body);
    return s;
  });

  return data;
};

const OpportunityForm = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { initialValues, edit } = props;

  const user = useSelector((state) => state.userReducer.user);
  const references = useSelector((state) => state.formReducer.references);

  const awardCodeRef = React.useRef(null);

  return (
    <>
      <Helmet>
        <title>Calls | weCreators</title>
      </Helmet>
      <Grid className={classes.creationMain}>
        <div className={classes.creationContainer}>
          <Typography className={classes.creationHeader}>
            {edit ? "Edit the call" : "Create a call"}
          </Typography>
          <Wizard
            title="Create a call"
            initialValues={initialValues ? initialValues : { stages: [] }}
            onSubmit={(values) => {
              if (edit) {
                dispatch(
                  formSubmitAction({
                    formValues: castValues(values),
                    submitType: "put",
                    endpoint: `/opportunities/${values.id}`,
                    redirect: `/calls/${values.id}`,
                  })
                );
              } else {
                dispatch(
                  formSubmitAction({
                    formValues: castValues(values),
                    //formValues: values,
                    endpoint: "/opportunities",
                    redirectToNew: true,
                  })
                );
                dispatch(
                  formGetHeaderDataAction({
                    endpoint: `users/${user.id}/opportunities`,
                    name: "opportunities",
                  })
                );
              }
            }}
          >
            <Wizard.Page breadcrumb="1. Content" validate={validatePage1}>
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12}>
                  <TextField
                    placeholder="Title Text"
                    name="title"
                    required={true}
                    InputLabelProps={{ className: classes.creationTitleLabel }}
                    InputProps={{
                      className: classes.creationTitle,
                      disableUnderline: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field name="body">
                    {(props) => {
                      return (
                        <div>
                          <Editor {...props.input} {...props.meta} />
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page breadcrumb="2. Cover">
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12}>
                  <Field name="cover">
                    {(props) => {
                      return <CoverImageModal {...props.input}
                        mainText="Drag and drop your call cover image"
                        subText="or <b>browse</b> to choose a file 660x400 px </br>JPEG, PNG"
                      />

                    }}
                  </Field>
                </Grid>






              </Grid>
            </Wizard.Page>
            <Wizard.Page breadcrumb="3. Stages" validate={validatePage3}>
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12}>
                  <FieldArray name="stages" label="Stages">
                    {({ fields, meta: { error } }) => {
                      return (
                        <div>
                          <DragDropContext
                            onDragEnd={makeOnDragEndFunction(fields)}
                          >
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    className={classes.creationStages}
                                    ref={provided.innerRef}
                                  >
                                    {fields.map((name, index) => (
                                      <Draggable
                                        key={name}
                                        draggableId={name}
                                        index={index}
                                      >
                                        {(provided, snapshot) => {
                                          return (
                                            <div>
                                              <div
                                                className={
                                                  classes.creationTitleStage
                                                }
                                              >
                                                Stage {index + 1}
                                              </div>
                                              <div
                                                className={
                                                  classes.creationItemStage
                                                }
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <TextField
                                                  placeholder="Title"
                                                  name={`${name}.title`}
                                                  required={true}
                                                  InputProps={{
                                                    className:
                                                      classes.creationTitle,
                                                  }}
                                                />
                                                <Field
                                                  name={`${name}.body`}
                                                //validate={requiredBody}
                                                >
                                                  {(props) => {
                                                    return (
                                                      <div>
                                                        <Editor
                                                          {...props.input}
                                                        />
                                                      </div>
                                                    );
                                                  }}
                                                </Field>

                                                <Button
                                                  className={
                                                    classes.creationRemoveStage
                                                  }
                                                  onClick={() =>
                                                    fields.remove(index)
                                                  }
                                                >
                                                  <img
                                                    src={RemoveIcon}
                                                    alt="Remove"
                                                  />
                                                </Button>

                                                <div
                                                  className={
                                                    classes.creationPosStage
                                                  }
                                                >
                                                  {index > 0 &&
                                                    index <= fields.length - 1 ? (
                                                    <Button
                                                      onClick={() => {
                                                        fields.move(
                                                          index,
                                                          index - 1
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={ArrowUpIcon}
                                                        alt="down"
                                                      />
                                                    </Button>
                                                  ) : null}
                                                  {index < fields.length - 1 ? (
                                                    <Button
                                                      onClick={() => {
                                                        fields.move(
                                                          index,
                                                          index + 1
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={ArrowDownIcon}
                                                        alt="down"
                                                      />
                                                    </Button>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Draggable>
                                    ))}
                                  </div>
                                );
                              }}
                            </Droppable>
                          </DragDropContext>
                          <Button
                            className={classes.buttonAddStage}
                            onClick={() =>
                              fields.push({ body: null, title: "" })
                            }
                          >
                            Add stage
                          </Button>
                          <FormSpy subscription={{ errors: true }}>
                            {(spyProps) => {
                              return spyProps.errors?.["stages"] &&
                                typeof spyProps.errors?.["stages"]?.[0] ===
                                "string" ? (
                                <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error errorStages">
                                  {spyProps.errors?.["stages"]}
                                </p>
                              ) : null;
                            }}
                          </FormSpy>
                        </div>
                      );
                    }}
                  </FieldArray>
                </Grid>
              </Grid>
            </Wizard.Page>
            <Wizard.Page breadcrumb="4. Settings" validate={validatePage4}>
              <Grid container className={classes.wizardContainer}>
                <Grid item xs={12}>
                  <Radios
                    formControlProps={{ children: <span>test</span> }}
                    label="Call type"
                    name="opportunity_type_id"
                    required={true}
                    fieldProps={{
                      parse: (val) => {
                        return parseInt(val);
                      },
                    }}
                    formLabelProps={{
                      className: classes.creationTitleField,
                    }}
                    formControlLabelProps={{
                      className: classes.creationRadioField,
                    }}
                    data={references?.["classifiers-opportunity-types"]?.map(
                      (v) => ({
                        label: (
                          <>
                            {v.name}
                            <DarkTooltip
                              className={classes.creationTooltip}
                              title={v.description}
                            />
                          </>
                        ),
                        value: v.id,
                        key: v.id,
                      })
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.CreationSelectBlock}
                >
                  <Select
                    name="award_type_id"
                    label="Select award type"
                    formControlProps={{ margin: "none" }}
                  >
                    {references?.["classifiers-award-types"]?.map((at) => (
                      <MenuItem value={at.id} key={at.id}>
                        {at.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                <FormSpy subscription={{ values: true }}>
                  {(spyProps) => {
                    let awardCode = references?.[
                      "classifiers-award-types"
                    ]?.reduce((acc, cur) => {
                      if (cur.id === parseInt(spyProps.values.award_type_id)) {
                        acc = cur.code;
                      }
                      return acc;
                    }, "");
                    awardCodeRef.current = awardCode;
                    if (awardCodeRef.current === "voluntary") {
                      spyProps.form.change("budget", 0);
                    }

                    return null;
                  }}
                </FormSpy>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.creationPriceField}
                >
                  <TextField
                    label="Project budget"
                    name="budget"
                    type="Number"
                    required={true}
                    InputProps={{ inputProps: { min: 0 } }}
                    disabled={awardCodeRef.current === "voluntary"}
                    fieldProps={{
                      parse: (val) => {
                        return val.replace(/^0+(?=\d)/, "");
                      },
                      format: (val) => {
                        return parseFloat(val);
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <Field name="opportunity_tags">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-opportunity-tags"
                          label="Tags"
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <Field name="creative_fields">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-creative-fields"
                          label="Creative fields"
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>
                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <Field name="interest_areas">
                    {(props) => {
                      return (
                        <CustomAutocomplete
                          {...props.input}
                          reference="classifiers-interest-areas"
                          label="Interest areas"
                          multiple
                        />
                      );
                    }}
                  </Field>
                </Grid>

                <Grid item xs={12} className={classes.CreationSelectBlock}>
                  <DatePicker
                    fullWidth
                    dateFunsUtils={MomentUtils}
                    name="start_date"
                    variant="inline"
                    label="Start date"
                    format="MM-DD-yyyy"
                  //required={true}
                  />
                  <DarkTooltip
                    className={classes.dateTooltip}
                    title="This is the day when this call will be started"
                  />
                </Grid>
                <FormSpy subscription={{ values: "start_date" }}>
                  {(spyProps) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        className={classes.CreationSelectBlock}
                      >
                        <DatePicker
                          fullWidth
                          dateFunsUtils={MomentUtils}
                          name="end_date"
                          variant="inline"
                          label="Finish date"
                          format="MM-DD-yyyy"
                          disabled={!spyProps.values.start_date}
                          minDate={spyProps.values.start_date}
                          required={true}
                        />
                        <DarkTooltip
                          className={classes.dateTooltip}
                          title="This is the day when the final result has to be delivered"
                        />
                      </Grid>
                    );
                  }}
                </FormSpy>

                <Grid item xs={12} md={6}>
                  <Field name="files">
                    {(props) => {
                      return (
                        <div>
                          <FileDropzone {...props.input} />
                        </div>
                      );
                    }}
                  </Field>
                </Grid>
              </Grid>
            </Wizard.Page>
          </Wizard>
        </div>
      </Grid>
    </>
  );
};

export default OpportunityForm;
