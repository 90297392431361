import React, { useState, useEffect } from "react";
import http from "../http";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CustomAutocomplete = (props) => {
  let setInitialSelectedOption = (props) => {
    return props.multiple ? [] : {};
  };

  const { multiple, ids, reference, value, record, getOptionLabel } = props;
  const [productOptions, setProductOptions] = useState([]);
  const [filteredProductOptions, setFilteredProductOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    setInitialSelectedOption(props)
  );

  useEffect(() => {
    //dispatch(formGetReferenceTableValuesAction(props.reference));

    async function getData() {
      const { data } = await http.get(reference, {});
      setProductOptions(data.data);
      if (ids) {
        setSelectedOption(data.data.filter((d) => ids?.includes(d.id)));
      }

      if (value) {
        if (multiple) {
          setSelectedOption(data.data.filter((d) => value?.includes(d.id)));
        } else {
          setSelectedOption(data.data.filter((d) => value === d.id));
        }
      }

      if (!value && !ids) {
        if (multiple) {
          setSelectedOption([]);
        } else {
          setSelectedOption({});
        }
      }
    }
    getData();

    if (record) {
      setSelectedOption(record);
    }
  }, [record, value, multiple, ids, reference]);

  const handleAutocompleteInputChange = async (event, value, reason) => {
    if (value) {
      const { data } = await http.get(reference, {
        params: {
          filter: JSON.stringify(["name", "contains", value]),
        },
      });

      setFilteredProductOptions(data.data);
    } else {
      setFilteredProductOptions([]);
      if (props.multiple) {
        props.onChange([]);
        setSelectedOption([]);
      } else {
        props.onChange(null);
        setSelectedOption({});
      }
    }
  };

  return (
    <Autocomplete
      {...props}
      options={
        filteredProductOptions.length ? filteredProductOptions : productOptions
      }
      getOptionLabel={(option) => {
        if (Array.isArray(option)) {
          return option[0].name;
        } else {
          if (Object.keys(option).length === 0) {
            return "";
          } else {
            return option.name;
          }
        }
      }}
      ListboxComponent={props.ListboxComponent ? props.ListboxComponent : "ul"}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(e, v) => {
        setSelectedOption(v);
        if (props.setParentValue) {
          props.setParentValue(v);
        }

        if (v) {
          if (props.multiple) {
            props.onChange(v.map((value) => value.id));
          } else {
            props.onChange(v.id);
          }
        }
      }}
      onInputChange={handleAutocompleteInputChange}
      value={selectedOption}
      renderOption={
        props.renderOption
          ? props.renderOption
          : (option) => {
              return <div>{option.name}</div>;
            }
      }
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              label={props.label}
              variant="standard"
              /*             inputProps={{
              ...params.inputProps,
            }} */
              required={props.required}
              disabled={props.disabled}
              error={props.error?.length}
              helperText={props.error?.[0]}
            />
          </>
        );
      }}
    />
  );
};

export default CustomAutocomplete;
