import React from "react";

import Modal from "@material-ui/core/Modal";
import AvatarEditor from "react-avatar-editor";
import { useState } from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import useStyles from "../styles/Profile";
import { fileUploadError } from "../utils/misc";

const AvatarModal = (props) => {
  const classes = useStyles();

  const { getRootProps, getInputProps, fileRejections } =
    useDropzone({
      //accept: "image/*",
      accept: [
        "image/bmp",
        "image/gif",
        "image/vnd.microsoft.icon",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "image/webp",
      ],
      multiple: false,
      noDrag: true,
      onDrop: (acceptedFiles) => {
        setImage(acceptedFiles[0]);
        setOpen(true);
      },
    });

  const [scale, setScale] = useState(1);
  const [editor, setEditor] = useState(null);
  const [image, setImage] = useState(null);

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const errors = fileRejections.map((file) => {
    return (
      <div className={classes.attachmentError} key={file.file.name}>
        {file.file.name}
        {file.errors?.map((e) => (
          <span> {fileUploadError(e.code)}</span>
        ))}
      </div>
    );
  });

  return (
    <div className="buttons">
      <div {...getRootProps({ className: "btn-dropzone" })}>
        <input {...getInputProps()} />
        <Button type="button">Upload New Picture</Button>
      </div>

      <Button
        type="button"
        className="invert"
        onClick={() => {
          props.onChange(null);
          setImage(null);
        }}
      >
        Delete
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          {fileRejections.length ? (
            <Grid container>
              <Grid item xs={12}>
                {errors}
                <span
                  className={classes.paperClose}
                  onClick={handleClose}
                ></span>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1" className={classes.paperTitle}>
                  Position or resize you profile picture
                </Typography>
                <span
                  className={classes.paperClose}
                  onClick={handleClose}
                ></span>
                <AvatarEditor
                  ref={(editor) => setEditor(editor)}
                  image={image}
                  width={160}
                  height={160}
                  border={50}
                  color={[245, 245, 245, 0.8]} // RGBA
                  scale={scale}
                  rotate={0}
                  borderRadius={500}
                />
              </Grid>
              <Grid item xs={12} className={classes.inputRange}>
                <input
                  name="scale"
                  type="range"
                  onChange={(e) => {
                    setScale(parseFloat(e.target.value));
                  }}
                  min="0.1"
                  max="2"
                  step="0.01"
                  defaultValue={scale}
                />
              </Grid>
              <Grid item xs={12} className="buttons">
                <Button
                  onClick={() => {
                    if (editor) {
                      const result = editor.getImage().toDataURL();
                      props.onChange({ b64: result, name: image.name });
                      setOpen(false);
                    }
                  }}
                >
                  Save photo
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AvatarModal;
