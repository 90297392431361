import React from "react";
import { Redirect } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import useStyles from "../styles/TeamApply";
import { push } from "connected-react-router";

const TeamApplySuccess = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  if (!props.location.state.name) {
    return <Redirect to="/profile" />;
  }

  return (
    <Grid className={classes.mainContainer}>
      <Grid className={classes.container}>
        <Grid className={classes.teamTitle}>
          <Typography variant="h1">
            You successfully applied&nbsp;for&nbsp;a&nbsp;team
          </Typography>
          <Typography variant="h2" noWrap>
            {props.location?.state?.name}
          </Typography>
        </Grid>
        <Typography variant="body2" className={classes.teamDescription}>
          {props.location?.state?.description}
        </Typography>
        <Grid className="buttons">
          <Button
            variant="contained"
            color="primary"
            className={classes.btnLogin}
            onClick={() => dispatch(push(`/teams`))}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamApplySuccess;
