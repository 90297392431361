import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    rowComment: {
        width: "100%",
        marginBottom: "30px",
        display: "flex",
    },
    chatListIcon: {
        minWidth: "auto",
        height: "auto",
        marginRight: 10,
    },
    createdBy: {
        fontWeight: 700,
    },
    avatar: {
        marginRight: "10px"
    },
    commentBlock: {
        width: "100%",
        marginRight: 16,
        position: "relative",
    },
    commentBody: {
        background: "#F5F5F5",
        borderRadius: 20,
        padding: "10px 20px",
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 500,
    },
    actionComment: {
        position: "absolute",
        right: -26,
        top: 2,
        cursor: "pointer",
        padding: "0 10px",
    },
    formComment: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    sendComment: {
        width: "100%",
    },
    allComments: {
        width: "100%",
        paddingLeft: 35,
        paddingRight: 30,
        boxSizing: "border-box",
        overflowY: "auto",
        flexGrow: 1,
        scrollbarWidth: "thin",
        scrollbarColor: "#000 #fff",
        "&::-webkit-scrollbar": {
            width: 6,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#000",
        }
    },
    commentReply: {
        padding: "10px 10px 0",
        display: "flex",
        fontFamily: "Gilroy",
        fontSize: "12px",
        lineHeight: "15px",
        fontWeight: 500,
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    dateReply: {
        color: "#BCBCBC",
    },
    wordReply: {
        color: "#BCBCBC",
        cursor: "pointer",
        paddingRight: 15,
        "&:hover": {
            color: "#000",
        }
    },
    countReplyBlock: {
        paddingRight: 15,
        "& .MuiSvgIcon-root": {
            fill: "#636363",
            height: 13,
            width: 13,
            marginRight: 5,
            verticalAlign: "top",
        }
    },
    countReply: {
        color: "#636363",
        cursor: "pointer",
    },
    replySendComment: {
        background: "#F5F5F5",
        borderRadius: 20,
        paddingRight: 9,
        marginRight: -16,
        "& > .MuiGrid-root": {
            marginTop: 15,
        },
        "& .MuiFormControl-root": {
            padding: "13px 26px 12px"
        },
        "& .MuiFormControl-root .MuiInputBase-input": {
            fontSize: 12,
            lineHeight: "15px",
        },
        "& .MuiGrid-root .MuiButtonBase-root": {
            marginBottom: 9,
            marginRight: 17,
        },
        "& .MuiGrid-root ul li": {
            fontSize: 14,
            lineHeight: "16px",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiGrid-root ul li .MuiButtonBase-root": {
            margin: 0,
        },
    },
    replyComments: {
        marginRight: -16,
        "& .itemRowComment": {
            margin: "15px 0 0",
        }
    }
}));

export default useStyles;