
import * as types from "./index";

export const formSubmitAndGetCustomDataAction = (data) => {
  return {
    type: types.FORM_SUBMIT_AND_GET_CUSTOM_DATA,
    data,
  };
};

export const formSubmitAndGetDataAction = (data) => {
  return {
    type: types.FORM_SUBMIT_AND_GET_DATA,
    data,
  };
};

export const formSubmitAndDispatchAction = (data) => {
  return {
    type: types.FORM_SUBMIT_AND_DISPATCH_ACTION,
    data,
  };
};

export const formSubmitAction = (data) => {
  return {
    type: types.FORM_SUBMIT,
    data,
  };
};

export const formUpdateUserAction = (data) => {
  return {
    type: types.FORM_UPDATE_USER,
    data,
  };
};

export const formGetReferenceTableValuesAction = (data) => {
  return {
    type: types.FORM_GET_REFERENCE_VALUES,
    data,
  };
};


export const formGetDataAction = (data) => {
  return {
    type: types.FORM_GET_DATA,
    data,
  };
};

export const formGetCustomDataAction = (data) => {
  return {
    type: types.FORM_GET_CUSTOM_DATA,
    data,
  };
};

export const formResetCustomDataAction = (data) => {
  return {
    type: types.FORM_RESET_CUSTOM_DATA,
    data,
  };
};

export const formGetStaticPageAction = (data) => {
  return {
    type: types.FORM_GET_STATIC_PAGE,
    data,
  };
};

export const formGetHeaderDataAction = (data) => {
  return {
    type: types.FORM_GET_HEADER_DATA,
    data,
  };
};