import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    searchHeader: {
        background: "#fff",
        padding: "90px 23px 58px",
        fontFamily: "Gilroy",
        "& h1": {
            fontSize: "32px",
            fontWeight: 400,
            lineHeight: "35px",
            margin: 0,
            fontFamily: "Gilroy",
            color: "#000",
        },
        [theme.breakpoints.up("md")]: {
            "& h1": {
                fontSize: "60px",
                fontWeight: 500,
                lineHeight: "68px",
                margin: 0,
            },
        },
        [theme.breakpoints.up("lg")]: {
            padding: "190px 250px 108px",
        },
    },
    searchTextarea: {
        position: "relative",
        paddingRight: 75,
        "&:before": {
            content: "''",
            width: 25,
            height: 25,
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAgMjRIMzgiIHN0cm9rZT0iI0JDQkNCQyIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTI2IDEwTDQwIDI0TDI2IDM4IiBzdHJva2U9IiNCQ0JDQkMiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            right: 0,
            top: 33,
            backgroundSize: 25,
        },
        "& textarea": {
            width: "100%",
            resize: "none",
            height: "71px!important",
            fontSize: 32,
            lineHeight: "35px",
            fontWeight: 400,
            outline: "none",
            fontFamily: "Gilroy",
            padding: 0,
            margin: 0,
            border: 0,
        },
        "& textarea:focus": {
            outline: "none",
        },
        "& textarea::placeholder": {
            color: "#BCBCBC",
            opacity: 1,
        },
        [theme.breakpoints.up("md")]: {
            "& textarea": {
                fontWeight: 500,
                fontSize: 60,
                lineHeight: "68px",
                height: "69px!important",
            },
            "&:before": {
                width: 48,
                height: 48,
                backgroundSize: 48,
                top: 12,
            },
        },
    },
    searchFilter: {
        display: "flex",
        paddingTop: 24,
        justifyContent: "space-between",
        [theme.breakpoints.up("sm")]: {
            paddingTop: 36,
        },
    },
    searchResult: {
        background: "#F5F5F5",
        position: "relative",
        padding: "30px 23px 50px",
        alignItems: "normal",
        [theme.breakpoints.up("sm")]: {
            padding: "30px 32px 50px",
            minHeight: "calc(100vh - 556px)",
        },
        [theme.breakpoints.up("md")]: {
            minHeight: "calc(100vh - 453px)",
        },
        [theme.breakpoints.up("lg")]: {
            minHeight: "calc(100vh - 603px)",
        },
    },
    searchContainer: {
        gridGap: "20px",
        gridTemplateColumns: "repeat(1, 1fr)",
        width: "100%",
        display: "grid",
        boxSizing: "border-box",
        maxWidth: "2968px",
        marginLeft: "auto",
        marginRight: "auto",
        position: "relative",
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            "& .MuiGrid-root": {
                marginBottom: 10,
            }
        },
        [theme.breakpoints.up("lg")]: {
            gridTemplateColumns: "repeat(5, 1fr)",
        },
        "@media (min-width:2149px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
        },
        "@media (min-width:2593px)": {
            gridTemplateColumns: "repeat(7, 1fr)",
        },
    },
    searchNotFound: {
        color: "#BCBCBC",
        fontSize: 32,
        lineHeight: "35px",
        fontWeight: 400,
        [theme.breakpoints.up("md")]: {
            fontSize: 44,
            lineHeight: "54px",
        },
        [theme.breakpoints.up("lg")]: {
            padding: "59px 218px 170px",
        },
    },
    searchNotFoundTitle: {
        maxWidth: 600,
        color: "#000",
        paddingBottom: 22,
    }
}));

export default useStyles;