import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";

import {
  formGetCustomDataAction,
  formSubmitAndGetCustomDataAction,
  formSubmitAction,
} from "../actions/formActions";
import { useSelector, useDispatch } from "react-redux";
import { Form, FormSpy } from "react-final-form";

import { Select } from "mui-rff";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Blocks from "editorjs-blocks-react-renderer";
import MessageInput from "./MessageInput";
import clsx from "clsx";
import moment from "moment";
import useStyles from "../styles/CreationOpportunity";
import ArrowIcon from "../assets/stage_arrow.svg";
import Comments from "./Comments";
import { useEffect } from "react";
import useStylesSingleCard from "../styles/SingleCard";
import UpdateTeamStatusButton from "./UpdateTeamStatusButton";
import { getUnifiedDate } from "../utils/misc";

const TeamTabStages = (props) => {
  const { team } = props;
  const classes = useStyles();
  const classesSingleCard = useStylesSingleCard();
  const dispatch = useDispatch();

  const router = useSelector((state) => state.router);
  const stages = useSelector((state) => state.formReducer.customData?.stages);
  const stageStatuses = useSelector(
    (state) => state.formReducer.customData?.stageStatuses
  );
  const currentUser = useSelector((state) => state.userReducer.user);
  const data = useSelector((state) => state.formReducer.data);

  useEffect(() => {
    dispatch(
      formGetCustomDataAction({
        endpoint: `teams/${team?.id}/stages`,
        name: "stages",
      })
    );

    dispatch(
      formGetCustomDataAction({
        endpoint: `team-tab-stages-statuses/${team?.id}`,
        name: "tabStageStatuses",
      })
    );
  }, [team, dispatch]);

  useEffect(() => {}, [router]);

  const statuses = useSelector(
    (state) => state.formReducer.customData?.tabStageStatuses
  );
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      {data?.opportunity ? (
        <Grid item xs={12} className={classes.creationHeader}>
          <div className={clsx(classes.creationContainer, classes.teamHeader)}>
            <Grid>
              <div className={classes.creationType}>Call</div>
              <Typography variant="h1" className={classes.creationTitleBlock}>
                {data?.opportunity?.title}
                <div
                  className={clsx(
                    classesSingleCard.status,
                    classes.creationStatus,
                    data?.opportunity?.status?.code
                  )}
                >
                  {data?.opportunity?.status?.name}
                </div>
              </Typography>
              <div className={classes.creationDate}>
                {getUnifiedDate(data?.opportunity?.created_at)}
              </div>
            </Grid>
            <Grid className={classes.teamDateBlock}>
              <Grid item className={classes.cretionDateItem}>
                <Typography variant="h3" noWrap>
                  Start date
                </Typography>
                <Typography variant="body2" noWrap>
                  {getUnifiedDate(data?.opportunity?.start_date)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" noWrap>
                  End date
                </Typography>
                <Typography variant="body2" noWrap>
                  {getUnifiedDate(data?.opportunity?.end_date)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      ) : null}
      <Grid item xs={12} className={classes.teamBriefBody}>
        <Grid container className={classes.creationContainer}>
          <Grid className={classes.stageButtons}>
            <Grid className="buttons">
              {currentUser.id === props.teamOwner?.id ||
              currentUser.id === props.opportunityOwner?.id ? (
                <Grid className={classes.stageButtonComplete}>
                  <UpdateTeamStatusButton
                    statuses={statuses ? statuses : []}
                    teamId={props.team?.id}
                    opportunityId={props.team?.opportunity_id}
                    currentStatus={props.team?.status?.code}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item className={classes.creationBody}>
            {stages?.map((stage) => {
              return (
                <Grid item xs={12} key={stage.id} className={classes.stageItem}>
                  <Accordion>
                    <AccordionSummary>
                      <Grid className={classes.stageItemHeader}>
                        <Grid className={classes.stageItemTitle}>
                          {stage?.title}
                          <img src={ArrowIcon} alt="Arrow" />
                        </Grid>
                        <FormControlLabel
                          className={classes.stageControlLabel}
                          //aria-label="Apply"
                          onClick={(event) => event.stopPropagation()}
                          onFocus={(event) => event.stopPropagation()}
                          control={
                            <Grid className={classes.stageItemStatus} item>
                              {stageStatuses ? (
                                <Form
                                  initialValues={{ status_id: stage.status_id }}
                                  onSubmit={async (values) => {
                                    dispatch(
                                      formSubmitAction({
                                        submitType: "put",
                                        formValues: values,
                                        endpoint: `teams/${data?.id}/stages/${stage.id}`,
                                      })
                                    );
                                    dispatch(
                                      formGetCustomDataAction({
                                        endpoint: `teams/${props.team?.id}/stages`,
                                        name: "stages",
                                      })
                                    );
                                    dispatch(
                                      formGetCustomDataAction({
                                        endpoint: `team-tab-stages-statuses/${props?.team?.id}`,
                                        name: "tabStageStatuses",
                                      })
                                    );
                                  }}
                                  render={({
                                    submitError,
                                    handleSubmit,
                                    reset,
                                    submitting,
                                    pristine,
                                    values,
                                  }) => (
                                    <form
                                      className="form-signup"
                                      onSubmit={handleSubmit}
                                    >
                                      <FormSpy>
                                        {(spyProps) => {
                                          return currentUser.id ===
                                            props.teamOwner?.id ||
                                            currentUser.id ===
                                              props.opportunityOwner?.id ? (
                                            <Grid
                                              className={
                                                classes.stageStatusSelect
                                              }
                                            >
                                              {["completed", "closed"].includes(
                                                data?.status?.code
                                              ) ? null : (
                                                <Select
                                                  name="status_id"
                                                  label="Status"
                                                  formControlProps={{
                                                    margin: "none",
                                                  }}
                                                  onChange={(e) => {
                                                    spyProps.form.change(
                                                      "status_id",
                                                      e.target.value
                                                    );
                                                    spyProps.form.submit();
                                                  }}
                                                >
                                                  {stageStatuses?.map((o) => {
                                                    return (
                                                      <MenuItem
                                                        key={o.id}
                                                        value={o.id}
                                                      >
                                                        {o.name}
                                                      </MenuItem>
                                                    );
                                                  })}
                                                </Select>
                                              )}
                                            </Grid>
                                          ) : (
                                            <Grid
                                              className={classes.stageStatus}
                                            >
                                              {stage.status.name}
                                            </Grid>
                                          );
                                        }}
                                      </FormSpy>
                                    </form>
                                  )}
                                />
                              ) : null}
                            </Grid>
                          }
                        />
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid>
                        <Blocks data={JSON.parse(stage?.body)} />
                        {currentUser.id === props.teamOwner?.id ||
                        currentUser.id === props.opportunityOwner?.id ? (
                          <Accordion className={classes.stageComments}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Grid className={classes.stageCommentTitle}>
                                Comments
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails
                              className={classes.stagesDetailsComment}
                            >
                              <Grid className={classes.stagesDetailsComments}>
                                <Comments comments={stage.comments} />
                              </Grid>
                              <Grid item xs={12}>
                                <MessageInput
                                  sendAction={(dataToSend) => {
                                    dispatch(
                                      formSubmitAndGetCustomDataAction({
                                        formValues: dataToSend,
                                        endpoint: `stages/${stage.id}/comments`,
                                        getEndpoint: `teams/${props.team?.id}/stages`,
                                        name: "stages",
                                      })
                                    );
                                  }}
                                />
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ) : null}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamTabStages;
