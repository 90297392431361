import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { TextField } from "mui-rff";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-final-form";
import { useHistory, Redirect } from "react-router-dom";

import { formSubmitAction } from "../actions/formActions";

import useStyles from "../styles/Login";
import { Helmet } from "react-helmet";

const PasswordReset = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);
  const history = useHistory();

  if (user) {
    return <Redirect to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title>Reset password | weCreators</title>
      </Helmet>
      <Grid className={classes.centerForm}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Form
            onSubmit={async (values) => {

              dispatch(
                formSubmitAction({
                  formValues: values,
                  endpoint: "/auth/forgot-password",
                  redirect: "/forgot-password",
                  message:
                    "Please follow the link that you have received in your email to continue the password recovery process.",
                })
              );
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <Grid>
                <span
                  className={classes.passwordGoBack}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Back
                </span>
                <Grid item xs={12}>
                  <Typography variant="h1" className={classes.h1Var1}>
                    Forgot password?
                  </Typography>
                </Grid>
                <form className="form-signup" onSubmit={handleSubmit}>
                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email Address"
                      fullWidth
                      variant="outlined"
                      className={classes.loginText}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.btnLogin}
                    >
                      Send
                    </Button>
                  </Grid>
                </form>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordReset;
