import React from "react";
import { useSelector } from "react-redux";
import ProfileFormCreator from "../forms/ProfileFormCreator";
import ProfileFormBusiness from "../forms/ProfileFormBusiness";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";

const ProfileForm = () => {
  const user = useSelector((state) => state.userReducer.user);
  let { userId } = useParams();

  if (user.id !== parseInt(userId)) {
    return <Redirect to="/profile" />;
  }

  return user.profile_types[0].name === "Creator" ? (
    <ProfileFormCreator />
  ) : (
    <ProfileFormBusiness />
  );
};

export default ProfileForm;
