import { put, call } from "redux-saga/effects";
import * as types from "../actions";

import {
  loadMessagesService
} from "../services/chatService";

export function* loadMessagesSaga(payload) {
  try {
    const response = yield call(
      loadMessagesService,
      payload.data
    );
    yield put({ type: types.LOAD_MESSAGES_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_MESSAGES_ERROR, error });
  }
}

