import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "../../../assets/menu_icon.svg";
import AddMenu from "./AddMenu";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  Drawer,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

import logoWhite from "../../../assets/logo-white.svg";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { isBusiness, isCreator, isMaster } from "../../../utils/misc";
import { push } from "connected-react-router";
import { formGetHeaderDataAction } from "../../../actions/formActions";
import clsx from "clsx";

import { useLocation } from "react-router-dom";

const MobileMenu = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    dispatch(
      formGetHeaderDataAction({
        endpoint: `users/${user.id}/opportunities`,
        name: "opportunities",
      })
    );

    dispatch(
      formGetHeaderDataAction({ endpoint: `teams/i-member`, name: "teams" })
    );
  }, [dispatch, user]);

  useEffect(() => {
    setOpen((open) => false);
  }, [location]);

  const opportunities = useSelector(
    (state) => state.formReducer.header?.opportunities
  );

  const teams = useSelector((state) => state.formReducer?.header?.teams);

  const sideList = (
    <div
      role="presentation"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <Button
        onClick={() => {
          dispatch(push(`/`));
        }}
        className={clsx(classes.addMenuBtn, classes.addMenuMobileBtn)}
        color="secondary"
      >
        Discover
      </Button>
      <Button
        onClick={() => {
          dispatch(push(`/ideation-spaces`));
        }}
        className={clsx(classes.addMenuBtn, classes.addMenuMobileBtn)}
        color="secondary"
      >
        Ideation spaces
      </Button>
      {isMaster(user) || isCreator(user) ? (
        <Accordion className="addMenuAccordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.menuItem}
          >
            <Typography className={classes.menuItemName}>Teams</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
            className={classes.menuSubContainer}
          >
            {teams?.map((t) => (
              <Link
                key={t.id}
                to={`/calls/${t.opportunity_id}/teams/${t.id}`}
              >
                <Typography variant="body1">{t.name}</Typography>
              </Link>
            ))}
            <Link to="/teams">
              <Typography variant="body1">Discover teams</Typography>
            </Link>
          </AccordionDetails>
        </Accordion>
      ) : null}
      {isBusiness(user) ? (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.menuItem}
          >
            <Typography className={classes.menuItemName}>
              Calls
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
            className={classes.menuSubContainer}
          >
            {opportunities?.map((o) => {
              return (
                <Link key={o.id} to={`/calls/${o.id}`}>
                  <Typography variant="body1">{o.title}</Typography>
                </Link>
              );
            })}
            <Link to="/">
              <Typography variant="body1">Discover calls</Typography>
            </Link>
          </AccordionDetails>
        </Accordion>
      ) : null}
      <Divider />
      <div className={classes.menuAddLink}>
        <AddMenu />
      </div>
    </div>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        aria-controls="mobileMenu"
        className={classes.mobileMenuIcon}
        onClick={() => setOpen(true)}
      >
        <img src={MenuIcon} alt="Notifications" />
      </IconButton>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.closeMenuDrawerContainer,
        }}
      >
        <div className={classes.closeMenuDrawer}>
          <Link to="/" className={classes.closeMenuDrawerLogo}>
            <img src={logoWhite} alt="Logo" />
          </Link>
          <IconButton
            aria-label="account of current user"
            //aria-controls={menuId}
            aria-haspopup="true"
            onClick={() => setOpen(false)}
            color="#fff"
            className={classes.closeMenuDrawerIcon}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {sideList}
      </Drawer>
    </>
  );
};

export default MobileMenu;
