import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import useStyles from "../views/global/header/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "../assets/search_filter.svg";
import CloseIcon from "../assets/close.svg";
import HomeSearchFilterSingle from "./HomeSearchFilterSingle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const HomeSearchFilter = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = () => {
    setAnchorEl(document.getElementById("toggleFilterContainer"));
  };

  const filters = [
    {
      defaultLabel: "Creative fields",
      label: props.creativeFieldLabel,
      setLabel: props.setCreativeFieldLabel,
      value: props.creativeField,
      setValue: props.setCreativeField,
      choices: [
        { value: "all", label: "Select all" },
        { value: "architecture", label: "Architecture" },
        { value: "art_direction", label: "Art Direction" },
        { value: "branding", label: "Branding" },
        { value: "fashion", label: "Fashion" },
        { value: "graphic_design", label: "Graphic Design" },
        { value: "illustration", label: "Illustration" },
        { value: "industrial_design", label: "Industrial Design" },
        { value: "interaction_design", label: "Interaction Design" },
        { value: "motion_graphics", label: "Motion Graphics" },
        { value: "photography", label: "Photography" },
        { value: "u_i/_u_x", label: "UI/UX" },
        { value: "web_design", label: "Web Design" },
      ],
    },
    {
      defaultLabel: "Tags",
      label: props.tagLabel,
      setLabel: props.setTagLabel,
      value: props.tag,
      setValue: props.setTag,
      choices: [
        { value: "all", label: "Select all" },
        { value: "marketing", label: "Marketing" },
        { value: "design", label: "Design" },
        { value: "technology", label: "Technology" },
        { value: "product", label: "Product" },
      ],
    },
    {
      defaultLabel: "Interest areas",
      label: props.interestAreaLabel,
      setLabel: props.setInterestAreaLabel,
      value: props.interestArea,
      setValue: props.setInterestArea,
      choices: [
        { value: "all", label: "Select all" },
        { value: "art", label: "Art" },
        { value: "environmentalism", label: "Environmentalism" },
        { value: "space", label: "Space" },
        { value: "crafts", label: "Crafts" },
        { value: "entertainment", label: "Entertainment" },
        { value: "d_i_y", label: "DIY" },
        { value: "robotics", label: "Robotics" },
        { value: "tourism", label: "Tourism" },
        { value: "freedom", label: "Freedom" },
        {
          value: "culture_and_creative_industries",
          label: "Culture and creative industries",
        },
        { value: "education", label: "Education" },
        { value: "psychology", label: "Psychology" },
        { value: "physics", label: "Physics" },
        { value: "architecture", label: "Architecture" },
        { value: "movie_industry", label: "Movie industry" },
        { value: "literature", label: "Literature" },
        { value: "science", label: "Science" },
        { value: "the_future", label: "The Future" },
        { value: "philosophy", label: "Philosophy" },
        { value: "entrepreneurship", label: "Entrepreneurship" },
        { value: "sports_and_leisure", label: "Sports & Leisure" },
        { value: "nature", label: "Nature" },
      ],
    },
    {
      defaultLabel: "Status",
      label: props.statusLabel,
      setLabel: props.setStatusLabel,
      value: props.status,
      setValue: props.setStatus,
      choices:
        props.type === "opportunity"
          ? [
              { value: "all", label: "Select all" },
              { value: "opened", label: "Opened" },
              { value: "ongoing", label: "Ongoing" },
              { value: "done", label: "Done" },
            ]
          : [
              { value: "all", label: "Select all" },
              { value: "for_sale", label: "For sale" },
              { value: "paused", label: "Paused" },
              { value: "archived", label: "Archived" },
            ],
    },
  ];

  return (
    <>
      <div className={classes.sectionDesktop}>
        {filters.map((f) => {
          let label = f.choices?.filter(v=> v.value !== "all")?.find(v => v.value === f.value)?.label
          if(label){
            f.setLabel(label)
          } else {
            f.setLabel(f.defaultLabel)
          }

          return (
          <HomeSearchFilterSingle
            defaultLabel={f.defaultLabel}
            label={f.label}
            setLabel={f.setLabel}
            value={f.value}
            setValue={f.setValue}
            choices={f.choices}
          />
        )})}
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          edge="end"
          aria-label="filter"
          aria-controls="homeFilterMobile"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="secondary"
          className="filter-button"
        >
          {isMenuOpen ? (
            <img src={CloseIcon} alt="Close" />
          ) : (
            <img src={FilterListIcon} alt="Filter" />
          )}
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          id="homeFilterMobile"
          keepMounted
          //transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className={classes.sectionMobileFilter}
        >
          <MenuItem style={{ display: "flex", flexDirection: "column" }}>
            {filters.map((f) => {
              return (
                <Accordion
                  className={classes.sectionMobileAccordion}
                  key={f.defaultLabel}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{f.label}</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <HomeSearchFilterSingle
                      defaultLabel={f.defaultLabel}
                      label={f.label}
                      setLabel={f.setLabel}
                      value={f.value}
                      setValue={f.setValue}
                      choices={f.choices}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default HomeSearchFilter;
