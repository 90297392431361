import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 14,
    },
    centerForm: {
        display: "flex",
        alignItems: "center",
        background: "#fff",
        paddingLeft: '28px',
        paddingRight: '28px',
        fontFamily: "Gilroy",
        flexGrow: 1,
        paddingTop: 30,
        [theme.breakpoints.up("sm")]: {
            paddingLeft: '68px',
            paddingRight: '68px',
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 150,
            paddingLeft: '148px',
            paddingRight: '148px',
        },
    },
    manifestCenterForm: {
        paddingLeft: '28px',
        paddingRight: '28px',
        paddingBottom: "50px",
        overflow: "hidden",
        [theme.breakpoints.up("sm")]: {
            paddingLeft: '68px',
            paddingRight: '68px',
            paddingBottom: "0",
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: '148px',
            paddingRight: 0,
            maxWidth: 1292,
            margin: "0 auto",
        },
    },
    manifestContainerForm: {
        maxWidth: 1500,
    },
    manifestContainer: {
      maxWidth: 1138,
        margin: "0 auto",
    },
    stepOneText: {
        position: "relative",
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
        paddingBottom: 53,
        [theme.breakpoints.up("md")]: {
            paddingRight: 37,
            paddingBottom: 150,
            alignItems: "flex-start",
        }
    },
    submitText: {
        textAlign: "left",
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: "120px",
        position: "relative",
        [theme.breakpoints.up("md")]: {
            display: "block",
            paddingBottom: "0",
            paddingTop: 120,
        },
    },
    stepOneText1: {
        color: "#000",
        // fontWeight: 700,
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        marginTop: 35,
        [theme.breakpoints.up("md")]: {
            fontSize: "44px",
            marginTop: 0,
            lineHeight: "54px",
            fontWeight: 500,
        },
    },
    stepOneText2: {
        color: "#BCBCBC",
        // fontWeight: 700,
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        [theme.breakpoints.up("md")]: {
            fontSize: "44px",
            lineHeight: "54px",
            width: "690px",
        },
    },
    manifestArrowRight: {
        transform: "rotate(313deg)  scale(-1, -1)",
        position: "absolute",
        right: "0",
        top: "30px",
        [theme.breakpoints.up("md")]: {
            transform: "none",
            position: "static",
            top: "unset",
            right: "unset",
            marginTop: 7,
            marginLeft: 142,
        },
    },
    stepOneTextObj: {
        right: "0",
        maxWidth: "240px",
        top: "20px",
        transform: "rotate(210deg)  scale(-1, 1)",
        [theme.breakpoints.up("sm")]: {
            right: "0",
        },
        [theme.breakpoints.up("md")]: {
            right: "10%",
            position: "absolute",
        },
        [theme.breakpoints.up("lg")]: {
            right: "15%",
        },
    },
    stepOneCircleBtnParent: {
        position: "relative",
        paddingBottom: "330px",
        [theme.breakpoints.up("sm")]: {
            paddingBottom: "380px",
        },
        [theme.breakpoints.up("md")]: {
            paddingBottom: "0px",
        },
    },
    stepOneCircleBtnContainer: {
        padding: 0,
        borderRadius: "50%",
        backgroundColor: "none",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        position: "absolute",
        left: 0,
        right: 0,
        "&:hover": {
            backgroundColor: "none",
        },
        [theme.breakpoints.up("md")]: {
            right: 0,
            marginLeft: "auto",
            marginRight: "unset",
            position: "relative",
            left: "unset",
        },
    },
    manifestCard: {
        marginBottom: 23,
        backgroundColor: "#F5F5F5",
        border: 0,
        minWidth: 275,
        boxShadow: "none",
        borderRadius: 0,
        cursor: "pointer",
        [theme.breakpoints.up("md")]: {
            display: "block",
            marginBottom: 30,
        },
        "&:last-child": {
            marginBottom: 0,
        },
        "& .MuiCardContent-root:last-child": {
            paddingBottom: 0
        }
    },
    manifestCardBodyTextContainer: {
        display: "flex",
        padding: "27px 18px 24px 18px",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            padding: "28px 33px 65px 68px",
        },
    },
    manifestCardBodyText1: {
        fontFamily: "Gilroy",
        color: "#636363",
        marginBottom: 30,
        fontSize: "16px",
        lineHeight: "24px"
    },
    manifestCardBodyText2: {
        fontFamily: "Gilroy",
        color: "#000",
        marginBottom: 30,
        // fontWeight: "800",
        fontSize: "32px",
        lineHeight: "35px",
        [theme.breakpoints.up("md")]: {
            fontSize: "34px",
            lineHeight: "43px",
        },
    },
    manifestCardBody: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: 0,
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
    },
    manifestForm: {
        width: "100%",
        display: "flex",
        minHeight: "451px",
        paddingTop: "72px",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            minHeight: "521px",
            paddingTop: "0",
            flexDirection: "row",
            alignItems: "center",
        },
        [theme.breakpoints.up("lg")]: {
            minHeight: "721px",
            paddingTop: 4,
        },
    },
    stepOneCircleBtn: {
        backgroundColor: "#F5F5F5",
        display: "flex",
        cursor: "pointer",
        color: "#6233C0",
        fontFamily: "Gilroy",
        fontSize: "44px",
        textAlign: "center",
        borderRadius: "50%",
        lineHeight: "54px",
        marginLeft: "0",
        fontWeight: 700,
        justifyContent: "center",
        alignItems: "center",
        transition: ".2s ease",
        animation: `$btnRotate  infinite 20s linear`,
        minWidth: "321px",
        minHeight: "321px",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#6233C0"
        },
        [theme.breakpoints.up("sm")]: {
            margin: "0 auto",
            minWidth: "353px",
            minHeight: "353px",
        },
        [theme.breakpoints.up("md")]: {
            minWidth: "521px",
            minHeight: "521px",
        },
        [theme.breakpoints.up("lg")]: {
            minWidth: "721px",
            minHeight: "721px",
        },
    },
    stepOneCircleBtnText: {
        fontSize: "44px",
        textAlign: "center",
        lineHeight: "54px",
    },
    selectStylesContainer: {
        paddingTop: '40px'
    },
    selectStyles: {
        fontSize: "52px",
        lineHeight: "64px",
        backgroundColor: "#fff",
        '&>*:focus': {
            backgroundColor: "#fff",
        },
    },
    "@keyframes btnRotate": {
        "from": {
            transform: "rotate(0deg)"
        },
        "to": {
            transform: "rotate(360deg)"
        }
    },

}));

export default useStyles;