import React from "react";
import DefaultProfileImage from "../components/DefaultProfileImage";
import useStyles from "../styles/Profile";

const ProfileImage = (props) => {
  const classes = useStyles();
  return props.imageLink ? (
    <img
      src={props.imageLink}
      alt="profile"
      //cover={true}
      className={classes.profileImage}
    />
  ) : (
    <DefaultProfileImage />
  );
};

export default ProfileImage;
