export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

export const LOGIN_USER_GOOGLE = "LOGIN_USER_GOOGLE";
export const LOGIN_USER_GOOGLE_SUCCESS = "LOGIN_USER_GOOGLE_SUCCESS";
export const LOGIN_USER_GOOGLE_ERROR = "LOGIN_USER_GOOGLE_ERROR";

export const LOGIN_USER_FACEBOOK_SUCCESS = "LOGIN_USER_FACEBOOK_SUCCESS";
export const LOGIN_USER_FACEBOOK_ERROR = "LOGIN_USER_FACEBOOK_ERROR";

export const LOGIN_OAUTH_SUCCESS = "LOGIN_OAUTH_SUCCESS";
export const LOGIN_OAUTH_ERROR = "LOGIN_OAUTH_ERROR";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const USER_RESET_DATA = "USER_RESET_DATA";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR";

export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

export const FORM_SUBMIT = "FORM_SUBMIT";
export const FORM_SUBMIT_AND_GET_CUSTOM_DATA = "FORM_SUBMIT_AND_GET_CUSTOM_DATA";
export const FORM_SUBMIT_AND_GET_DATA = "FORM_SUBMIT_AND_GET_DATA";
export const FORM_SUBMIT_AND_DISPATCH_ACTION = "FORM_SUBMIT_AND_DISPATCH_ACTION";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_ERROR = "FORM_SUBMIT_ERROR";
export const FORM_RESET_DATA = "FORM_RESET_DATA";

export const FORM_UPDATE_USER = "FORM_UPDATE_USER";
export const FORM_UPDATE_USER_SUCCESS = "FORM_UPDATE_USER_SUCCESS";
export const FORM_UPDATE_USER_ERROR = "FORM_UPDATE_USER_ERROR";

export const FORM_GET_REFERENCE_VALUES = "FORM_GET_REFERENCE_VALUES";
export const FORM_GET_REFERENCE_VALUES_SUCCESS =
  "FORM_GET_REFERENCE_VALUES_SUCCESS";
export const FORM_GET_REFERENCE_VALUES_ERROR =
  "FORM_GET_REFERENCE_VALUES_ERROR";

export const FORM_GET_DATA = "FORM_GET_DATA";
export const FORM_GET_DATA_SUCCESS = "FORM_GET_DATA_SUCCESS";
export const FORM_GET_DATA_ERROR = "FORM_GET_DATA_ERROR";

export const FORM_GET_CUSTOM_DATA = "FORM_GET_CUSTOM_DATA";
export const FORM_GET_CUSTOM_DATA_SUCCESS = "FORM_GET_CUSTOM_DATA_SUCCESS";
export const FORM_GET_CUSTOM_DATA_ERROR = "FORM_GET_CUSTOM_DATA_ERROR";
export const FORM_RESET_CUSTOM_DATA = "FORM_RESET_CUSTOM_DATA";

export const FORM_GET_HEADER_DATA = "FORM_GET_HEADER_DATA";
export const FORM_GET_HEADER_DATA_SUCCESS = "FORM_GET_HEADER_DATA_SUCCESS";
export const FORM_GET_HEADER_DATA_ERROR = "FORM_GET_HEADER_DATA_ERROR";

export const FORM_GET_STATIC_PAGE = "FORM_GET_STATIC_PAGE";
export const FORM_GET_STATIC_PAGE_SUCCESS = "FORM_GET_STATIC_PAGE_SUCCESS";
export const FORM_GET_STATIC_PAGE_ERROR = "FORM_GET_STATIC_PAGE_ERROR";

export const SET_MESSAGE = "SET_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

export const REGISTER_WS = "REGISTER_WS";
export const REGISTER_WS_SUCCESS = "REGISTER_WS_SUCCESS";
export const REGISTER_WS_ERROR = "REGISTER_WS_ERROR";
export const DISCONNECT_WS_SUCCESS = "DISCONNECT_WS_SUCCESS";

export const CONNECT_WS = "CONNECT_WS";
export const CONNECT_WS_SUCCESS = "CONNECT_WS_SUCCESS";
export const CONNECT_WS_ERROR = "CONNET_WS_ERROR";
export const WS_RESET_DATA = "WS_RESET_DATA";

export const WEBSOCKET_MESSAGE_RECEIVED = "WEBSOCKET_MESSAGE_RECEIVED";

export const WS_SEND_MESSAGE = "WS_SEND_MESSAGE";
export const WS_SEND_MESSAGE_SUCCESS = "WS_SEND_MESSAGE_SUCCESS";
export const WS_SEND_MESSAGE_ERROR = "WS_SEND_MESSAGE_ERROR";

export const LOAD_MESSAGES = "LOAD_MESSAGES";
export const LOAD_MESSAGES_SUCCESS = "LOAD_MESSAGES_SUCCESS";
export const LOAD_MESSAGES_ERROR = "LOAD_MESSAGES_ERROR";
export const MESSAGES_RESET_DATA = "MESSAGES_RESET_DATA";

export const WEBSOCKET_NOTIFICATION_RECEIVED = "WEBSOCKET_NOTIFICATION_RECEIVED";
export const WEBSOCKET_POPUP_RECEIVED = "WEBSOCKET_POPUP_RECEIVED";

export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS";
export const LOAD_NOTIFICATIONS_SUCCESS = "LOAD_NOTIFICATIONS_SUCCESS";
export const LOAD_NOTIFICATIONS_ERROR = "LOAD_NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_RESET_DATA = "NOTIFICATIONS_RESET_DATA";

export const RELOAD_NOTIFICATIONS = "RELOAD_NOTIFICATIONS";
export const RELOAD_NOTIFICATIONS_SUCCESS = "RELOAD_NOTIFICATIONS_SUCCESS";
export const RELOAD_NOTIFICATIONS_ERROR = "RELOAD_NOTIFICATIONS_ERROR";

export const RESET_DATA = "RESET_DATA";


export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";