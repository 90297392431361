import React from "react";
import { withStyles} from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import clsx from "clsx";

const TooltipContent = (props) => {
  return (
    <DarkTooltip title={props.title} placement="right" className={clsx(props.className, "main-tooltip")}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="info-background"
          d="M10.335 19.67C15.4906 19.67 19.67 15.4906 19.67 10.335C19.67 5.17942 15.4906 1 10.335 1C5.17942 1 1 5.17942 1 10.335C1 15.4906 5.17942 19.67 10.335 19.67Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="info-text"
          d="M10.3438 14.0684L10.3438 10.3344"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          className="info-text"
          d="M10.3438 6.60156L10.3354 6.60156"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </DarkTooltip>
  );
};
const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    background: "#000000",
    borderRadius: 8,
    color: "#fff",
    fontFamily: "Gilroy",
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    maxWidth: 220,
    padding: "9px 13px 7px 13px",
  },
}))(Tooltip);

export default TooltipContent;
