import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    creationHeader: {
        background: "#F5F5F5",
        padding: "21px 23px 38px",
        "& h1": {
            fontSize: "32px",
            lineHeight: "39px",
            marginBottom: 14,
        },
        [theme.breakpoints.up("md")]: {
            padding: "74px 23px 56px",
        }
    },
    opportunityHeader: {
        background: "#F5F5F5",
        padding: "21px 23px 59px",
        "& h1": {
            fontSize: "32px",
            lineHeight: "39px",
            marginBottom: 14,
        },
        [theme.breakpoints.up("md")]: {
            padding: "74px 23px 88px",
        }
    },
    creationContainer: {
        "& *": {
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
        },
        [theme.breakpoints.up("md")]: {
            width: 678,
            margin: "0 auto",
            position: "relative",
        }
    },
    pageCreationBody: {
        [theme.breakpoints.down("md")]: {
            order: 2,
        },
    },
    creationContainerBody: {
        "& *": {
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
        },
        [theme.breakpoints.up("md")]: {
            width: 678,
            margin: "0 auto 0",
        },
        [theme.breakpoints.up("lg")]: {
            margin: "0 auto 0 233px",
        }
    },
    creationTitleBlock: {
      display: "flex",
      alignItems: "center",
    },
    creationType: {
        fontSize: "32px",
        lineHeight: "39px",
        color: "#BCBCBC",
    },
    creationStatus: {
        position: "relative",
        fontSize: 12,
        lineHeight: "22px",
        left: 0,
        top: 0,
        marginLeft: 24,
    },
    creationDate: {
        color: "#636363",
    },
    creationDateBlock: {
        paddingTop: 22,
        "& h3": {
            color: "#BCBCBC",
            paddingBottom: 8,
        },
        "& .MuiTypography-body2": {
            fontSize: 16,
            lineHeight: "24px",
            color: "#636363",
        },
        [theme.breakpoints.down("md")]: {
            display: "flex",
        },
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            right: "-233px",
            width: 233,
            top: 17,
            paddingLeft: 44,
            paddingTop: 0,
            boxSizing: "border-box",
        },
    },
    teamDateBlock: {
        paddingTop: 22,
        "& h3": {
            color: "#BCBCBC",
            paddingBottom: 8,
        },
        "& .MuiTypography-body2": {
            fontSize: 16,
            lineHeight: "24px",
            color: "#636363",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.up("lg")]: {
            position: "relative",
            top: "-13px",
            paddingLeft: 44,
            paddingTop: 0,
            boxSizing: "border-box",
        },
    },
    cretionDateItem: {
      paddingBottom: 19,
        [theme.breakpoints.down("md")]: {
            marginRight: 13,
        },
    },
    creationAdditional: {
        padding: "40px 23px 36px",
        boxSizing: "border-box",
        display: "flex",
        flexWrap: "wrap",
        "& .buttons": {
            width: "100%",
        },
        "& .buttons button": {
            margin: "14px 0 51px",
            width: "100%",
        },
        [theme.breakpoints.down("md")]: {
            order: 1,
        },
        [theme.breakpoints.up("md")]: {
            padding: "64px 0 36px 0",
        },
        [theme.breakpoints.up("lg")]: {
            width: 233,
            padding: "64px 24px 120px 44px",
            order: 2,
            display: "block"
        }
    },
    creationUser: {
        width: "100%",
        margin: "0 0 50px -10px",
    },
    creationUserBlock: {
        width: "100%",
        margin: "26px 0 0 -10px",
    },
    creationBody: {
        padding: "36px 23px 74px",
        width: "100%",
        wordWrap: "anywhere",
        "& h2": {
            fontSize: "26px",
            lineHeight: "34px",
            margin: "0 0 30px",
        },
        "& img": {
            maxWidth: "100%",
            display: "block",
        },
        "& p": {
            color: "#636363",
            opacity: 0.8,
            fontSize: "16px",
            lineHeight: "24px",
            margin: "0 0 48px",
        },
        "& .default-profile-avatar-container p": {
            color: "#fff",
            opacity: 1,
            fontSize: "14px",
            lineHeight: "20px",
            margin: "0",
        },
        "& p + p": {
            marginTop: "-38px",
        },
        "& figure": {
            margin: "0",
        },
        "& figure + figure": {
            marginTop: 8,
        },
        "& figure + p, & figure + h2": {
            marginTop: 68,
        },
        [theme.breakpoints.up("md")]: {
            padding: "60px 0 124px",
            "& p": {
                margin: "0 0 70px",
            },
            "& figure + figure": {
                marginTop: 20,
            },
            "& figure + p, & figure + h2": {
                marginTop: 80,
            },
            "& p + p": {
                marginTop: "-58px",
            },
        }
    },
    creationFieldTitle: {
        color: "#636363",
        fontSize: "14px",
        lineHeight: "18px",
    },
    creationField: {
        fontSize: "16px",
        lineHeight: "24px",
        marginTop: 6
    },
    creationAdditionalBlock: {
        marginBottom: 22,
        width: "50%",
        [theme.breakpoints.up("lg")]: {
            width: "100%",
        }
    },
    creationFooter: {
        background: "#F5F5F5",
        padding: "60px 23px 89px",
        display: "block",
        [theme.breakpoints.up("md")]: {
            padding: "67px 0 100px",
        }
    },
    creationFooterContainer: {
        display: "grid",
        gridGap: 50,
        gridTemplateColumns: "repeat(1, 1fr)",
        "& .buttons": {
            display: "block",
            marginTop: "-15px"
        },
        "& button": {
            margin: 0
        },
        [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
            gridGap: 20,
        }
    },
    creationFooterColumn: {
        "& h6": {
            fontWeight: 400,
            lineHeight: "24px",
            color: "#636363",
            paddingBottom: 12,
        },
        "& a": {
            textDecoration: "none",
        },
        "& a .MuiChip-root": {
            cursor: "pointer",
        },
        "& .MuiChip-root": {
            borderRadius: 4,
            border: "1px solid rgba(0, 0, 0, 0.1)",
            background: "#F5F5F5",
            height: 28,
            margin: "0 10px 10px 0",
        },
        [theme.breakpoints.up("md")]: {
            paddingBottom: 52,
        }
    },
    creationFooterPrices: {
        paddingTop: 32,
    },
    creationFooterPrice: {
        fontSize: "22px",
        lineHeight: "24px",
    },
    creationGoBack: {
        display: "flex",
        alignItems: "center",
        fontSize: "18px",
        lineHeight: "24px",
        color: "#000",
        marginBottom: 38,
        cursor: "pointer",
        "&:before": {
            content: "''",
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTkgMTJINSIgc3Ryb2tlPSIjMjUyODJCIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0xMiAxOUw1IDEyTDEyIDUiIHN0cm9rZT0iIzI1MjgyQiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48L3N2Zz4=)",
            width: 24,
            height: 24,
            marginRight: 10,
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
        }
    },
    creationFieldTxt: {
        fontSize: "12px",
        lineHeight: "16px",
    },
    creationFooterRole: {
        fontSize: "16px",
        lineHeight: "24px",
        "&:not(:first-child)": {
            paddingTop: 4
        },
        "& span": {
            color: "#636363",
            fontSize: "16px",
            lineHeight: "24px",
        }
    },
    creationInformation: {
        fontFamily: "Gilroy",
        "& .MuiTabPanel-root": {
            padding: 0,
        },
        "& *": {
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        [theme.breakpoints.down("md")]: {
            display: "grid",
            "& .MuiTabPanel-root": {
                order: 2,
            }
        },
        [theme.breakpoints.up("lg")]: {
            display: "flex",
        }
    },
    teamBriefBody: {
        background: "#fff",
        [theme.breakpoints.up("md")]: {
            "&:before": {
                content: "''",
                background: "#fff",
                position: "fixed",
                height: "100%",
                width: "100%",
                zIndex: "0",
            }
        },
    },
    teamHeader: {
        display: "flex",
        justifyContent: "space-between",
    },
    paper: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#fff",
        padding: "49px 20px 60px",
        textAlign: "center",
        overflowY: "scroll",
        display: "flex",
        alignItems: "center",
        "& .buttons": {
            marginTop: 8
        },
        "& .buttons button": {
            minWidth: 92,
        },
        [theme.breakpoints.up("sm")]: {
            width: 496,
            boxSizing: "border-box",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "49px 50px 60px",
            right: "unset",
            bottom: "unset",
            overflowY: "hidden",
        },
    },
    paperClose: {
        position: "absolute",
        top: 25,
        right: 25,
        width: 26,
        height: 26,
        display: "block",
        cursor: "pointer",
        backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNEw0IDEyIiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIi8+PHBhdGggZD0iTTQgNEwxMiAxMiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvc3ZnPg==)",
        backgroundSize: 26,
        [theme.breakpoints.up("sm")]: {
            top: 30,
            right: 30,
        },
    },
    creationModalUser: {
        "& a": {
            padding: 0,
        },
        "& .MuiTypography-body1": {
            padding: "6px 0 0",
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
        }
    },
    paperTitle: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        margin: "22px 6px 52px",
        [theme.breakpoints.up("sm")]: {
            lineHeight: "39px",
        },
    },
    paperTextField: {
        minWidth: "100%",
        "& .MuiOutlinedInput-root": {
            background: "#F5F5F5",
            padding: "14px 16px",
            minHeight: 100,
            alignItems: "normal"
        },
        "& textarea": {
            fontSize: 16,
            lineHeight: "24px",
        },
        "& .MuiFormLabel-root": {
            fontSize: 16,
            lineHeight: "24px",
            color: "#000"
        },
        "& .MuiFormLabel-root:not(.Mui-focused)": {
            top: "-7px"
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
            top: "-3px",
        }
    },
    opportunityContainer: {
        position: "relative",
        [theme.breakpoints.up("md")]: {
            maxWidth: 678,
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "initial",
        },
    },
    opportunityTabs: {
        background: "#F5F5F5",
        position: "absolute",
        left: 23,
        top: "-32px",
        padding: 0,
        "& .MuiTabs-scroller": {
            maxWidth: 678,
            margin: "0 auto",
        },
        [theme.breakpoints.up("md")]: {
            left: 0,
        },
        [theme.breakpoints.up("lg")]: {
            left: 233,
        },
    },
    opportunityButtons: {
        width: "100%",
        "& .buttons": {
            display: "block",
        },
        "& .buttons:not(:empty)": {
            paddingBottom: 10,
        },
        "& .buttons button": {
            margin: "0 0 10px",
        },
        [theme.breakpoints.down("md")]: {
            "& .buttons:not(:empty)": {
                paddingBottom: 40,
            },
        },
    },
    opportunityTooltip: {
        position: "relative",
        "& svg": {
            position: "absolute",
            top: 10,
            right: "-34px",
        },
        [theme.breakpoints.down("md")]: {
            paddingTop: 26,
            "& svg": {
                position: "absolute",
                top: 0,
                right: 0,
            },
        },
    },
    opportunityStatusButtons: {
        width: "100%",
        "& .buttons": {
            display: "block",
        },
        "& .buttons button": {
            margin: "0 0 10px",
        },
        "& svg": {
            position: "absolute",
            top: 10,
            right: "-34px",
        },
        "& .statusBlockButton": {
          position: "relative",
        },
        [theme.breakpoints.down("md")]: {
            "& svg": {
                display: "none",
            },
        },
    },
    opportunityitemStage: {
        "&:not(:first-child)": {
            marginTop: 60,
        },
        [theme.breakpoints.up("md")]: {
            "&:not(:first-child)": {
                marginTop: 94,
            },
        },
    },
    creationBodyTeam: {
        padding: "36px 23px 74px",
        width: "100%",
        wordWrap: "anywhere",
        [theme.breakpoints.up("md")]: {
            padding: "60px 0 124px",
        }
    },
    itemTeam: {
        marginBottom: 30,
        boxShadow: "none",
        "&:before": {
            display: "none",
        },
        "& .default-profile-avatar-container": {
            width: 40,
            height: 40,
        },
        "& .MuiAccordionSummary-root": {
            padding: 0,
            minHeight: "auto",
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
        "& .MuiAccordionSummary-expandIcon": {
            padding: 0,
            marginRight: 0,
        },
        "& .MuiAccordionSummary-expandIcon svg": {
            width: 20,
            height: 20,
            fill: "#000",
        },
        "& .MuiAccordionDetails-root": {
            padding: "25px 0 18px",
        }
    },
    itemTeamHead: {
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        }
    },
    itemTeamHeadInfo: {
        display: "flex",
    },
    itemTeamTitleBlock: {
      paddingLeft: 16,
    },
    itemTeamButtons: {
        "& .MuiFormControlLabel-root": {
            margin: 0,
        },
        [theme.breakpoints.up("lg")]: {
            marginRight: 15,
        }
    },
    itemTeamButton: {
        background: "#F5F5F5",
        width: 90,
        border: "1px solid #F5F5F5",
        height: 40,
        marginRight: 15,
        color: "#000",
        borderRadius: 20,
        textTransform: "initial",
        "& .MuiButton-label": {
            fontWeight: 600,
        },
        "&:hover": {
            background: "#6233C0",
            color: "#fff",
            border: "1px solid #6233C0",
        },
        "&:active": {
            background: "#fff",
            border: "1px solid #000",
            color: "#000",
        },
        "& .MuiTouchRipple-root":{
            display: "none",
        },
        "&.Mui-disabled": {
            background: "#EAEAEA",
            color: "#BCBCBC",
            cursor: "default",
        },
        "&.Mui-disabled:hover": {
            color: "#BCBCBC",
            background: "#EAEAEA",
        },
        "&.selectedTeam": {
            background: "#fff",
            border: "1px solid #000",
            color: "#000",

        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 15,
        }
    },
    itemTeamTitle: {
        margin: 0,
        "& a": {
            fontFamily: "Gilroy",
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: 500,
            color: "#000",
            textDecoration: "none",
        },
        "& a:hover": {
            color: "#6233C0"
        }
    },
    itemTeamStatus: {
        color: "#636363",
        fontFamily: "Gilroy",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 500,
        "& span": {
            color: "#6233C0",
            paddingLeft: 32,
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 500,
        }
    },
    itemTeamContent: {
        boxShadow: "0 0 8px rgba(37, 40, 43, 0.12)",
        borderRadius: 8,
        width: "100%",
        padding: "12px 24px 7px",
    },
    stageItem: {
        "&:not(:first-child)": {
            marginTop: 67,
        },
        "& .MuiPaper-root": {
            boxShadow: "none"
        },
        "& .MuiAccordionSummary-root": {
            padding: 0,
            minHeight: "auto",
            "&.Mui-expanded img":{
                transform: "rotate(180deg)",
                bottom: 12,
            },
        },
        "& .MuiAccordionDetails-root": {
            padding: 0,
        },
        "& .MuiAccordionDetails-root > div": {
            width: "100%",
        },
        "& .MuiAccordionSummary-content": {
            margin: "0 0 20px",
        },
    },
    stageItemHeader: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
    },
    stageItemTitle: {
        fontFamily: "Gilroy",
        fontSize: "26px",
        lineHeight: "34px",
        fontWeight: 500,
        paddingRight: 20,
        "& img": {
            display: "inline-block",
            marginLeft: 20,
            position: "relative",
            top: "-2px",
        }
    },
    stageControlLabel: {
        margin: 0,
    },
    stageItemStatus: {
        width: 154,
        marginTop: "-16px",
        [theme.breakpoints.up("md")]: {
            width: 236,
        }
    },
    stageButtons: {
        "& .buttons": {
            display: "block",
        },
        "& .buttons button": {
            margin: 0,
            display: "block",
        },
        "& .buttons button:disabled": {
            background: "#EAEAEA",
            color: "#BCBCBC",
            pointerEvents: "none",
        },
        [theme.breakpoints.down("md")]: {
            padding: "45px 0 0",
            width: "100%",
            "& .buttons button": {
                width: "100%",
            },
        },
        [theme.breakpoints.down("sm")]: {
            padding: "45px 23px 15px",
        },
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            right: "-209px",
            top: "60px",
        }
    },
    stageButtonComplete: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
        "& svg": {
            position: "absolute",
            right: "-31px",
            top: 10,
        },
        [theme.breakpoints.down("md")]: {
            position: "relative",
            "& svg": {
                position: "absolute",
                right: 0,
                top: "-26px",
            },
            "& .stageButtonGrid": {
                width: "100%",
            }
        }
    },
    stageComments: {
        marginTop: 30,
        "&.Mui-expanded":{
           margin: "30px 0 0",
        },
        "&:before":{
            display: "none"
        },
        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    },
    stageCommentTitle: {
        fontSize: 12,
        lineHeight: "16px",
        color: "#636363",
    },
    stagesDetailsComment: {
        boxShadow: "0 0 8px rgba(37, 40, 43, 0.12)",
        borderRadius: 8,
        marginTop: 6,
        display: "block",
        overflow: "hidden",
    },
    stagesDetailsComments: {
      height: 390,
      overflowY: "auto",
    },
    stageStatus: {
        background: "#F5F5F5",
        fontSize: 14,
        color: "#000",
        margin: 0,
        lineHeight: "18px",
        padding: "16px 18px",
        marginTop: 16,
    },
    stageStatusSelect: {
        position: "relative",
        "& .MuiAutocomplete-inputRoot": {
            paddingTop: 8,
            paddingBottom: 6
        },
        "& .MuiFormControl-root": {
            marginBottom: 0,
            minWidth: "100%",
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #BCBCBC",
            height: "100%"
        },
        "& .MuiInput-underline::after": {
            borderBottom: 0,
            height: "100%",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "1px solid #BCBCBC",
        },
        "& .MuiInputLabel-root": {
            left: 16,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            background: "#fff",
        },
        "& .MuiIconButton-label": {
            color: "#000",
        },
        "& .MuiAutocomplete-endAdornment": {
            right: 15,
        },
        "& .MuiInputBase-input": {
            padding: "14px 16px 12px",
            background: "#fff",
            fontFamily: "Gilroy",
            fontWeight: 500,
            fontSize: 16,
        },
        "& .MuiSelect-icon": {
            color: "#000",
            right: 15,
            width: 20,
            height: 20,
        },
        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input:first-child": {
            paddingLeft: 16,
            paddingRight: 16,
        }
    },
    itemTeamWinner: {
        display: "flex",
        height: 38,
        width: 95,
        borderRadius: 20,
        marginRight: 15,
        "&.karma": {
            border: "1px solid #000000",
        },
        "&.winner": {
            border: "1px solid #6233C0",
            background: "#6233C0",
            "& p": {
                color: "#fff",
            },
            "& p:before": {
                border: "1px solid #fff",
            },
            "& p:after": {
                content: "''",
                position: "absolute",
                top: 16,
                left: 14,
                borderRadius: "50%",
                width: 6,
                height: 6,
                background: "#fff",
            },
        },
        "&.disabled": {
            border: "1px solid #C4C4C4",
            "& p": {
                color: "#BCBCBC",
            },
            "& p:before": {
                border: "1px solid #BCBCBC",
            },
        },
        "& .MuiTypography-body1": {
            fontSize: 16,
            lineHeight: "38px",
            position: "relative",
            paddingLeft: 31,
        },
        "& input": {
            display: "none",
        },
        "& p:before": {
            content: "''",
            position: "absolute",
            top: 12,
            left: 10,
            borderRadius: "50%",
            width: 12,
            height: 12,
            border: "1px solid #000000",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: 15,
        }
    },
    teamMember: {
        padding: "10px 0 20px",
    },
    teamMemberUserName: {
        paddingLeft: 16,
        "& .MuiTypography-body1": {
            fontSize: "18px",
            lineHeight: "22px",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
    },
    teamMemberRole: {
        "& .MuiTypography-body1": {
            fontSize: 14,
            lineHeight: "16px",
        },
        [theme.breakpoints.up("md")]: {
            textAlign: "center",
        },
    },
}));

export default useStyles;