import React from "react";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";

const ReferenceChips = (props) => {
  return (
    <>
      <Typography variant="h6" noWrap>
        {props.title}
      </Typography>

      {props.data?.map((d) => {
        return <Chip key={d.id} label={d.name} />;
      })}
    </>
  );
};

export default ReferenceChips;

ReferenceChips.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
