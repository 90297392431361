import React from "react";
import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { formGetDataAction } from "../actions/formActions";
import { Redirect } from "react-router";
import TeamsFormWrapper from "../containers/TeamsFormWrapper";

const TeamEditWrapper = () => {
  const dispatch = useDispatch();
  let { opportunityId, teamId } = useParams();
  useEffect(() => {
    dispatch(
      formGetDataAction({
        endpoint: `opportunities/${opportunityId}/teams/${teamId}`,
      })
    );
  }, [dispatch, opportunityId, teamId]);
  const data = useSelector((state) => state.formReducer.data);
  const user = useSelector((state) => state.userReducer.user);
  if (!data) {
    return <CircularProgress className="main-progress-bar" />;
  }

  if (data.created_by?.id !== user.id) {
    return <Redirect to={`/calls/${opportunityId}/teams/${teamId}`} />;
  }

  return <TeamsFormWrapper initialValues={data} edit={true} />;
};

export default TeamEditWrapper;
