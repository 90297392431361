import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    messageFileAttachments: {
        display: "flex",
        padding: "7px 2px 8px 0",
        justifyContent: "space-between",
    },
    messageFileName: {
        fontSize: 16,
        lineHeight: "24px",
        wordWrap: "anywhere",
    },
    messageFileAttachmentsBtn: {
        width: 89,
        height: 89,
        minWidth: 89,
        background: "#6A3FEB",
        borderRadius: 8,
        marginLeft: 14,
        display: "block",
        position: "relative",
        "&:before": {
            content: "''",
            position: "absolute",
            top: 28,
            left: 29,
            width: 30,
            height: 30,
            border: "1px solid #fff",
            borderRadius: 16,
        },
        "&:after": {
            content: "''",
            width: 8,
            height: 11,
            display: "block",
            position: "absolute",
            top: 39,
            left: 41,
            backgroundImage: "url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDggMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTMuNjQ2NDUgMTAuMzUzNkMzLjg0MTcxIDEwLjU0ODggNC4xNTgyOSAxMC41NDg4IDQuMzUzNTUgMTAuMzUzNkw3LjUzNTUzIDcuMTcxNTdDNy43MzA4IDYuOTc2MzEgNy43MzA4IDYuNjU5NzMgNy41MzU1MyA2LjQ2NDQ3QzcuMzQwMjcgNi4yNjkyIDcuMDIzNjkgNi4yNjkyIDYuODI4NDMgNi40NjQ0N0w0IDkuMjkyODlMMS4xNzE1NyA2LjQ2NDQ3QzAuOTc2MzExIDYuMjY5MiAwLjY1OTcyOSA2LjI2OTIgMC40NjQ0NjYgNi40NjQ0N0MwLjI2OTIwNCA2LjY1OTczIDAuMjY5MjA0IDYuOTc2MzEgMC40NjQ0NjYgNy4xNzE1N0wzLjY0NjQ1IDEwLjM1MzZaTTMuNSAyLjE4NTU3ZS0wOEwzLjUgMTBMNC41IDEwTDQuNSAtMi4xODU1N2UtMDhMMy41IDIuMTg1NTdlLTA4WiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=)",
        },
        [theme.breakpoints.up("md")]: {
            marginLeft: 17,
        },
    },
    messageFileInfo: {
        display: "flex",
        justifyContent: "space-between",
        color: "#BCBCBC",
        "& *": {
            fontSize: 10,
            lineHeight: "12px",
        },
        "& a": {
            textDecoration: "underline",
            "&:hover": {
                textDecoration: "none",
            }
        }
    },
    messageFileDate: {
        paddingLeft: 10,
        fontSize: "10px!important",
        lineHeight: "12px!important",
        color: "#BCBCBC",

    },
    messageComment: {
        display: "flex",
        justifyContent: "space-between",
        padding: "14px 3px 2px 0",
        alignItems: "center"
    }
}));

export default useStyles;