import React from "react";
import { Button } from "@material-ui/core";
import DarkTooltip from "./DarkTooltip";
import { useDispatch } from "react-redux";
import { formSubmitAndGetDataAction } from "../actions/formActions";

const statusData = (data) => {

  const { code, currentStatus } = data;
  switch (code) {
    case "draft":
      return {
        code: "draft",
        title: "Draft",
        tooltip: "tooltip for draft",
      };
    case "consideration":
      return {
        code: "consideration",
        title: "Publish",
        tooltip: "After moving this creation to consideration wait till your creation will be approved by admin",
        forceTooltip: true,
      };
    case "approved":
      return {
        code: "approved",
        title: "Approve",
        tooltip: "tooltip for approved",
      };
    case "for_sale":
      return {
        code: "for_sale",
        title: "For sale",
        tooltip: "tooltip for sale",
      };
    case "paused":
      return {
        code: "paused",
        title: "Paused",
        tooltip: "tooltip for paused",
      };
    case "denied":
      return {
        code: "denied",
        title: "Deny",
        tooltip: "tooltip for denied",
      };
    case "archived":
      return {
        code: "archived",
        title: "Archive",
        tooltip: "tooltip for archived",
      };

    default:
      return {
        code: "",
        title: "",
        tooltip: "",
      };
  }
};

const UpdateCreationStatusButton = (props) => {
  const { statuses, creationId, currentStatus } = props;
  const dispatch = useDispatch();
  let nextStatuses = statuses.filter((s) => s.code !== "denied");
  return nextStatuses.map((s) => {
    return (
      <div key={s.id}  className="statusBlockButton">
        <Button
          className={statusData({ code: s.code, currentStatus }).code}
          disabled={!s.available}
          onClick={() => {
            dispatch(
              formSubmitAndGetDataAction({
                formValues: { status_id: s.id },
                submitType: "put",
                endpoint: `/creations/${creationId}`,
                getEndpoint: `/creations/${creationId}`,
              })
            );
          }}
        >
          {statusData({ code: s.code, currentStatus }).title}
        </Button>
        {!s.available ||
          statusData({ code: s.code, currentStatus }).forceTooltip ? (
          <DarkTooltip
            title={statusData({ code: s.code, currentStatus }).tooltip}
          />
        ) : null}
      </div>
    );
  });
};

export default UpdateCreationStatusButton;
