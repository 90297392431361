import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { Grid, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "../styles/Login";
import { push } from "connected-react-router";
import { Helmet } from "react-helmet";
import clsx from "clsx";

const PasswordForgot = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer.user);
  const history = useHistory();

  if (user) {
    return <Redirect to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title>Forgot password | weCreators</title>
      </Helmet>
      <Grid className={classes.centerForm}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid>
            <span
              className={classes.passwordGoBack}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </span>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.h1Var1}>
                Forgot password?
              </Typography>
            </Grid>
            <div className="form-signin">
              <Grid item xs={12}>
                <p
                  className={clsx(
                    "mt-3 mb-3 text-muted text-center",
                    classes.textPasswordForgot
                  )}
                >
                  Password recovery information has been sent to your email
                  address.
                </p>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnLogin}
                  onClick={() => dispatch(push(`/login`))}
                >
                  Done
                </Button>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PasswordForgot;
