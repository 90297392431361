import {makeStyles} from "@material-ui/core/styles";
import btnBg from "../assets/btn-bg-test.svg";

const useStyles = makeStyles((theme) => ({
    mainContainerStep: {
        minHeight: "calc(100vh - 108px)",
        display: "flex",
    },
    centerForm: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 50,
        background: "#fff",
        paddingLeft: '28px',
        paddingRight: '28px',
        flexGrow: 1,
        [theme.breakpoints.up("md")]: {
            paddingBottom: 96,
            paddingLeft: '148px',
            paddingRight: '88px',
            paddingTop: 13,
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: 1440,
            margin: "0 auto",
            boxSizing: "border-box"
        },
    },
    stepOneText: {
        position: "relative",
        "@media (min-width:1440px)": {
            marginTop: 41,
        },
        [theme.breakpoints.up("lg")]: {
            marginRight: 37,
            width: 446,
            paddingRight: 20,
            boxSizing: "border-box",
        },
    },
    stepOneText1: {
        fontSize: "32px",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Gilroy",
        lineHeight: "35px",
        marginTop: 35,
        [theme.breakpoints.up("md")]: {
            fontSize: "44px",
            lineHeight: "54px",
        },
        [theme.breakpoints.up("lg")]: {
            marginTop: 0,
        },
    },
    stepOneText2: {
        fontSize: "32px",
        color: "#BCBCBC",
        fontWeight: 500,
        fontFamily: "Gilroy",
        lineHeight: "35px",
        marginTop: 6,
        [theme.breakpoints.up("md")]: {
            fontSize: "44px",
            lineHeight: "54px",
        },
        "@media (min-width:1440px)": {
            marginTop: 0,
        },
    },
    stepOneTextObj: {
        display: "block",
        [theme.breakpoints.down("md")]: {
            transform: "rotate(138deg)",
            margin: "36px auto 42px",
        },
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            right: 0,
            bottom: "-153px",
        },
        "@media (min-width:1440px)": {
            right: 33,
        },
    },
    arrowProfileCreative: {
        [theme.breakpoints.up("lg")]: {
            transform: "rotate(-33deg)",
            bottom: "-160px",
            left: 0,
        },
    },
    stepOneCircleBtnContainer: {
        height: "100%",
        overflow: "hidden"
    },
    stepOneCircleBtn: {
        borderRadius: "100%",
        background: `url(${btnBg})`,
        backgroundPosition: "center",
        display: "flex",
        width: "321px",
        height: "321px",
        cursor: "pointer",
        color: "#fff",
        fontFamily: "Gilroy",
        fontSize: "32px",
        textAlign: "center",
        lineHeight: "35px",
        fontWeight: 600,
        justifyContent: "center",
        alignItems: "center",
        animation: `$btnRotate  infinite 20s linear`,
        marginTop: "20px",
        textTransform: "uppercase",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#6233C0",
            backgroundImage: "none"
        },
        "& span": {
            maxWidth: 250,
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0 auto"
        },
        [theme.breakpoints.up("md")]: {
            width: "421px",
            height: "421px",
            marginLeft: "auto"
        },
        [theme.breakpoints.up("lg")]: {
            width: "521px",
            height: "521px",
            fontSize: "44px",
            lineHeight: "54px",
            "& span": {
                maxWidth: 420,
            },
        },
        "@media (min-width:1440px)": {
            width: "721px",
            height: "721px",
        },
    },
    containerStepOne: {
        [theme.breakpoints.up("lg")]: {
            display: "flex",
            alignItems: "center",
        },
    },
    stepOneCircleBtnText: {
        fontSize: "44px",
        textAlign: "center",
        lineHeight: "54px",
    },
    selectStylesContainer: {
        paddingTop: '40px'
    },
    selectStyles: {
        fontSize: "52px",
        lineHeight: "64px",
        backgroundColor: "#fff",
        '&>*:focus': {
            backgroundColor: "#fff",
        },
    },
    "@keyframes btnRotate": {
        "from": {
            transform: "rotate(0deg)"
        },
        "to": {
            transform: "rotate(360deg)"
        }
    },

}));

export default useStyles;