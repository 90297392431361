import React, {useRef} from "react";
import { Link, Redirect, useParams } from "react-router-dom";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "mui-rff";
import { Form } from "react-final-form";
import { formSubmitAction } from "../actions/formActions";
import useStyles from "../styles/Login";
import { Helmet } from "react-helmet";
import { sleep } from "../utils/misc";

const AccountVerify = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const stateRef = useRef();
  let { hash } = useParams();
  const user = useSelector((state) => state.userReducer.user);
  let errors = useSelector((state) => state.errorReducer.error);
  stateRef.current = errors;

  if (user) {
    return <Redirect to="/profile" />;
  }

  return (
    <>
      <Helmet>
        <title>Verify account | weCreators</title>
      </Helmet>
      <Paper className={classes.centerForm}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Form
            initialValues={{
              hash: hash,
            }}
            onSubmit={async (values) => {
              dispatch(
                formSubmitAction({
                  formValues: values,
                  endpoint: "/auth/verify",
                  redirect: "/login",
                  message: "Account verified successfully"
                })
              );
              await sleep(600);
              return stateRef.current;
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => (
              <form className="form-signup" onSubmit={handleSubmit}>
                <Grid item xs={12}>
                  <Typography variant="h1" className={classes.h1Var1}>
                    Verify account
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email address"
                    variant="outlined"
                    className={classes.loginText}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.btnLogin}
                  >
                    Verify
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <p className={classes.textSignup}>
                    Already registered ? <Link to="/login">Sign in here</Link>
                  </p>
                </Grid>
              </form>
            )}
          />
        </Grid>
      </Paper>
    </>
  );
};

export default AccountVerify;
