import { useEffect } from "react";
import { useSelector } from "react-redux";

const NotificationsWrapper = ({ children }) => {
  const notification = useSelector((state) => state.notificationsReducer?.last);
  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }, []);
  useEffect(() => {
    if (notification) {
      showNotification(notification);
    }
  }, [notification]);

  const showNotification = (notification) => {
    var data = {
      body: notification?.message,
      dir: "ltr",
    };

    new Notification("weCreators", data);
  };

  return <>{children}</>;
};

export default NotificationsWrapper;
