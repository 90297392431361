import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formGetReferenceTableValuesAction } from "../actions/formActions";
import { CircularProgress } from "@material-ui/core";
import IdeationPostForm from "../forms/IdeationPostForm";

const IdeationPostFormWrapper = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-interest-areas",
      })
    );
  }, [dispatch]);

  const references = useSelector((state) => state.formReducer.references);
  if (
    !references?.["classifiers-interest-areas"]
  ) {
    return <CircularProgress className="main-progress-bar" />;
  }

  return <IdeationPostForm {...props} />;
};

export default IdeationPostFormWrapper;
