import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Typography } from "@material-ui/core";
import { useEffect } from "react";

import {
  formUpdateUserAction,
  formGetReferenceTableValuesAction,
} from "../actions/formActions";
import { Form } from "react-final-form";

import useStyles from "../styles/Manifests";
import arrowRight from "../assets/creative_test_arrow_right.svg";
import clsx from "clsx";

const ManifestForm = () => {
  const dispatch = useDispatch();
  window.scrollTo(0, 0);

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-creative-test-results",
      })
    );
  }, [dispatch]);

  const userId = useSelector((state) => state.userReducer.user.id);
  const classes = useStyles();

  return (
    <Grid alignItems="center" className={classes.manifestCenterForm}>
      <Form
        initialValues={{ is_manifest_agree: 1 }}
        onSubmit={(values) =>
          dispatch(
            formUpdateUserAction({
              endpoint: "/users",
              submitType: "put",
              formValues: values,
              id: userId,
              redirect: "/",
            })
          )
        }
        render={({
          handleSubmit,
          submitError,
          form,
          submitting,
          pristine,
          values,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid className={classes.manifestForm}>
                <Grid item xs={12} md={3} className={classes.submitText}>
                  <Typography className={classes.stepOneText1} noWrap>
                    Press it
                  </Typography>
                  <img
                    src={arrowRight}
                    alt="arrow-right"
                    className={classes.manifestArrowRight}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={9}
                  className={classes.stepOneCircleBtnParent}
                >
                  <Button
                    className={classes.stepOneCircleBtnContainer}
                    type="submit"
                  >
                    <Typography
                      variant="body2"
                      noWrap
                      className={clsx("rotate", classes.stepOneCircleBtn)}
                    >
                      I AGREE
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Grid>
  );
};

export default ManifestForm;
