import * as types from "../actions";
/* import { LOCATION_CHANGE } from "connected-react-router"; */

const initState = {
  error: null,
  isOpen: false,
};

export default function errorReducer(state = initState, action) {
  const { error } = action;

  switch (action.type) {
    case types.SET_ERROR:
      if (error?.error) {
        return {
          ...state,
          error: error?.error,
          isOpen: true,
        };
      } else {
        return {
          ...state,
          error: error,
          isOpen: false,
        };
      }

    case types.HIDE_ERROR:
      return {
        ...state,
        error: null,
        isOpen: false,
      };
/*     case LOCATION_CHANGE:
      return initState; */

    default:
      return state;
  }
}
