import React from "react";

import useStyles from "../styles/Comments";
import { Grid } from "@material-ui/core";

import MessagesList from "./MessagesList";

const Comments = (props) => {
  let { comments } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.chatBody}>
      <MessagesList data={comments} />
    </Grid>
  );
};
export default Comments;
