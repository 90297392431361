import React from "react";

import Wizard from "../components/Wizard";
import useStyles from "../styles/CreationOpportunityTeamForm";
import { TextField, Select } from "mui-rff";
import { Grid, MenuItem, Typography, Tooltip } from "@material-ui/core";
import { FormSpy } from "react-final-form";
import { useSelector } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import UserCard from "../components/UserCard";

const TeamsFormPage3 = (props) => {
  const {
    setProfitSplit,
    opportunityBudget,
    maxProfit,
    setMaxProfit,
    budget,
    placeholderSymbol,
    opportunity,
  } = props;

  const classes = useStyles();
  const references = useSelector((state) => state.formReducer.references);

  return (
    <Wizard.Page>
      <Grid container className={classes.wizardTeamContainer}>
        <Grid item xs={12}>
          <div className={classes.titleRoles}>
            <Typography variant="body2" className={classes.creationTitleField}>
              Select terms of profit split
            </Typography>
            <Tooltip title="Distribute the total amount of reward among the project participants">
              <InfoOutlinedIcon />
            </Tooltip>
          </div>

          <FormSpy>
            {(spyProps) => {
              if (opportunity?.award_type?.code === "voluntary") {
                spyProps.form.change(
                  "terms_of_profit_id",

                  references?.["classifiers-team-terms-of-profit"]?.reduce(
                    (acc, cur) => {
                      if (cur.code === "fixed_price") {
                        acc = cur.id;
                      }
                      return acc;
                    },
                    ""
                  )
                );

                spyProps.values?.opportunity_roles?.forEach((role, index) => {
                  spyProps.form.change(`opportunity_roles[${index}].amount`, 0);
                });
              }
              return references?.["classifiers-team-terms-of-profit"] ? (
                <Grid className={classes.CreationSelectBlock}>
                  <Select
                    disabled={opportunity?.award_type?.code === "voluntary"}
                    name="terms_of_profit_id"
                    label="Terms of profit split"
                    formControlProps={{ margin: "none" }}
                    required={true}
                    onChange={(e) => {
                      spyProps.form.change(
                        "terms_of_profit_id",
                        parseInt(e.target.value)
                      );
                      setProfitSplit((profitSplit) =>
                        references?.[
                          "classifiers-team-terms-of-profit"
                        ]?.reduce((acc, cur) => {
                          if (cur.id === parseInt(e.target.value)) {
                            acc = cur.code;
                          }
                          return acc;
                        }, "")
                      );
                      spyProps.values?.opportunity_roles?.forEach(
                        (role, index) => {
                          spyProps.form.change(
                            `opportunity_roles[${index}].amount`,
                            null
                          );
                        }
                      );
                    }}
                  >
                    {references?.["classifiers-team-terms-of-profit"]?.map(
                      (o) => (
                        <MenuItem value={o.id} key={o.id}>
                          {`

                            ${o.name}
                            ${o.code === "percentage" ? 100 : opportunityBudget}
                            ${o.code === "percentage" ? "%" : "EUR"}

                            `}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Grid>
              ) : null;
            }}
          </FormSpy>
        </Grid>
        <FieldArray name="opportunity_roles" label="Call roles">
          {({ fields }) => {
            return (
              <Grid item xs={12} className={classes.teamOpportunityRoles}>
                {fields.map((name, index) => {
                  return (
                    <div
                      key={name}
                      className={classes.teamItemOpportunityRoles}
                    >
                      <FormSpy subscription={{ values: `${name}.amount` }}>
                        {(spyProps) => {
                          let totalUsed =
                            spyProps.values?.opportunity_roles?.reduce(
                              (acc, cur) => {
                                let parsedAmount = parseInt(cur.amount || 0);
                                return acc + parsedAmount;
                              },
                              0
                            );
                          setMaxProfit((maxProfit) => budget - totalUsed);

                          return spyProps.values.terms_of_profit_id ? (
                            <>
                              <Grid container className={classes.teamUserRoles}>
                                <Grid item>
                                  {spyProps.values.opportunity_roles[index]
                                    ?.role
                                    ? spyProps.values.opportunity_roles[index]
                                        ?.role?.name
                                    : references[
                                        "classifiers-opportunity-roles"
                                      ]?.reduce((acc, cur) => {
                                        if (
                                          cur.id ===
                                          spyProps.values.opportunity_roles[
                                            index
                                          ]?.role_id
                                        ) {
                                          acc = cur.name;
                                        }
                                        return acc;
                                      }, "")}
                                </Grid>

                                <Grid item>
                                  <UserCard
                                    user={
                                      spyProps.values.opportunity_roles[index]
                                        ?.member
                                    }
                                  />
                                </Grid>
                              </Grid>

                              <Grid className={classes.CreationSelectBlock}>
                                <TextField
                                  disabled={
                                    opportunity?.award_type?.code ===
                                    "voluntary"
                                  }
                                  type="number"
                                  name={`${name}.amount`}
                                  label={`Enter amount (out of ${maxProfit} ${placeholderSymbol})`}
                                  placeholder={`Enter amount (out of ${maxProfit} ${placeholderSymbol})`}
                                  onChange={(e) => {
                                    let totalUsed =
                                      spyProps.values?.opportunity_roles?.reduce(
                                        (acc, cur, i) => {
                                          let parsedAmount;
                                          if (i === index) {
                                            parsedAmount = parseInt(
                                              e.target.value
                                            );
                                          } else {
                                            parsedAmount = parseInt(
                                              cur.amount || 0
                                            );
                                          }
                                          return acc + parsedAmount;
                                        },
                                        0
                                      );

                                    let amount =
                                      parseInt(e.target.value) >
                                      budget -
                                        totalUsed +
                                        parseInt(e.target.value)
                                        ? budget -
                                          totalUsed +
                                          parseInt(e.target.value)
                                        : parseInt(e.target.value);

                                    spyProps.form.change(
                                      `${name}.amount`,
                                      amount
                                    );
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                      max:
                                        budget -
                                        totalUsed +
                                        parseInt(
                                          spyProps.values.opportunity_roles[
                                            index
                                          ]?.amount || 0
                                        ),
                                    },
                                  }}
                                />
                              </Grid>
                            </>
                          ) : null;
                        }}
                      </FormSpy>
                    </div>
                  );
                })}
              </Grid>
            );
          }}
        </FieldArray>
      </Grid>
    </Wizard.Page>
  );
};

export default TeamsFormPage3;
