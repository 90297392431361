import * as types from "./index";

export const connectToSocket = (data) => {
  return {
    type: types.CONNECT_WS,
    data,
  };
};

export const sendMessage = (data) => {
  return {
    type: types.WS_SEND_MESSAGE,
    data,
  };
};


