import { put, call } from "redux-saga/effects";
import * as types from "../actions";

import {
  loadNotificationsService
} from "../services/notificationsService";

export function* loadNotificationsSaga(payload) {
  try {

    const response = yield call(
      loadNotificationsService,
      payload.data
    );
    yield put({ type: types.LOAD_NOTIFICATIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.LOAD_NOTIFICATIONS_ERROR, error });
  }
}


export function* reloadNotificationsSaga(payload) {
  try {

    const response = yield call(
      loadNotificationsService,
      payload.data
    );
    yield put({ type: types.RELOAD_NOTIFICATIONS_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.RELOAD_NOTIFICATIONS_ERROR, error });
  }
}