import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  formSubmitAction,
  formGetCustomDataAction,
  formSubmitAndGetCustomDataAction,
  formSubmitAndGetDataAction,
} from "../actions/formActions";
import useStyles from "../styles/CreationOpportunity";
import TeamMember from "./TeamMember";
import clsx from "clsx";

const OpportunityTabTeamsApply = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.formReducer.data);
  const teams = useSelector((state) => state.formReducer.customData?.teams);
  const classes = useStyles();

  return (
    <Grid>
      {teams?.map((team) => {
        return (
          <Accordion key={team.id} className={classes.itemTeam}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container className={classes.itemTeamHead}>
                <Grid className={classes.itemTeamHeadInfo}>
                  <div
                    className={clsx(
                      "default-profile-avatar-container",
                      `profile-gradient-${team?.id % 15}`
                    )}
                  ></div>
                  <Grid className={classes.itemTeamTitleBlock}>
                    <FormControlLabel
                      aria-label="Apply"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      className={classes.itemTeamTitle}
                      control={
                        <Link
                          to={`/calls/${data?.id}/teams/${team.id}`}
                        >
                          {team.name}
                        </Link>
                      }
                    />
                    <Grid className={classes.itemTeamStatus}>
                      Team status
                      <span>{team.status?.name}</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.itemTeamButtons}>
                  <Grid container>
                    <Button
                        className={clsx(classes.itemTeamButton, team.rejected_at ? "selectedTeam" : "")}
                      disabled={
                        data?.opportunity_type?.code === "one_team_apply"
                          ? team?.rejected_at ||
                            team?.applied_at ||
                            team?.status?.code !== "formed"
                          : team?.rejected_at || team?.status?.code !== "formed"
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        dispatch(
                          formSubmitAndGetDataAction({
                            formValues: null,
                            endpoint: `opportunities/${data?.id}/teams/${team.id}/reject`,
                            getEndpoint: `opportunities/${data?.id}`,
                          })
                        );

/*                         dispatch(
                          formGetCustomDataAction({
                            endpoint: `opportunity-statuses/${data?.id}`,
                            name: "statuses",
                          })
                        ); */
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      className={clsx(
                        classes.itemTeamButton, team.applied_at ? "selectedTeam" : ""
                      )}
                      disabled={
                        team?.applied_at || team?.status?.code !== "formed"
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        if (data?.opportunity_type?.code === "one_team_apply") {
                          let rejectedTeamsIds = teams?.reduce((acc, cur) => {
                            if (cur.id !== team.id) {
                              acc = [...acc, cur.id];
                            }
                            return acc;
                          }, []);
                          dispatch(
                            formSubmitAction({
                              endpoint: `opportunities/${data?.id}/teams/${team.id}/apply`,
                            })
                          );

                          dispatch(
                            formSubmitAndGetDataAction({
                              formValues: {ids:rejectedTeamsIds},
                              endpoint: `opportunities/${data?.id}/teams-reject`,
                              getEndpoint: `opportunities/${data?.id}`,
                            })
                          );
                        } else {
                          dispatch(
                            formSubmitAndGetDataAction({
                              formValues: null,
                              endpoint: `opportunities/${data?.id}/teams/${team.id}/apply`,
                              getEndpoint: `opportunities/${data?.id}`,
                            })
                          );
                        }

/*                         dispatch(
                          formGetCustomDataAction({
                            endpoint: `opportunity-statuses/${data?.id}`,
                            name: "statuses",
                          })
                        ); */
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid className={classes.itemTeamContent}>
                {team.pending?.map((m) => (
                  <Grid item xs={12} key={m.id}>
                    <TeamMember
                      pending={true}
                      member={m}
                      opportunity={data?.id}
                      teamId={team.id}
                    />
                  </Grid>
                ))}
                {team.members?.map((m) => (
                  <Grid item xs={12} key={m.id}>
                    <TeamMember
                      member={m}
                      opportunity={data?.id}
                      teamId={team.id}
                    />
                  </Grid>
                ))}
                {team.unusedRoles?.map((m) => (
                  <Grid item xs={12} key={m.id}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.teamMember}
                    >
                      <Grid item xs={12} md={5}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          className={classes.teamMemberUser}
                        >
                          <div
                            className={clsx(
                              "default-profile-avatar-container",
                              "open-role"
                            )}
                          ></div>
                          {/*                           <ProfileAvatar
                            imageLink={mm?.member?.profile_image?.link}
                            user={m?.member}
                          /> */}
                          <Grid className={classes.teamMemberUserName}>
                            <Typography variant="body1">Open role</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className={classes.teamMemberEmail}
                      ></Grid>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        className={classes.teamMemberRole}
                      >
                        <Typography variant="body1">{m?.role?.name}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Grid>
  );
};

export default OpportunityTabTeamsApply;
