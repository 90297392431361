import React from 'react';
import useStyles from "../styles/ProfileCard";
import SingleCard from "./SingleCard";
import { Typography } from "@material-ui/core";

const ProfileCard = (props) => {
	const classes = useStyles();

	return props.data?.length ? (
		<div style={{ flex: "grow" }}>
			<Typography variant="h3" className={classes.profileCardTitle}>{props.title}<span>{props.data?.length}</span></Typography>
			<div className={classes.mainContainer}>
				{props.data?.map((d) => (
					<SingleCard data={d} key={d.id} />
				))}
			</div>
		</div>
	) : null;
}

export default ProfileCard;