import React from "react";
import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { formGetDataAction } from "../actions/formActions";
import { Redirect } from "react-router";
import CreationFormWrapper from "../containers/CreationFormWrapper";

const CreationEditWrapper = () => {
  const dispatch = useDispatch();
  let { creationId } = useParams();
  useEffect(() => {
    dispatch(formGetDataAction({ endpoint: `creations/${creationId}` }));
  }, [dispatch, creationId]);
  const data = useSelector((state) => state.formReducer.data);
  const user = useSelector((state) => state.userReducer.user);
  if (!data) {
    return <CircularProgress className="main-progress-bar" />;
  }

  if (data.created_by.id !== user.id) {
    return <Redirect to={`/creation/${creationId}`} />;
  }

  let modifiedData = { ...data, body: JSON.parse(data.body) };

  return <CreationFormWrapper initialValues={modifiedData} edit={true} />;
};

export default CreationEditWrapper;
