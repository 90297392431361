import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/CreationOpportunity";
import ReferenceChips from "../components/ReferenceChips";
import AttachmentChips from "../components/AttachmentChips";
import { useSelector } from "react-redux";
import ReferenceChipsSearch from "./ReferenceChipsSearch";

const OpportunityFooter = (props) => {
  const { data } = props;
  const classes = useStyles();
  const references = useSelector((state) => state.formReducer.references);
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.creationFooter}
    >
      <Grid className={classes.creationContainer}>
        <Grid className={classes.creationFooterContainer}>
          <Grid className={classes.creationFooterColumn}>
            <AttachmentChips title="Attachments" data={data.files} />
          </Grid>
          <Grid className={classes.creationFooterColumn}>
            <ReferenceChipsSearch
                title="Interest areas"
                data={ references["classifiers-interest-areas"] ? references["classifiers-interest-areas"] : []}
                type="opportunity"
                param="interestArea"
            />
          </Grid>
          <Grid className={classes.creationFooterColumn}>
            <ReferenceChipsSearch
              title="Tags"
              data={references["classifiers-opportunity-tags"] ? references["classifiers-opportunity-tags"] : []}
              type="opportunity"
              param="tag"
            />
          </Grid>
          <Grid className={classes.creationFooterColumn}>
            <ReferenceChipsSearch
              title="Creative fields"
              data={references["classifiers-creative-fields"] ? references["classifiers-creative-fields"] : []}
              type="opportunity"
              param="creativeField"
            />
            <Grid className={classes.creationFooterPrices}>
              <Typography variant="h6">Price</Typography>
              <Typography
                  variant="body1"
                  className={classes.creationFooterPrice}
              >
                € {data.budget}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityFooter;
