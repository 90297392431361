import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../styles/CreationOpportunity";
import Blocks from "editorjs-blocks-react-renderer";

const OpportunityTabBrief = (props) => {
  const classes = useStyles();
  const { data } = props;

  return (
    <Grid container className={classes.creationContainerBody}>
      <Grid item className={classes.creationBody}>
        {data?.body ? <Blocks data={JSON.parse(data.body)} /> : null}
      </Grid>
    </Grid>
  );
};

export default OpportunityTabBrief;
