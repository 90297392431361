import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ProfileCover from "../components/ProfileCover";
import ProfileOnlinePresence from "../components/ProfileOnlinePresence";
import ProfileBio from "../components/ProfileBio";
import ProfileChips from "../components/ProfileChips";
import { push } from "connected-react-router";
import ProfileImage from "../components/ProfileImage";
import ProfileCard from "../components/ProfileCard";
import useStyles from "../styles/Profile";
import { Helmet } from "react-helmet";
import AttachmentChips from "../components/AttachmentChips";
import { setCookie, getExpires, getCookie } from "../utils/misc";
import { isMaster } from "../utils/misc";
import premium from "../assets/premium.svg"

const Profile = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { user } = props;
  const currentUser = useSelector((state) => state.userReducer.user);
  const creations = useSelector(
    (state) => state.formReducer.customData?.creations
  );
  const opportunities = useSelector(
    (state) => state.formReducer.customData?.opportunities
  );

  const showBanner = getCookie("profile_banner") ? false : true;

  const [open, setOpen] = useState(showBanner);

  const imgProfileSrc = user?.profile_image
    ? `${process.env.REACT_APP_SWIFT_STORAGE_URL}${process.env.REACT_APP_SWIFT_CONTAINER_NAME}/${user?.profile_image?.link}`
    : null;


  const closeBanner = () => {
    let expirationDate = getExpires(30);
    setOpen(false);
    setCookie("profile_banner", "false", expirationDate);
  };

  return (
    <>
      <Helmet>
        <title>{`${user?.name} | weCreators`}</title>
      </Helmet>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <ProfileCover user={user} />
        </Grid>
        <Grid item xs={12} className={classes.profileUser}>
          <ProfileImage imageLink={imgProfileSrc} />
          <Typography variant="h1" className={classes.profileUserName}>
            {user?.first_name} {user?.last_name} <span>@{user?.name}</span>
            {isMaster(user) ? <img src={premium} alt="premium" style={{marginLeft: 19}}/> : null}
          </Typography>

          {user?.id === currentUser.id ? (
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                className="invert"
                onClick={() => dispatch(push(`/profile/edit/${user?.id}`))}
              >
                Edit profile
              </Button>
            </div>
          ) : null}
        </Grid>
        <Grid className={classes.profileBody}>
          <Grid>
             <Grid className={classes.profileBalance}>
              <Grid className={classes.profileCQ}>
                <Typography variant="h6" className={classes.profileTitleBlock}>
                  CQ
                </Typography>
                {user?.cq}
              </Grid>
{/*               <Grid>
                <Typography variant="h6" className={classes.profileTitleBlock}>
                  Wallet
                </Typography>
                € {user?.wallet}
              </Grid> */}
            </Grid>
            <ProfileOnlinePresence user={user} />
          </Grid>
          <Grid>
            <ProfileBio title="Bio" user={user} />
          </Grid>
          <Grid>
            <ProfileChips user={user} title="Skills" dataKey="skills" />
          </Grid>
          <Grid>
            <ProfileChips
              user={user}
              title="Interests"
              dataKey="interest_areas"
            />
          </Grid>
          <Grid className={classes.profileAttachments}>
            <AttachmentChips title="Attachments" data={user?.files} />
          </Grid>
        </Grid>
        {open && user?.id === currentUser.id && (
          <Grid className={classes.profileBanner} item xs={12}>
            <span
              className={classes.profileBannerClose}
              onClick={closeBanner}
            ></span>
            <div className={classes.profileBannerTitle}>
              <span>
                Improve your chances to be hired by providing your experience.
              </span>
              Tell us about yourself
            </div>
            <div className={classes.profileBannerTip}>
              <div className={classes.profileBannerTipTitle}>
                <span>Tip 1:</span> "Making the most of experience"
              </div>
              Use assertive and positive language under the bio section, such as
              "developed", "organised" or "achieved". Try to relate the skills
              you have learned to the job role you're applying for.
            </div>
            <div className={classes.profileBannerTip}>
              <div className={classes.profileBannerTipTitle}>
                <span>Tip 2:</span> "Portfolio speaks louder than words"
              </div>
              Attach links or files that communicate your proficiency level and
              have the power to represent yourself from a positive side and make
              a strong first impression.
            </div>
            <div className={classes.profileBannerTip}>
              <div className={classes.profileBannerTipTitle}>
                <span>Tip 3:</span> "Making the most of skills"
              </div>
              Under the skills section of your profile don't forget to mention
              key skills that can help you to stand out from the crowd.
            </div>
            <div className={classes.profileBannerTip}>
              <div className={classes.profileBannerTipTitle}>
                <span>Tip 4:</span> "Making the most of interests"
              </div>
              Under interests, highlight the things that show off areas you're
              fascinated with.
            </div>
            <div className={classes.profileBannerTip}>
              <div className={classes.profileBannerTipTitle}>
                <span>Tip 5:</span> "Keep your profile updated"
              </div>
              It's crucial to review your profile on a regular basis and add any
              new skills or experience that's missing.
            </div>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.profileCardContainer}
        >
          <Grid item xs={12}>
            <ProfileCard data={creations} title="Creations" />
          </Grid>
          <Grid item xs={12}>
            <ProfileCard data={opportunities} title="Calls" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
