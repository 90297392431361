import React from "react";
import { Button } from "@material-ui/core";
import DarkTooltip from "./DarkTooltip";

import { useDispatch } from "react-redux";
import { formSubmitAndGetDataAction } from "../actions/formActions";

const FinishOpportunityButton = React.forwardRef((props, ref) => {
  const { opportunityId, statuses, winner } = props;
  const dispatch = useDispatch();
  let doneStatus = statuses.reduce((acc, cur) => {
    if (cur.code === "done") {
      acc = cur.id;
    }
    return acc;
  }, null);
  return doneStatus ? (
    <div className="statusBlockButton">
      <Button
        disabled={
          winner
            ? false
            : !ref?.current || !ref?.current?.getState()?.values?.winner
        }
        onClick={() => {
          ref?.current?.submit();
          dispatch(
            formSubmitAndGetDataAction({
              formValues: { status_id: doneStatus },
              submitType: "put",
              endpoint: `/opportunities/${opportunityId}`,
              getEndpoint: `opportunities/${opportunityId}`,
            })
          );

        }}
      >
        Finish
      </Button>
      <DarkTooltip title="Choose a winner using a radio button" />
    </div>
  ) : null;
});

export default FinishOpportunityButton;
