import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useSelector, useDispatch } from "react-redux";
import { formSubmitAction } from "../actions/formActions";
import useStyles from "../styles/Team";
import ProfileAvatar from "./ProfileAvatar";

const TeamApplication = (props) => {
  const { application } = props;

  const router = useSelector((state) => state.router);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <Accordion className={classes.paperApplication}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.applicationSummary}
      >
        <Typography className={classes.applicationTitle}>
          {application?.role?.name}
          <span>{application.requests.length} requests</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.applicationDetails}>
        {application.requests.map((a) => {
          return (
            <Grid className={classes.applicationUserContainer}>
              <Accordion key={a.id} className={classes.applicationUser}>
                <AccordionSummary
                  className={classes.applicationUserSummary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={a.id}
                  id={a.id}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.teamMemberUser}
                  >
                    <ProfileAvatar
                      imageLink={a.user.profile_image?.link}
                      user={a.user}
                    />
                    <Grid className={classes.teamMemberUserName}>
                      <Typography variant="body1">{a.user?.name}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.applicationDetailsUser}>
                  <Grid className={classes.teamMemberLetter}>
                    {a.cover_letter}
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Grid className="buttons">
                <Button
                  onClick={() => {
                    dispatch(
                      formSubmitAction({
                        formValues: null,
                        endpoint: `teams/${a.team_id}/requests/${a.id}/accept`,
                        redirect: router.location.pathname,
                      })
                    );
                  }}
                >
                  Accept
                </Button>
                <Button
                  className="invert"
                  onClick={() => {
                    dispatch(
                      formSubmitAction({
                        formValues: null,
                        endpoint: `teams/${a.team_id}/requests/${a.id}/decline`,
                        redirect: router.location.pathname,
                      })
                    );
                  }}
                >
                  Deny
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default TeamApplication;
