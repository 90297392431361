import React, { useState } from "react";
import { Grid, Button, Modal, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import coverCreator from "../assets/cover_creator.png";
import coverBusiness from "../assets/cover_business.png";
import useStyles from "../styles/Profile";
import { swiftWrapper, isCreator } from "../utils/misc";
import { formUpdateUserAction } from "../actions/formActions";
import { Form, Field } from "react-final-form";
import ImageDropzone from "../components/ImageDropzone";
import CoverImageModal from "./CoverImageModal";

const ProfileCover = (props) => {
  const { user } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const currentUser = useSelector((state) => state.userReducer.user);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ChangeCoverButton = () => {
    return (
      <div className="buttons buttonsCover">
        <Button
          variant="contained"
          color="primary"
          className="invert"
          onClick={() => handleOpen()}
        >
          Change cover
        </Button>
      </div>
    );
  };

  let coverSrc = user.cover_image?.link
    ? swiftWrapper(user.cover_image?.link)
    : isCreator(user)
      ? coverCreator
      : coverBusiness;

  return (
    <Grid
      container
      className={classes.profileHeader}
      style={{ backgroundImage: `url(${coverSrc})` }}
    >
      {isCreator(user) ? (
        <Grid>
          <Grid item className={classes.profileTitle}>
            <Typography variant="h3">Your creative type</Typography>
            <Typography variant="h1">
              {user?.creative_test_result?.name}
            </Typography>
          </Grid>
          {user?.id === currentUser.id ? <ChangeCoverButton /> : null}
        </Grid>
      ) : (
        <Grid>
          <Grid item className={classes.profileTitle}>
            <Typography variant="h1">Business account</Typography>
          </Grid>
          {user?.id === currentUser.id ? <ChangeCoverButton /> : null}
        </Grid>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <Form
            initialValues={{ cover_image: null }}
            onSubmit={async (values) => {
              values.cover_image = values.cover_image;
              dispatch(
                formUpdateUserAction({
                  submitType: "put",
                  endpoint: "/users",
                  formValues: values,
                  id: user.id,
                  redirect: "/profile",
                })
              );
              handleClose();
            }}
            render={({
              submitError,
              handleSubmit,
              reset,
              submitting,
              pristine,
              values,
            }) => {
              return (
                <form className="form-signin" onSubmit={handleSubmit}>
                  <Grid container className={classes.wizardContainer}>
                    <Grid item xs={12} className={classes.paperCover}>
                      <Field name="cover_image">
                        {(props) => {
                          return (
                            <div>
                              <span
                                className={classes.paperClose}
                                onClick={handleClose}
                              ></span>
                              <Typography
                                variant="h3"
                                className={classes.paperTitle}
                              >
                                Upload your cover&nbsp;image
                              </Typography>

                              <CoverImageModal {...props.input}
                                mainText="Drag and drop your profile cover image"
                                subText="or <b>browse</b> to choose a file 660x400 px </br>JPEG, PNG"
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} className="buttons">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={
                          submitting || pristine || !values?.cover_image
                        }
                      >
                        Save photo
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )
            }}
          />
        </div>
      </Modal>
    </Grid>
  );
};

export default ProfileCover;
