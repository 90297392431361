
import { put } from "redux-saga/effects";
import * as types from "../actions";

export function* resetDataSaga() {
    yield put({ type: types.USER_RESET_DATA});
    yield put({ type: types.NOTIFICATIONS_RESET_DATA});
    yield put({ type: types.MESSAGES_RESET_DATA});
    yield put({ type: types.FORM_RESET_DATA});
    //yield put({ type: types.WS_RESET_DATA});
}

