import React from "react";
import PropTypes from "prop-types";
import { Form, FormSpy } from "react-final-form";
import { Button } from "@material-ui/core";
import arrayMutators from "final-form-arrays";
import moment from "moment";
import { connect } from "react-redux";
import { push, goBack } from "connected-react-router";
import { getUnifiedDate } from "../utils/misc";

class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
      isEdited: Boolean(props.initialValues?.id),
      children: props.children.filter(Boolean)
    };
  }

  componentDidUpdate(prevProps, prevState) {


    if( this.props?.rerenderValue && this.props?.rerenderValue !== prevProps?.rerenderValue) {
      this.setState(state =>({...state, children: this.props.children.filter(Boolean)}))
    }

  }
  next = async (values) => {
    if (this.state.isEdited) {
      let errors = await this.validate(values);
      if (Object.keys(errors).length > 0) {
        return errors;
      } else {
        this.setState((state) => ({
          page: Math.min(state.page + 1, this.state.children.length - 1),
          values,
        }));
      }
    } else {
      return this.setState((state) => ({
        page: Math.min(state.page + 1, this.state.children.length - 1),
        values,
      }));
    }
  };

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = async (values) => {
    const activePage = React.Children.toArray(this.state.children)[
      this.state.page
    ];
    if (activePage.props.validate) {
      let validationResults = await activePage.props.validate(values);
      return validationResults;
    } else {
      return {};
    }
  };

  handleSubmit = async (values) => {
    const { onSubmit } = this.props;
    const { children, page, isEdited } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage || isEdited) {
      let errors = await this.validate(values);
      return Object.keys(errors).length > 0 ? errors : onSubmit(values);
    } else {
      let errors = await this.validate(values);
      return Object.keys(errors).length > 0 ? errors : this.next(values);
    }
  };

  render() {
    const { children, page, values, isEdited } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    const breadcrumbs = children.map((ch, i) => {
      return {
        key: i,
        page: i,
        text: ch.props.breadcrumb,
      };
    });

    return (
      <>
        <div
          className="creationSteps"
          style={{
            marginBottom: "60px",
            paddingLeft: "23px",
            paddingRight: "23px",
          }}
        >
          {breadcrumbs.map((bc) => {
            return (
              <span
                key={bc.text}
                className="selectBtn"
                style={{
                  color: bc.page === page ? "#000000" : "#BCBCBC",
                  marginRight: "30px",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontFamily: "Gilroy",
                }}
              >
                {bc.text}
              </span>
            );
          })}
        </div>
        <Form
          mutators={{
            ...arrayMutators,
          }}
          initialValues={values}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          {({
            handleSubmit,
            submitting,
            values,
            form: {
              mutators: { push, pop, move, swap },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              {activePage}
              <div className="buttons">
                {page > 0 && (
                  <Button type="button" onClick={this.previous}>
                    Previous
                  </Button>
                )}
                {!isLastPage && (
                  <FormSpy>
                    {(spyProps) => {
                      return (
                        <Button
                          onClick={() => {
                            this.state.isEdited
                              ? this.next(spyProps.values)
                              : spyProps.form.submit();
                          }}
                        //onClick={() => spyProps.form.submit()}
                        >
                          Next
                        </Button>
                      );
                    }}
                  </FormSpy>
                )}
                {(isLastPage || isEdited) && (
                  <FormSpy>
                    {(spyProps) => {
                      return (
                        <>
                          <Button
                            onClick={() => spyProps.form.submit()}
                            disabled={submitting || this.props.form.loading}
                          >
                            {spyProps.values.id
                              ? this.props.saveTitle
                                ? this.props.saveTitle
                                : "Save"
                              : "Create"}
                          </Button>
                        </>
                      );
                    }}
                  </FormSpy>
                )}
                {this.props.noCancel ? null : (
                  <Button
                    className="invert"
                    onClick={() => {
                      this.props.goBack();
                    }}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                )}
                {
                  <FormSpy>
                    {(spyProps) => {
                      return spyProps.values.id ? (
                        <div className="dateSaved">
                          {`Date saved ${getUnifiedDate(
                            spyProps.values.updated_at
                          )}`}
                        </div>
                      ) : null;
                    }}
                  </FormSpy>
                }
              </div>

              {/*               <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </form>
          )}
        </Form>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    router: state.router,
    form: state.formReducer
  };
};
const mapDispatchToProps = {
  push,
  goBack,
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
