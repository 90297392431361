import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { Field, Form, FormSpy } from "react-final-form";
import { useSelector, useDispatch } from "react-redux";
import { formSubmitAction, formGetDataAction } from "../actions/formActions";
import useStyles from "../styles/CreationOpportunity";
import TeamMember from "./TeamMember";
import clsx from "clsx";

const OpportunityTabTeamsWinner = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.formReducer.data);
  const teams = useSelector((state) => state.formReducer.customData?.teams);
  const classes = useStyles();

  return (
    <Grid>
      <Form
        initialValues={{
          winner: data?.winner_team_id?.toString(),
        }}
        //initialValues={{ winner: data?.winner_team_id?.toString()  }}
        onSubmit={(values) => {
          dispatch(
            formSubmitAction({
              formValues: values,
              submitType: "post",
              endpoint: `/opportunities/${data?.id}/teams/${values?.winner}/make-winner`,
              //redirect: `/opportunity/${data?.id}/`,
            })
          );
        }}
        render={({
          form,
          submitError,
          handleSubmit,
          reset,
          submitting,
          pristine,
          values,
        }) => {
          ref.current = form;
          return (
            <FormSpy
              subscription={{
                values: "winner",
              }}
            >
              {(spyProps) => {
                return (
                  <form
                    className="form-signup"
                    onSubmit={handleSubmit}
                    id="winnerForm"
                  >
                    {teams?.filter(t => t?.status?.code !== "rejected").map((team) => {
                      return (
                        <Accordion className={classes.itemTeam} key={team.id}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid container className={classes.itemTeamHead}>
                              <Grid className={classes.itemTeamHeadInfo}>
                                <div
                                  className={clsx(
                                    "default-profile-avatar-container",
                                    `profile-gradient-${team?.id % 15}`
                                  )}
                                ></div>
                                <Grid className={classes.itemTeamTitleBlock}>
                                  <FormControlLabel
                                    aria-label="Apply"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    className={classes.itemTeamTitle}
                                    control={
                                      <Link
                                        to={`/calls/${data?.id}/teams/${team.id}`}
                                      >
                                        {team.name}
                                      </Link>
                                    }
                                  />
                                  <Grid className={classes.itemTeamStatus}>
                                    Team status
                                    <span>{team.status?.name}</span>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item className={classes.itemTeamButtons}>
                                <FormControlLabel
                                  aria-label="Apply"
                                  onClick={(event) => event.stopPropagation()}
                                  onFocus={(event) => event.stopPropagation()}
                                  control={
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Field
                                          name="winner"
                                          type="radio"
                                          value={team.id.toString()}
                                        >
                                          {({ input }) => {
                                            return (
                                              <Grid
                                                className={clsx(
                                                  classes.itemTeamWinner,
                                                  input.checked
                                                    ? "winner"
                                                    : "karma",
                                                  data?.status?.code === ""
                                                    ? "disabled"
                                                    : ""
                                                )}
                                              >
                                                <input
                                                  disabled={
                                                    data?.status?.code ===
                                                    "done" || data?.winner_team_id
                                                  }
                                                  name={input.name}
                                                  type="radio"
                                                  value={team.id.toString()}
                                                  checked={input.checked}
                                                  onChange={(e) => {
                                                    input.onChange(e);
                                                    props.setWinner(
                                                      (winner) => e.target.value
                                                    );
                                                  }}
                                                  //onChange={input.onChange}
                                                />
                                                <Typography>
                                                  {input.checked
                                                    ? "Winner"
                                                    : "Karma"}
                                                </Typography>
                                              </Grid>
                                            );
                                          }}
                                        </Field>
                                      </Grid>
                                    </Grid>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid className={classes.itemTeamContent}>
                              {team.pending?.map((m) => (
                                <Grid item xs={12} key={m.id}>
                                  <TeamMember
                                    pending={true}
                                    member={m}
                                    opportunity={data?.id}
                                    teamId={team.id}
                                  />
                                </Grid>
                              ))}
                              {team.members?.map((m) => (
                                <Grid item xs={12} key={m.id}>
                                  <TeamMember
                                    member={m}
                                    opportunity={data?.id}
                                    teamId={team.id}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </form>
                );
              }}
            </FormSpy>
          );
        }}
      />
    </Grid>
  );
});

export default OpportunityTabTeamsWinner;
