import { combineReducers } from "redux";
import register from "./registerReducer";
import login from "./loginReducer";
import userReducer from "./userReducer";
import formReducer from "./formReducer";
import errorReducer from "./errorReducer";
import successReducer from "./successReducer";
import wsReducer from "./wsReducer";
import chatReducer from "./chatReducer";
import notificationsReducer from "./notificationsReducer";
import { connectRouter } from "connected-react-router";

const rootReducer = (history) =>
  combineReducers({
    register,
    login,
    userReducer,
    errorReducer,
    successReducer,
    router: connectRouter(history),
    formReducer,
    wsReducer,
    chatReducer,
    notificationsReducer,
  });

export default rootReducer;
