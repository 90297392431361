import React, { useEffect } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import useStyles from "../views/global/header/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "../assets/search_filter.svg";
import CloseIcon from "../assets/close.svg";
import HomeSearchFilterSingle from "./HomeSearchFilterSingle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSelector, useDispatch } from "react-redux";

import { formGetReferenceTableValuesAction } from "../actions/formActions";

const DiscoverTeamFilter = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      formGetReferenceTableValuesAction({
        endpoint: "classifiers-opportunity-roles",
      })
    );
  }, [dispatch]);

  const references = useSelector((state) => state.formReducer.references);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = () => {
    setAnchorEl(document.getElementById("toggleFilterContainer"));
  };

  const filters = [
    {
      defaultLabel: "Creative fields",
      label: props.creativeFieldLabel,
      setLabel: props.setCreativeFieldLabel,
      value: props.creativeField,
      setValue: props.setCreativeField,
      choices: [
        { value: "all", label: "Select all" },
        { value: "architecture", label: "Architecture" },
        { value: "art_direction", label: "Art Direction" },
        { value: "branding", label: "Branding" },
        { value: "fashion", label: "Fashion" },
        { value: "graphic_design", label: "Graphic Design" },
        { value: "illustration", label: "Illustration" },
        { value: "industrial_design", label: "Industrial Design" },
        { value: "interaction_design", label: "Interaction Design" },
        { value: "motion_graphics", label: "Motion Graphics" },
        { value: "photography", label: "Photography" },
        { value: "u_i/_u_x", label: "UI/UX" },
        { value: "web_design", label: "Web Design" },
      ],
    },
    {
      defaultLabel: "Roles",
      label: props.roleLabel,
      setLabel: props.setRoleLabel,
      value: props.role,
      setValue: props.setRole,
      choices: references?.["classifiers-opportunity-roles"]
        ? [
            { value: "all", label: "Select all" },
            ...references?.["classifiers-opportunity-roles"]?.map((r) => ({
              value: r.code,
              label: r.name,
            })),
          ]
        : [{ value: "all", label: "Select all" }],
    },
  ];

  return (
    <div className={classes.discoverTeamsFilter}>
      <div className={classes.sectionDesktop}>
        {filters.map((f) => (
          <HomeSearchFilterSingle
            defaultLabel={f.defaultLabel}
            label={f.label}
            setLabel={f.setLabel}
            value={f.value}
            setValue={f.setValue}
            choices={f.choices}
          />
        ))}
      </div>
      <div className={classes.sectionMobile}>
        <IconButton
          edge="end"
          aria-label="filter"
          aria-controls="homeFilterMobile"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="secondary"
          className="filter-button"
        >
          {isMenuOpen ? (
            <img src={CloseIcon} alt="Close" />
          ) : (
            <img src={FilterListIcon} alt="Filter" />
          )}
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          id="homeFilterMobile"
          keepMounted
          //transformOrigin={{ vertical: "top", horizontal: "right" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
          className={classes.sectionMobileFilter}
        >
          <MenuItem style={{ display: "flex", flexDirection: "column" }}>
            {filters.map((f) => (
              <Accordion className={classes.sectionMobileAccordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{f.label}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <HomeSearchFilterSingle
                    label={f.label}
                    setLabel={f.setLabel}
                    value={f.value}
                    setValue={f.setValue}
                    choices={f.choices}
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default DiscoverTeamFilter;
