import { makeStyles } from "@material-ui/core/styles";
import * as variables from "../../../styles/variables"

const useStyles = makeStyles((theme) => ({
    loginFooter: {
        background: "#000000",
        minHeight: 58,
        alignItems: "center",
        paddingLeft: variables.gutterSm,
        paddingRight: variables.gutterSm,
        color: "#fff",
        paddingBottom: 15,
        paddingTop: 28,
        [theme.breakpoints.up("md")]: {
            maxHeight: 58,
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: variables.gutterBg,
            paddingRight: variables.gutterBg,
            zIndex: 1,
        }
    },
    noGrow: {
        flexGrow: 0
    },
    footerNavItem: {
        color: "#fff",
        fontSize: 14,
        lineHeight: '18px',
        fontFamily: "Gilroy",
        "&:hover": {
            opacity: 0.6,
            textDecoration: "none",
        },
        [theme.breakpoints.up("md")]: {
            marginBottom: 0,
            marginLeft: 15,
            marginRight: 15,
        }
    },
    footerNav: {
        display: "grid",
        padding: "27px 0 75px",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: 22,
        [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "repeat(5, 1fr)",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            display: "flex",
            gridGap: 0,
            padding: 0,
        }
    },
    [theme.breakpoints.up("md")]: {
        noWrap: {
            flexWrap: "nowrap"
        }
    },
    copyright: {
        fontSize: 14,
        lineHeight: "18px",
        fontFamily: "Gilroy",
        color: "#BCBCBC",
        "& a": {
            color: "#BCBCBC",
            fontSize: 14,
            lineHeight: "18px",
        },
        "& a:visited": {
            color: "#BCBCBC"
        },
        [theme.breakpoints.up("md")]: {
            paddingTop: 0,
        },
        MuiLink: {
            root: {
                color: "#fff"
            }
        }
    }
}));

export default useStyles;