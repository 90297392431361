import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    creationMain: {
        paddingTop: 83,
        background: "#E5E5E5",
        [theme.breakpoints.up("md")]: {
            paddingBottom: 132,
            paddingTop: 110,
            minHeight: "calc(100vh - 108px)",
            boxSizing: "border-box",
        },
    },
    teamMain: {
        background: "#fff"
    },
    creationContainer: {
        "& .buttons": {
            padding: "100px 0 66px 23px",
            justifyContent: "start",
            flexWrap: "wrap",
            [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
                padding: "57px 0 41px 23px",
            }
        },
        "& .buttons button": {
            margin: 0,
            marginRight: 20,
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
            }
        },
        "& .buttons .dateSaved": {
            whiteSpace: "nowrap",
            [theme.breakpoints.down("sm")]: {
                marginBottom: 16,
            }
        },
        "& .creationSteps": {
            whiteSpace: "nowrap",
            overflowX: "auto",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: 910,
            margin: "0 auto",
            "& .selectBtn:first-child": {
                marginLeft: "-23px",
            },
            "& .selectBtn": {
                fontWeight: 500
            },
            "& .buttons": {
                padding: "70px 0 0 0",
            },
        },
    },
    creationHeader: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        marginBottom: "30px",
        lineHeight: "35px",
        fontWeight: "normal",
        paddingLeft: 23,
        paddingRight: 23,
        [theme.breakpoints.up("md")]: {
            fontSize: "44px",
            fontWeight: 500,
            lineHeight: "54px",
            padding: 0,
        }
    },
    teamHeaderOpportunity: {
        fontFamily: "Gilroy",
        fontSize: "32px",
        lineHeight: "35px",
        fontWeight: "normal",
        color: "#BCBCBC",
        padding: "0 23px 6px",
        [theme.breakpoints.up("md")]: {
            fontSize: "44px",
            fontWeight: 500,
            lineHeight: "54px",
            padding: "0 0 1px",
        },
    },
    creationBreadcrumb: {
        fontFamily: "Gilroy",
        fontSize: "16px",
        lineHeight: "24px",
        marginRight: "30px"
    },
    creationBodyText: {
        paddingBottom: 197,
        "& .Mui-error": {
            margin: "4px 0 0 10px",
            fontFamily: "Gilroy",
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: 500,
            color: "#f44336",
        },
        [theme.breakpoints.up("md")]: {
            paddingBottom: 257,
        },
    },
    wizardContainer: {
        background: "#fff",
        paddingLeft: 23,
        paddingRight: 23,
        paddingTop: 50,
        boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.04)",
        "& .codex-editor__redactor": {
            paddingBottom: "0!important",
        },
        "& .ce-toolbar__actions": {
            right: "-16px",
        },
        "& .ce-toolbar__plus": {
            left: "-26px",
        },
        "& .ce-block__content": {
            marginRight: 0,
            marginLeft: 0,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            "& .ce-paragraph:before": {
                fontWeight: 500,
            }
        },
        "& .ce-block": {
            border: "1px solid #fff",
            padding: "0 9px",
        },
        "& .ce-block--focused": {
            border: "1px solid rgb(98,51,192,0.2)",
        },
        "& .ce-paragraph": {
            lineHeight: "24px",
            padding: 0,
            fontWeight: 500,
        },
        "& .ce-header": {
            margin: 0,
            padding: 0,
            fontSize: "16px",
            lineHeight: "24px",
        },
        "& .MuiFormControl-root": {
            marginBottom: 30,
        },
        "& .errorStages": {
            color: "#F21B0F",
            fontFamily: "Gilroy",
            fontSize: "12px",
            lineHeight: "16px",
            margin: "-51px 0 27px",
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 107,
            paddingRight: 107,
            paddingTop: 90,
        },
    },
    addItemBtnContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 40,
        zIndex: 1,
    },
    addItemBtn: {
        fontFamily: "Gilroy",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 500,
        textTransform: "none",
        marginRight: "45px",
        padding: "0 0 0 27px",
        position: "relative",
        "&:before,&:after": {
            content: "''",
            border: "1px solid #000000",
            width: 14,
            position: "absolute",
            left: 0,
        },
        "&:after": {
            transform: "rotate(90deg)"
        },
        "&:last-child": {
            marginRight: 0
        }
    },
    creationTitleLabel: {
        color: "#BCBCBC",
        fontFamily: "Gilroy",
        fontSize: "26px",
        lineHeight: "34px",
    },
    creationTitleField: {
        color: "#636363!important",
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: 500,
        marginTop: 20,
        marginBottom: 12,
    },
    creationRadioField: {
        "& .MuiTypography-body1": {
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
        },
        "& .MuiRadio-root":{
            padding: "6px 12px 6px 9px",
            background: "none",
        },
        "& .MuiSvgIcon-root": {
            fontSize: 16,
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
            background: "none",
            color: "#6233C0",
        },
        "& .MuiFormControlLabel-label": {
            display: "flex",
            alignItems: "center",
        },
        "& .MuiRadio-colorSecondary.Mui-checked + .MuiFormControlLabel-label": {
            background: "none",
            color: "#6233C0",
        },
        "& .MuiFormControlLabel-label .MuiSvgIcon-root": {
            margin: "0 0 0 10px",
            color: "#000"
        },
    },
    creationPriceField: {
        "& .MuiInputBase-input": {
            padding: "14px 16px 12px",
            zIndex: 2,
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiFormControl-root":{
            minWidth: "100%",
        },
        "& .MuiFormLabel-root": {
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            left: 16,
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #F5F5F5",
            height: "45px",
            background: "#F5F5F5",
        },
        "& .MuiInput-underline::after": {
            borderBottom: 0,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: 0,
        },
        [theme.breakpoints.up("md")]: {
            paddingLeft: 20,
        }
    },
    creationTitle: {
        color: "#000",
        fontFamily: "Gilroy",
        fontSize: "26px",
        lineHeight: "34px",
        "&>input": {
            padding: "0 9px",
            height: 34,
            border: "1px solid #fff"
        },
        "&>input::selection": {
            background: "#fff"
        },
        "&>input:focus": {
            border: "1px solid rgb(98,51,192,0.2)"
        },
        "&:after, &:before": {
            display: "none",
        },
        "& + .Mui-error": {
            paddingLeft: 11,
            marginTop: 29,
            fontFamily: "Gilroy",
            fontSize: "12px",
            lineHeight: "12px",
            fontWeight: 500,
            color: "#f44336",
        },
        [theme.breakpoints.up("md")]: {
            fontWeight: 500,
        },
    },
    dropzoneBtn: {
        marginBottom: 60,
        position: "relative",
        "& .btn-dropzone": {
            paddingTop: 61,
            paddingLeft: 56,
            paddingRight: 56,
            paddingBottom: 43,
            borderStyle: "dashed",
            borderColor: "#636363",
            borderWidth: 1,
        },
        [theme.breakpoints.up("md")]: {
            marginBottom: 90,
        },
    },
    dropzoneError: {
        fontFamily: "Gilroy",
        fontSize: "12px",
        lineHeight: "16px",
        color: "#F21B0F",
        paddingTop: 25,
        textAlign: "left"
    },
    dropzoneBtnMainText: {
        fontFamily: "Gilroy",
        fontSize: "18px",
        lineHeight: "24px",
        textAlign: "center",
        display: "block",
        marginBottom: 12
    },
    dropzoneBtnSubText: {
        fontFamily: "Gilroy",
        fontSize: "14px",
        lineHeight: "18px",
        textAlign: "center",
        display: "block"
    },
    dropzoneAside: {
        justifyContent: "center",
        "&>div": {
            width: "100%"
        }
    },
    dropzoneFile: {
        paddingTop: 11,
        paddingLeft: 56,
        paddingRight: 56,
        paddingBottom: 13,
        borderStyle: "dashed",
        borderColor: "#636363",
        borderWidth: 1,
        textAlign: "center"
    },
    creationInputFieldContainer: {
        paddingLeft: 43,
        paddingRight: 43,
    },
    CreationSelectBlock: {
        position: "relative",
        "& .MuiAutocomplete-inputRoot": {
            paddingTop: 8,
            paddingBottom: 6
        },
        "& .MuiFormControl-root":{
            minWidth: "auto",
        },
        "& .MuiInput-underline::before": {
            border: "1px solid #BCBCBC",
            height: "100%"
        },
        "& .MuiInput-underline::after": {
            borderBottom: 0,
            height: "100%",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "1px solid #BCBCBC",
        },
        "& .MuiInputLabel-root": {
            left: 16,
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
            color: "#000",
            zIndex: 2,
            background: "#fff",
        },
        "& .MuiIconButton-label": {
            color: "#000",
        },
        "& .MuiAutocomplete-endAdornment": {
            right: 15,
        },
        "& .MuiInputBase-input": {
            padding: "14px 16px 12px",
            background: "#fff",
            fontFamily: "Gilroy",
            fontWeight: 500,
        },
        "& .MuiSelect-icon": {
            color: "#000",
            right: 15,
        },
        "& .MuiChip-root": {
            background: "#6233C0",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            borderRadius: 4,
            margin: "0 0 3px 10px",
            color: "#fff",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
            height: 28
        },
        "& .MuiChip-label": {
            paddingLeft: 10,
            paddingRight: 13
        },
        "& .MuiChip-deleteIcon": {
            color: "#fff"
        },

        "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input:first-child": {
            paddingLeft: 16,
            paddingRight: 16,
        }
    },
    teamBoardSelect: {
        marginTop: "-14px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "0!important",
        },
    },
    teamBoardComment: {
        width: "100%",
        "& .MuiTypography-body2": {
            marginTop: 0,
        }
    },
    teamCommentForm: {
        overflow: "hidden",
        boxShadow: "0 0 8px rgba(37, 40, 43, 0.12)",
        borderRadius: 8,
        marginBottom: 35,
    },
    teamCommentFormContainer: {
        height: 390,
        overflowY: "auto",
    },
    teamBoardReporter: {
        [theme.breakpoints.down("sm")]: {
            marginBottom: 40,
        },
    },
    teamBoardReporterTitle: {
        fontFamily: "Gilroy",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "24px",
        paddingBottom: 9,
        marginTop: "-15px",
    },
    teamBoardSelectStage: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: 20,
        },
    },
    teamFormContainer: {
        "& .buttons": {
            padding: "30px 0 66px 23px",
            justifyContent: "start",
        },
        "& .buttons button": {
            margin: 0,
            marginRight: 30,
        },
        [theme.breakpoints.down("sm")]: {
            "& .buttons": {
                flexWrap: "wrap",
            },
            "& .buttons button, & .buttons .dateSaved": {
                marginBottom: 10,
            },
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: 678,
            margin: "0 auto",
            "& .selectBtn:first-child": {
                marginLeft: "-23px",
            },
            "& .selectBtn": {
                fontWeight: 500
            },
            "& .buttons": {
                padding: "30px 0 0 0",
            },
        },
    },
    teamTextField: {
        minWidth: "100%",
        "& .MuiOutlinedInput-root": {
            background: "#F5F5F5",
            padding: "14px 16px",
            minHeight: 100,
            alignItems: "normal"
        },
        "& textarea": {
            fontSize: 16,
            lineHeight: "24px",
        },
        "& .MuiFormLabel-root": {
            fontSize: 16,
            lineHeight: "24px",
            color: "#000"
        },
        "& .MuiFormLabel-root:not(.Mui-focused)": {
            top: "-10px"
        },
        "& .MuiFilledInput-root, & .MuiInput-root": {
            background: "#F5F5F5",
            padding: "13px 16px 10px",
            borderRadius: 0,
            marginTop: 0,
        },
        "& .MuiFilledInput-root:before, .MuiFilledInput-root:after, & .MuiInput-root:after, & .MuiInput-root:before": {
            display: "none",
        },
        "& .MuiFilledInput-input, & .MuiInput-input": {
            fontFamily: "Gilroy",
            fontSize: "16px",
            lineHeight: "24px",
            height: 24,
            padding: 0,
        },
        "& .MuiInputLabel-filled.MuiInputLabel-shrink, & .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiInputLabel-formControl.MuiInputLabel-shrink": {
            transform: "translate(14px, -10px) scale(0.75)",
            top: "-3px",
        },
        "& .MuiInputLabel-formControl": {
            zIndex: 1,
            transform: "translate(12px, 20px) scale(1)",
            pointerEvents: "none",
        }
    },
    teamOpportunity: {
        marginTop: "-14px",
    },
    teamRolesContainer: {
      marginTop: "-20px",
        position: "relative",
    },
    teamRoleTitle: {
        fontFamily: "Gilroy",
        fontSize: "26px",
        lineHeight: "34px",
        fontWeight: 500,
        padding: "32px 0 39px",
    },
    titleRoles: {
        display: "flex",
        "& .MuiTypography-body2": {
            margin: 0,
        },
        "& .MuiSvgIcon-root": {
            marginLeft: 10,
            width: 16,
            height: 16,
        }
    },
    teamSettings: {
        marginTop: "-15px",
    },
    teamOpportunityRoles: {
        paddingTop: 2,
    },
    teamItemOpportunityRoles: {
        marginTop: "-10px",
    },
    teamUserRoles: {
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "-4px",
        "& *": {
            fontFamily: "Gilroy",
            fontSize: "14px",
            fontWeight: 500,
        }
    },
    wizardTeamContainer: {
        [theme.breakpoints.down("sm")]: {
            padding: "0 23px",
        }
    },
    attachments: {
        padding: "30px 0 40px",
        fontSize: 14,
        lineHeight: "18px",
        color: "#000",
        "& ul": {
            margin: 0,
            padding: 0,
            listStyle: "none",
        },
        "& li": {
          paddingBottom: 10,
        },
        "& li:last-child": {
            paddingBottom: 0,
        }
    },
    attachFileBlock: {
        padding: 0,
        margin: 0,
    },
    attachFileItem: {
        position: "relative",
        textDecoration: "none",
        paddingLeft: 24,
        display: "flex",
        alignItems: "center",
        "& img": {
            position: "absolute",
            left: 0,
            top: 2,
        },
    },
    deleteAttachFile: {
        padding: "3px 0 0 10px",
        color: "#BCBCBC",
        textDecoration: "underline",
        "&:hover": {
            background: "none",
            color: "#000",
        },
        "& .MuiIconButton-label": {
            fontSize: "10px",
            lineHeight: "12px",
        }
    },
    attachmentErrorItem: {
        color: "#F21B0F",
        fontSize: 12,
        lineHeight: "16px",
    },
    attachmentError: {
        color: "#F21B0F",
        padding: "0 0 0 10px",
        fontSize: 12,
        lineHeight: "16px",
    },
    thumbInner: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: "#fff",
        "& img": {
            height: "100%",
            left: 0,
            position: "absolute",
            top: 0,
            width: "100%",
            objectFit: "contain",
        }
    },
    thumbCancel: {
        position: "absolute",
        right: "11px",
        borderRadius: 0,
        background: "#fff",
        "&:hover": {
            background: "#fff",
        },
        [theme.breakpoints.up("md")]: {
            right: "-22px",
        }
    },
    creationStages: {
        "&:not(:empty)": {
            paddingBottom: 40,
        }
    },
    creationItemStage: {
        border: "1px dashed #BCBCBC",
        marginBottom: 55,
        padding: 24,
        position: "relative",
    },
    creationTitleStage: {
        fontFamily: "Gilroy",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 500,
        paddingBottom: 45,
        [theme.breakpoints.down("sm")]: {
            paddingBottom: 7,
        }
    },
    buttonAddStage: {
        color: "#636363",
        fontFamily: "Gilroy",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 500,
        textTransform: "none",
        padding: "0 0 0 27px",
        position: "relative",
        marginBottom: 90,
        "&:before,&:after": {
            content: "''",
            border: "1px solid #636363",
            width: 14,
            position: "absolute",
            left: 0,
        },
        "&:after": {
            transform: "rotate(90deg)"
        },
        "&:hover": {
            color: "#000",
            background: "none",
        },
        "&:hover:before, &:hover:after": {
            border: "1px solid #000",
        },
    },
    creationRemoveStage: {
        position: "absolute",
        top: "-4px",
        right: "-30px",
        padding: 0,
        minWidth: 20,
        "&:hover": {
            background: "none",
        },
        [theme.breakpoints.down("sm")]: {
            top: "-30px",
            right: "0",
        }
    },
    creationPosStage: {
        width: 48,
        "& button": {
            display: "block",
            padding: 0,
            minWidth: 48,
            height: 48,
            margin: "15px 0",
        },
        "& button:hover": {
            background: "none",
        },
        "& button:hover img": {
            filter: "brightness(0)",
        },
        [theme.breakpoints.down("sm")]: {
            margin: "0 auto",
        },
        [theme.breakpoints.up("md")]: {
            top: "50%",
            right: "-70px",
            position: "absolute",
            transform: "translate(0, -50%)",
        }
    },
    addRoleBtn: {
        color: "#000",
        fontFamily: "Gilroy",
        fontSize: "18px",
        lineHeight: "24px",
        fontWeight: 500,
        textTransform: "none",
        padding: "0 0 0 32px",
        position: "relative",
        margin: "25px 0 40px",
        "&:before,&:after": {
            content: "''",
            border: "1px solid #000",
            width: 14,
            position: "absolute",
            left: 0,
        },
        "&:after": {
            transform: "rotate(90deg)"
        },
        "&:hover": {
            color: "#000",
            background: "none",
        },
        "&:hover:before, &:hover:after": {
            border: "1px solid #000",
        },
    },
    btnRemoveRole: {
        position: "absolute",
        top: 40,
        right: 0,
        padding: 0,
        minWidth: 20,
    },
    teamBoardDropzone: {
        margin: "-20px 0 0"
    },
    creationTooltip: {
        marginLeft: 10,
        width: 18,
        height: 18,
    },
    dateTooltip: {
        position: "absolute",
        top: "-18px",
        right: 0,
        [theme.breakpoints.up("md")]: {
            top: 27,
            right: "-34px",
        }
    },
    teamUserSelect: {
       margin: "0 -19px",
    },
    teamUserSelectOption: {
        padding: "5px",
        "& li": {
            padding: "0!important",
            height: "100%",
        }
    },
    teamItemUser: {
        color: "#fff",
        width: "100%",
        padding: "15px 10px",
        background: "#000",
        height: "100%",
        boxSizing: "border-box",
        "&:hover": {
            background: "#6233C0",
        },
        "& .MuiTypography-body1": {
            fontFamily: "Gilroy",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
            paddingRight: 30,
        },
        "& .MuiGrid-root a": {
            color: "#fff!important",
            fontFamily: "Gilroy",
            fontSize: "10px",
            lineHeight: "16px",
            fontWeight: 500,
            position: "absolute",
            right: 0,
            top: 7,
            width: 22,
            padding: 0,
        }
    },
    taskHistory: {
        "& .MuiTypography-body2": {
            marginTop: 0,
        }
    },
    containerTaskHistory: {
        width: "100%",
        boxShadow: "none",
        paddingBottom: 20,
        "&.Mui-expanded": {
            margin: 0,
        },
        "&:before": {
            display: "none",
        },
        "& .MuiAccordionSummary-root": {
            padding: 0,
            alignItems: "end"
        },
        "& .MuiAccordionDetails-root": {
            padding: 0,
            flexWrap: "wrap",
        },
        "& .MuiAccordionSummary-content": {
            flexWrap: "wrap",
            margin: 0,
        },
        "& .MuiAccordionSummary-expandIcon": {
            marginBottom: 11,
        },
        "& .MuiButtonBase-root svg": {
            fill: "#000",
        },
        "& .itemHistoryTask": {
            marginBottom: 15,
            display: "flex",
        },
        "& .itemHistoryTask .default-profile-avatar-container": {
            width: 40,
            height: 40,
        },
        "& .itemHistoryTask .default-profile-avatar-container p": {
            fontSize: 20,
            lineHeight: "24px",
        },
        "& .itemHistoryTask img": {
            height: 40,
            minWidth: 40,
        },
        "& .infoHistoryTask": {
            marginLeft: 16,
            fontFamily: "Gilroy",
            fontSize: 18,
            lineHeight: "22px",
            fontWeight: 500,
            color: "#BCBCBC",
            "& span": {
                color: "#000",
                paddingRight: 4,
            }
        },
        "& .dateHistoryTask": {
            paddingTop: 5,
            fontFamily: "Gilroy",
            fontSize: 10,
            lineHeight: "12px",
            fontWeight: 500,
            color: "#BCBCBC",
        }
    }
}));

export default useStyles;